import { Suspense, lazy, type ComponentPropsWithoutRef } from "react";

const DeploymentCreateFormInner = lazy(() => import("./component"));

export default function DeploymentCreateForm(
  props: ComponentPropsWithoutRef<typeof DeploymentCreateFormInner>
) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DeploymentCreateFormInner {...props} />
    </Suspense>
  );
}
