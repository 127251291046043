import { addHours, subHours } from "date-fns";
import {
  type ListResponseEndpointOut,
  type ListResponseMessageAttemptOut,
  type ListResponseMessageOut,
  type MessageAttemptOut,
  MessageStatus,
  type StatisticsPeriod,
} from "svix";
import type { Prediction } from "../../types";

export interface RecentActivityResponse {
  data: {
    failureCount: number[];
    successCount: number[];
  };
  endDate: string;
  period: StatisticsPeriod; // defaults to "FiveMinutes"
  startDate: string;
}

export enum ActivityDateRange {
  OneHour = 1,
  SixHours = 6,
  TwelveHours = 12,
  TwentyFourHours = 24,
}

export async function fetchRecentActivity({
  token,
  appId,
  range,
}: { token: string; appId: string; range: ActivityDateRange }) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  const endDate = new Date();
  const startDate = subHours(endDate, range);

  const params = new URLSearchParams({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  const statsRes = await fetch(
    `https://api.us.svix.com/api/v1/stats/app/${appId}/attempt/?${params.toString()}`,
    {
      headers,
    }
  );
  const stats = await statsRes.json<RecentActivityResponse>();
  return stats;
}

export async function fetchDefaultEndpoint({
  token,
  appId,
}: { token: string; appId: string }) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  const endpointRes = await fetch(
    `https://api.us.svix.com/api/v1/app/${appId}/endpoint`,
    {
      headers,
    }
  );

  // We can assume that there is one and only one endpoint.
  const endpoints = await endpointRes.json<ListResponseEndpointOut>();
  return endpoints.data?.[0];
}

export async function fetchEndpointFailedAttempts({
  token,
  appId,
  before,
  after,
}: { token: string; appId: string; before: string; after: string }) {
  const defaultEndpoint = await fetchDefaultEndpoint({ token, appId });

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  const baseUrl = `https://api.us.svix.com/api/v1/app/${appId}/attempt/endpoint/${defaultEndpoint?.id}/`;
  const params = new URLSearchParams({
    before,
    after,
    status: String(MessageStatus.Fail),
  });
  const attemptsRes = await fetch(`${baseUrl}?${params}`, {
    headers,
  });

  const attempts = await attemptsRes.json<ListResponseMessageAttemptOut>();
  return attempts;
}

export interface FetchPredictionWebhooksInfoParams {
  prediction: Prediction;
  appId: string;
  token: string;
}

async function fetchMessagesForPrediction(
  params: FetchPredictionWebhooksInfoParams
) {
  const { id, created_at } = params.prediction;

  const baseUrl = `https://api.us.svix.com/api/v1/app/${params.appId}/msg`;
  const queryParams = new URLSearchParams({
    tag: `prediction_${id}`,
    before: addHours(new Date(created_at), 24).toISOString(),
    after: new Date(created_at).toISOString(),
  });
  const url = `${baseUrl}?${queryParams}`;
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${params.token}`,
      Accept: "application/json",
    },
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch messages for ${id}`);
  }

  return res.json<ListResponseMessageOut>();
}

async function getAttemptsForMessage(
  params: FetchPredictionWebhooksInfoParams,
  messageId: string
) {
  const url = `https://api.us.svix.com/api/v1/app/${params.appId}/attempt/msg/${messageId}`;
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${params.token}`,
      Accept: "application/json",
    },
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch message attempts for ${messageId}`);
  }

  return res.json<ListResponseMessageAttemptOut>();
}

export async function fetchPredictionWebhooksInfo(
  params: FetchPredictionWebhooksInfoParams
) {
  const messageResponse = await fetchMessagesForPrediction(params);

  let attempts: MessageAttemptOut[] = [];

  for (const message of messageResponse.data) {
    const messageAttemptsResponse = await getAttemptsForMessage(
      params,
      message.id
    );
    attempts = attempts.concat(messageAttemptsResponse.data);
  }

  return {
    attempts,
    messages: messageResponse.data,
  };
}
