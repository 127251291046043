import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";

export default function TextTooltip({
  anchorText,
  tooltipText,
  tooltipClass = "",
  anchorClass = "",
  placement = "top",
}: {
  anchorText: string;
  tooltipText: React.ReactNode;
  tooltipClass?: string;
  anchorClass?: string;
  placement?: "top" | "bottom" | "left" | "right";
}) {
  return (
    <TooltipProvider placement={placement} showTimeout={0}>
      <TooltipAnchor
        className={`underline decoration-dotted cursor-help ${anchorClass}`}
      >
        {anchorText}
      </TooltipAnchor>
      <Tooltip className={`max-w-48 text-r8-xs z-50 ${tooltipClass}`}>
        <TooltipArrow />
        {tooltipText}
      </Tooltip>
    </TooltipProvider>
  );
}
