import { Suspense, lazy } from "react";
import type { Account } from "../types";
import { Avatar } from "./avatar";
const LazyBgGoo = lazy(() => import("./api-playground/bg-goo"));

export default function ProfilePageAvatar({
  account,
  avatar_url,
  hasHobbyPlan,
}: { account: Account; avatar_url: string | null; hasHobbyPlan: boolean }) {
  if (account.kind === "organization") {
    return (
      <div className="w-36 h-36 md:w-52 md:h-52 bg-transparent overflow-hidden relative">
        <Avatar
          avatar={{
            kind: account.kind,
            url: avatar_url,
          }}
        />
      </div>
    );
  }

  return (
    <div className="relative inline-flex rounded-full">
      {hasHobbyPlan ? (
        <div
          aria-hidden="true"
          className="absolute inset-0 w-full h-full scale-90 z-[-2] pointer-events-none bg-gradient-to-br from-branding-red via-branding-pinkDarker to-branding-blushDarker blur-lg rounded-full"
        />
      ) : null}
      <div className="w-36 h-36 md:w-52 md:h-52 bg-transparent rounded-full overflow-hidden relative p-2">
        <Avatar
          className={hasHobbyPlan ? "ring-white ring-4" : ""}
          avatar={{
            kind: account.kind,
            url: avatar_url,
          }}
        />
        {hasHobbyPlan ? (
          <div
            aria-hidden="true"
            className="absolute inset-0 w-full h-full z-[-1] pointer-events-none"
          >
            <Suspense>
              <LazyBgGoo speed={0.5} resolution={2.0} depth={4} />
            </Suspense>
          </div>
        ) : null}
      </div>
    </div>
  );
}
