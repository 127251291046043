import { CurlIcon } from "./curl";
import { DockerIcon } from "./docker";
import { NodeJSIcon } from "./nodejs";
import { PythonIcon } from "./python";

const languageIcons = {
  CurlIcon,
  DockerIcon,
  NodeJSIcon,
  PythonIcon,
};

export default languageIcons;
