import type { Token } from "../../types/tokens";

export function TokenList({
  tokens,
}: {
  tokens: Token[];
}) {
  const COLORS = [
    "bg-r8-blue-3 border-r8-blue-6",
    "bg-r8-red-3 border-r8-red-6",
    "bg-r8-yellow-3 border-r8-yellow-6",
    "bg-r8-green-3 border-r8-green-6",
  ];

  const colorFor = (index: number) => {
    return COLORS[index % COLORS.length];
  };

  return (
    <>
      {(tokens || []).map(([id, token], index) => (
        <span
          key={`input-token-${index}`}
          title={`${id}`}
          className={`border-b ${colorFor(index)}`}
        >
          {token}
          {id === 13 ? <br /> : ""}
        </span>
      ))}
    </>
  );
}
