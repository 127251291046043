import type { ReactNode } from "react";
import { P, match } from "ts-pattern";
import { formatCurrency, formatNumber } from "../intl_helpers";
import type { OfficialModel } from "../types";
import { humanizeTokenCount } from "./api-playground/util";

export default function OfficialModelPricing({
  billingUnit,
  officialModel,
}: {
  billingUnit: Exclude<OfficialModel["billing_unit"], null>;
  officialModel: OfficialModel;
}): ReactNode {
  const units = match(billingUnit)
    .with("1m-tokens", () => (
      <>
        <abbr title="one million" className="no-underline">
          1M
        </abbr>{" "}
        tokens
      </>
    ))
    .with("image", () => "image")
    .otherwise((unit) => {
      unit satisfies never;

      return "unit";
    });

  const pluralUnit = match(billingUnit)
    .with("1m-tokens", () => "tokens")
    .with("image", () => "images")
    .otherwise((unit) => {
      unit satisfies never;

      return "unit";
    });

  const inputOutputValues: {
    type: "Input" | "Output";
    price: string;
  }[] = [];

  if (officialModel.cost_per_billing_unit_for_input_dollars) {
    inputOutputValues.push({
      type: "Input",
      price: officialModel.cost_per_billing_unit_for_input_dollars,
    });
  }

  if (officialModel.cost_per_billing_unit_for_output_dollars) {
    inputOutputValues.push({
      type: "Output",
      price: officialModel.cost_per_billing_unit_for_output_dollars,
    });
  }

  return (
    <div className="space-y-lh">
      <div
        key={officialModel.full_name}
        id={`per-token-${officialModel.full_name}`}
      >
        <div className="bg-r8-gray-2 border border-r8-gray-5 rounded">
          <table className="table-auto w-full tabular-nums">
            <tbody className="divide-y divide-r8-gray-3">
              <tr className="sr-only">
                <th>Type</th>
                <th>Per unit</th>
                <th>Per $1</th>
              </tr>
              {inputOutputValues.map((values, idx) => (
                <tr
                  className="flex sm:table-row flex-col sm:flex-row divide-y sm:divide-y-0 divide-r8-gray-3"
                  key={idx}
                >
                  <td className="px-4 w-full">
                    <span className="font-semibold">{values.type}</span>
                  </td>
                  <td className="px-4" colSpan={2}>
                    <div className="gap-2 sm:gap-4 flex flex-wrap sm:flex-nowrap w-full sm:text-right [&>*]:whitespace-nowrap">
                      {values.price ? (
                        <>
                          <div>
                            {formatCurrency(Number(values.price), {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 3,
                            })}{" "}
                            <span className="text-r8-gray-11 whitespace-nowrap">
                              / {units}
                            </span>
                          </div>
                          <div className="italic text-r8-gray-11">or</div>
                          <div>
                            {billingUnit === "1m-tokens"
                              ? humanizeTokenCount(
                                  (1 / Number(values.price)) * 1_000_000
                                )
                              : Math.floor(1 / Number(values.price))}{" "}
                            {pluralUnit}{" "}
                            <span className="text-r8-gray-11 whitespace-nowrap">
                              / $1
                            </span>
                          </div>
                        </>
                      ) : (
                        <p>Pricing unavailable</p>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ExampleCosts billingUnit={billingUnit} officialModel={officialModel} />
      </div>
    </div>
  );
}

function ExampleCosts({
  billingUnit,
  officialModel,
}: {
  billingUnit: Exclude<OfficialModel["billing_unit"], null>;
  officialModel: OfficialModel;
}) {
  return match({
    billingUnit,
    costPerBillingUnitForInput:
      officialModel.cost_per_billing_unit_for_input_dollars,
    costPerBillingUnitForOutput:
      officialModel.cost_per_billing_unit_for_output_dollars,
  })
    .with(
      {
        billingUnit: "image",
        costPerBillingUnitForInput: P.nullish,
        costPerBillingUnitForOutput: P.not(P.nullish),
      },
      ({ costPerBillingUnitForOutput }) => (
        <div className="text-r8-sm mt-05lh">
          <p className="leading-relaxed">
            For example, generating{" "}
            <strong className="font-semibold">100 images</strong> should cost
            around{" "}
            <strong className="font-semibold">
              {formatCurrency(Number(costPerBillingUnitForOutput) * 100, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </strong>
            .
          </p>
        </div>
      )
    )
    .with(
      {
        billingUnit: "1m-tokens",
        costPerBillingUnitForInput: P.not(P.nullish),
        costPerBillingUnitForOutput: P.not(P.nullish),
      },
      ({ costPerBillingUnitForInput, costPerBillingUnitForOutput }) => {
        const inputCostPerMillion = Number(costPerBillingUnitForInput);
        const outputCostPerMillion = Number(costPerBillingUnitForOutput);

        const dollars = 10;
        const inputTokensPerPrediction = 15;
        const outputTokensPerPrediction = 700;

        const inputCost = inputCostPerMillion / 1_000_000;
        const outputCost = outputCostPerMillion / 1_000_000;

        const totalInputTokens = dollars / inputCost;
        const totalOutputTokens = dollars / outputCost;

        const numberOfInputs = totalInputTokens / inputTokensPerPrediction;
        const numberOfOutputs = totalOutputTokens / outputTokensPerPrediction;

        const totalPredictions = Math.min(numberOfInputs, numberOfOutputs);

        return (
          <div className="text-r8-sm mt-05lh">
            <p className="leading-relaxed">
              For example, for $10 you can run around{" "}
              <strong className="font-semibold">
                {formatNumber(totalPredictions, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </strong>{" "}
              predictions where the input is{" "}
              <strong className="font-semibold">
                a sentence or two (15 tokens)
              </strong>{" "}
              and the output is{" "}
              <strong className="font-semibold">
                a few paragraphs (700 tokens)
              </strong>
              .
            </p>
          </div>
        );
      }
    )
    .otherwise(() => null);
}
