import { FileMagnifyingGlass } from "@phosphor-icons/react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  PopoverProvider,
} from "@replicate/ui";

export default function PredictionOutputPreview({
  file,
}: {
  file: string;
}) {
  return (
    <PopoverProvider>
      <PopoverDisclosure render={<IconButton variant="clear" size="sm" />}>
        <FileMagnifyingGlass />
      </PopoverDisclosure>
      <Popover className="p-2 z-[1]" fitViewport flip>
        <PopoverArrow />
        <img src={file} className="max-w-80" />
      </Popover>
    </PopoverProvider>
  );
}
