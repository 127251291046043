import { Suspense, lazy } from "react";

const LazyBgGoo = lazy(() => import("./api-playground/bg-goo"));

export default function SignInPageGoo({
  speed = 0.2,
  resolution = 2.0,
  type = "default",
}: { speed?: number; resolution?: number; type?: "default" | "contour" }) {
  return (
    <Suspense fallback={null}>
      <LazyBgGoo speed={speed} resolution={resolution} depth={4} type={type} />
    </Suspense>
  );
}
