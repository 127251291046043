import { isEmpty } from "lodash-es";
import { match } from "ts-pattern";
import {
  cleanInputForSubmission,
  processInputForSubmission,
} from "./components/api-playground/util";
import { getInputSchema, getOutputSchema } from "./schema";
import type {
  CogInputPropertySchema,
  CogInputSchema,
  Model,
  Version,
} from "./types";

interface VersionedSnippetParams {
  input: Record<string, any>;
  version: Version;
}

interface VersionlessSnippetParams {
  input: Record<string, any>;
  model: Model;
  version: Version;
}

export function indent(
  value: string,
  indentation:
    | number
    | { first: number; inner: number; last: number; character?: string }
): string {
  const lines = value.split("\n");
  const character =
    (typeof indentation !== "number" && indentation?.character) || " ";
  return lines
    .map((line, index) => {
      let lineIndentation: number;
      if (typeof indentation === "number") {
        lineIndentation = indentation;
      } else if (index === 0) {
        lineIndentation = indentation.first;
      } else if (index === lines.length - 1) {
        lineIndentation = indentation.last;
      } else {
        lineIndentation = indentation.inner;
      }

      return character.repeat(lineIndentation) + line;
    })
    .join("\n");
}

function formatFileAsExampleUrl(file: File): string {
  return `https://example.com/${file.name}`;
}

function formatPythonValue(value: any, schema: CogInputPropertySchema): string {
  return match(schema)
    .with(
      { type: "string", format: "uri" },
      () => value instanceof File,
      () => JSON.stringify(formatFileAsExampleUrl(value as File))
    )
    .with(
      { type: "array", items: { type: "string", format: "uri" } },
      () => Array.isArray(value) && value.every((v) => v instanceof File),
      () => {
        return `[${value
          .map((v: File) => JSON.stringify(formatFileAsExampleUrl(v)))
          .join(",")}]`;
      }
    )
    .with({ type: "boolean" }, () => (value ? "True" : "False"))
    .otherwise(() => JSON.stringify(value));
}

export function formatPythonInput(
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true
): string {
  if (isEmpty(schema) || isEmpty(input)) {
    return "{}";
  }
  return [
    "{",
    Object.entries(input)
      .map(
        ([key, value]) => `"${key}": ${formatPythonValue(value, schema[key])}`
      )
      .join(withLinebreaks ? ",\n" : ", "),
    "}",
  ].join(withLinebreaks ? "\n" : " ");
}

export function makePythonRunSnippet({
  input,
  version,
}: VersionedSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatPythonInput(processedInput, schema);
  const { owner, name } = version._extras.model;

  let code = `output = replicate.run("${owner}/${name}:${version.id}")`;
  if (!isEmpty(schema)) {
    code = `
output = replicate.run(
    "${owner}/${name}:${version.id}",
    input=${indent(formattedInput, { first: 0, inner: 8, last: 4 })}
)
`.trim();
  }

  return `${code}\nprint(output)`;
}

export function makePythonRunSnippetWithStreaming({
  input,
  streaming,
  version,
}: VersionedSnippetParams & {
  streaming: "entry" | "concatenate";
}) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatPythonInput(processedInput, schema);
  const { owner, name } = version._extras.model;

  let code = `output = replicate.run("${owner}/${name}:${version.id}")`;
  if (!isEmpty(schema)) {
    code = `
output = replicate.run(
    "${owner}/${name}:${version.id}",
    input=${indent(formattedInput, { first: 0, inner: 8, last: 4 })}
)
`.trim();
  }

  return `
${code}

# The ${owner}/${name} model can stream output as it's running.
# The predict method returns an iterator, and you can iterate over that output.
for item in output:
    # https://replicate.com/${owner}/${name}/api#output-schema
    ${streaming === "concatenate" ? 'print(item, end="")' : "print(item)"}
`.trim();
}

export function makeVersionlessPythonRunSnippet({
  input,
  model,
  version,
}: VersionlessSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatPythonInput(processedInput, schema);
  const { owner, name } = model;

  let code = `output = replicate.run("${owner}/${name}")`;
  if (!isEmpty(schema)) {
    code = `
output = replicate.run(
    "${owner}/${name}",
    input=${indent(formattedInput, { first: 0, inner: 8, last: 4 })}
)
`.trim();
  }

  return `${code}\nprint(output)`;
}

export function makeVersionlessPythonRunSnippetWithStreaming({
  input,
  model,
  version,
}: VersionlessSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const formattedInput = formatPythonInput(cleanedInput, schema);
  const { owner, name } = model;

  return `
# The ${owner}/${name} model can stream output as it's running.
for event in replicate.stream(
    "${owner}/${name}",
    input=${indent(formattedInput, { first: 0, inner: 8, last: 4 })},
):
    print(str(event), end="")
`.trim();
}

export function makePythonImportAndConfigureClientSnippet() {
  return "import replicate";
}

function formatNodeValue(value: any, schema: CogInputPropertySchema): string {
  return JSON.stringify(
    match(schema)
      .with(
        { type: "string", format: "uri" },
        () => value instanceof File,
        () => formatFileAsExampleUrl(value as File)
      )
      .with(
        { type: "array", items: { type: "string", format: "uri" } },
        () => Array.isArray(value) && value.every((v) => v instanceof File),
        () => {
          return value.map((v: File) => formatFileAsExampleUrl(v));
        }
      )
      .otherwise(() => value)
  );
}

export function formatNodeInput(
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true
): string {
  if (isEmpty(schema) || isEmpty(input)) {
    return "{}";
  }

  return [
    "{",
    Object.entries(input)
      .map(([key, value]) => `${key}: ${formatNodeValue(value, schema[key])}`)
      .join(withLinebreaks ? ",\n" : ", "),
    "}",
  ].join(withLinebreaks ? "\n" : " ");
}

export function makeNodeRunSnippet({ input, version }: VersionedSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatNodeInput(processedInput, schema);

  return `const output = await replicate.run(
  "${version._extras.model.owner}/${version._extras.model.name}:${version.id}",
  {
    input: ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }
);
console.log(output);`;
}

export function makeVersionlessNodeRunSnippet({
  input,
  model,
  version,
}: VersionlessSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatNodeInput(processedInput, schema);
  const { owner, name } = model;

  return `
const input = ${indent(formattedInput, {
    first: 0,
    inner: 2,
    last: 0,
  })};

const output = await replicate.run("${owner}/${name}", { input });
console.log(output);`.trim();
}

export function makeVersionlessNodeRunSnippetWithStreaming({
  input,
  model,
  version,
}: VersionlessSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const formattedInput = formatNodeInput(cleanedInput, schema);
  const { owner, name } = model;

  return `
const input = ${indent(formattedInput, {
    first: 0,
    inner: 2,
    last: 0,
  })};

for await (const event of replicate.stream("${owner}/${name}", { input })) {
  process.stdout.write(event.toString());
};
    `.trim();
}

export function makeNodeImportAndConfigureClientSnippet() {
  return `import Replicate from "replicate";

const replicate = new Replicate({
  auth: process.env.REPLICATE_API_TOKEN,
});`;
}

function formatHTTPValue(value: any, schema: CogInputPropertySchema): string {
  return (
    JSON.stringify(
      match(schema)
        .with(
          { type: "string", format: "uri" },
          () => value instanceof File,
          () => formatFileAsExampleUrl(value as File)
        )
        .with(
          { type: "array", items: { type: "string", format: "uri" } },
          () => Array.isArray(value) && value.every((v) => v instanceof File),
          () => {
            return value.map((v: File) => formatFileAsExampleUrl(v));
          }
        )
        .otherwise(() => value)
    )
      // Escape \ in the input into \\.
      .replace(/\\/g, "\\\\")
      // Escape ' in the input into \'. Note that this escaping is because we
      // wrap the input string in single quotes and needs to happen after the
      // escaping of escapes done in the line above.
      .replace(/'/g, "\\'")
  );
}

export function formatHTTPInput(
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true
): string {
  if (isEmpty(schema) || isEmpty(input)) {
    return "{}";
  }

  return [
    "{",
    Object.entries(input)
      .map(([key, value]) => `"${key}": ${formatHTTPValue(value, schema[key])}`)
      .join(withLinebreaks ? ",\n" : ", "),
    "}",
  ].join(withLinebreaks ? "\n" : " ");
}

export function makeHTTPRunSnippet({ input, version }: VersionedSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatHTTPInput(processedInput, schema);

  return String.raw`curl -s -X POST \
  -H "Authorization: Bearer $REPLICATE_API_TOKEN" \
  -H "Content-Type: application/json" \
  -H "Prefer: wait" \
  -d $'{
    "version": "${version.id}",
    "input": ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }' \
  https://api.replicate.com/v1/predictions`;
}

export function makeVersionlessHTTPRunSnippet({
  input,
  model,
  version,
}: VersionlessSnippetParams) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const formattedInput = formatHTTPInput(cleanedInput, schema);
  const { owner, name } = model;

  return String.raw`curl -s -X POST \
  -H "Authorization: Bearer $REPLICATE_API_TOKEN" \
  -H "Content-Type: application/json" \
  -H "Prefer: wait" \
  -d $'{
    "input": ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }' \
  https://api.replicate.com/v1/models/${encodeURIComponent(
    owner
  )}/${encodeURIComponent(name)}/predictions`;
}

function formatCogValue(value: any, schema: CogInputPropertySchema): string {
  return formatHTTPValue(value, schema);
}

function formatCogInput(
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true
): string {
  return Object.entries(input)
    .map(([key, value]) => {
      const formattedValue = formatCogValue(value, schema[key]);
      const prefix = formattedValue.includes("\\") ? "$" : "";
      return `-i ${prefix}'${key}=${formattedValue}'`;
    })
    .join(withLinebreaks ? " \\\n" : " ");
}

export function makeCogRunSnippet({
  dockerImageName,
  input,
  version,
}: VersionedSnippetParams & {
  dockerImageName: string;
}) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatCogInput(processedInput, schema);

  return String.raw`cog predict ${dockerImageName} \
${indent(formattedInput, 2)}`;
}

function formatDockerInput(
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true
): string {
  return formatHTTPInput(input, schema, withLinebreaks);
}

export function makeDockerRunSnippet({
  dockerImageName,
  input,
  version,
}: VersionedSnippetParams & {
  dockerImageName: string;
}) {
  const schema = getInputSchema(version).properties;
  const cleanedInput = cleanInputForSubmission(input, schema);
  const processedInput = processInputForSubmission(cleanedInput, schema);
  const formattedInput = formatDockerInput(processedInput, schema);

  return [
    String.raw`docker run -d -p 5000:5000 ${
      version._extras.arch === "gpu" ? "--gpus=all " : ""
    }${dockerImageName}`,
    String.raw`curl -s -X POST \
  -H "Content-Type: application/json" \
  -d $'{
    "input": ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }' \
  http://localhost:5000/predictions`,
  ];
}

export function supportsStreaming(
  version: Version
): "none" | "entry" | "concatenate" {
  const output = getOutputSchema(version);
  const supportsStreaming = output?.["x-cog-array-type"] === "iterator";

  let streaming: "none" | "entry" | "concatenate" = "none";
  if (supportsStreaming) {
    streaming =
      output?.["x-cog-array-display"] === "concatenate"
        ? "concatenate"
        : "entry";
  }
  return streaming;
}

export function getFileInputs(version: Version): string[] {
  const schema = getInputSchema(version).properties;
  return Object.entries(schema)
    .sort((a, b) => a[1]["x-order"] - b[1]["x-order"])
    .filter(
      ([_, prop]) =>
        "type" in prop && prop.type === "string" && prop.format === "uri"
    )
    .map(([key, _]) => key);
}
