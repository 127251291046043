import hljs from "highlight.js/lib/core";
// loaded separately so they can be tree shaken
import css from "highlight.js/lib/languages/css";
import dockerfile from "highlight.js/lib/languages/dockerfile";
import elixir from "highlight.js/lib/languages/elixir";
import glsl from "highlight.js/lib/languages/glsl";
import go from "highlight.js/lib/languages/go";
import http from "highlight.js/lib/languages/http";
import java from "highlight.js/lib/languages/java";
import javascript from "highlight.js/lib/languages/javascript";
import json from "highlight.js/lib/languages/json";
import julia from "highlight.js/lib/languages/julia";
import python from "highlight.js/lib/languages/python";
import ruby from "highlight.js/lib/languages/ruby";
import shell from "highlight.js/lib/languages/shell";
import swift from "highlight.js/lib/languages/swift";
import yaml from "highlight.js/lib/languages/yaml";
import { RunWithContext } from "./components/api-playground/run-with";

const supportedLanguages = [
  "css",
  "dockerfile",
  "elixir",
  "glsl",
  "go",
  "http",
  "java",
  "javascript",
  "json",
  "julia",
  "python",
  "ruby",
  "shell",
  "swift",
  "yaml",
] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];

hljs.configure({
  cssSelector: [
    ".highlight",
    '[class^="language-"]', // for python markdownify output like <code class="language-py">
  ].join(", "),
});

export function initHighlight() {
  hljs.registerLanguage("css", css);
  hljs.registerLanguage("go", go);
  hljs.registerLanguage("glsl", glsl);
  hljs.registerLanguage("elixir", elixir);
  hljs.registerLanguage("java", java);
  hljs.registerLanguage("javascript", javascript);
  hljs.registerLanguage("json", json);
  hljs.registerLanguage("julia", julia);
  hljs.registerLanguage("python", python);
  hljs.registerLanguage("ruby", ruby);
  hljs.registerLanguage("shell", shell);
  hljs.registerLanguage("swift", swift);
  hljs.registerLanguage("yaml", yaml);
  hljs.registerLanguage("dockerfile", dockerfile);
  hljs.registerLanguage("http", http);
  hljs.highlightAll();
}

export function assertNever(value: never): never {
  throw new Error(
    `Unhandled discriminated union member: ${JSON.stringify(value)}`
  );
}

export function runWithContextToSupportedLanguage(
  context: RunWithContext | undefined
): SupportedLanguage | undefined {
  switch (context) {
    case RunWithContext.Cog:
      return "shell";
    case RunWithContext.Docker:
      return "shell";
    case RunWithContext.HTTP:
      return "http";
    case RunWithContext.NodeJS:
      return "javascript";
    case RunWithContext.Python:
      return "python";
    case undefined:
      return undefined;
    default:
      return assertNever(context);
  }
}
