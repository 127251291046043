import { Portal } from "@ariakit/react";
import { CaretUpDown, PlusCircle, UserPlus } from "@phosphor-icons/react";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuGroupLabel,
  MenuItem,
  MenuProvider,
} from "@replicate/ui";
import Cookies from "js-cookie";
import { type ReactNode, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useMediaMatch } from "../../hooks";
import type { AccountWithAvatar } from "../../types";
import { route } from "../../urls";
import { Avatar } from "../avatar";
import { InviteToOrganizationDialog } from "../invite-to-organization-dialog";
import { LogoutMenuItem } from "./logout-menu-item";
import { SwitchAccountMenuItem } from "./switch-account-menu-item";

export async function switchAccount({
  account,
}: { account: AccountWithAvatar }) {
  const res = await fetch(route("api_set_principal"), {
    method: "PUT",
    body: JSON.stringify(
      account.kind === "user"
        ? { username: account.username }
        : { organization_name: account.username }
    ),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (!res.ok) {
    throw new Error("Failed to switch account");
  }
}

export async function logout() {
  const res = await fetch(route("signout"), {
    method: "POST",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (res.ok) {
    window.location.href = res.url;
  } else {
    throw new Error("Failed to log out");
  }
}

export default function AccountPicker({
  account,
  hideName = false,
  user,
  organizations,
  hasSuperpowers,
}: {
  hideName?: boolean;
  account: AccountWithAvatar;
  organizations: AccountWithAvatar[];
  user: AccountWithAvatar;
  hasSuperpowers: boolean;
}): ReactNode {
  const isMobileScreen = useMediaMatch("(max-width: 767px)");
  const accounts = [user, ...organizations];

  const switchableAccounts = accounts.filter(
    (a) => a.username !== account.username
  );

  useEffect(() => {
    // We never want to show stale account information if the user has switched
    // accounts and is using their browser's back and forward buttons to
    // navigate their history.
    const performanceEntries =
      window.performance.getEntriesByType("navigation");

    const [maybeNavigationEntry] = performanceEntries;
    if (
      "PerformanceNavigationTiming" in window &&
      maybeNavigationEntry instanceof window.PerformanceNavigationTiming &&
      maybeNavigationEntry.type === "back_forward"
    ) {
      location.reload();
    }
  }, []);

  let disclosure = (
    <MenuButton className="flex items-center gap-2 p-2 hover:bg-r8-gray-3 focus:bg-r8-gray-3 text-left max-w-[25ch] truncate overflow-ellipsis">
      <div className="w-7 h-7 overflow-hidden flex-shrink-0">
        <Avatar
          avatar={{
            kind: account.kind,
            url: account.avatar_url,
          }}
        />
      </div>
      <div className="flex items-center gap-1 flex-1 truncate">
        <p className="text-r8-sm md:text-r8-base font-semibold truncate overflow-ellipsis">
          {account.username}
        </p>
        <CaretUpDown size={16} className="text-r8-gray-11" weight="regular" />
      </div>
    </MenuButton>
  );

  if (hideName || isMobileScreen) {
    disclosure = (
      <MenuButton
        className={`focus:outline-none focus:ring ${
          account.kind === "user" ? "rounded-full" : ""
        }`}
      >
        <div className="w-8 h-8 lg:w-10 lg:h-10 flex-shrink-0">
          <Avatar
            avatar={{
              kind: account.kind,
              url: account.avatar_url,
            }}
          />
        </div>
      </MenuButton>
    );
  }

  return (
    <>
      <Portal>
        <Toaster position="bottom-right" />
      </Portal>
      <MenuProvider
        focusLoop="vertical"
        placement={hideName ? "right-end" : "bottom"}
      >
        {disclosure}
        <Menu className="w-[17rem] max-h-[28rem] overflow-auto" gutter={8}>
          <MenuGroup>
            <MenuItem
              render={
                // biome-ignore lint/a11y/useAnchorContent: content injected
                <a
                  className="no-underline no-default no-focus"
                  href={route("account_settings")}
                />
              }
            >
              Account settings
            </MenuItem>
            <MenuItem
              render={
                // biome-ignore lint/a11y/useAnchorContent: content injected
                <a
                  className="no-underline no-default no-focus"
                  href={route("account_api_token_settings")}
                />
              }
            >
              API tokens
            </MenuItem>
            <MenuItem
              render={
                // biome-ignore lint/a11y/useAnchorContent: content injected
                <a
                  className="no-underline no-default no-focus"
                  href={route("account_detail", {
                    username: account.username,
                  })}
                />
              }
            >
              View profile
            </MenuItem>
            {hasSuperpowers ? (
              <MenuItem
                render={
                  <a
                    className="no-underline no-default no-focus"
                    href="/aidmin"
                  >
                    Admin
                  </a>
                }
              />
            ) : null}
          </MenuGroup>

          {switchableAccounts.length > 0 ? (
            <MenuGroup>
              <MenuGroupLabel>Switch account</MenuGroupLabel>
              {switchableAccounts.map((account) => {
                return (
                  <SwitchAccountMenuItem
                    key={account.username}
                    account={account}
                  />
                );
              })}
            </MenuGroup>
          ) : null}
          <MenuGroup>
            <MenuItem
              render={
                // biome-ignore lint/a11y/useAnchorContent: content injected
                <a
                  className="no-underline no-focus no-default"
                  href={route("organization_create_or_join")}
                />
              }
            >
              <div className="flex items-center gap-2">
                <PlusCircle weight="bold" />
                <span>Join or create organization</span>
              </div>
            </MenuItem>
            {account.kind === "organization" ? (
              <InviteToOrganizationDialog
                organizationName={account.username}
                disclosure={
                  <MenuItem>
                    <div className="flex items-center gap-2">
                      <UserPlus weight="bold" />
                      Invite to organization
                    </div>
                  </MenuItem>
                }
              />
            ) : null}
            <LogoutMenuItem />
          </MenuGroup>
        </Menu>
      </MenuProvider>
    </>
  );
}
