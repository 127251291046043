import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import classNames from "classnames";
import { Duration } from "./duration";

export default function PredictionDuration({
  className,
  enqueuedTime,
  predictTime,
}: {
  className?: string;
  enqueuedTime: number;
  predictTime: number;
}) {
  return (
    <TooltipProvider>
      <TooltipAnchor
        className={classNames(
          "border-dotted border-b border-r8-gray-10",
          className
        )}
      >
        <Duration
          className="text-r8-gray-11"
          seconds={predictTime + enqueuedTime}
          short
        />
      </TooltipAnchor>
      <Tooltip>
        <Duration seconds={enqueuedTime} short /> queued /{" "}
        <Duration seconds={predictTime} short /> running
        <TooltipArrow />
      </Tooltip>
    </TooltipProvider>
  );
}
