import * as Ariakit from "@ariakit/react";
import { Folder } from "@phosphor-icons/react";
import mime from "mime";
import { useState } from "react";
import { match } from "ts-pattern";
import { formatNodeInput, indent } from "../../code-snippets";
import { getInputSchema, getOutputSchema } from "../../schema";
import type {
  AccessToken,
  CogOutputSchema,
  Prediction,
  Version,
} from "../../types";
import AuthToken from "../auth-token";
import { FilesView } from "./files-view";
import { PredictionCards } from "./prediction-cards";
import { CommandLine, OutputLine, TerminalWindow } from "./terminal-window";

const packageJsonContent = JSON.stringify(
  {
    type: "module",
    name: "node-starter",
    version: "1.0.0",
    description:
      "A starter project for running machine learning models in the cloud with Replicate.",
    main: "index.js",
    scripts: {
      predict: "node index.js",
    },
    author: "mattrothenberg",
    license: "MIT",
    dependencies: {
      dotenv: "^16.3.1",
      replicate: "^0.21.1",
    },
  },
  null,
  2
);

function CommandPreview({ id }: { id: string }) {
  return (
    <TerminalWindow>
      <CommandLine typed={false}>
        {`replicate scaffold --template=node ${id} ./replicate-node-starter`}
      </CommandLine>
    </TerminalWindow>
  );
}

function OutputLinkWithPreview({
  output,
  schema,
}: { output: any; schema: CogOutputSchema }) {
  return match(schema)
    .with(
      { type: "string", format: "uri" },
      {
        type: "array",
        items: {
          type: "string",
          format: "uri",
        },
      },
      () => {
        // let's get the first image if an array, otherwise just use the string which is the uri.
        const uriHref = Array.isArray(output) ? output[0] : output;
        const mimeType = mime.getType(uriHref);

        return (
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor
              href={uriHref}
              className="decoration-current text-white"
            >
              {Array.isArray(output) ? `["${uriHref}"]` : `"${uriHref}"`}
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              gutter={8}
              className="bg-white w-64 p-2 not-prose"
            >
              <Ariakit.HovercardArrow />
              {mimeType?.includes("image") && (
                <img
                  src={uriHref}
                  className="object-cover w-full h-full"
                  alt="Prediction output"
                />
              )}
              {mimeType?.includes("video") && (
                <video
                  src={uriHref}
                  className="object-cover w-full h-full"
                  autoPlay
                  loop
                  muted
                />
              )}
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
        );
      }
    )
    .with(
      {
        type: "array",
        items: {
          type: "string",
        },
      },
      () => {
        const joinedOutput = (output as string[]).join("");
        return <span>{joinedOutput}</span>;
      }
    )
    .otherwise(() => null);
}

function NodeRunExample({
  prediction,
  version,
}: {
  prediction: Prediction;
  version: Version;
}) {
  const qualifiedModelName = `${version._extras.model.owner}/${version._extras.model.name}:${version._extras.short_id}`;
  const outputSchema = getOutputSchema(version);

  return (
    <div className="space-y-lh relative">
      <p>
        With this scaffolding in place, running the model again is as simple as
        invoking the start script and waiting for your output. The following
        interactive demo simulates what this workflow looks like. Please note
        that all output here is simulated, and you won't pay for any compute
        time until you actually run the model from your machine.
      </p>
      <TerminalWindow>
        <CommandLine typed={false}>cd ./replicate-node-starter</CommandLine>
        <CommandLine interactive typed={false}>
          npm run predict
        </CommandLine>
        <OutputLine duration={3500}>Running {qualifiedModelName}...</OutputLine>
        <OutputLine duration={0}>
          <OutputLinkWithPreview
            schema={outputSchema}
            output={prediction.output}
          />
        </OutputLine>
      </TerminalWindow>
    </div>
  );
}

export default function ScaffoldCommandExamples({
  predictions = [],
  token,
  versions,
}: {
  predictions: Prediction[];
  token: AccessToken;
  versions: Version[];
}) {
  const [activePredictionId, setActivePredictionId] = useState<string>(
    predictions[0].id
  );

  const activePrediction = predictions.find(
    (p) => p.id === activePredictionId
  ) as Prediction;

  const activeVersion = versions.find(
    (v) => v.id === activePrediction.version
  ) as Version;

  const inputSchema = getInputSchema(activeVersion);

  const owner = activeVersion._extras.model.owner;
  const name = activeVersion._extras.model.name;

  const modelString = `${owner}/${name}`;

  const jsContent = `import Replicate from "replicate";
import 'dotenv/config';

const token = process.env.REPLICATE_API_TOKEN;

const replicate = new Replicate({
  auth: token,
});

const model = "${modelString}";
const version = "${activePrediction.version}"

async function main () {
  if (!token) {
    throw new Error("Please set your REPLICATE_API_TOKEN environment variable.");
  }

  const output = await replicate.run(
    "${modelString}:${activePrediction.version}",
    {
      input: ${indent(
        formatNodeInput(activePrediction.input, inputSchema.properties, true),
        {
          first: 0,
          inner: 8,
          last: 6,
        }
      )}
    }
  );

  console.log(output);
}

main().then(() => {
  console.log("Done!");
}).catch(err => {
  console.error("Uh oh, an error occurred.");
  console.error(err);
})
`;

  return (
    <div className="space-y-6">
      <div className="not-prose">
        <PredictionCards
          predictions={predictions}
          versions={versions}
          activeId={activePredictionId}
          onChange={setActivePredictionId}
        />
      </div>
      {activePredictionId && <CommandPreview id={activePredictionId} />}
      <p>
        Running this command will both generate a directory on your machine with
        all of the files you need to run this model locally with Node.js, and
        then immediately run the model in the cloud and return the output. The
        example code makes use of Replicate’s{" "}
        <a href="https://github.com/replicate/replicate-javascript#readme">
          open-source Javascript client
        </a>
        . The code is intentionally simple (creating a prediction and logging
        the output), but you can use this as a starting point for more complex
        applications.
      </p>
      <div className="border border-black not-prose">
        <div className="bg-r8-gray-2 border-b border-black p-2">
          <div className="flex items-center gap-1.5">
            <Folder weight="fill" />
            <p className="text-r8-sm text-r8-gray-12">replicate-node-starter</p>
          </div>
        </div>
        <FilesView
          initialTab="index.js"
          key={activePredictionId}
          files={{
            "package.json": {
              content: packageJsonContent,
              language: "json",
            },
            "index.js": {
              content: jsContent,
              language: "javascript",
            },
            ".env": {
              content: (
                <div className="[&>p]:p-2 [&>p]:text-r8-sm">
                  <AuthToken token={token} type="dotenv" />
                </div>
              ),
              language: "dotenv",
            },
          }}
        />
      </div>
      <NodeRunExample
        key={activePredictionId}
        version={activeVersion}
        prediction={activePrediction}
      />
    </div>
  );
}
