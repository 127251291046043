import hljs from "highlight.js/lib/core";
import { useEffect } from "react";
import { useRemoteMarkdown } from "./hooks";

export function MarkdownValue({ url }: { url: string }) {
  const { data, isPending, isError } = useRemoteMarkdown({ url });

  useEffect(() => {
    if (!data) return;
    hljs.highlightAll();
  }, [data]);

  if (isPending) {
    return (
      <div>
        <p className="text-r8-sm text-r8-gray-11">Rendering markdown...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <p className="text-r8-sm text-r8-red-10">Error rendering markdown.</p>
      </div>
    );
  }

  return (
    <div
      className="readme-prose"
      // biome-ignore lint/security/noDangerouslySetInnerHtml: The data passed in is sanitized before it gets here.
      dangerouslySetInnerHTML={{ __html: data }}
    />
  );
}
