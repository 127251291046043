import { Extension } from "@tiptap/core";
import type { Node as ProseMirrorNode } from "@tiptap/pm/model";
import type { EditorState, Transaction } from "@tiptap/pm/state";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export interface HighlightWordOptions {
  wordToHighlight?: string;
}

export const HighlightWordExtension = Extension.create<HighlightWordOptions>({
  name: "highlightWord",

  addOptions() {
    return {
      wordToHighlight: undefined,
    };
  },

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey("highlightWord");

    // Define the function to get decorations with proper TypeScript annotations
    const getDecorations = (doc: ProseMirrorNode): DecorationSet => {
      if (!this.options.wordToHighlight) return DecorationSet.empty;
      const decorations: Decoration[] = [];
      const regex = new RegExp(this.options.wordToHighlight, "g");

      doc.descendants((node, pos) => {
        if (!node.isText) return;

        const text = node.text || "";
        let match = regex.exec(text);

        while (match !== null) {
          const from = pos + match.index;
          const to = from + match[0].length;

          decorations.push(
            Decoration.inline(from, to, {
              class: "tiptap-text-highlight",
            })
          );

          match = regex.exec(text);
        }
      });

      return DecorationSet.create(doc, decorations);
    };

    return [
      new Plugin({
        key: pluginKey,
        state: {
          init(_, { doc }: { doc: ProseMirrorNode }) {
            return getDecorations(doc);
          },
          apply(transaction: Transaction, oldState: DecorationSet) {
            const { doc } = transaction;
            if (transaction.docChanged) {
              return getDecorations(doc);
            }
            return oldState;
          },
        },
        props: {
          decorations(state: EditorState) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
