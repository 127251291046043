import { CommandLine, OutputLine, TerminalWindow } from "./terminal-window";

const helpText = `Create a new local development environment from a prediction

Usage:
  replicate scaffold <prediction-ID-or-URL> [<directory>] [--template=<template>] [flags]

Flags:
  -h, --help              help for scaffold
  -t, --template string   Starter git repo template to use. Currently supported: node, python
`;

export default function ScaffoldCommandBreakdown() {
  return (
    <TerminalWindow>
      <CommandLine typed={false}>replicate scaffold --help</CommandLine>
      <OutputLine duration={0}>{helpText}</OutputLine>
    </TerminalWindow>
  );
}
