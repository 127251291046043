import type React from "react";
import type { CogInputSchema, PredictionInputs } from "../../types";
import { FallbackValue } from "./fallback-value";
import { PropertyValue } from "./property-value";

const RenderInput = ({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="space-y-1.5 flex flex-col" key={name}>
      <dt className="text-r8-sm text-r8-gray-11">{name}</dt>
      <dd className="text-r8-sm">{children}</dd>
    </div>
  );
};

export const InputOverview = ({
  input,
  properties,
  reportFallback,
  alwaysRenderURLsAsDownload = false,
}: {
  input: PredictionInputs;
  properties: CogInputSchema["properties"] | undefined;
  reportFallback: boolean;
  alwaysRenderURLsAsDownload: boolean;
}) => {
  return (
    <dl data-testid="input-overview" className="space-y-4">
      {Object.keys(input).map((name) => {
        const rawSchema = properties?.[name];
        const value = input[name];
        const schema =
          rawSchema && "anyOf" in rawSchema
            ? rawSchema.anyOf.find((s) => s.type === (typeof value as string))
            : rawSchema;

        if (!schema) {
          return (
            <RenderInput name={name} key={name}>
              <FallbackValue
                name={name}
                schema={rawSchema}
                value={value}
                report={reportFallback}
              />
            </RenderInput>
          );
        }

        return (
          <RenderInput name={name} key={name}>
            <PropertyValue
              alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
              name={name}
              reportFallback={reportFallback}
              schema={schema}
              value={value}
            />
          </RenderInput>
        );
      })}
    </dl>
  );
};
