import { ArrowsClockwise, CaretDown, CaretUp, X } from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogProvider,
  Disclosure,
  DisclosureContent,
  DisclosureProvider,
  IconButton,
} from "@replicate/ui";
import { useMemo, useRef } from "react";
import { MessageStatus, type MessageAttemptOut } from "svix";
import { useMediaMatch } from "../../hooks";
import type { Prediction } from "../../types";
import { usePredictionWebhooksInfo } from "../svix/hooks";
import { Skeleton } from "./entity-meta";

const StatusDisclosure = ({ attempts }: { attempts: MessageAttemptOut[] }) => {
  const status = useMemo(() => {
    if (attempts.every((attempt) => attempt.status === MessageStatus.Success)) {
      return "success";
    }

    if (attempts.every((attempt) => attempt.status === MessageStatus.Fail)) {
      return "fail";
    }

    if (attempts.some((attempt) => attempt.status === MessageStatus.Fail)) {
      return "partial-fail";
    }

    return "pending";
  }, [attempts]);

  const intents = {
    success: "success",
    fail: "danger",
    "partial-fail": "danger",
    pending: "primary",
  } as const;

  return (
    <DialogDisclosure
      render={<Button variant="outlined" intent={intents[status]} size="xs" />}
    >
      {status === "success" ? (
        <div className="flex items-center gap-2">
          <WebhookStatusIndicator status={MessageStatus.Success} />
          <span className="text-r8-sm">All successful</span>
        </div>
      ) : null}

      {status === "fail" ? (
        <div className="flex items-center gap-2">
          <WebhookStatusIndicator status={MessageStatus.Fail} />
          <span className="text-r8-sm">All failed</span>
        </div>
      ) : null}

      {status === "partial-fail" ? (
        <div className="flex items-center gap-2">
          <WebhookStatusIndicator status={MessageStatus.Fail} />
          <span className="text-r8-sm">Some failed</span>
        </div>
      ) : null}

      {status === "pending" ? (
        <div className="flex items-center gap-2">
          <WebhookStatusIndicator status={MessageStatus.Pending} />
          <span className="text-r8-sm">Still processing</span>
        </div>
      ) : null}
    </DialogDisclosure>
  );
};

export function WebhookStatusIndicator({ status }: { status: MessageStatus }) {
  if (status === MessageStatus.Success) {
    return (
      <div className="flex-none rounded-full p-1 text-r8-green-10 bg-r8-green-3">
        <div className="h-1.5 w-1.5 rounded-full bg-current" />
      </div>
    );
  }

  if (status === MessageStatus.Fail) {
    return (
      <div className="flex-none rounded-full p-1 text-r8-red-10 bg-r8-red-3">
        <div className="h-1.5 w-1.5 rounded-full bg-current" />
      </div>
    );
  }

  return (
    <div className="flex-none rounded-full p-1 text-r8-gray-8 bg-r8-gray-3">
      <div className="h-1.5 w-1.5 rounded-full bg-current" />
    </div>
  );
}

export function PredictionDetailWebhookInfo(params: {
  prediction: Prediction;
  appId: string;
  token: string;
}) {
  const dismissRef = useRef<HTMLButtonElement>(null);
  const { data, isPending, isError, refetch, isRefetching } =
    usePredictionWebhooksInfo(params);
  const isDesktopScreen = useMediaMatch("(min-width: 1024px)");

  if (isPending) {
    return <Skeleton />;
  }

  if (isError) {
    return <span>-</span>;
  }

  if (data.messages.length === 0) {
    return <span>-</span>;
  }

  return (
    <DialogProvider>
      <StatusDisclosure attempts={data.attempts} />
      <Dialog
        variant="drawer"
        side={isDesktopScreen ? "right" : "bottom"}
        className="lg:w-[38rem] h-full flex flex-col overflow-hidden"
        initialFocus={dismissRef}
      >
        <div className="p-4 border-b border-r8-gray-6 shrink-0">
          <div className="flex items-start justify-between">
            <div className="flex flex-col min-w-0">
              <div className="flex items-center gap-2 mb-2">
                <h3 className="text-r8-lg font-semibold leading-none">
                  Webhooks
                </h3>
                <IconButton
                  type="button"
                  onClick={() => {
                    refetch();
                  }}
                  loading={isRefetching}
                  size="sm"
                  variant="outlined"
                >
                  <ArrowsClockwise />
                </IconButton>
              </div>
              <span className="shrink-0 text-r8-xs truncate block">
                {data.attempts?.[0].url}
              </span>
            </div>
            <div className="shrink-0">
              <DialogDismiss ref={dismissRef}>
                <X size={18} weight="bold" />
              </DialogDismiss>
            </div>
          </div>
        </div>
        <div className="overflow-auto flex-1 min-h-0">
          {data.attempts.map((attempt) => {
            const formattedDate = new Date(attempt.timestamp).toUTCString();
            const parentMessage = data.messages.find(
              (message) => message.id === attempt.msgId
            );
            return (
              <DisclosureProvider key={attempt.id}>
                <div className="group">
                  <Disclosure className="w-full text-left flex gap-4 items-start md:items-center justify-between p-4 group border-b border-r8-gray-6 aria-expanded:border-transparent focus:outline-none">
                    <div className="relative top-1 md:top-0">
                      <span className="hidden group-aria-[expanded=true]:block">
                        <CaretUp />
                      </span>
                      <span className="hidden group-aria-[expanded=false]:block">
                        <CaretDown />
                      </span>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-1 md:gap-4 flex-1">
                      <div className="flex items-center gap-2 min-w-0">
                        <WebhookStatusIndicator status={attempt.status} />
                        <span className="text-r8-sm text-r8-gray-12 truncate">
                          {parentMessage?.eventType}
                        </span>
                      </div>
                      <span className="text-r8-sm flex-shrink-0 min-w-0">
                        {formattedDate}
                      </span>
                    </div>
                  </Disclosure>
                  <DisclosureContent className="px-4 pb-4 pt-1 border-b border-r8-gray-6 focus:outline-none">
                    <dl className="flex flex-wrap gap-4">
                      <div>
                        <dt className="text-r8-sm text-r8-gray-11">
                          Status code
                        </dt>
                        <dd className="text-r8-sm">
                          {attempt.responseStatusCode}
                        </dd>
                      </div>
                    </dl>
                    {attempt.response && (
                      <div className="mt-4">
                        <p className="text-r8-sm text-r8-gray-11 mb-1">
                          Response
                        </p>
                        <pre className="text-r8-xs font-mono p-2 bg-r8-gray-3 overflow-auto">
                          {attempt.response}
                        </pre>
                      </div>
                    )}
                  </DisclosureContent>
                </div>
              </DisclosureProvider>
            );
          })}
        </div>
      </Dialog>
    </DialogProvider>
  );
}
