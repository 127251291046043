import { Button, TextField } from "@replicate/ui";
import Cookies from "js-cookie";
import type React from "react";
import { useState } from "react";
import { route } from "../urls";

export default function NewsletterSignupForm() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus("loading");

    try {
      const response = await fetch(route("newsletter_signup"), {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        } as HeadersInit,
        body: `email=${encodeURIComponent(email)}`,
      });
      const data = await response.json();
      if (data.success) {
        setStatus("success");
        setEmail("");
      } else {
        setStatus("error");
        setErrorMessage(data.error || "An error occurred. Please try again.");
      }
    } catch (error) {
      setStatus("error");
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="newsletter-signup mt-2">
      {status === "success" && (
        <p className="mt-2 text-green-600">Thank you for subscribing!</p>
      )}
      {status === "error" && (
        <p className="mt-2 text-red-600">{errorMessage}</p>
      )}
      {status === "loading" && <p className="mt-2">Subscribing...</p>}
      {status === "idle" && (
        <form onSubmit={handleSubmit} className="newsletter-form flex">
          <TextField
            id="newsletter-email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="newsletter-input max-w-sm"
          />
          <Button type="submit">Subscribe</Button>
        </form>
      )}
    </div>
  );
}
