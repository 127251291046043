import Color from "colorjs.io";
import { useReducedMotion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDebounce, useRafInterval } from "../../hooks";
import CodeBlock from "../code-block";
import BgGoo from "./bg-goo";

export const defaultEffectFn = String.raw`vec2 effect(vec2 p, float i, float time) {
  return vec2(sin(p.x * i + (time * speed)) * cos(p.y * i + (time * speed)), sin(length(p.x)) * cos(length(p.y)));
}`;

export function Goo({
  inView,
  size,
  speed,
  resolution,
  depth,
  seed,
  still,
  effectFn,
}: {
  inView: boolean;
  size: { width: number; height: number };
  speed: number;
  resolution: number;
  depth: number;
  seed: number;
  still: boolean;
  effectFn: string;
}) {
  const mousePosRef = useRef({ x: 0, y: 0 });
  const startRef = useRef(performance.now());
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const glRef = useRef<WebGLRenderingContext | null>(null);

  const shaderProgramRef = useRef<WebGLProgram | null>(null);
  const reducedMotion = useReducedMotion();

  const vertexShaderSource = `
	attribute vec2 position;
	void main() {
			gl_Position = vec4(position, 0.0, 1.0);
	}
`;

  const fragmentShaderSource = `
	precision mediump float;
	uniform vec2 iResolution;
	uniform float iTime;
	uniform vec2 iMouse;
	float speed = ${speed.toFixed(2)};

	vec3 color1 = vec3(235.0/255.0, 231.0/255.0, 92.0/255.0);
	vec3 color2 = vec3(223.0/255.0, 72.0/255.0, 67.0/255.0);
	vec3 color3 = vec3(235.0/255.0, 64.0/255.0, 240.0/255.0);

	${effectFn}

	void main() {
			vec2 p = (2.0 * gl_FragCoord.xy - iResolution.xy) / max(iResolution.x, iResolution.y);
      p.x += ${seed.toFixed(1)}; // Use the seed prop to offset the starting position of the goo effect
      p.y += ${seed.toFixed(1)};

			p *= ${resolution.toFixed(1)};
			for (int i = 1; i < ${depth}; i++) {
					float fi = float(i);
					p += effect(p, fi, iTime * speed);
			}
			vec3 col = mix(mix(color1, color2, 1.0-sin(p.x)), color3, cos(p.y+p.x));
			gl_FragColor = vec4(col, 1.0);
	}
`;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const gl =
      canvas.getContext("webgl", { preserveDrawingBuffer: still }) ||
      (canvas.getContext("experimental-webgl") as WebGLRenderingContext);

    if (!gl) {
      console.error(
        "Unable to initialize WebGL. Your browser may not support it."
      );
      return;
    }

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) return;

    gl.shaderSource(vertexShader, vertexShaderSource);
    gl.compileShader(vertexShader);

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader) return;

    gl.shaderSource(fragmentShader, fragmentShaderSource);
    gl.compileShader(fragmentShader);

    const shaderProgram = gl.createProgram();
    if (!shaderProgram) return;

    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);
    gl.useProgram(shaderProgram);

    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = [
      -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

    const positionLocation = gl.getAttribLocation(shaderProgram, "position");
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    glRef.current = gl;
    shaderProgramRef.current = shaderProgram;
  }, []);

  useEffect(() => {
    const gl = glRef.current;
    const shaderProgram = shaderProgramRef.current;
    const { width, height } = size;
    if (!gl || !shaderProgram) return;
    gl.viewport(0, 0, width, height); // Set the WebGL viewport to match
    const iResolutionLocation = gl.getUniformLocation(
      shaderProgram,
      "iResolution"
    );
    gl.uniform2f(iResolutionLocation, gl.canvas.width, gl.canvas.height);
  }, [size]);

  const frameHandler = () => {
    const start = startRef.current;
    const time = performance.now() - start;
    const mousePos = mousePosRef.current;

    if ((reducedMotion || still) && time > 200) {
      return;
    }

    const gl = glRef.current;
    const shaderProgram = shaderProgramRef.current;
    if (!gl || !shaderProgram) return;

    const iTimeLocation = gl.getUniformLocation(shaderProgram, "iTime");
    const iMouseLocation = gl.getUniformLocation(shaderProgram, "iMouse");

    gl.uniform2f(iMouseLocation, mousePos.x, mousePos.y);
    gl.uniform1f(iTimeLocation, time * 0.001);

    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  };

  useRafInterval(frameHandler, inView ? 1000 / 60 : undefined);

  return <canvas {...size} ref={canvasRef} className="w-full h-full" />;
}

export function GooContour({
  inView,
  size,
  speed,
  resolution,
  depth,
  seed,
  still,
  effectFn,
}: {
  inView: boolean;
  size: { width: number; height: number };
  speed: number;
  resolution: number;
  depth: number;
  seed: number;
  still: boolean;
  effectFn: string;
}) {
  const mousePosRef = useRef({ x: 0, y: 0 });
  const startRef = useRef(performance.now());
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const glRef = useRef<WebGLRenderingContext | null>(null);

  const shaderProgramRef = useRef<WebGLProgram | null>(null);
  const reducedMotion = useReducedMotion();

  // Handle dark mode switching
  const [invert, setInvert] = useState(
    () => window.matchMedia?.("(prefers-color-scheme: dark)").matches
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e: MediaQueryListEvent) => {
      setInvert(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  // Grab the tint based on the current text color

  const [tint, setTint] = useState(invert ? [1, 1, 1] : [0, 0, 0]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: invert may not be defined due to feature flags
  useEffect(() => {
    if (canvasRef.current) {
      const color = new Color(
        getComputedStyle(canvasRef.current).getPropertyValue("color")
      );
      setTint(color.coords.map((c) => c));
    }
  }, [invert]);

  const vertexShaderSource = `
	attribute vec2 position;
	void main() {
			gl_Position = vec4(position, 0.0, 1.0);
	}
`;

  const fragmentShaderSource = `
  #extension GL_OES_standard_derivatives : enable

  precision mediump float;
	uniform vec2 iResolution;
	uniform float iTime;
	uniform vec2 iMouse;
  uniform vec3 iTint;
	float speed = ${speed.toFixed(2)};
  
	vec3 colorBlack = vec3(0.0/255.0, 0.0/255.0, 0.0/255.0);
  vec3 colorWhite = vec3(255.0/255.0, 255.0/255.0, 255.0/255.0);

  ${effectFn}

	void main() {
			vec2 p = (gl_FragCoord.xy - iResolution.xy) / max(iResolution.x, iResolution.y);
      p.x += ${seed.toFixed(1)}; // Use the seed prop to offset the starting position of the goo effect
      p.y += ${seed.toFixed(1)};

			p *= ${resolution.toFixed(1)};
			for (int i = 1; i < ${depth}; i++) {
					float fi = float(i);
					p += effect(p, fi, iTime * speed);
			}
			vec3 noise = mix(mix(colorBlack, colorWhite, 1.0-sin(p.x)), colorBlack, cos(p.y+p.x));

      float spacing = 1. / (iResolution.x * 0.0025);
      float lines = mod(noise.r, spacing) / spacing;
      lines = min(lines * 2., 1.) - max(lines * 2. - 1., 0.);
      lines /= fwidth(noise.r / spacing);
      
      float weight = smoothstep(0.0, 1.0, gl_FragCoord.y / iResolution.y);
      weight = mix(2.0, 5.0, weight);
      
      lines -= weight * 1.;
    
      gl_FragColor = vec4(iTint, -lines);
	}
`;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const gl =
      canvas.getContext("webgl", { preserveDrawingBuffer: still }) ||
      (canvas.getContext("experimental-webgl") as WebGLRenderingContext);

    if (!gl) {
      console.error(
        "Unable to initialize WebGL. Your browser may not support it."
      );
      return;
    }

    // Check if the extension is successfully enabled
    const ext = gl.getExtension("OES_standard_derivatives");
    if (!ext) {
      console.warn("OES_standard_derivatives not supported");
      return;
    }

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) return;

    gl.shaderSource(vertexShader, vertexShaderSource);
    gl.compileShader(vertexShader);

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader) return;

    gl.shaderSource(fragmentShader, fragmentShaderSource);
    gl.compileShader(fragmentShader);

    const shaderProgram = gl.createProgram();
    if (!shaderProgram) return;

    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);
    gl.useProgram(shaderProgram);

    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = [
      -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0,
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

    const positionLocation = gl.getAttribLocation(shaderProgram, "position");
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    glRef.current = gl;
    shaderProgramRef.current = shaderProgram;
  }, []);

  useEffect(() => {
    const gl = glRef.current;
    const shaderProgram = shaderProgramRef.current;
    const { width, height } = size;
    if (!gl || !shaderProgram) return;
    gl.viewport(0, 0, width * devicePixelRatio, height * devicePixelRatio); // Set the WebGL viewport to match

    const iResolutionLocation = gl.getUniformLocation(
      shaderProgram,
      "iResolution"
    );
    gl.uniform2f(iResolutionLocation, gl.canvas.width, gl.canvas.height);
  }, [size]);

  const frameHandler = () => {
    const start = startRef.current;
    const time = performance.now() - start;
    const mousePos = mousePosRef.current;

    if ((reducedMotion || still) && time > 200) {
      return;
    }

    const gl = glRef.current;
    const shaderProgram = shaderProgramRef.current;
    if (!gl || !shaderProgram) return;

    const iTimeLocation = gl.getUniformLocation(shaderProgram, "iTime");
    const iTintLocation = gl.getUniformLocation(shaderProgram, "iTint");
    const iMouseLocation = gl.getUniformLocation(shaderProgram, "iMouse");

    gl.uniform2f(iMouseLocation, mousePos.x, mousePos.y);
    gl.uniform1f(iTimeLocation, time * 0.001);
    gl.uniform3f(iTintLocation, tint[0], tint[1], tint[2]);

    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  };

  const devicePixelRatio = (window.devicePixelRatio || 1) + 1;
  useRafInterval(frameHandler, inView ? 1000 / 60 : undefined);
  return (
    <canvas
      width={size.width * devicePixelRatio}
      height={size.height * devicePixelRatio}
      ref={canvasRef}
      className="w-full h-full"
    />
  );
}

export default function GooOutput({ output }: { output: string[] }) {
  const [speed, setSpeed] = useState(0.2);
  const [resolution, setResolution] = useState(2.0);
  const [depth, setDepth] = useState(4);

  const debouncedSpeed = useDebounce(speed, 300);
  const debouncedResolution = useDebounce(resolution, 300);
  const debouncedDepth = useDebounce(depth, 300);

  const isValidOutput =
    Array.isArray(output) &&
    output.length > 0 &&
    output.every((item) => typeof item === "string");

  const joinedOutput = output?.join("");

  return (
    <div className="divide-y border border-r8-gray-6">
      <div className="aspect-video relative bg-r8-gray-2 z-0">
        <BgGoo
          key={`${joinedOutput}-${debouncedSpeed}-${debouncedResolution}-${debouncedDepth}`}
          effectFn={joinedOutput}
          speed={speed}
          resolution={resolution}
          depth={depth}
        />
      </div>
      <div className="px-3 py-3 flex items-center flex-wrap gap-2">
        <div className="flex flex-col gap-1">
          <label htmlFor="speed" className="text-r8-xs">
            Speed
          </label>
          <input
            id="speed"
            value={speed}
            onChange={(e) => {
              setSpeed(Number(e.target.value));
            }}
            min="0.1"
            max="2.0"
            step="0.1"
            type="range"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="resolution" className="text-r8-xs">
            Resolution
          </label>
          <input
            id="resolution"
            value={resolution}
            onChange={(e) => setResolution(Number(e.target.value))}
            min="0.1"
            max="4.0"
            step="0.1"
            type="range"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="depth" className="text-r8-xs">
            Depth
          </label>
          <input
            id="depth"
            value={depth}
            onChange={(e) => setDepth(Number(e.target.value))}
            min="1"
            max="6"
            step="1"
            type="range"
          />
        </div>
      </div>
      {isValidOutput && (
        <CodeBlock textContent={joinedOutput} language="glsl" />
      )}
    </div>
  );
}
