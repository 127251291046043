import { Asterisk, NotePencil, WarningCircle } from "@phosphor-icons/react";
import {
  Banner,
  Button,
  Checkbox,
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getSortedProperties } from "../../schema";
import type {
  CogInputPropertyItemSchema,
  CogInputSchema,
  CogTrainingInputSchema,
  Version,
} from "../../types";
import { route } from "../../urls";
import { usePlaygroundContext } from "./context";

async function updateFeaturedInputs({
  version,
  data,
  type,
}: {
  version: Version;
  data: Record<string, boolean>;
  type: "prediction" | "training";
}) {
  const url = route(
    type === "prediction"
      ? "api_model_display_settings_update"
      : "api_model_training_display_settings_update",
    {
      username: version._extras.model.owner,
      name: version._extras.model.name,
    }
  );

  const payload = {
    hidden: Object.keys(data).filter(
      (name) => data[name] === false || data[name] === undefined
    ),
  };

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!res.ok) {
    const error = await res.json();
    throw new Error(
      error?.detail || error?.message || "An unknown error occurred"
    );
  }
}

export function FeaturedInputs({
  schema,
  type,
  alwaysRequired,
}: {
  schema: CogInputSchema | CogTrainingInputSchema;
  type: "prediction" | "training";
  alwaysRequired?: string[];
}) {
  const properties = getSortedProperties(schema);
  const required: CogInputSchema["required"] = schema.required || [];

  const { version, modelInputSettings } = usePlaygroundContext();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const defaultValues = Object.fromEntries(
    properties.map(([key]) => [key, !modelInputSettings.hidden.includes(key)])
  );

  const { handleSubmit, control, reset } = useForm({ defaultValues });

  const mutation = useMutation({
    mutationFn: updateFeaturedInputs,
    onSuccess: () => {
      setDrawerOpen(false);
      window.location.reload();
    },
  });

  const onSubmit = async (data) => {
    mutation.mutate({
      version,
      data,
      type,
    });
  };

  const ifRequiredAndNoDefault = (
    name: string,
    property: CogInputPropertyItemSchema
  ) => {
    return required?.includes(name) && property.default === undefined;
  };

  return (
    <DialogProvider open={drawerOpen} setOpen={setDrawerOpen}>
      <DialogDisclosure
        render={
          <Button startIcon={<NotePencil />} size="sm" variant="outlined" />
        }
      >
        Edit visible inputs
      </DialogDisclosure>
      <Dialog
        side="right"
        className="max-w-[600px] overflow-y-scroll"
        variant="drawer"
      >
        <div className="flex items-center justify-between p-3">
          <DialogHeading>Edit visible inputs</DialogHeading>
          <DialogDismiss />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-3 pb-3 flex flex-col gap-4">
            <p>
              You can choose to hide advanced inputs on your model to make it
              easier to use. By deselecting them, advanced inputs appear behind
              a toggle, after the other inputs.
            </p>

            <div className="flex flex-col divide-y divide-r8-gray-5 border border-r8-gray-8 rounded bg-r8-gray-2">
              {properties.map(([name, property]) => {
                return (
                  <label
                    key={name}
                    className="flex items-center has-[:checked]:bg-r8-gray-3 first:rounded-t-[3px] last:rounded-b-[3px]"
                  >
                    <div
                      className={`p-4 border-r border-r8-gray-5 flex-shrink-0 [place-self:normal] ${
                        ifRequiredAndNoDefault(name, property) ||
                        alwaysRequired?.includes(name)
                          ? "opacity-25"
                          : ""
                      }`}
                    >
                      <Controller
                        name={name}
                        control={control}
                        render={({
                          field: { value: fieldValue, ...props },
                        }) => (
                          <Checkbox
                            {...props}
                            disabled={
                              ifRequiredAndNoDefault(name, property) ||
                              alwaysRequired?.includes(name)
                            }
                            defaultChecked={
                              ifRequiredAndNoDefault(name, property) ||
                              alwaysRequired?.includes(name)
                                ? true
                                : fieldValue
                            }
                            checked={
                              ifRequiredAndNoDefault(name, property) ||
                              alwaysRequired?.includes(name)
                                ? true
                                : fieldValue
                            }
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-wrap gap-x-2 px-4 py-2">
                      <span className="font-mono text-r8-sm break-all">
                        {name}
                      </span>
                      {ifRequiredAndNoDefault(name, property) ||
                      alwaysRequired?.includes(name) ? (
                        <span title="This field is required">
                          <Asterisk color="red" weight="bold" size={12} />
                        </span>
                      ) : null}
                      {"type" in property ? (
                        <span className="text-r8-sm text-r8-gray-11">
                          {property.type}
                        </span>
                      ) : null}
                    </div>
                  </label>
                );
              })}
            </div>
            {mutation.error && (
              <Banner
                severity="error"
                icon={<WarningCircle />}
                description={
                  mutation.error?.message || "An unknown error occurred"
                }
                condensed
              />
            )}
            <div className="flex justify-end gap-2">
              <DialogDismiss
                onClick={() => reset(defaultValues)}
                render={<Button variant="outlined" />}
              >
                Cancel
              </DialogDismiss>
              <Button type="submit">Save</Button>
            </div>
          </div>
        </form>
      </Dialog>
    </DialogProvider>
  );
}
