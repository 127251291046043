import { P, match } from "ts-pattern";
import type {
  AnyAnyOfCogPropertyItemSchema,
  AnyOfCogPropertyItemSchema,
  BasicStringArrayCogPropertyItemSchema,
  BasicStringCogPropertyItemSchema,
} from "../../types";
import { FallbackInput } from "./fallback-input";
import { TextInput } from "./text-input";

export function AnyOfInput({
  disabled,
  name,
  onSubmit,
  required,
  schema,
}: {
  disabled: boolean;
  name: string;
  onSubmit?: () => void;
  required: boolean;
  schema: AnyAnyOfCogPropertyItemSchema;
}) {
  return match(schema)
    .with(
      {
        default: P.union(P.string, P.array(P.string)).optional(),
        anyOf: [
          {
            type: "string",
            format: P.nullish.optional(),
          },
          {
            type: "array",
            items: {
              type: "string",
              format: P.nullish.optional(),
            },
          },
        ],
      },
      (matchedSchema) => {
        // Check at build time that we've filtered to an expected type.
        matchedSchema satisfies AnyOfCogPropertyItemSchema<
          | BasicStringCogPropertyItemSchema
          | BasicStringArrayCogPropertyItemSchema
        >;

        return (
          <TextInput
            disabled={disabled}
            name={name}
            onSubmit={onSubmit}
            required={required}
            type="string | string[]"
          />
        );
      }
    )
    .otherwise((matchedSchema) => {
      return (
        <FallbackInput
          name={name}
          required={required}
          schema={matchedSchema}
          report
        />
      );
    });
}
