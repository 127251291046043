import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { formatCurrency } from "../intl_helpers";
import type { Account, ErrorWithJSON, Invoice } from "../types";
import { route } from "../urls";
import GlyphLoader from "./glyph-loader";

export default function Invoices({
  account,
}: {
  account: Account;
}) {
  const query = useInvoicesQuery(account);

  if (query.isError) {
    return (
      <p className="text-r8-red-10">
        Sorry, we were unable to load your invoices.
      </p>
    );
  }

  if (query.isPending) {
    return (
      <div className="flex items-center gap-2">
        <GlyphLoader />
        <p>Loading invoices...</p>
      </div>
    );
  }

  const dateTimeFormatter = new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });

  return (
    <table className="table-auto w-full text-sm">
      <thead>
        <tr className="border-b border-r8-gray-5 text-left text-base text-r8-gray-11 font-normal">
          <th className="p-2 font-normal">Billing Period</th>
          <th className="p-2 font-normal">Status</th>
          <th className="p-2 font-normal">Total</th>
        </tr>
      </thead>
      <tbody>
        {query.data.invoices.map((invoice) => (
          <tr key={invoice.id} className="border-b border-r8-gray-5">
            <td className="p-2">
              <a
                href={
                  account.kind === "organization"
                    ? route("organization_invoice_detail", {
                        organization_name: account.username,
                        invoice_id: invoice.id,
                      })
                    : route("user_invoice_detail", {
                        username: account.username,
                        invoice_id: invoice.id,
                      })
                }
              >
                {invoice.ended_before != null
                  ? dateTimeFormatter.formatRange(
                      new Date(invoice.started_on),
                      new Date(invoice.ended_before)
                    )
                  : `${dateTimeFormatter.format(
                      new Date(invoice.started_on)
                    )} –`}
              </a>
            </td>
            <td className="p-2">{formatInvoiceStatus(invoice.status)}</td>
            <td className="p-2">{formatCurrency(invoice.total_cost)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function formatInvoiceStatus(status: string) {
  switch (status) {
    case "DRAFT":
      return "In progress";
    case "FINALIZED":
      return "Issued";
    default:
      return status;
  }
}

export interface InvoicesResponse {
  invoices: Invoice[];
  unused_credit: string;
}

export function useInvoicesQuery(
  account: Account
): UseQueryResult<InvoicesResponse, ErrorWithJSON> {
  return useQuery({
    queryKey: ["invoices", account.kind, account.username],
    queryFn: () => fetchInvoices(account),
    refetchOnWindowFocus: false,
  });
}

async function fetchInvoices(account: Account): Promise<InvoicesResponse> {
  const res = await fetch(
    account.kind === "organization"
      ? route("api_organization_list_invoices", {
          organization_name: account.username,
        })
      : route("api_user_list_invoices", {
          username: account.username,
        })
  );

  if (res.ok) {
    return res.json<InvoicesResponse>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to fetch invoices",
      status: res.status,
    });
  }
}
