import { Button, Checkbox, Label, TextField } from "@replicate/ui";
import Cookies from "js-cookie";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { route } from "../urls";

const ONBOARDING_SURVEY_PROVENANCE_OPTIONS = [
  {
    name: "Search",
    value: "search",
  },
  {
    name: "YouTube",
    value: "youtube",
  },
  {
    name: "X (Twitter)",
    value: "twitter",
  },
  {
    name: "Instagram",
    value: "instagram",
  },
  {
    name: "TikTok",
    value: "tiktok",
  },
  {
    name: "Facebook",
    value: "facebook",
  },
  {
    name: "Reddit",
    value: "reddit",
  },
  {
    name: "LinkedIn",
    value: "linkedin",
  },
  {
    name: "Discord",
    value: "discord",
  },
  {
    name: "At an event",
    value: "event",
  },
  {
    name: "Hacker News",
    value: "hackernews",
  },
  {
    name: "Word of mouth",
    value: "word_of_mouth",
  },
  {
    name: "Other",
    value: "other",
  },
];
const USER_SURVEY_ENTITY_TYPE_OPTIONS = [
  {
    name: "For personal use",
    value: "personal",
  },
  {
    name: "For business use",
    value: "business",
  },
  {
    name: "For academic use",
    value: "academic",
  },
];

const USER_SURVEY_USAGE_INTENT_OPTIONS = [
  {
    name: "Adding AI to an existing application",
    value: "existing_app",
  },
  {
    name: "Using AI in a new application",
    value: "new_app",
  },
  {
    name: "Uploading a private AI model",
    value: "private_model",
  },
  {
    name: "Exploring new models",
    value: "exploring",
  },
  {
    name: "Fine-tuning existing models",
    value: "fine_tuning",
  },
  {
    name: "Other",
    value: "other",
  },
];

const USER_SURVEY_PREFERRED_PROGRAMMING_LANGUAGE = [
  {
    name: "Python",
    value: "python",
  },
  {
    name: "JavaScript",
    value: "javascript",
  },
  {
    name: "TypeScript",
    value: "typescript",
  },
  {
    name: "Go",
    value: "go",
  },
  {
    name: "Elixir",
    value: "elixir",
  },
  {
    name: "Swift",
    value: "swift",
  },
  {
    name: "Other",
    value: "other",
  },
];

const Step1EmailPreferences = ({ setCurrentStep, defaultUserEmail }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const url = "/users/onboarding/email";
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append(
      "marketing_email_opt_in",
      data.marketing_email_opt_in || "false"
    );

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: formData,
    });

    if (res.ok) {
      setCurrentStep(2);
    } else {
      const errors = await res.json();
      for (const key of Object.keys(errors)) {
        setError(key, {
          type: "manual",
          message: errors[key][0],
        });
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2 space-y-2">
          <Label htmlFor="id_email">
            What is your preferred email address?
          </Label>
          <TextField
            id="id_email"
            maxLength={255}
            placeholder="Email"
            required
            type="email"
            defaultValue={defaultUserEmail}
            {...register("email", {
              required: "Email is required",
              pattern: { value: /^\S+@\S+$/i, message: "Invalid email format" },
            })}
          />
          {errors.email && (
            <p className="text-r8-sm text-r8-red-10">
              {errors.email.message?.toString()}
            </p>
          )}
        </div>
        <div className="mb-2">
          <Label
            className="inline-flex items-center mb-2"
            htmlFor="id_marketing_email_opt_in"
          >
            <Checkbox
              {...register("marketing_email_opt_in")}
              id="id_marketing_email_opt_in"
              type="checkbox"
            />
            <span className="ml-2">
              I would like to receive the{" "}
              <a
                href={route("newsletter_index")}
                className="underline font-bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Replicate Intelligence newsletter
              </a>
              .
            </span>
          </Label>
          <hr className="my-5" />
        </div>

        <Button type="submit" className="mt-2">
          Save email preferences
        </Button>
      </form>
    </section>
  );
};

const Step2UserOnboardingSurvey = ({ setCurrentStep }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const url = "/users/onboarding";
    const formData = new FormData();
    formData.append("usage_type", data.usage_type);
    formData.append("usage_intent", data.usage_intent);
    formData.append("discovery_method", data.discovery_method);
    formData.append("user_research_opt_in", data.user_research_opt_in);
    formData.append(
      "preferred_programming_language",
      data.preferred_programming_language
    );

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: formData,
    });

    if (res.ok) {
      window.location.href = "/";
    }
  };

  const onDismiss = async (data) => {
    const url = "/users/onboarding/dismiss";
    const formData = new FormData();
    formData.append("dismiss", "true");

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: formData,
    });

    if (res.ok) {
      window.location.href = "/";
    }
  };

  return (
    <section>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="mb-2">
            <Label htmlFor="id_usage_type">
              What best describes how you are intending to use Replicate?
            </Label>
            <div className="[&_select]:w-full tw-select my-2 space-y-2">
              <select
                id="id_usage_type"
                className="w-full my-2"
                {...register("usage_type", {
                  required: "This field is required",
                })}
              >
                <option value="">Please select one</option>
                {USER_SURVEY_ENTITY_TYPE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.usage_type && (
                <p className="text-r8-sm text-r8-red-10">
                  {errors.usage_type.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <Label htmlFor="id_preferred_programming_language">
              What is your preferred programming language?
            </Label>
            <div className="[&_select]:w-full tw-select my-2 space-y-2">
              <select
                id="id_preferred_programming_language"
                className="w-full my-2"
                {...register("preferred_programming_language", {
                  required: "This field is required",
                })}
              >
                <option value="">Please select one</option>
                {USER_SURVEY_PREFERRED_PROGRAMMING_LANGUAGE.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.preferred_programming_language && (
                <p className="text-r8-sm text-r8-red-10">
                  {errors.preferred_programming_language.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <Label htmlFor="id_usage_intent">
              What are you going to use Replicate for?
            </Label>
            <div className="[&_select]:w-full tw-select my-2 space-y-2">
              <select
                id="id_usage_intent"
                className="w-full my-2"
                {...register("usage_intent", {
                  required: "This field is required",
                })}
              >
                <option value="">Please select one</option>
                {USER_SURVEY_USAGE_INTENT_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.usage_intent && (
                <p className="text-r8-sm text-r8-red-10">
                  {errors.usage_intent.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <Label htmlFor="id_discovery_method">
              Where did you learn about Replicate?
            </Label>
            <div className="[&_select]:w-full tw-select my-2 space-y-2">
              <select
                id="id_discovery_method"
                className="w-full my-2"
                {...register("discovery_method", {
                  required: "This field is required",
                })}
              >
                <option value="">Please select one</option>
                {ONBOARDING_SURVEY_PROVENANCE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.discovery_method && (
                <p className="text-r8-sm text-r8-red-10">
                  {errors.discovery_method.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div>
            <Label
              className="inline-flex items-center mb-2"
              htmlFor="id_user_research_opt_in"
            >
              <Checkbox
                {...register("user_research_opt_in")}
                id="id_user_research_opt_in"
                type="checkbox"
              />
              <span className="ml-2">
                I’m interested in participating in user research
              </span>
            </Label>
            <hr className="my-5" />
          </div>
          <div className="flex justify-between items-center mt-4">
            <Button type="button" variant="outlined" onClick={onDismiss}>
              Skip this
            </Button>
            <Button type="submit" className="ml-auto">
              🚀 Continue
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default function OnboardingUserSurvey({
  userJoinedToday,
  currentUserEmail,
}: {
  userJoinedToday: boolean;
  currentUserEmail: string;
}) {
  const [currentStep, setCurrentStep] = useState(1);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1EmailPreferences
            setCurrentStep={setCurrentStep}
            defaultUserEmail={currentUserEmail}
          />
        );
      case 2:
        return <Step2UserOnboardingSurvey setCurrentStep={setCurrentStep} />;
      default:
        return (
          <Step1EmailPreferences
            setCurrentStep={setCurrentStep}
            defaultUserEmail={currentUserEmail}
          />
        );
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-8 max-w-2xl">
      <div className="content-container">
        <h3 className="mb-lh">
          👋 {userJoinedToday ? "Welcome to Replicate" : "Welcome back"}
        </h3>

        {renderStep()}
      </div>
    </div>
  );
}
