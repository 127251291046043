import { Broadcast as BroadcastIcon } from "@phosphor-icons/react";
import { useState } from "react";
import CodeBlock from "./code-block";

type PropsType<C> = C extends React.ComponentType<infer P> ? P : never;

export type Props = Omit<
  PropsType<typeof CodeBlock>,
  "textContent" | "copyContent"
> & {
  streamingContent: string;
  standardContent: string;
};

export default function StreamingCodeBlock(props: Props) {
  const { streamingContent, standardContent, ...rest } = props;

  const [isStreaming, setIsStreaming] = useState(true);
  const textContent = isStreaming ? streamingContent : standardContent;

  const handleStreamingClick = () => {
    setIsStreaming((isStreaming) => !isStreaming);
  };

  return (
    <CodeBlock
      {...rest}
      textContent={textContent}
      extraActions={[
        {
          label: "Streaming",
          icon: (
            <BroadcastIcon
              className={isStreaming ? "text-r8-blue-10" : undefined}
            />
          ),
          tooltip: isStreaming ? "Show run API" : "Show stream API",
          onClick: handleStreamingClick,
        },
      ]}
    />
  );
}
