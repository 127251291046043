import { Suspense, lazy, type ComponentPropsWithoutRef } from "react";
const GroupedInvoiceInner = lazy(() => import("./grouped-invoice"));

export default function GroupedInvoice(
  props: ComponentPropsWithoutRef<typeof GroupedInvoiceInner>
) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <GroupedInvoiceInner {...props} />
    </Suspense>
  );
}
