import { Button } from "@replicate/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { Prediction } from "../../types";
import { cancelPrediction } from "./api";
import { queryKeys } from "./hooks";

export function CancelPredictionButton({
  prediction,
}: {
  prediction: Prediction;
}) {
  const client = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: cancelPrediction,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: queryKeys.predictions.uuid(prediction.id),
      });
    },
  });

  return (
    <Button
      onClick={() => {
        mutate({ id: prediction.id });
      }}
      disabled={isPending}
      loading={isPending}
      variant="outlined"
    >
      <span>{isPending ? "Canceling..." : "Cancel"}</span>
    </Button>
  );
}
