import {
  type ReactNode,
  Suspense,
  lazy,
  type ComponentPropsWithoutRef,
} from "react";
import { Toaster } from "react-hot-toast";

const ManageAPITokensInner = lazy(() => import("./component"));

export default function ManageAPITokens(
  props: ComponentPropsWithoutRef<typeof ManageAPITokensInner>
): ReactNode {
  return (
    <>
      <Suspense>
        <ManageAPITokensInner {...props} />
      </Suspense>
      <Toaster />
    </>
  );
}
