import { Suspense, lazy } from "react";
import { SvixCustomerContext } from "../svix/context";

const SvixWebhookLogsComponent = lazy(() => import("./component"));

export default function SvixWebhookLogs(props: {
  svixCustomerContext: SvixCustomerContext;
}) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SvixCustomerContext.Provider value={props.svixCustomerContext}>
        <SvixWebhookLogsComponent
          appId={props.svixCustomerContext.app_id}
          token={props.svixCustomerContext.token}
        />
      </SvixCustomerContext.Provider>
    </Suspense>
  );
}
