export function PythonIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      aria-hidden="true"
    >
      <path
        d="M9.59866 0.120468L10.2023 0.254322L10.692 0.428332L11.0878 0.629113L11.3896 0.843279L11.6177 1.07083L11.7854 1.29838L11.8927 1.51924L11.9598 1.72002L11.9866 1.89403L12 2.02789L11.9933 2.11489V5.68879L11.9598 6.11043L11.8726 6.47853L11.7317 6.78639L11.5573 7.04071L11.3561 7.24819L11.1347 7.4155L10.8999 7.54267L10.6652 7.63636L10.4438 7.70329L10.2426 7.75014L10.0682 7.77691L9.92733 7.7903H5.92286L5.46003 7.82376L5.06428 7.91746L4.7289 8.0647L4.45389 8.2454L4.23253 8.45957L4.05143 8.69381L3.91727 8.93475L3.81666 9.18238L3.74958 9.41662L3.70263 9.63079L3.6758 9.81149L3.66238 9.95204V12H2.16657L2.02571 11.9799L1.8379 11.9331L1.62325 11.8528L1.38849 11.7323L1.14701 11.5583L0.905534 11.3173L0.670766 11.0095L0.456121 10.6146L0.268306 10.126L0.127446 9.53709L0.0335383 8.83436L0 8.01116L0.0402459 7.19465L0.147568 6.49861L0.308552 5.91634L0.523197 5.44116L0.764673 5.05968L1.03298 4.7652L1.3147 4.54434L1.59642 4.38371L1.86473 4.27663L2.1062 4.2097L2.32085 4.17624L2.48183 4.16955H2.58916L2.6294 4.17624H8.10285V3.62075H4.18558L4.17887 1.78026L4.16546 1.53263L4.19899 1.30508L4.27278 1.0976L4.38681 0.910206L4.5545 0.736196L4.76244 0.582264L5.01733 0.448411L5.31247 0.327942L5.65456 0.227552L6.0436 0.147239L6.47289 0.0803123L6.94913 0.0401562L7.46562 0.0133854L8.02907 0L8.88094 0.0334635L9.59866 0.120468ZM5.37283 1.44562L5.21856 1.66648L5.1649 1.94088L5.21856 2.21528L5.37283 2.44283L5.59419 2.59007L5.8692 2.65031L6.14422 2.59007L6.36557 2.44283L6.51984 2.21528L6.5735 1.94088L6.51984 1.66648L6.36557 1.44562L6.14422 1.29838L5.8692 1.23815L5.59419 1.29838L5.37283 1.44562Z"
        fill="#3776AB"
      />
      <path
        d="M6.40134 15.8795L5.79765 15.7457L5.30799 15.5717L4.91224 15.3709L4.6104 15.1567L4.38234 14.9292L4.21464 14.7016L4.10732 14.4808L4.04025 14.28L4.01342 14.106L4 13.9721L4.00671 13.8851L4.00671 10.3112L4.04025 9.88957L4.12745 9.52147L4.26831 9.21361L4.44271 8.95929L4.64393 8.75181L4.86529 8.5845L5.10006 8.45733L5.33482 8.36364L5.55618 8.29671L5.75741 8.24986L5.93181 8.22309L6.07267 8.2097L10.0771 8.2097L10.54 8.17624L10.9357 8.08254L11.2711 7.9353L11.5461 7.7546L11.7675 7.54044L11.9486 7.30619L12.0827 7.06525L12.1833 6.81762L12.2504 6.58338L12.2974 6.36921L12.3242 6.18851L12.3376 6.04796L12.3376 4L13.8334 4L13.9743 4.02008L14.1621 4.06693L14.3767 4.14724L14.6115 4.26771L14.853 4.44172L15.0945 4.68266L15.3292 4.99052L15.5439 5.38539L15.7317 5.87395L15.8726 6.46291L15.9665 7.16564L16 7.98885L15.9598 8.80535L15.8524 9.50139L15.6914 10.0837L15.4768 10.5588L15.2353 10.9403L14.967 11.2348L14.6853 11.4557L14.4036 11.6163L14.1353 11.7234L13.8938 11.7903L13.6792 11.8238L13.5182 11.8305L13.4108 11.8305L13.3706 11.8238L7.89715 11.8238L7.89715 12.3793L11.8144 12.3793L11.8211 14.2197L11.8345 14.4674L11.801 14.6949L11.7272 14.9024L11.6132 15.0898L11.4455 15.2638L11.2376 15.4177L10.9827 15.5516L10.6875 15.6721L10.3454 15.7724L9.9564 15.8528L9.52711 15.9197L9.05087 15.9598L8.53438 15.9866L7.97093 16L7.11906 15.9665L6.40134 15.8795ZM10.6272 14.5544L10.7814 14.3335L10.8351 14.0591L10.7814 13.7847L10.6272 13.5572L10.4058 13.4099L10.1308 13.3497L9.85578 13.4099L9.63443 13.5572L9.48016 13.7847L9.4265 14.0591L9.48016 14.3335L9.63443 14.5544L9.85578 14.7016L10.1308 14.7619L10.4058 14.7016L10.6272 14.5544Z"
        fill="#FFDC41"
      />
    </svg>
  );
}
