import { useAtomValue, useSetAtom } from "jotai";
import { createContext, useContext, useEffect, type ReactNode } from "react";
import { focusedFlagAtom, usedFlagsAtom, type Flags } from "./flag-store";

const FlagContext = createContext<Flags>({});

export const FlagProvider = FlagContext.Provider;

export function useFlag(name: string): boolean {
  const flags = useContext(FlagContext);
  const flagValue = flags[name];
  useUpdateFlagState(name, flagValue);

  return flagValue;
}

function useUpdateFlagState(name: string, value: boolean): void {
  const setUsedFlags = useSetAtom(usedFlagsAtom);

  useEffect(() => {
    setUsedFlags((curr) => {
      return {
        ...curr,
        [name]: {
          count: (curr[name] ?? { count: 0 }).count + 1,
          value,
        },
      };
    });

    return () => {
      setUsedFlags((curr) => {
        return {
          ...curr,
          [name]: {
            count: (curr[name] ?? { count: 1 }).count - 1,
            value,
          },
        };
      });
    };
  }, [name, setUsedFlags, value]);
}

export function IfFlag({
  flag,
  fallback,
  children,
}: {
  flag: keyof Flags;
  fallback?: ReactNode;
  children: ReactNode;
}): ReactNode {
  const focusedFlag = useAtomValue(focusedFlagAtom);
  const flagValue = useFlag(flag);
  const elClass = focusedFlag === flag ? "bg-r8-yellow-a3" : undefined;

  if (flagValue) return <div className={elClass}>{children}</div>;
  if (fallback) return <div className={elClass}>{fallback}</div>;
  return null;
}
