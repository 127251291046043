import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import React from "react";
import type { Version } from "../../types";
import { route } from "../../urls";
import { MS_TO_WAIT_FOR_COLD_BOOT } from "./constants";

export function PredictionColdBootWarning({
  runningSince,
  version,
}: {
  runningSince?: Date | number;
  version: Version;
}) {
  const [now] = React.useState(Date.now());

  if (
    runningSince === undefined ||
    differenceInMilliseconds(runningSince, now) < MS_TO_WAIT_FOR_COLD_BOOT
  ) {
    return null;
  }

  return (
    <div className="p-4 text-r8-sm text-r8-gray-11 bg-r8-gray-4">
      <p>
        It can sometimes take a bit of time for models to set up. If you'd like
        to find out more about why this happens, take a look at{" "}
        <a
          className="no-default no-focus underline"
          href="/docs/how-does-replicate-work#cold-boots"
        >
          the section on cold boots of our guide on how Replicate works
        </a>
        .
      </p>
      {version._extras.model.visibility === "private" && (
        <p className="mt-lh">
          Want to avoid cold boots?{" "}
          <a
            href={`${route("deployment_create")}?model=${
              version._extras.model.owner
            }/${version._extras.model.name}&version=${version.id}`}
          >
            Create a deployment
          </a>{" "}
          to keep this model running all the time.
        </p>
      )}
    </div>
  );
}
