import { type ComponentPropsWithoutRef, Suspense, lazy } from "react";

const RunDialog = lazy(() => import("./index"));

export default function RunDialogLazyWrapper(
  props: ComponentPropsWithoutRef<typeof RunDialog>
) {
  return (
    <Suspense>
      <RunDialog {...props} />
    </Suspense>
  );
}
