import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * useMediaMatch
 *
 * A react hook that signals whether or not a media query is matched.
 *
 * @param query The media query to signal on. Example, `"print"` will signal
 * `true` when previewing in print mode, and `false` otherwise.
 * @returns Whether or not the media query is currently matched.
 * @see https://rooks.vercel.app/docs/useMediaMatch
 */
export function useMediaMatch(query: string): boolean {
  const matchMedia = useMemo<MediaQueryList>(
    () => window.matchMedia(query),
    [query]
  );
  const [matches, setMatches] = useState<boolean>(() => matchMedia.matches);

  useEffect(() => {
    setMatches(matchMedia.matches);
    const listener = (event: MediaQueryListEventMap["change"]) =>
      setMatches(event.matches);

    if (matchMedia.addEventListener) {
      matchMedia.addEventListener("change", listener);
      return () => matchMedia.removeEventListener("change", listener);
    }

    matchMedia.addListener(listener);
    return () => matchMedia.removeListener(listener);
  }, [matchMedia]);

  if (typeof window === "undefined") {
    console.warn("useMediaMatch cannot function as window is undefined.");

    return false;
  }

  return matches;
}

const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

export const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return focused;
};

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = img.onabort = () => {
      reject(src);
    };
    img.src = src;
  });
}

export function usePreloadImages(images: string[], enabled = true) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    if (imagesPreloaded) return;
    if (!enabled) return;
    // biome-ignore lint/complexity/useLiteralKeys: TODO: Add `connection` to `Navigator`.
    if (window.navigator?.["connection"]?.saveData) return;

    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of images) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [images, enabled, imagesPreloaded]);

  return { imagesPreloaded };
}

export function usePersistentState<T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [isReady, setIsReady] = useState(false);
  const [state, setInternalState] = useState<T>(initialValue);

  useEffect(() => {
    try {
      const value = localStorage.getItem(key);

      if (value != null) {
        setInternalState(JSON.parse(value));
      }
    } catch (error) {
      console.error("Error reading from localStorage:", error);
    }

    setIsReady(true);
  }, [key]);

  function setState(value: T) {
    setInternalState(value);
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error writing to localStorage:", error);
    }
  }

  return [state, setState, isReady];
}

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useQueryParam(name: string, defaultValue?: string) {
  const [value, setValue] = useState<string | undefined>(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name) ?? defaultValue;
  });

  useEffect(() => {
    const updateURL = () => {
      const params = new URLSearchParams(window.location.search);

      if (value === undefined || value === defaultValue) {
        params.delete(name);
      } else {
        params.set(name, value);
      }

      const newURL = new URL(window.location.href);
      newURL.search = params.toString();
      window.history.replaceState(null, "", newURL.toString());
    };

    updateURL();
  }, [name, value, defaultValue]);

  return [value, setValue] as const;
}

interface RafHandle {
  id: number;
}

const setRafInterval = (callback: () => void, timeout = 0) => {
  const interval = timeout < 0 ? 0 : timeout;
  const handle: RafHandle = {
    id: 0,
  };

  let startTime = Date.now();

  const loop = () => {
    const nowTime = Date.now();
    if (nowTime - startTime >= interval) {
      startTime = nowTime;
      callback();
    }

    handle.id = requestAnimationFrame(loop);
  };

  handle.id = requestAnimationFrame(loop);

  return handle;
};

const clearRafInterval = (handle?: RafHandle | null) => {
  if (handle) {
    cancelAnimationFrame(handle.id);
  }
};

export const useRafInterval = (fn: () => void, timeout = 0) => {
  const timerRef = useRef<RafHandle>();

  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    timerRef.current = setRafInterval(() => {
      fnRef.current();
    }, timeout);

    return () => {
      clearRafInterval(timerRef.current);
    };
  }, [timeout]);

  const clear = useCallback(() => {
    clearRafInterval(timerRef.current);
  }, []);

  return clear;
};

export default useRafInterval;

function useFirstMountState(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isFirstMount = useFirstMountState();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!isFirstMount) {
      return effect();
    }
  }, deps);
};

type ColorScheme = "light" | "dark";

export function usePrefersColorScheme(): ColorScheme {
  const [preferredColorScheme, setPreferredColorScheme] =
    useState<ColorScheme>("light");

  useEffect(() => {
    if (!window.matchMedia) {
      setPreferredColorScheme("light");
      return;
    }

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    setPreferredColorScheme(mediaQuery.matches ? "dark" : "light");

    function onChange(event: MediaQueryListEvent): void {
      setPreferredColorScheme(event.matches ? "dark" : "light");
    }

    mediaQuery.addEventListener("change", onChange);

    return () => {
      mediaQuery.removeEventListener("change", onChange);
    };
  }, []);

  return preferredColorScheme;
}
