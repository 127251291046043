import { Cake } from "@phosphor-icons/react";
import {
  Banner,
  Button,
  Dialog,
  DialogDescription,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
} from "@replicate/ui";
import Cookies from "js-cookie";
import { useCallback, useState, type FormEvent, type ReactNode } from "react";
import { route } from "../urls";

// Note: It's the caller's responsibility to make sure doing this is safe and
// appropriate for this user.
export default function RenameAccountBanner({
  githubUsername,
  hasDeployments,
  hasModels,
  username,
}: {
  githubUsername: string;
  hasDeployments: boolean;
  hasModels: boolean;
  username: string;
}): ReactNode {
  const [dismissed, setDismissed] = useState(false);
  const dismissForNow = useCallback(
    (event?: FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }

      setDismissed(true);
      fetch(
        route("api_dismiss_account_rename", {
          username,
        }),
        {
          method: "POST",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
    },
    [username]
  );
  const dismissForever = useCallback(
    (event?: FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }

      setDismissed(true);

      const formData = new FormData();
      formData.set("permanent", "true");

      fetch(
        route("api_dismiss_account_rename", {
          username,
        }),
        {
          method: "POST",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
          body: formData,
        }
      );
    },
    [username]
  );
  const renameAccount = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }

      await fetch(
        route("api_rename_account", {
          username,
        }),
        {
          method: "POST",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      window.location.reload();
    },
    [username]
  );

  if (dismissed) {
    return null;
  }

  return (
    <Banner
      description={
        <div className="content-container mt-1 space-y-1">
          <p>
            It looks like your GitHub username has changed to{" "}
            <span className="font-mono">{githubUsername}</span>. Would you like
            to rename your account on Replicate from{" "}
            <span className="font-mono">{username}</span> to{" "}
            <span className="font-mono">{githubUsername.toLowerCase()}</span> to
            match?
          </p>
          {hasDeployments && hasModels ? (
            <p>
              Note: this will also rename all of your models and deployments to
              use your new username. You’ll need to update any references to
              your deployments by name in your code. Your models will continue
              to work using your current username until someone else creates an
              account with the same username and model name, so we recommend you
              also update any references to your models, asap.
            </p>
          ) : hasDeployments ? (
            <p>
              Note: this will also rename all of your deployments to use your
              new username. You’ll need to update any references to your
              deployments by name in your code.
            </p>
          ) : hasModels ? (
            <p>
              Note: this will also rename all of your models to use your new
              username. They’ll continue to work using your current username
              until someone else creates a model with the same name, so we
              recommend you also update any references to your models by name in
              your code, asap.
            </p>
          ) : null}
          <div className="pt-2 flex gap-2">
            <DialogProvider>
              <DialogDisclosure
                render={<Button intent="danger">Rename me</Button>}
              />
              <Dialog className="p-4" size="lg">
                <DialogHeading>Are you sure?</DialogHeading>
                <DialogDescription>
                  Once your account has been renamed to{" "}
                  <span className="font-mono">
                    {githubUsername.toLowerCase()}
                  </span>
                  , you can’t go back to{" "}
                  <span className="font-mono">{username}</span>.
                </DialogDescription>
                <div className="mt-6 flex items-center justify-end gap-4">
                  <DialogDismiss
                    render={<Button variant="outlined">Cancel</Button>}
                  />
                  <form onSubmit={renameAccount}>
                    <Button intent="danger" type="submit">
                      Rename me
                    </Button>
                  </form>
                </div>
              </Dialog>
            </DialogProvider>
            <form onSubmit={dismissForNow}>
              <Button type="submit" variant="outlined">
                Ask me later
              </Button>
            </form>
            <form onSubmit={dismissForever}>
              <Button type="submit" variant="outlined">
                Don’t ask me again
              </Button>
            </form>
          </div>
        </div>
      }
      dismissible
      flushTop
      fullWidth
      heading="Congratulations on the new username!"
      icon={<Cake weight="duotone" />}
      onDismiss={dismissForNow}
    />
  );
}
