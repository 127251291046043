import { DotsSixVertical, TextT, X } from "@phosphor-icons/react";
import { Button, IconButton } from "@replicate/ui";
import { useState } from "react";
import { useController, useFieldArray } from "react-hook-form";
import { Label } from "./label";

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type ArrayFieldValue<T> = {
  [key: string]: { value: T }[];
};

export function MultiStringInput({
  disabled,
  name,
  required,
  type,
}: {
  disabled: boolean;
  name: string;
  onSubmit?: () => void;
  required: boolean;
  type: "string[]";
  placeholder?: string;
}) {
  const [pendingValue, setPendingValue] = useState("");
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { field } = useController({ name });

  const { fields, append, remove, move } = useFieldArray<
    ArrayFieldValue<string>
  >({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
      validate: () => {
        if (pendingValue.length > 0) {
          return "Add value to the list";
        }
      },
    },
  });

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (pendingValue.length === 0) return;
      append({ value: pendingValue });
      setPendingValue("");
    }
  };

  const handleAddValue = () => {
    if (!pendingValue.length) return;
    append({ value: pendingValue });
    setPendingValue("");
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      const oldIndex = fields.findIndex((value) => value.id === active.id);
      const newIndex = fields.findIndex((value) => value.id === over.id);
      move(oldIndex, newIndex);
    }
  }

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:items-center md:justify-between">
        <Label type={type} Icon={TextT} required={required} name={name} />
      </div>
      <div>
        <div className="relative">
          <input
            disabled={disabled}
            className="border border-r8-gray-12 bg-white dark:bg-r8-gray-1 p-2 w-full pr-24 focus:outline-none focus:border-r8-gray-12"
            onKeyDown={handleInputKeyDown}
            placeholder="Add value to list below"
            {...field}
            value={pendingValue}
            onChange={(e) => {
              setPendingValue(e.target.value);
            }}
          />
          <div className="absolute right-2 top-0 bottom-0 flex items-center">
            <Button
              variant="filled"
              disabled={disabled}
              onClick={handleAddValue}
              size="xs"
            >
              Add value
            </Button>
          </div>
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <div className="my-1.5 divide-y divide-r8-gray-6">
            <SortableContext
              items={fields}
              strategy={verticalListSortingStrategy}
            >
              {fields.map(({ value, id }, index) => (
                <SortableItem
                  value={value}
                  disabled={disabled}
                  onDelete={() => remove(index)}
                  key={id}
                  id={id}
                />
              ))}
            </SortableContext>
          </div>
        </DndContext>
      </div>
    </div>
  );
}

function SortableItem({
  value,
  disabled,
  onDelete,
  id,
}: { value: string; disabled: boolean; onDelete: () => void; id: string }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="py-2 flex items-center justify-between group"
    >
      <div className="flex items-center gap-2 truncate flex-1">
        <div
          aria-label="Re-order item"
          role="button"
          className="text-r8-gray-11 group-hover:text-r8-gray-12 flex-shrink-0 cursor-move"
          {...listeners}
        >
          <DotsSixVertical weight="bold" />
        </div>
        <span className="text-r8-sm select-none truncate">{value}</span>
      </div>
      <div className="flex-shrink-0 flex items-center ml-4">
        <IconButton
          size="xs"
          variant="clear"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <X />
        </IconButton>
      </div>
    </div>
  );
}
