import { match } from "ts-pattern";
import {
  BannerFormSubmit,
  BannerLimitFreeUsage,
  BannerRunModelOnPlaygroundDotCom,
} from "./banners";
import { usePlaygroundContext } from "./context";

export function InputFormFooter({
  disabled,
  isAuthenticated,
  isLoading,
  modelRunnableOnPlaygroundDotCom,
  onReset,
  submitLabel = "Run",
  isOfficial = false,
}: {
  disabled: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  modelRunnableOnPlaygroundDotCom: boolean;
  onReset: () => void;
  submitLabel?: string;
  isOfficial?: boolean;
}) {
  const { version } = usePlaygroundContext();

  const conditions = {
    disabled,
    modelRunnableOnPlaygroundDotCom,
  };

  return (
    <div className="sticky bottom-[-4px] bg-white">
      {match(conditions)
        .with(
          {
            disabled: true,
            modelRunnableOnPlaygroundDotCom: true,
          },
          () => <BannerRunModelOnPlaygroundDotCom />
        )
        .with(
          {
            disabled: true,
          },
          () => (
            <BannerLimitFreeUsage
              isAuthenticated={isAuthenticated}
              version={version}
            />
          )
        )
        .with(
          {
            disabled: false,
          },
          () => (
            <BannerFormSubmit
              disabled={disabled}
              isLoading={isLoading}
              onReset={onReset}
              submitLabel={submitLabel}
            />
          )
        )
        .exhaustive()}
    </div>
  );
}
