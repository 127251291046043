import {
  Button,
  Dialog,
  DialogDescription,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
  TextField,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useCallback, useState, type FormEvent } from "react";
import { toast } from "react-hot-toast";
import type { Deployment } from "../types";
import { route } from "../urls";

const FALLBACK_ERROR_MESSAGE =
  "An unknown error occurred while renaming this deployment. Please try again.";

async function renameDeployment({
  deployment,
  newName,
}: {
  deployment: Deployment;
  newName: string;
}) {
  const formData = new FormData();
  formData.set("name", newName);

  const response = await fetch(
    route("api_deployment_edit_or_delete", {
      username: deployment.owner,
      name: deployment.name,
    }),
    {
      method: "PATCH",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: formData,
    }
  );

  if (!response.ok) {
    if (response.status === 400) {
      const { detail } = await response.json<{ detail: string }>();
      throw new Error(detail);
    }

    throw new Error(FALLBACK_ERROR_MESSAGE);
  }

  const { next } = await response.json<{ next: string }>();
  return next;
}

export function RenameDeploymentForm({
  deployment,
  disabledReason,
}: {
  deployment: Deployment;
  disabledReason: string;
}) {
  const [open, setOpen] = useState(false);

  const renameDeploymentMutation = useMutation({
    mutationFn: renameDeployment,
    onSuccess(next) {
      window.location.href = next;
    },
    onError(error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error(FALLBACK_ERROR_MESSAGE);
      }
    },
    onSettled() {
      setOpen(false);
    },
  });

  const [newName, setNewName] = useState(deployment.name);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      renameDeploymentMutation.mutate({ deployment, newName });
    },
    [deployment, newName, renameDeploymentMutation]
  );

  return (
    <div className="flex flex-col">
      <h3 className="text-r8-xl text-r8-gray-12 font-semibold mb-2">
        Rename deployment
      </h3>

      <div className="flex flex-col md:flex-row md:items-start gap-4 md:gap-8">
        <div className="flex-1 md:max-w-96">
          <TextField
            id="name"
            disabled={deployment._extras.disabled}
            onChange={(event) => {
              setNewName(event.currentTarget.value);
            }}
            helpText={
              "After renaming a deployment, you’ll need to update any references to it by name in your code to continue to use it."
            }
            required
            type="text"
            value={newName}
          />
        </div>
        <div className="shrink-0">
          <TooltipProvider showTimeout={0}>
            <TooltipAnchor>
              <DialogProvider open={open} setOpen={setOpen}>
                <DialogDisclosure
                  render={
                    <Button
                      intent="danger"
                      type="submit"
                      disabled={Boolean(disabledReason)}
                    />
                  }
                >
                  Rename deployment
                </DialogDisclosure>
                <Dialog className="p-4" size="lg">
                  <DialogHeading>Rename deployment</DialogHeading>
                  <DialogDescription className="mt-4">
                    Are you sure you want to rename the deployment from{" "}
                    <span className="text-r8-gray-11">
                      {deployment.owner}/{deployment.name}
                    </span>{" "}
                    to{" "}
                    <span className="text-r8-gray-12 font-semibold">
                      {deployment.owner}/{newName}
                    </span>
                  </DialogDescription>
                  <div className="mt-6 flex items-center justify-end gap-4">
                    <DialogDismiss
                      render={<Button variant="outlined">Cancel</Button>}
                    />
                    <form onSubmit={handleSubmit}>
                      <Button
                        loading={renameDeploymentMutation.isPending}
                        intent="danger"
                        type="submit"
                      >
                        Rename deployment
                      </Button>
                    </form>
                  </div>
                </Dialog>
              </DialogProvider>
            </TooltipAnchor>
            {disabledReason ? (
              <Tooltip>
                <TooltipArrow />
                {disabledReason}
              </Tooltip>
            ) : null}
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
}
