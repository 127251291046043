import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "../../hooks";
import { search, searchDeploymentModelOptions } from "./api";

export type SearchEntity = "models" | "collections" | "deployments";

export interface SearchParams<T extends SearchEntity[]> {
  query: string | undefined;
  debounceMs?: number;
  enabled?: boolean;
  entities: T;
}

export function useSearch<T extends SearchEntity[]>({
  query,
  entities,
  debounceMs = 300,
  enabled = true,
}: SearchParams<T>) {
  const debouncedSearchQuery = useDebounce(query, debounceMs);
  return useQuery({
    enabled: enabled && Boolean(debouncedSearchQuery),
    queryKey: ["search", entities.join(","), debouncedSearchQuery],
    queryFn: () => search(debouncedSearchQuery || "", entities),
  });
}

export function useSearchDeploymentModelOptions({
  query,
  debounceMs = 300,
  enabled = true,
}: Omit<SearchParams<["models"]>, "entities">) {
  const debouncedSearchQuery = useDebounce(query, debounceMs);
  return useQuery({
    enabled: enabled && Boolean(debouncedSearchQuery),
    queryKey: ["search", debouncedSearchQuery],
    queryFn: () => searchDeploymentModelOptions(debouncedSearchQuery),
  });
}
