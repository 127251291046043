import { Box, NativeSelect } from "@replicate/ui";
import { formatDistance } from "date-fns";
import { useState } from "react";
import { Remark } from "react-remark";

export interface VersionForVersionPicker {
  created_at: string; // ISO 8601 timestamp
  id: string;
  release_notes: string | null;
  short_id: string;
}

export default function VersionPicker({
  name,
  value,
  versions,
  onVersionChange,
}: {
  name: string;
  value: VersionForVersionPicker;
  versions: VersionForVersionPicker[];
  onVersionChange: (version: VersionForVersionPicker) => void;
}) {
  const [selectedVersion, setSelectedVersion] =
    useState<VersionForVersionPicker>(value);

  return (
    <div className="space-y-3">
      <NativeSelect
        id="version-picker"
        value={selectedVersion.id}
        name={name}
        onChange={(e) => {
          const newVersion = // biome-ignore lint/style/noNonNullAssertion: All possible values come from `versions`, so this will always be set.
            versions.find((v) => v.id === e.target.value)!;

          setSelectedVersion(newVersion);
          onVersionChange(newVersion);
        }}
      >
        {versions.map((version, index) => {
          return (
            <option key={version.id} value={version.id}>
              {version.short_id} (
              {formatDistance(new Date(version.created_at), new Date(), {
                addSuffix: true,
              })}
              {index === 0 && ", latest"})
            </option>
          );
        })}
      </NativeSelect>
      <Box variant="well" className="p-3 max-h-48 overflow-auto">
        <p className="text-r8-sm">Release notes: {selectedVersion.short_id}</p>
        {selectedVersion.release_notes ? (
          <div className="readme-prose text-r8-sm [&>*]:last-of-type:mb-0">
            <Remark>{selectedVersion.release_notes}</Remark>
          </div>
        ) : (
          <p className="text-r8-sm text-r8-gray-11">
            No release notes available
          </p>
        )}
      </Box>
    </div>
  );
}
