import type { ErrorWithJSON } from "../../types";

export type TimeDisplayPreference = "utc" | "local";

export interface TimeseriesDatum {
  time_bin: string;
}

export interface MetricsResponse<T extends TimeseriesDatum> {
  metrics: T[];
  bucket_size: number;
}

export async function fetchDeploymentMetrics<
  D extends TimeseriesDatum,
  M extends MetricsResponse<D>,
>(url: string): Promise<M> {
  const res = await fetch(url);

  if (res.ok) {
    return res.json<M>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to fetch metrics",
      status: res.status,
    });
  }
}
