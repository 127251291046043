import "vite/modulepreload-polyfill";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Suspense, useLayoutEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { initAnalytics } from "./analytics";
import { initHighlight, type SupportedLanguage } from "./highlight";
import { initLazyLoad } from "./lazy-load";
import { getMetaTagContent } from "./util";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AccountPicker from "./components/account-picker";
import APIPlayground from "./components/api-playground/index";
import PredictionDetailView from "./components/api-playground/prediction-detail-view";
import PredictionReportOutput from "./components/api-playground/prediction-report-output";
import ArcticPost from "./components/arctic-post";
import AuthToken from "./components/auth-token";
import BillingSummary from "./components/billing-summary";
import CodeBlock from "./components/code-block";
import CopyIconButton from "./components/copy-icon-button";
import CopyToClipboard from "./components/copy-to-clipboard";
import DarkModeToggle from "./components/dark-mode-toggle";
import DeleteModelButton from "./components/delete-model-button";
import DeleteVersionButton from "./components/delete-version-button";
import DeletedPredictionTooltip from "./components/deleted-prediction-tooltip";
import DeploymentAPIReference from "./components/deployment-api-reference";
import DeploymentCards from "./components/deployment-cards";
import DeploymentCreateForm from "./components/deployment-create-form";
import DeploymentMetrics from "./components/deployment-metrics";
import DeploymentSettingsForm from "./components/deployment-settings-form";
import DismissableBanner from "./components/dismissable-banner";
import EndAllSubscriptionsNotice from "./components/end-all-subscriptions-notice";
import ExampleList from "./components/example-list";
import FeedButtons from "./components/feed-buttons";
import FlagPopover from "./components/flags/flag-popover";
import { FlagProvider } from "./components/flags/if-flag";
import FluxBanner from "./components/flux-banner";
import GroupedInvoice from "./components/grouped-invoice";
import IconTooltip from "./components/icon-tooltip";
import Invite from "./components/invite";
import Invoices from "./components/invoices";
import Llama2Post from "./components/llama2-post";
import Llama3Post from "./components/llama3-post";
import Llama31Post from "./components/llama31-post";
import ManageAPITokens from "./components/manage-api-tokens";
import MissingReleaseNumberTooltip from "./components/missing-release-number-tooltip";
import MobileMenu from "./components/mobile-menu";
import ModelPricePopover from "./components/model-price-popover";
import ModelStatusIndicator from "./components/model-status-indicator";
import ModelVersionPicker from "./components/model-version-picker";
import NewsletterSignupForm from "./components/newsletter-signup";
import OfficialModelPriceList from "./components/official-model-price-list";
import OfficialModelPricing from "./components/official-model-pricing";
import Onboarding from "./components/onboarding";
import PredictionOutputPreview from "./components/prediction-output-preview";
import PriceTooltip from "./components/price-tooltip";
import ProfilePageAvatar from "./components/profile-page-avatar";
import RenameAccountBanner from "./components/rename-account-banner";
import RunDialog from "./components/run-dialog/lazy-wrapper";
import ScaffoldCommandBreakdown from "./components/scaffold-blog-post/command-breakdown";
import ScaffoldCommandDemo from "./components/scaffold-blog-post/command-demo";
import ScaffoldCommandExamples from "./components/scaffold-blog-post/command-examples";
import SearchForm from "./components/search/index";
import QuickSearchMenu from "./components/search/quick-search-menu";
import SetupRunLogs from "./components/setup-run-logs";
import SignInPageGoo from "./components/sign-in-page-goo";
import StableDiffusion3Post from "./components/stable-diffusion-3-post";
import StarModel from "./components/star-model";
import SupportForm from "./components/support-form";
import SvixWebhookLogs from "./components/svix-webhook-logs";
import TextTooltip from "./components/text-tooltip";
import Training from "./components/training";
import GenericTraining from "./components/training/generic";
import SDXLTraining from "./components/training/sdxl";
import VLLMTraining from "./components/training/vllm";
import UserOnboardingSurvey from "./components/user-onboarding-survey";
import VercelIntegration from "./components/vercel-integration";
import VercelProjectPicker from "./components/vercel-project-picker";
import VersionListItemMenu from "./components/version-list-item-menu";
import WebhookSigningKey from "./components/webhook-signing-key";

const components = {
  AccountPicker,
  APIPlayground,
  ArcticPost,
  AuthToken,
  BillingSummary,
  CopyIconButton,
  CopyToClipboard,
  DarkModeToggle,
  DeletedPredictionTooltip,
  DeleteModelButton,
  DeleteVersionButton,
  DeploymentAPIReference,
  DeploymentCards,
  DeploymentCreateForm,
  DeploymentMetrics,
  DeploymentSettingsForm,
  DismissableBanner,
  EndAllSubscriptionsNotice,
  ExampleList,
  FeedButtons,
  FlagPopover,
  FluxBanner,
  GenericTraining,
  GroupedInvoice,
  IconTooltip,
  Invite,
  Invoices,
  Llama2Post,
  Llama3Post,
  Llama31Post,
  StableDiffusion3Post,
  ManageAPITokens,
  MissingReleaseNumberTooltip,
  MobileMenu,
  ModelPricePopover,
  ModelStatusIndicator,
  ModelVersionPicker,
  NewsletterSignupForm,
  OfficialModelPricing,
  OfficialModelPriceList,
  Onboarding,
  PredictionDetailView,
  PredictionOutputPreview,
  PredictionReportOutput,
  PriceTooltip,
  ProfilePageAvatar,
  QuickSearchMenu,
  RenameAccountBanner,
  RunDialog,
  ScaffoldCommandBreakdown,
  ScaffoldCommandDemo,
  ScaffoldCommandExamples,
  SDXLTraining,
  SearchForm,
  SetupRunLogs,
  SignInPageGoo,
  StarModel,
  SupportForm,
  SvixWebhookLogs,
  TextTooltip,
  Training,
  UserOnboardingSurvey,
  VercelIntegration,
  VercelProjectPicker,
  VersionListItemMenu,
  VLLMTraining,
  WebhookSigningKey,
};

const queryClient = new QueryClient();

/**
 * Attempts to transform any <pre><code /></pre> blocks into our CodeBlock React component.
 */
function initCodeBlocks() {
  for (const el of document.body.querySelectorAll("pre")) {
    if (el.classList.contains("no-copy-button")) {
      break;
    }

    const code = el.querySelector("code");

    if (!code) {
      break;
    }

    if (!code.textContent) break;

    // If the code has a "language-xxx" class, we'll use that as the language.
    // Otherwise, we'll default to "".
    const languageClass = Array.from(code.classList).find((c) =>
      c.startsWith("language-")
    );

    const maybeLanguage = languageClass?.split("-")[1];

    const props = {
      language: maybeLanguage as SupportedLanguage,
      textContent: code.textContent,
      className: code.className,
    };

    // Let's wrap the pre tag in a div. This shouldn't modify the layout,
    // but it gives us a place to render the component.
    const wrappingDiv = document.createElement("div");
    wrappingDiv.classList.add("pre-code-wrap");
    el.parentNode?.insertBefore(wrappingDiv, el);
    wrappingDiv.appendChild(el);

    const instance = (
      <Sentry.ErrorBoundary
        fallback={() => {
          return (
            <pre className="code">
              <code className={code.className}>{code.textContent}</code>
            </pre>
          );
        }}
      >
        <CodeBlock {...props} />
      </Sentry.ErrorBoundary>
    );

    const root = createRoot(wrappingDiv);
    root.render(instance);
  }
}

function getReactPlaceholder(componentName: string): Element | null {
  return document.querySelector(`[data-react-placeholder="${componentName}"]`);
}

/**
 * Encapsulates a Django-rendered React component, ensuring:
 *
 * 1. The component is wrapped in a Sentry.ErrorBoundary.
 * 2. The component is wrapped in a FlagProvider.
 * 3. The component is wrapped in a QueryClientProvider.
 * 4. The component is wrapped in a Suspense boundary.
 * 5. A placeholder element is remove when the component is mounted and/or the
 *    lazy imported component is loaded.
 */
function DjangoReactComponent({
  componentName,
  flags,
  children,
}: {
  componentName: string;
  flags: Record<string, boolean>;
  children: React.ReactNode;
}) {
  const [placeholderElMarkup] = useState(() => {
    const maybePlaceholderEl = getReactPlaceholder(componentName);
    if (!maybePlaceholderEl) return null;
    return maybePlaceholderEl.innerHTML;
  });

  useLayoutEffect(() => {
    const placeholder = getReactPlaceholder(componentName);
    if (placeholder) {
      placeholder.remove();
    }
  }, [componentName]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <p className="text-red">
          Oops – something went wrong. Refresh the page and try again.
        </p>
      }
    >
      <FlagProvider value={flags}>
        <QueryClientProvider client={queryClient}>
          <Suspense
            fallback={
              placeholderElMarkup ? (
                <div
                  // biome-ignore lint/security/noDangerouslySetInnerHtml: This is HTML generated by Django, and therefore safe as long as the Django template is safe.
                  dangerouslySetInnerHTML={{ __html: placeholderElMarkup }}
                />
              ) : null
            }
          >
            {children}
          </Suspense>
        </QueryClientProvider>
      </FlagProvider>
    </Sentry.ErrorBoundary>
  );
}

/**
 * Attempts to transform the markup generated by our {% react_component %}
 * helper into an actual React component.
 */
function initReactComponents() {
  for (const el of document.body.querySelectorAll<HTMLElement>(
    "[data-component]"
  )) {
    const component = el.dataset.component;
    if (!component) {
      throw new Error("Missing required data-component attribute");
    }

    const Component = components[component];
    if (!Component) {
      throw new Error(`Component ${component} does not exist`);
    }

    const propsScriptId = el.dataset.props;
    if (!propsScriptId) {
      throw new Error("Missing required data-props attribute");
    }

    const propsEl = document.getElementById(propsScriptId);
    if (!propsEl) {
      throw new Error(
        "Can't find element with id matching data-props attribute value"
      );
    }
    if (!propsEl.textContent) {
      throw new Error(
        "Element with id matching data-props attribute value has no content"
      );
    }

    const { __flags, ...props } = JSON.parse(propsEl.textContent);

    const instance = (
      <DjangoReactComponent componentName={component} flags={__flags ?? {}}>
        <Component {...props} />
      </DjangoReactComponent>
    );

    const root = createRoot(el);
    root.render(instance);
  }
}

function initSentry() {
  const sentryDsnKey = getMetaTagContent("sentry-dsn-js");
  const anonymousId = getMetaTagContent("anonymous-id");
  const userId = getMetaTagContent("user-id") ?? anonymousId;

  if (!sentryDsnKey) {
    console.warn("Sentry DSN key not found");
    return;
  }

  try {
    Sentry.init({
      dsn: sentryDsnKey,
      integrations: [new BrowserTracing()],
      // Record 10% of page transactions. Note that you can provide a function
      // to tracesSampler instead if you need to opt into a higher sampling rate
      // for a particular page or context.
      tracesSampleRate: 0.1,
      beforeSend(event) {
        // https://www.ctrl.blog/entry/detect-machine-translated-webpages.html
        const translated = Boolean(
          document.querySelector(
            "html.translated-ltr, html.translated-rtl, ya-tr-span, *[_msttexthash], *[x-bergamot-translated]"
          )
        );
        event.extra = {
          ...event.extra,
          translated,
        };
        return event;
      },
    });
    Sentry.configureScope((scope) => {
      if (userId) {
        scope.setUser({ id: userId }); // nice job copilot
      }
    });
  } catch (e) {
    console.warn("Sentry initialization failed", e);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  initSentry();
  initAnalytics();
  initCodeBlocks();
  initReactComponents();
  initHighlight();
  initLazyLoad();
});
