import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export function RenderInPortal({
  children,
  domNodeId,
}: {
  children: React.ReactNode;
  domNodeId: string;
}) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  const domNode = document.getElementById(domNodeId);

  if (!domNode) {
    console.error(`RenderInPortal: DOM node with ID ${domNodeId} not found.`);
    return null;
  }

  return createPortal(children, domNode);
}
