import {
  Button,
  Dialog,
  DialogDescription,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
  TextField,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useState, type FormEvent } from "react";
import { toast } from "react-hot-toast";
import type { Deployment } from "../types";
import { route } from "../urls";

const FALLBACK_ERROR_MESSAGE =
  "An unknown error occurred while deleting this deployment. Please try again.";

async function deleteDeployment({
  deployment,
}: {
  deployment: Deployment;
}) {
  const response = await fetch(
    route("api_deployment_edit_or_delete", {
      username: deployment.owner,
      name: deployment.name,
    }),
    {
      method: "DELETE",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    }
  );

  if (!response.ok) {
    if (response.status === 400) {
      const { detail } = await response.json<{ detail: string }>();
      throw new Error(detail);
    }

    throw new Error(FALLBACK_ERROR_MESSAGE);
  }

  const { next } = await response.json<{ next: string }>();
  return next;
}

export function DeleteDeploymentButton({
  deployment,
  disabledReason,
}: {
  deployment: Deployment;
  disabledReason: string;
}) {
  const [typedDeploymentName, setTypedDeploymentName] = useState("");

  const deleteDeploymentMutation = useMutation({
    mutationFn: deleteDeployment,
    onSuccess(next) {
      window.location.href = next;
    },
    onError(error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error(FALLBACK_ERROR_MESSAGE);
      }
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteDeploymentMutation.mutate({ deployment });
  };

  const hasConfirmedDeploymentName =
    typedDeploymentName === `${deployment.owner}/${deployment.name}`;

  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor>
        <DialogProvider>
          <DialogDisclosure
            render={
              <Button intent="danger" disabled={Boolean(disabledReason)} />
            }
          >
            Delete deployment
          </DialogDisclosure>
          <Dialog className="p-4" size="lg">
            <DialogHeading>Delete deployment</DialogHeading>
            <DialogDescription>
              Are you sure you want to delete the deployment{" "}
              <span className="font-mono">
                {deployment.owner}/{deployment.name}
              </span>
              ? This cannot be undone.
            </DialogDescription>
            <div className="my-4">
              <p className="text-r8-sm text-r8-gray-11 mb-2">
                To confirm, type "{deployment.owner}/{deployment.name}" in the
                box below
              </p>
              <TextField
                value={typedDeploymentName}
                onChange={(e) => {
                  setTypedDeploymentName(e.target.value);
                }}
                id="confirmation"
              />
            </div>
            <div className="mt-6 flex items-center justify-end gap-4">
              <DialogDismiss
                render={<Button variant="outlined">Cancel</Button>}
              />
              <form onSubmit={handleSubmit}>
                <Button
                  disabled={!hasConfirmedDeploymentName}
                  loading={deleteDeploymentMutation.isPending}
                  intent="danger"
                  type="submit"
                >
                  Delete deployment
                </Button>
              </form>
            </div>
          </Dialog>
        </DialogProvider>
      </TooltipAnchor>
      {disabledReason ? (
        <Tooltip>
          <TooltipArrow />
          {disabledReason}
        </Tooltip>
      ) : null}
    </TooltipProvider>
  );
}
