import { Suspense, lazy } from "react";
import { FormProvider } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { getTrainingInputSchema } from "../../../schema";
import type {
  AccessToken,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../../../types";
import {
  PlaygroundContext,
  type PlaygroundContextValue,
} from "../../api-playground/context";
import { useCreateTrainingForm, type TrainingAction } from "../shared";

const TrainingForm = lazy(() => import("../training-form"));

export default function GenericTraining({
  authenticated,
  version,
  owner,
  avatar,
  token,
  destinations,
  training,
  trainingVersion,
  modelInputSettings,
  permissions,
}: {
  authenticated: boolean;
  version: Version;
  owner: string;
  avatar: string;
  token: AccessToken | null;
  destinations?: { name: string; username: string }[];
  training?: Prediction;
  trainingVersion?: Version;
  modelInputSettings?: { hidden: string[] };
  permissions?: PlaygroundPermissions;
}) {
  const trainingInputSchema = getTrainingInputSchema(version);

  const action: TrainingAction =
    training && trainingVersion ? "update" : "create";

  const destination = trainingVersion
    ? `${trainingVersion._extras.model.owner}/${trainingVersion._extras.model.name}`
    : null;

  const form = useCreateTrainingForm({
    action,
    training,
    trainingVersion,
    version,
  });

  const context: PlaygroundContextValue = {
    elementVisibility: {},
    features: {},
    hideAdvancedInputs: false,
    hideVersionMismatchWarning: false,
    isAuthenticated: true,
    modelStatus: null,
    permissions: permissions ?? {
      create_example: false,
      debug: false,
      delete: false,
      edit_featured_inputs: false,
      report: version._extras.model.visibility === "public",
      run: false,
      share: true,
      tweak: true,
    },
    modelInputSettings: modelInputSettings ?? { hidden: [] },
    renderMode: "default",
    token: token ?? null,
    version: version,
  };

  return (
    <PlaygroundContext.Provider value={context}>
      <Toaster />
      <Suspense>
        <FormProvider {...form}>
          <TrainingForm
            trainingVersion={trainingVersion}
            version={version}
            authenticated={authenticated}
            owner={owner}
            avatar={avatar}
            token={token}
            destinations={destinations}
            action={action}
            properties={trainingInputSchema?.properties || {}}
          />
        </FormProvider>
      </Suspense>
    </PlaygroundContext.Provider>
  );
}
