import { Gear } from "@phosphor-icons/react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuProvider,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import type { TimeDisplayPreference } from "./metrics";

export function DeploymentMetricsChartOptionsMenu({
  timeDisplay,
  onToggleTimeDisplay,
}: {
  timeDisplay: TimeDisplayPreference;
  onToggleTimeDisplay: () => void;
}) {
  const menuDisplayValue =
    timeDisplay === "utc" ? "Use my timezone in charts" : "Use UTC in charts";

  return (
    <MenuProvider>
      <TooltipProvider>
        <MenuButton
          render={
            <TooltipAnchor
              render={
                <IconButton variant="outlined">
                  <Gear weight="regular" />
                </IconButton>
              }
            />
          }
        />
        <Tooltip>
          <TooltipArrow />
          Chart display options
        </Tooltip>
      </TooltipProvider>
      <Menu gutter={8}>
        <MenuItem
          onClick={() => {
            onToggleTimeDisplay();
          }}
        >
          {menuDisplayValue}
        </MenuItem>
      </Menu>
    </MenuProvider>
  );
}
