import * as Ariakit from "@ariakit/react";
import Editor, { type Monaco, type OnMount } from "@monaco-editor/react";
import { CaretDown, WarningCircle } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import type { editor as EditorType } from "monaco-editor";
import pluralize from "pluralize";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDebounce, usePrefersColorScheme } from "../../hooks";
import type { CogInputPropertySchema } from "../../types";
import { cleanInputForSubmission, processInputForSubmission } from "./util";

export default function JSONEditor({
  schema,
}: { schema: Record<string, CogInputPropertySchema> }) {
  const { setValue, getValues } = useFormContext();
  const [localValue, setLocalValue] = useState<string>(() => {
    const formValueSnapshot = getValues();
    const filteredValues = cleanInputForSubmission(formValueSnapshot, schema, {
      skipFiles: true,
    });
    const sanitizedValues = processInputForSubmission(filteredValues, schema);
    return JSON.stringify(sanitizedValues, null, 2);
  });
  const [problems, setProblems] = useState<EditorType.IMarker[]>([]);
  const editorRef = useRef<EditorType.IStandaloneCodeEditor>();
  const monacoRef = useRef<Monaco>();
  const theme = usePrefersColorScheme();

  const debouncedValue = useDebounce(localValue, 300);

  useEffect(() => {
    if (!debouncedValue) return;
    try {
      const parsed = JSON.parse(debouncedValue);
      for (const entry of Object.entries(parsed)) {
        const [key, value] = entry;
        // mattr: This is a bit of hack!
        // We only need to transform the value if it's an array of strings, because
        // we use a special React component and accompanying hook from react-hook-form
        // to handle array values, where the array is an array of objects with a `value` key.
        // For arrays of numbers, for example, we don't need to do anything special. YET.
        if (Array.isArray(value) && value.every((v) => typeof v === "string")) {
          setValue(
            key,
            value.map((v) => ({ value: v }))
          );
        } else {
          setValue(key, value);
        }
      }
    } catch (e) {
      console.error("Invalid JSON", e);
    }
  }, [debouncedValue, setValue]);

  const handleEditorDidMount: OnMount = (editor, monaco: Monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemaRequest: "ignore",
      schemaValidation: "warning",
      schemas: [
        {
          // Note: this URI isn't actually necessary, since we're provind the schema directly.
          uri: "",
          fileMatch: ["*"],
          schema: {
            type: "object",
            properties: schema,
          },
        },
      ],
    });
  };

  return (
    <div>
      <div className="mb-6">
        <Banner
          icon={<WarningCircle className="top-0.5 relative" />}
          condensed
          description="Only file URLs are supported in the JSON editor. To upload files, use the file input(s) on the 'Form' tab."
        />
      </div>
      <div className="relative flex flex-col h-96">
        {problems.length > 0 && (
          <div className="absolute bottom-0 left-0 right-0 z-[999] bg-r8-red-10 w-full">
            <ProblemList problems={problems} />
          </div>
        )}
        <Editor
          height="100%"
          defaultLanguage="json"
          value={localValue}
          onValidate={(markers) => {
            setProblems(markers);
          }}
          onChange={(value) => {
            setLocalValue(value ?? "");
          }}
          options={{
            minimap: {
              enabled: false,
            },
          }}
          onMount={handleEditorDidMount}
          theme={theme === "dark" ? "vs-dark" : "light"}
        />
      </div>
    </div>
  );
}

function ProblemList({ problems }: { problems: EditorType.IMarker[] }) {
  return (
    <Ariakit.DisclosureProvider>
      <Ariakit.Disclosure className="group p-2 text-r8-gray-1 text-r8-sm font-semibold flex items-center justify-between w-full text-left">
        <div className="flex-1">
          {problems.length} {pluralize("problem", problems.length)}
        </div>
        <CaretDown className="group-aria-expanded:rotate-180" weight="bold" />
      </Ariakit.Disclosure>
      <Ariakit.DisclosureContent className="bg-r8-red-10 p-2 text-r8-sm text-r8-gray-1 border-t border-r8-red-11">
        <ul>
          {problems.map((problem, index) => (
            <li key={`problem-${index}`}>
              <div className="flex">
                <div className="flex items-center gap-1 5">
                  <div className="flex-shrink-0">
                    <WarningCircle weight="bold" />
                  </div>
                  <span>
                    {problem.message} [{problem.startLineNumber}:
                    {problem.startColumn}]
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Ariakit.DisclosureContent>
    </Ariakit.DisclosureProvider>
  );
}
