import { type RefObject, useEffect, useRef, useState } from "react";

import { useLayoutEffect } from "react";

const useElementScrollTop = (ref: RefObject<HTMLDivElement>) => {
  const [scrollTop, setScrollTop] = useState<number>(0);

  useEffect(() => {
    const { current } = ref;
    if (!current) return;

    const handleScroll = () => {
      setScrollTop(current.scrollTop);
    };

    current.addEventListener("scroll", handleScroll);

    return () => {
      current.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return scrollTop;
};

const useIsOverflow = (
  ref: RefObject<HTMLDivElement>,
  callback?: (hasOverflow: boolean) => void
): boolean | undefined => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const { current } = ref;
    if (!current) return;

    const trigger = () => {
      const hasOverflow = current?.scrollHeight > current?.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};

interface AutoscrollProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  /**
   * The starting position of the scroll.
   */
  startPosition?: "top" | "bottom";
  /**
   * A threshold (in pixels, positive numbers only) to determine if the scroll is overflowing.
   */
  overflowThreshold?: number;
  children: (props: {
    hasContentBelow: boolean;
    scrollToBottom: () => void;
  }) => React.ReactNode;
}

export function Autoscroll({
  children,
  startPosition = "top",
  overflowThreshold = 0,
  style,
  ...rest
}: AutoscrollProps) {
  const [hasOverflow, setHasOverflow] = useState(startPosition === "bottom");
  const ref = useRef<HTMLDivElement>(null);

  const scrollY = useElementScrollTop(ref);

  const isOverflowing = useIsOverflow(ref, (isOverflowFromCallback) => {
    if (!hasOverflow && isOverflowFromCallback) {
      setHasOverflow(true);
    }
  });

  const negativeOverflowThreshold = overflowThreshold * -1;

  const hasContentBelow = scrollY < negativeOverflowThreshold && isOverflowing;

  const scrollToBottom = () => {
    if (!ref.current) return;
    ref.current.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div
      ref={ref}
      style={{
        flexDirection:
          startPosition === "bottom" || hasOverflow
            ? "column-reverse"
            : "column",
        display: "flex",
        ...style,
      }}
      {...rest}
    >
      <div>
        {children({
          hasContentBelow: hasContentBelow ?? false,
          scrollToBottom,
        })}
      </div>
    </div>
  );
}
