import classNames from "classnames";
import type { AccountAvatar } from "../types";
import { getStaticAssetPath } from "../util";

export function Avatar({
  avatar,
  className,
}: {
  avatar: AccountAvatar;
  className?: string;
}) {
  const fallbackUrl =
    avatar.kind === "organization"
      ? getStaticAssetPath("/static/placeholder-avatar-organization.svg")
      : getStaticAssetPath("/static/placeholder-avatar-user.svg");

  const src = avatar.url ?? fallbackUrl;

  return (
    <img
      className={classNames(
        "avatar",
        className,
        avatar.kind !== "organization" && "rounded-full"
      )}
      src={src}
      alt=""
      role="presentation"
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = fallbackUrl;
      }}
    />
  );
}
