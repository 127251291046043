import { useMemo, useState, type ReactNode } from "react";
import { formatCurrency, formatNumber } from "../intl_helpers";
import type { OfficialModel } from "../types";
import { getMaxDecimalPlaces } from "../util";
import { humanizeTokenCount } from "./api-playground/util";

export default function OfficialModelPriceList({
  billingUnit,
  officialModels,
}: {
  billingUnit: Exclude<OfficialModel["billing_unit"], null>;
  officialModels: OfficialModel | OfficialModel[];
}): ReactNode {
  const filteredOfficialModels = useMemo(() => {
    const allOfficialModels = Array.isArray(officialModels)
      ? officialModels
      : [officialModels];

    return allOfficialModels.filter(
      (officialModel) =>
        officialModel.billing_unit === billingUnit &&
        (officialModel.cost_per_billing_unit_for_input_dollars != null ||
          officialModel.cost_per_billing_unit_for_output_dollars != null)
    );
  }, [billingUnit, officialModels]);

  const longestDecimalPlace = useMemo(
    () =>
      getMaxDecimalPlaces(
        filteredOfficialModels
          .map((officialModel) => [
            officialModel.cost_per_billing_unit_for_input_dollars,
            officialModel.cost_per_billing_unit_for_output_dollars,
          ])
          .reduce((acc, values) => acc.concat(values))
      ),
    [filteredOfficialModels]
  );

  const hasInputDollars = useMemo(
    () =>
      filteredOfficialModels.some(
        (officialModel) => officialModel.cost_per_billing_unit_for_input_dollars
      ),
    [filteredOfficialModels]
  );
  const hasOutputDollars = useMemo(
    () =>
      filteredOfficialModels.some(
        (officialModel) =>
          officialModel.cost_per_billing_unit_for_output_dollars
      ),
    [filteredOfficialModels]
  );
  const units = useMemo(
    () =>
      billingUnit === "1m-tokens" ? (
        <>
          <abbr title="one million" className="no-underline">
            1M
          </abbr>{" "}
          tokens
        </>
      ) : billingUnit === "image" ? (
        "image"
      ) : (
        "unit"
      ),
    [billingUnit]
  );
  const singleUnits = useMemo(
    () =>
      billingUnit === "1m-tokens"
        ? "tokens"
        : billingUnit === "image"
          ? "images"
          : "units",
    [billingUnit]
  );

  const [showUnitsPerDollar, setShowUnitsPerDollar] = useState(false);

  return (
    <div className="space-y-lh">
      <small className="block text-right">
        <label className="select-none">
          <input
            type="checkbox"
            value="unitsPerDollar"
            name="displayMode"
            checked={showUnitsPerDollar}
            onChange={(e) => {
              setShowUnitsPerDollar(e.target.checked);
            }}
            aria-label="Toggle display mode"
          />{" "}
          Show {singleUnits} / $1
        </label>
      </small>

      <table
        className="table-auto w-full tabular-nums text-sm"
        aria-live="polite"
      >
        <thead>
          <tr className="border border-r8-gray-5 text-left text-sm text-r8-gray-11 font-normal bg-r8-gray-2">
            <th>Model</th>
            {hasInputDollars && <th>Input</th>}
            {hasOutputDollars && <th>Output</th>}
          </tr>
        </thead>

        <tbody>
          {filteredOfficialModels.map((officialModel) => (
            <tr
              key={officialModel.full_name}
              id={`per-token-${officialModel.full_name}`}
              className="border-b border-r8-gray-5 hover:bg-r8-gray-2"
            >
              <td className="font-bold py-05lh">
                <a href={officialModel.url}>{officialModel.full_name}</a>
              </td>
              {(hasInputDollars && hasOutputDollars
                ? [
                    officialModel.cost_per_billing_unit_for_input_dollars,
                    officialModel.cost_per_billing_unit_for_output_dollars,
                  ]
                : hasInputDollars
                  ? [officialModel.cost_per_billing_unit_for_input_dollars]
                  : hasOutputDollars
                    ? [officialModel.cost_per_billing_unit_for_output_dollars]
                    : []
              ).map((price, idx) => (
                <td
                  key={idx}
                  className="text-right whitespace-nowrap w-1 pr-lh"
                >
                  {price ? (
                    <>
                      {showUnitsPerDollar ? (
                        <>
                          {billingUnit === "1m-tokens"
                            ? humanizeTokenCount(
                                (1 / Number(price)) * 1_000_000
                              )
                            : formatNumber(Math.floor(1 / Number(price)))}{" "}
                          <span className="text-r8-gray-11 whitespace-nowrap">
                            {singleUnits} / $1
                          </span>
                        </>
                      ) : (
                        <>
                          {formatCurrency(Number(price), {
                            minimumFractionDigits: longestDecimalPlace,
                            maximumFractionDigits: longestDecimalPlace,
                          })}{" "}
                          <span className="text-r8-gray-11 whitespace-nowrap">
                            / {units}
                          </span>
                        </>
                      )}
                    </>
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
