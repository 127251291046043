import {
  Button,
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  PopoverProvider,
  TextField,
} from "@replicate/ui";
import copy from "copy-to-clipboard";
import { useRef, useState } from "react";
import type { Prediction } from "../types";
import { route } from "../urls";
import { SharePrediction } from "./api-playground/share-prediction";

export function SharePredictionPopover({
  prediction,
}: { prediction: Prediction }) {
  const [copied, setCopied] = useState(false);
  const isShared = prediction._extras.is_shared;
  const predictionUrl =
    window.location.origin +
    route("prediction_detail", {
      prediction_uuid: prediction.id,
    });

  const handleCopy = () => {
    setCopied(true);
    copy(predictionUrl);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const viewRef = useRef<HTMLButtonElement>(null);

  return (
    <PopoverProvider>
      <PopoverDisclosure render={<Button variant="outlined" size="sm" />}>
        Share
      </PopoverDisclosure>
      <Popover className="w-72" initialFocus={viewRef}>
        <PopoverArrow />
        {isShared ? (
          <div className="p-4 flex flex-col space-y-4">
            <TextField
              id="prediction-url"
              size="sm"
              readOnly
              value={predictionUrl}
            />
            <div className="flex flex-row gap-4">
              <Button
                ref={viewRef}
                //  biome-ignore lint/a11y/useAnchorContent: content is injected by the component.
                render={<a href={predictionUrl} />}
                variant="outlined"
                size="sm"
              >
                View
              </Button>
              <Button onClick={handleCopy} variant="outlined" size="sm">
                {copied ? "Copied!" : "Copy URL"}
              </Button>
            </div>
          </div>
        ) : (
          <div className="p-4 gap-4 flex flex-col">
            <p className="text-r8-sm text-r8-gray-11">
              By sharing this prediction, anyone with the link will be able to
              view its input and output.
            </p>
            <SharePrediction prediction={prediction} isAuthenticated />
          </div>
        )}
      </Popover>
    </PopoverProvider>
  );
}
