import { Cpu, Money } from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import classNames from "classnames";
import type { ReactNode } from "react";
import { P, match } from "ts-pattern";
import { formatCurrency } from "../intl_helpers";
import type { OfficialModel } from "../types";

export default function ModelPricePopover({
  hardware,
  officialModel,
  p50price,
  price,
  showPopover,
}: {
  hardware: string;
  officialModel: OfficialModel | null;
  p50price: string | null;
  price: string | null;
  showPopover: boolean;
}) {
  const anchorClassName = "text-r8-sm flex gap-2 items-center";
  const anchorTitleClassName = classNames("inline-flex items-center gap-1", {
    "underline decoration-dotted cursor-help": showPopover,
  });

  return match(officialModel)
    .with(
      {
        billing_unit: "1m-tokens",
        cost_per_billing_unit_for_input_dollars: P.not(P.nullish),
        cost_per_billing_unit_for_output_dollars: P.not(P.nullish),
      },
      (officialModel) => {
        const anchor = (
          <div className={anchorClassName}>
            <Money />
            <div className={anchorTitleClassName}>Priced per token</div>
          </div>
        );

        if (!showPopover) {
          return anchor;
        }

        return (
          <ModelPriceTooltip anchor={anchor}>
            <div className="space-y-1">
              <p className="">
                This language model is priced by how many input tokens are sent
                as inputs and how many output tokens are generated.
              </p>
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Input</p>
                  <p>
                    <span>
                      {formatCurrency(
                        Number(
                          officialModel.cost_per_billing_unit_for_input_dollars
                        ),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 3,
                        }
                      )}
                    </span>
                    <span className="mx-1">/</span>
                    <span className="text-r8-gray-5 whitespace-nowrap">
                      <abbr title="one million" className="no-underline">
                        1M
                      </abbr>{" "}
                      tokens
                    </span>
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Output</p>
                  <p>
                    <span>
                      {formatCurrency(
                        Number(
                          officialModel.cost_per_billing_unit_for_output_dollars
                        ),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 3,
                        }
                      )}
                    </span>
                    <span className="mx-1">/</span>
                    <span className="text-r8-gray-5 whitespace-nowrap">
                      <abbr title="one million" className="no-underline">
                        1M
                      </abbr>{" "}
                      tokens
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </ModelPriceTooltip>
        );
      }
    )
    .with(
      {
        billing_unit: "image",
        cost_per_billing_unit_for_output_dollars: P.not(P.nullish),
      },
      (officialModel) => {
        const anchor = (
          <div className={anchorClassName}>
            <Money />
            <div className={anchorTitleClassName}>
              {formatCurrency(
                Number(officialModel.cost_per_billing_unit_for_output_dollars),
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 3,
                }
              )}{" "}
              per image
            </div>
          </div>
        );

        if (!showPopover) {
          return anchor;
        }

        return (
          <ModelPriceTooltip anchor={anchor}>
            <p>This model is priced by how many images are generated.</p>
          </ModelPriceTooltip>
        );
      }
    )
    .otherwise(() => {
      const anchor = (
        <div className={anchorClassName}>
          <Cpu />
          <div className={anchorTitleClassName}>{hardware}</div>
        </div>
      );

      if (!showPopover) {
        return anchor;
      }

      if (!p50price && !price) {
        return anchor;
      }

      return (
        <ModelPriceTooltip anchor={anchor}>
          <div className="space-y-2">
            {p50price != null && (
              <p>
                This model costs approximately{" "}
                <span className="font-semibold">{p50price}</span> to run on
                Replicate, but this varies depending on your inputs.
              </p>
            )}
            {price != null && (
              <p>
                This model runs on {hardware} hardware which costs{" "}
                <a href="https://replicate.com/docs/billing">
                  <span className="font-semibold">{price}</span>
                </a>
                .
              </p>
            )}
          </div>
        </ModelPriceTooltip>
      );
    });
}

function ModelPriceTooltip({
  anchor,
  children,
}: {
  anchor: ReactNode;
  children: ReactNode;
}) {
  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor>{anchor}</TooltipAnchor>
      <Tooltip className="max-w-56 text-r8-xs z-50">
        <TooltipArrow />
        {children}
      </Tooltip>
    </TooltipProvider>
  );
}
