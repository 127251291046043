import { Flag } from "@phosphor-icons/react";
import {
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  PopoverProvider,
} from "@replicate/ui";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { focusedFlagAtom, usedFlagsAtom, type Flags } from "./flag-store";

function FlagListItem({
  count,
  flag,
  setsFocus,
  value,
}: {
  count: number;
  flag: keyof Flags;
  setsFocus: boolean;
  value: Flags[keyof Flags];
}) {
  const setFocusedFlag = useSetAtom(focusedFlagAtom);

  return (
    <a
      href={`https://app.launchdarkly.com/projects/default/flags/${flag}?env=production&selected-env=production`}
      onMouseEnter={() => {
        if (setsFocus) {
          setFocusedFlag(flag);
        }
      }}
      onMouseLeave={() => {
        if (setsFocus) {
          setFocusedFlag(null);
        }
      }}
      className={classNames(
        "text-r8-sm font-mono no-underline no-default no-focus hover:underline",
        {
          "hover:bg-r8-yellow-a3": setsFocus,
        }
      )}
    >
      "{flag}": {String(value)}{" "}
      <span className="text-r8-gray-11">(uses: {count})</span>
    </a>
  );
}

export default function FlagPopover({
  backendUsedFlags,
  templateUsedFlags,
}: {
  backendUsedFlags: Record<
    keyof Flags,
    {
      count: number;
      value: Flags[keyof Flags];
    }
  > | null;
  templateUsedFlags: Record<
    keyof Flags,
    {
      count: number;
      value: Flags[keyof Flags];
    }
  > | null;
}) {
  const usedFlags = useAtomValue(usedFlagsAtom);
  const usedFlagCount = useMemo(
    () =>
      Object.entries(usedFlags).reduce(
        (total, [, { count }]) => total + count,
        0
      ) +
      Object.entries(backendUsedFlags ?? {}).reduce(
        (total, [, { count }]) => total + count,
        0
      ) +
      Object.entries(templateUsedFlags ?? {}).reduce(
        (total, [, { count }]) => total + count,
        0
      ),
    [backendUsedFlags, templateUsedFlags, usedFlags]
  );

  if (usedFlagCount < 1) {
    return null;
  }

  return (
    <PopoverProvider>
      <PopoverDisclosure className="fixed bottom-4 left-4 p-4 flex items-center justify-center bg-r8-yellow-10 text-black">
        <Flag size={36} weight="duotone" />
        <span className="absolute -top-2 -right-2 bg-r8-yellow-10 rounded-full size-8 text-4 font-bold flex items-center justify-center">
          {usedFlagCount}
        </span>
      </PopoverDisclosure>
      <Popover className="p-3" gutter={8}>
        <PopoverArrow />
        {Object.keys(usedFlags).length > 0 && (
          <>
            <p className="text-r8-sm text-r8-gray-11 mb-2">
              Request flags referenced on this page in React components:
            </p>
            <ul>
              {Object.entries(usedFlags).map(([flag, { count, value }]) => (
                <li key={flag}>
                  <FlagListItem
                    count={count}
                    flag={flag}
                    setsFocus
                    value={value}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {templateUsedFlags != null &&
          Object.keys(templateUsedFlags).length > 0 && (
            <>
              <p className="text-r8-sm text-r8-gray-11 mb-2">
                Flags referenced on this page in Django templates (there may
                have also been more than we currently track):
              </p>
              <ul>
                {Object.entries(templateUsedFlags).map(
                  ([flag, { count, value }]) => (
                    <li key={flag}>
                      <FlagListItem
                        count={count}
                        flag={flag}
                        setsFocus={false}
                        value={value}
                      />
                    </li>
                  )
                )}
              </ul>
            </>
          )}
        {backendUsedFlags != null &&
          Object.keys(backendUsedFlags).length > 0 && (
            <>
              <p className="text-r8-sm text-r8-gray-11 mb-2">
                Flags referenced in Python while rendering this page (there may
                have also been more than we currently track):
              </p>
              <ul>
                {Object.entries(backendUsedFlags).map(
                  ([flag, { count, value }]) => (
                    <li key={flag}>
                      <FlagListItem
                        count={count}
                        flag={flag}
                        setsFocus={false}
                        value={value}
                      />
                    </li>
                  )
                )}
              </ul>
            </>
          )}
      </Popover>
    </PopoverProvider>
  );
}
