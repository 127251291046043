import {
  Button,
  Dialog,
  DialogDescription,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
  TextField,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useState } from "react";
import { toast } from "react-hot-toast";
import type { Model } from "../types";
import { route } from "../urls";

const FALLBACK_ERROR_MESSAGE =
  "An unknown error occurred while deleting this model";

async function deleteModel({ model }: { model: Model }) {
  const response = await fetch(
    route("api_model_delete", {
      username: model.owner,
      name: model.name,
    }),
    {
      method: "DELETE",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    }
  );

  if (response.ok) {
    const { next } = await response.json<{ next: string }>();
    return next;
  }

  if (response.status === 400) {
    const { detail } = await response.json<{ detail: string }>();
    throw new Error(detail);
  }

  throw new Error(FALLBACK_ERROR_MESSAGE);
}

export default function DeleteModelButton({
  model,
  validationError,
}: {
  model: Model;
  validationError?: string;
}) {
  const [typedModelName, setTypedModelName] = useState("");

  const { mutate, status } = useMutation({
    mutationFn: deleteModel,
    onSuccess: (next) => {
      window.location.href = next;
    },
    onError: (error) => {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error(FALLBACK_ERROR_MESSAGE);
      }
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate({ model });
  };

  const hasConfirmedModelName =
    typedModelName === `${model.owner}/${model.name}`;

  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor>
        <DialogProvider>
          <DialogDisclosure
            render={
              <Button intent="danger" disabled={!!validationError}>
                Delete model
              </Button>
            }
          >
            Delete model
          </DialogDisclosure>
          <Dialog className="p-4" size="lg">
            <DialogHeading>Delete model</DialogHeading>
            <DialogDescription className="mt-1.5">
              Deleting this model will permanently remove it from your account
            </DialogDescription>
            <div className="my-4">
              <p className="text-r8-sm text-r8-gray-11 mb-2">
                To confirm, type "{model.owner}/{model.name}" in the box below
              </p>
              <TextField
                value={typedModelName}
                onChange={(e) => {
                  setTypedModelName(e.target.value);
                }}
                id="confirmation"
              />
            </div>
            <div className="mt-6 flex items-center justify-end gap-4">
              <DialogDismiss
                render={<Button variant="outlined">Cancel</Button>}
              />
              <form onSubmit={handleSubmit}>
                <Button
                  disabled={!hasConfirmedModelName}
                  loading={status === "pending"}
                  intent="danger"
                  type="submit"
                >
                  Delete model
                </Button>
              </form>
            </div>
          </Dialog>
        </DialogProvider>
      </TooltipAnchor>
      {validationError ? (
        <Tooltip>
          <TooltipArrow />
          {validationError}
        </Tooltip>
      ) : null}
    </TooltipProvider>
  );
}
