import { Trash } from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";

export default function DeletedPredictionTooltip() {
  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor>
        <Trash />
      </TooltipAnchor>
      <Tooltip className="w-48 text-center text-r8-xs">
        <TooltipArrow />
        This prediction's data has been removed.
      </Tooltip>
    </TooltipProvider>
  );
}
