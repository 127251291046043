import { CaretDown, CaretRight, Warning } from "@phosphor-icons/react";
import {
  Button,
  Disclosure,
  DisclosureContent,
  DisclosureProvider,
} from "@replicate/ui";
import * as Sentry from "@sentry/react";
import Linkify from "linkify-react";
import { isNil } from "lodash-es";
import {
  useLayoutEffect,
  useMemo,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";
import { useFormContext, type FieldValues } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { P, isMatching, match } from "ts-pattern";
import type {
  AnyAnyOfCogPropertyItemSchema,
  AnyArrayCogPropertyItemSchema,
  BasicStringCogPropertyItemSchema,
  BooleanCogPropertyItemSchema,
  CogInputSchema,
  NumericCogPropertyItemSchema,
  ObjectCogPropertyItemSchema,
  SecretCogPropertyItemSchema,
  StringCogPropertyItemSchema,
  URLArrayCogPropertySchema,
  URLCogPropertyItemSchema,
  UnknownCogPropertyItemSchema,
} from "../../types";
import { useFlag } from "../flags/if-flag";
import { TiptapTextInput } from "../tiptap-text-input";
import { AnyOfInput } from "./any-of-input";
import { ArrayInput } from "./array-input";
import { BooleanInput } from "./boolean-input";
import { DisableSafetyCheckerInput } from "./disable-safety-checker-input";
import { EnumInput } from "./enum-input";
import { FallbackInput } from "./fallback-input";
import { FileInput, FileInputPreview } from "./file-input";
import { MultiFileInput } from "./multi-file-input";
import { NumericInput } from "./numeric-input";
import { SecretInput } from "./secret-input";
import { TextInput } from "./text-input";
import { getSortedInputProperties } from "./util";

/**
 * A (hopefully) short-lived interface that allows us to
 * customize the behavior of the underlying text input component
 * (e.g. passing "FRUITHEAD" as a "wordToHighlight" to the TipTap editor).
 */
export interface TextInputOptions {
  tiptap?: {
    wordToHighlight?: string;
  };
}

// This constant is very important.
// In HTML5, you can trigger form submits
// from elements outside of the form by
// using the `form` attribute.
export const FORM_ID = "input-form";

function HiddenFields({
  hiddenProperties,
  showHiddenFields,
  setShowHiddenFields,
  children,
}: {
  hiddenProperties: string[];
  showHiddenFields: boolean;
  setShowHiddenFields: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}) {
  const [firstHiddenProperty, ...remainingHiddenProperties] = hiddenProperties;

  return (
    <DisclosureProvider open={showHiddenFields} setOpen={setShowHiddenFields}>
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-2 border-t pt-3 mt-3">
          <Disclosure
            disabled={showHiddenFields}
            render={
              <Button
                variant="clear"
                size="sm"
                className="whitespace-nowrap min-w-44"
              />
            }
          >
            {showHiddenFields ? (
              <>
                <CaretDown />
                Showing advanced inputs
              </>
            ) : (
              <>
                <CaretRight />
                Show advanced inputs
              </>
            )}
          </Disclosure>
          <div className="text-r8-gray-11 text-sm truncate whitespace-nowrap">
            Including{" "}
            <span className="font-mono text-xs">{firstHiddenProperty}</span>{" "}
            {remainingHiddenProperties.length ? (
              <span>and {remainingHiddenProperties.length} more...</span>
            ) : null}
          </div>
        </div>
        <DisclosureContent>
          <div className="flex-col gap-4">{children}</div>
        </DisclosureContent>
      </div>
    </DisclosureProvider>
  );
}

export function InputForm({
  disabled,
  hideAdvancedInputs,
  hiddenFields = [],
  hideInitialFilePreview = false,
  itemClass,
  onSubmit,
  properties,
  required,
  advanced = [],
  overrides = {},
  children,
  textInputOptions = {},
  unstable_getExtraFieldProperties = () => ({}),
}: {
  disabled: boolean;
  hideAdvancedInputs: boolean;
  hiddenFields?: string[];
  hideInitialFilePreview?: boolean;
  itemClass?: string;
  onSubmit: (values: FieldValues) => void;
  properties: CogInputSchema["properties"];
  required: string[];
  advanced?: string[];
  overrides?: Record<string, any>;
  children?: ReactNode;
  // An experimental (and ideally short-lived) prop
  // that allows us to customize the behavior of an
  // underlying field component (e.g. passing "Accept" to FileInput).
  textInputOptions?: TextInputOptions;
  unstable_getExtraFieldProperties?: (name: string) => Record<string, unknown>;
}) {
  const useTiptapTextInput = useFlag("use-tiptap-text-input");
  const { formState, handleSubmit, getValues } = useFormContext();

  const editedProperties = Object.entries(getValues())
    .map(([key, value]) => {
      if (properties[key]?.default && value !== properties[key]?.default) {
        return key;
      }
    })
    .filter(Boolean);

  const sortedProperties = getSortedInputProperties(properties);
  const filteredHiddenFields = hiddenFields
    .filter((name) => sortedProperties.includes(name))
    .filter((name) => !required.includes(name));

  const visibleProperties =
    (filteredHiddenFields.length &&
      filteredHiddenFields.length !== sortedProperties.length) ||
    hideAdvancedInputs
      ? sortedProperties
          .filter((name) => !advanced.includes(name)) // Filter out advanced fields
          .filter((name) => !filteredHiddenFields.includes(name)) // Filter out hidden fields
      : sortedProperties;

  const hiddenProperties = sortedProperties
    .filter((name) => !visibleProperties.includes(name)) // Filter out visible properties
    .filter((name) => filteredHiddenFields.includes(name)); // Filter in hidden fields

  const [showHiddenFields, setShowHiddenFields] = useState(false);

  const onInvalid = (errors) => {
    if (Object.keys(errors).some((key) => hiddenProperties.includes(key))) {
      setShowHiddenFields(true);
    }
  };

  // If the user has edited a hidden property in the prediction, show the hidden fields
  // rather than making them expand it

  const hasEditedHiddenProperties = editedProperties.some((prop: string) =>
    hiddenProperties.includes(prop)
  );

  useLayoutEffect(() => {
    if (hasEditedHiddenProperties) {
      setShowHiddenFields(true);
    }
  }, [hasEditedHiddenProperties]);

  const submit = handleSubmit(onSubmit, onInvalid);
  useHotkeys(
    "mod+enter",
    (e) => {
      submit();
    },
    {
      enabled: !disabled,
      enableOnContentEditable: true,
      enableOnFormTags: true,
    }
  );

  const isFileType = isMatching({ type: P.string, format: "uri" });

  const fileInputs = useMemo(() => {
    return sortedProperties.filter((name) => {
      const data = properties[name];
      return isFileType(data);
    });
  }, [isFileType, properties, sortedProperties]);

  // A holdover from the original code wherein
  // if we determine that the form has a single file input,
  // we render it at the top of the form so it
  // visually aligns with the output preview.

  const initialFileValue = getValues([fileInputs[0]]);
  const hasInitialFileInput =
    fileInputs.length === 1 && Boolean(initialFileValue);

  const generateFields = (fields) => {
    return fields.map((name) => {
      const schema = properties[name];
      const isRequired = required.includes(name);

      if (overrides[name]) {
        return overrides[name];
      }

      let component: ReactNode;
      try {
        component = match(schema)
          .with(
            {
              enum: P.array(P.union(P.string, P.number)),
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies
                | StringCogPropertyItemSchema
                | NumericCogPropertyItemSchema;

              return (
                <EnumInput
                  disabled={disabled}
                  name={name}
                  options={matchedSchema.enum}
                  required={isRequired}
                  type={matchedSchema.type}
                />
              );
            }
          )
          .with(
            {
              type: "string",
              format: "uri",
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies URLCogPropertyItemSchema;

              const extraProps = unstable_getExtraFieldProperties(name);

              const previewType =
                hasInitialFileInput && name === fileInputs[0]
                  ? "simple"
                  : "full";

              return (
                <FileInput
                  placeholder="Enter a URL, paste a file, or drag a file over."
                  {...extraProps}
                  disabled={disabled}
                  name={name}
                  previewType={previewType}
                  required={isRequired}
                  type="file"
                />
              );
            }
          )
          .with(
            {
              type: "string",
              format: "password",
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies SecretCogPropertyItemSchema;

              return (
                <SecretInput
                  disabled={disabled}
                  name={name}
                  onSubmit={submit}
                  required={isRequired}
                  type={matchedSchema.type}
                  format={matchedSchema.format}
                />
              );
            }
          )
          .with(
            { type: "array", items: { type: "string", format: "uri" } },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies URLArrayCogPropertySchema;

              return (
                <MultiFileInput
                  disabled={disabled}
                  type="file[]"
                  name={name}
                  required={isRequired}
                />
              );
            }
          )
          .with(
            {
              type: "string",
              format: P.nullish.optional(),
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies BasicStringCogPropertyItemSchema;
              if (useTiptapTextInput) {
                return (
                  <TiptapTextInput
                    disabled={disabled}
                    name={name}
                    onSubmit={submit}
                    required={isRequired}
                    type={matchedSchema.type}
                    {...textInputOptions.tiptap}
                  />
                );
              }

              return (
                <TextInput
                  disabled={disabled}
                  name={name}
                  onSubmit={submit}
                  required={isRequired}
                  type={matchedSchema.type}
                />
              );
            }
          )
          .with(
            {
              type: P.union("number", "integer"),
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies NumericCogPropertyItemSchema;

              return (
                <NumericInput
                  disabled={disabled}
                  maximum={matchedSchema.maximum}
                  minimum={matchedSchema.minimum}
                  name={name}
                  required={isRequired}
                  type={matchedSchema.type}
                />
              );
            }
          )
          .with(
            {
              type: "boolean",
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies BooleanCogPropertyItemSchema;

              if (name === "disable_safety_checker") {
                return (
                  <DisableSafetyCheckerInput
                    name={name}
                    required={isRequired}
                    type={matchedSchema.type}
                  />
                );
              }

              return (
                <BooleanInput
                  disabled={disabled}
                  name={name}
                  required={isRequired}
                  type={matchedSchema.type}
                />
              );
            }
          )
          .with(
            {
              type: "object",
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies ObjectCogPropertyItemSchema;

              return (
                <FallbackInput
                  name={name}
                  required={isRequired}
                  schema={matchedSchema}
                  report
                />
              );
            }
          )
          .with(
            {
              type: "array",
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies AnyArrayCogPropertyItemSchema;

              return (
                <ArrayInput
                  disabled={disabled}
                  name={name}
                  required={isRequired}
                  schema={matchedSchema}
                />
              );
            }
          )
          .with(
            {
              anyOf: P.array(),
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies AnyAnyOfCogPropertyItemSchema;

              return (
                <AnyOfInput
                  disabled={disabled}
                  name={name}
                  onSubmit={submit}
                  required={isRequired}
                  schema={matchedSchema}
                />
              );
            }
          )
          .with(
            {
              type: P.nullish.optional(),
            },
            (matchedSchema) => {
              // Check at build time that we've filtered to an expected type.
              matchedSchema satisfies UnknownCogPropertyItemSchema;

              return (
                <FallbackInput
                  name={name}
                  required={isRequired}
                  schema={matchedSchema}
                  report
                />
              );
            }
          )
          .exhaustive();
      } catch (err) {
        Sentry.captureException(err, {
          extra: {
            name,
            schema: JSON.stringify(schema),
          },
        });

        component = (
          <FallbackInput
            name={name}
            required={isRequired}
            schema={schema}
            report={false}
          />
        );
      }

      return (
        <div
          className={itemClass}
          data-type={"type" in schema ? schema.type : "anyOf"}
          data-name={name}
          key={name}
        >
          {component}
          <div className="mt-2 space-y-1">
            {schema.description && (
              <p className="text-r8-sm text-r8-gray-12">
                <Linkify
                  options={{
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }}
                >
                  {schema.description}
                </Linkify>
              </p>
            )}
            {!isNil(schema.default) ? (
              <p className="text-r8-sm text-r8-gray-11 break-words">
                Default: {JSON.stringify(schema.default)}
              </p>
            ) : null}
          </div>
          {formState.errors[name] ? (
            <div className="mt-2 flex items-center text-r8-red-10 gap-1">
              <Warning size={16} />
              <p className="text-r8-sm">
                {formState.errors[name]?.message?.toString() ||
                  formState.errors[name]?.root?.message?.toString() ||
                  "An unexpected error occurred"}
              </p>
            </div>
          ) : null}
        </div>
      );
    });
  };

  const visibleFieldsComponent = useMemo(
    () => generateFields(visibleProperties),
    [generateFields, visibleProperties]
  );
  const hiddenFieldsComponent = useMemo(
    () => generateFields(hiddenProperties),
    [generateFields, hiddenProperties]
  );

  return (
    <form
      noValidate
      data-testid="input-form"
      id={FORM_ID}
      onSubmit={submit}
      autoComplete="off"
    >
      {hasInitialFileInput && !hideInitialFilePreview && (
        <div className="mb-3" data-testid="initial-file-input">
          <FileInputPreview showHeader={false} name={fileInputs[0]} />
        </div>
      )}
      {visibleProperties.length === 0 ? (
        <p className="text-r8-sm text-r8-gray-11 pb-1.5">
          This version has no inputs.
        </p>
      ) : (
        <>
          {children}
          {visibleFieldsComponent}
          {hiddenProperties.length > 0 ? (
            <HiddenFields
              showHiddenFields={showHiddenFields}
              setShowHiddenFields={setShowHiddenFields}
              hiddenProperties={hiddenProperties}
            >
              {hiddenFieldsComponent}
            </HiddenFields>
          ) : null}
        </>
      )}
    </form>
  );
}
