import { NativeSelect } from "@replicate/ui";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { usePersistentState } from "../../hooks";
import { Chart, type ChartDatum } from "./chart";
import { DeploymentMetricsChartOptionsMenu } from "./deployment-metrics-chart-options-menu";
import {
  InstanceTimeChart,
  type InstanceTimeAggregateStats,
  type InstanceTimeChartDatum,
} from "./instance-time-chart";
import {
  fetchDeploymentMetrics,
  type MetricsResponse,
  type TimeDisplayPreference,
  type TimeseriesDatum,
} from "./metrics";

export interface InstanceTimeDatum extends TimeseriesDatum {
  active: number;
  idle: number;
  setup: number;
}

export interface InstanceTimeResponse
  extends MetricsResponse<InstanceTimeDatum> {
  aggregate_stats: InstanceTimeAggregateStats;
}

interface QueueLengthDatum extends TimeseriesDatum {
  instances: number;
}

type QueueLengthResponse = MetricsResponse<QueueLengthDatum>;

interface ThroughputDatum extends TimeseriesDatum {
  predictions: number;
}

type ThroughputResponse = MetricsResponse<ThroughputDatum>;

const displayPeriod = {
  "2h": "2 hours",
  "24h": "24 hours",
};

export default function DeploymentMetrics({
  instanceType,
  runType = "prediction",
  fullName,
  instanceTimeUrl,
  queueLengthUrl,
  throughputUrl,
  enablePeriodSelector = false,
}: {
  instanceType: "deployment" | "model";
  runType?: "prediction" | "training";
  fullName: string;
  instanceTimeUrl?: string;
  queueLengthUrl?: string;
  throughputUrl?: string;
  enablePeriodSelector?: boolean;
}) {
  const [timeDisplay, setTimeDisplay] =
    usePersistentState<TimeDisplayPreference>(
      "deployment-metrics-time-display",
      "utc"
    );
  const settingsUrl = `${window.location.href}/settings#autoscaling`;
  const [metricsPeriod, setMetricsPeriod] = useState("2h");
  const handleTimeDisplayToggle = () => {
    setTimeDisplay(timeDisplay === "utc" ? "local" : "utc");
  };

  const instanceTimeQuery = useQuery({
    queryKey: [
      "metrics",
      instanceType,
      runType,
      fullName,
      "instance-time",
      metricsPeriod,
    ],
    queryFn: instanceTimeUrl
      ? () =>
          fetchDeploymentMetrics<InstanceTimeDatum, InstanceTimeResponse>(
            enablePeriodSelector
              ? `${instanceTimeUrl}/${metricsPeriod}`
              : instanceTimeUrl
          )
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const queueLengthQuery = useQuery({
    queryKey: [
      "metrics",
      instanceType,
      runType,
      fullName,
      "queue-length",
      metricsPeriod,
    ],
    queryFn: queueLengthUrl
      ? () =>
          fetchDeploymentMetrics<QueueLengthDatum, QueueLengthResponse>(
            enablePeriodSelector
              ? `${queueLengthUrl}/${metricsPeriod}`
              : queueLengthUrl
          )
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const throughputQuery = useQuery({
    queryKey: [
      "metrics",
      instanceType,
      runType,
      fullName,
      "throughput",
      metricsPeriod,
    ],
    queryFn: throughputUrl
      ? () =>
          fetchDeploymentMetrics<ThroughputDatum, ThroughputResponse>(
            enablePeriodSelector
              ? `${throughputUrl}/${metricsPeriod}`
              : throughputUrl
          )
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const transformedData = useMemo(() => {
    return {
      throughput:
        throughputQuery.data?.metrics.map<ChartDatum>((datum) => {
          return {
            x: new Date(datum.time_bin),
            y: datum.predictions,
          };
        }) || [],
      queueLength:
        queueLengthQuery.data?.metrics.map<ChartDatum>((datum) => {
          return {
            x: new Date(datum.time_bin),
            y: datum.instances,
          };
        }) || [],
      instanceTime:
        instanceTimeQuery.data?.metrics.map<InstanceTimeChartDatum>((datum) => {
          return {
            x: new Date(datum.time_bin),
            y: [-datum.setup, datum.active, datum.idle],
          };
        }) || [],
    };
  }, [
    instanceTimeQuery.data?.metrics,
    queueLengthQuery.data?.metrics,
    throughputQuery.data?.metrics,
  ]);
  const hasData = useMemo(
    () =>
      transformedData.throughput.some((d) => d.y > 0) ||
      transformedData.queueLength.some((d) => d.y > 0) ||
      transformedData.instanceTime.some((d) => d.y.some((v) => v > 0)),
    [transformedData]
  );

  const chartSyncId = ["metrics", instanceType, runType, fullName].join(":");

  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-center justify-between mb-2">
          <h4 className="text-r8-xl text-r8-gray-12 font-semibold">Metrics</h4>
          <DeploymentMetricsChartOptionsMenu
            timeDisplay={timeDisplay}
            onToggleTimeDisplay={handleTimeDisplayToggle}
          />
        </div>
        {enablePeriodSelector && (
          <div className="w-36 relative">
            <label htmlFor="period" className="sr-only">
              Period
            </label>
            <NativeSelect
              name="period"
              id="period"
              size="sm"
              value={metricsPeriod}
              onChange={(e) => setMetricsPeriod(e.target.value)}
            >
              <option value="2h">Past 2hrs</option>
              <option value="24h">Past 24hrs</option>
            </NativeSelect>
          </div>
        )}
      </div>
      {throughputUrl && (
        <div>
          <p className="mb-1 font-semibold text-sm">
            {runType.charAt(0).toUpperCase() + runType.slice(1)} requests
          </p>
          <Chart
            unit={runType}
            type="step"
            data={transformedData.throughput}
            loading={throughputQuery.isPending}
            displayEmptyState={!hasData}
            error={throughputQuery.isError}
            noDataReason={`No ${runType}s have been processed in the past ${displayPeriod[metricsPeriod]}. When your ${instanceType} is active and you are making ${runType}s with it, this is where you’ll see its performance.`}
            syncId={chartSyncId}
            timeDisplay={timeDisplay}
          />
        </div>
      )}
      {queueLengthUrl && (
        <div>
          <p className="mb-1 font-semibold text-sm">Queue length</p>
          <Chart
            unit={runType}
            type="step"
            data={transformedData.queueLength}
            loading={queueLengthQuery.isPending}
            displayEmptyState={!hasData}
            error={queueLengthQuery.isError}
            noDataReason={`No ${runType}s have been queued in the past ${displayPeriod[metricsPeriod]}. When you are making ${runType}s with this ${instanceType}, this is where you’ll see the size of the queue.`}
            syncId={chartSyncId}
            timeDisplay={timeDisplay}
          />
        </div>
      )}
      {instanceTimeUrl && (
        <div>
          <p className="mb-1 font-semibold text-sm">
            Autoscaling
            {instanceType === "deployment" && (
              <a
                className="font-normal ml-2 no-default underline text-xs text-r8-gray-12"
                href={settingsUrl}
              >
                configure
              </a>
            )}
          </p>
          <InstanceTimeChart
            data={transformedData.instanceTime}
            bucketSize={instanceTimeQuery.data?.bucket_size}
            aggregateStats={instanceTimeQuery.data?.aggregate_stats}
            loading={instanceTimeQuery.isPending}
            displayEmptyState={!hasData}
            error={instanceTimeQuery.isError}
            noDataReason={`No instances were online in the past ${displayPeriod[metricsPeriod]}. When your ${instanceType} is active, this is where you’ll see how many instances are running and what they’re spending their time doing.`}
            syncId={chartSyncId}
            timeDisplay={timeDisplay}
          />
        </div>
      )}
    </div>
  );
}
