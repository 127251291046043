import Ansi from "@curvenote/ansi-to-react";
import { Button, Tab, TabList, TabPanel, TabProvider } from "@replicate/ui";
import { Download, Play } from "lucide-react";
import ScrollToBottom from "react-scroll-to-bottom";
import { P, match } from "ts-pattern";
import type { Prediction } from "../types";
import { deconstructVersionFullName } from "../util";
import { CancelPredictionButton } from "./api-playground/cancel-prediction-button";
import { PredictionLoadingState } from "./api-playground/prediction-loading-state";
import CodeBlock from "./code-block";
import { LogsFooter } from "./logs-footer";

function TrainingSuccess({
  output,
}: {
  output: {
    logs?: string;
    weights: string;
    version: string;
  };
}) {
  const trainedVersion = deconstructVersionFullName(output.version);
  const jsExampleCode = `import Replicate from "replicate";

const replicate = new Replicate({
  auth: process.env.REPLICATE_API_TOKEN,
});

const output = await replicate.run(
  "${output.version}",
  {
    input: {
      prompt: "..."
    }
  }
);`;

  const pythonExampleCode = `import replicate
output = replicate.run(
    "${output.version}",
    input={"prompt": ...}
)
`;

  const curlCode = `curl -s -X POST \\
-d '{"version": "${trainedVersion.id}", "input": {"prompt": "..."}}' \\
-H "Authorization: Bearer $REPLICATE_API_TOKEN" \\
"https://api.replicate.com/v1/predictions"`;

  return (
    <div>
      <div className="space-y-4">
        <p className="">
          The training was successful! You can now run and learn more about your
          trained model in our web playground or start running predictions via
          the Replicate API.
        </p>
        <div className="gap-2 flex">
          <Button
            startIcon={<Play aria-hidden size={16} />}
            variant="outlined"
            // biome-ignore lint/a11y/useAnchorContent: content is injected by the Button component
            render={<a href={trainedVersion._extras.url} />}
          >
            Run trained model
          </Button>
          <Button
            startIcon={<Download aria-hidden size={16} />}
            variant="outlined"
            // biome-ignore lint/a11y/useAnchorContent: content is injected by the Button component
            render={<a href={output.weights} />}
          >
            Download weights
          </Button>
          {output.logs != null && (
            <Button
              startIcon={<Download aria-hidden size={16} />}
              variant="outlined"
              render={
                // biome-ignore lint/a11y/useAnchorContent: content is injected by the Button component
                <a href={output.logs} />
              }
            >
              Download full logs
            </Button>
          )}
        </div>
        <TabProvider>
          <div className="relative">
            <TabList size="sm">
              <Tab>
                <span>JS</span>
              </Tab>
              <Tab>
                <span>Python</span>
              </Tab>
              <Tab>
                <span>HTTP</span>
              </Tab>
            </TabList>
          </div>
          <TabPanel>
            <div className="training-code-block">
              <CodeBlock textContent={jsExampleCode} language="javascript" />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="training-code-block">
              <CodeBlock textContent={pythonExampleCode} language="python" />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="training-code-block">
              <CodeBlock textContent={curlCode} language="shell" />
            </div>
          </TabPanel>
        </TabProvider>
      </div>
    </div>
  );
}

function OutputView({
  training,
}: {
  training: Prediction;
}) {
  const ctaBlock = match(training.status)
    .with("starting", "processing", () => {
      return (
        <div className="gap-2 flex flex-wrap">
          <CancelPredictionButton prediction={training} />
        </div>
      );
    })
    .otherwise(() => {
      return null;
    });

  const outputBlock = match(training)
    .with({ status: "starting" }, () => (
      <div className="space-y-4">
        <PredictionLoadingState>Starting...</PredictionLoadingState>
      </div>
    ))
    .with({ status: "processing" }, () => (
      <div className="space-y-4">
        <PredictionLoadingState>Running...</PredictionLoadingState>
      </div>
    ))
    .with(
      {
        status: "succeeded",
        output: {
          weights: P.string,
          version: P.string,
        },
      },
      ({ output }) => {
        return <TrainingSuccess output={output} />;
      }
    )
    .with(
      {
        status: "succeeded",
        output: P.nullish,
      },
      () => {
        return <div>No output.</div>;
      }
    )
    .with(
      {
        status: "succeeded",
        output: P._,
      },
      () => {
        return (
          <pre className="text-r8-sm overflow-auto">
            <code className="p-2">
              {JSON.stringify(training.output, null, 2)}
            </code>
          </pre>
        );
      }
    )
    .with({ status: "canceled" }, () => {
      return (
        <div className="space-y-2">
          <p>Training was canceled</p>
        </div>
      );
    })
    .with({ status: "canceling" }, () => {
      return <div>Canceling training...</div>;
    })
    .with({ status: "failed" }, () => (
      <div>
        <p className="text-r8-red-10">Training failed.</p>
        {training.error && (
          <p className="text-r8-sm text-r8-red-10">{training.error}</p>
        )}
      </div>
    ))
    .exhaustive();

  return (
    <div className="flex flex-col flex-1 space-y-4 pb-4">
      <div id="output">{outputBlock}</div>
      {ctaBlock}
    </div>
  );
}

function JSONView({
  training,
}: {
  training: Prediction;
}) {
  return (
    <div>
      <CodeBlock
        language="json"
        textContent={JSON.stringify(training, null, 2)}
      />
    </div>
  );
}

export function TrainingDetail({
  training,
}: {
  training: Prediction;
}) {
  return (
    <TabProvider defaultActiveId="preview" defaultSelectedId="preview">
      <TabList size="sm">
        <Tab id="preview">Preview</Tab>
        <Tab id="json">JSON</Tab>
      </TabList>
      <TabPanel className="py-4" tabId="preview">
        <OutputView training={training} />
      </TabPanel>
      <TabPanel className="py-4" tabId="json">
        <JSONView training={training} />
      </TabPanel>
      <div className="my-0">
        {training.logs ? (
          <div className="relative">
            <div>
              <ScrollToBottom
                initialScrollBehavior="auto"
                followButtonClassName="hidden"
                className="h-60 overflow-auto bg-r8-gray-3 text-r8-gray-12 text-sm font-mono overscroll-contain"
              >
                <div className="p-05lh whitespace-pre" role="log">
                  <Ansi useClasses>{training.logs}</Ansi>
                </div>
              </ScrollToBottom>
              <LogsFooter prediction={training} />
            </div>
          </div>
        ) : (
          <p className="text-r8-sm text-r8-gray-11">
            Logs are not available for this training.
          </p>
        )}
      </div>
    </TabProvider>
  );
}
