import { Atom, RssSimple } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";

export default function FeedButtons({
  rssUrl,
  atomUrl,
}: { rssUrl?: string; atomUrl: string }) {
  return (
    <div className="flex items-center gap-2">
      {rssUrl ? (
        <Button
          // biome-ignore lint/a11y/useAnchorContent: Content injected by Button
          render={<a href={rssUrl} />}
          variant="outlined"
          size="sm"
          startIcon={<RssSimple weight="bold" />}
        >
          RSS
        </Button>
      ) : null}
      {atomUrl ? (
        <Button
          // biome-ignore lint/a11y/useAnchorContent: Content injected by Button
          render={<a href={atomUrl} />}
          variant="outlined"
          size="sm"
          startIcon={<Atom weight="bold" />}
        >
          Atom
        </Button>
      ) : null}
    </div>
  );
}
