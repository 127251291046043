import * as Ariakit from "@ariakit/react";
import Ansi from "@curvenote/ansi-to-react";
import { useQuery } from "@tanstack/react-query";

async function checkSetupFailure(versionId: string) {
  const res = await fetch(`/api/setup-failures/${versionId}`);
  return (await res.json()) as {
    setup_run?: {
      status: string;
      logs: string;
      started_at: string;
      completed_at: string;
    };
  };
}

export function SetupFailureCheck({ versionId }: { versionId: string }) {
  const disclosure = Ariakit.useDisclosureStore();

  const { data } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["version", "setup-failure", versionId],
    queryFn: () => {
      return checkSetupFailure(versionId);
    },
  });

  const showingLogs = disclosure.useState("open");

  if (data?.setup_run) {
    return (
      <div className="bg-r8-yellow-4 p-4 space-y-2">
        <p className="text-r8-sm">
          ⚠️ This model version recently failed to complete setup. Your
          prediction may fail to start if setup is failing consistently.
        </p>
        <Ariakit.Disclosure
          store={disclosure}
          className="text-r8-sm hover:underline focus:underline"
        >
          {showingLogs ? "Hide logs" : "Show logs"}
        </Ariakit.Disclosure>
        <Ariakit.DisclosureContent store={disclosure}>
          <pre className="h-[10rem] overflow-auto border border-r8-yellow-7 p-2">
            <code className="h-full w-full text-r8-sm">
              <Ansi useClasses>
                {data.setup_run.logs || "No logs available for this version."}
              </Ansi>
            </code>
          </pre>
        </Ariakit.DisclosureContent>
      </div>
    );
  }

  return null;
}
