import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import type {
  CogInputPropertyItemSchema,
  CogInputPropertyValue,
  CogOutputItemSchema,
  CogOutputValue,
} from "../../types";

export function FallbackValue({
  name,
  schema,
  value,
  report,
}: {
  name?: string;
  schema: CogInputPropertyItemSchema | CogOutputItemSchema | undefined;
  value: CogInputPropertyValue | CogOutputValue;
  report: boolean;
}) {
  useEffect(() => {
    if (report) {
      Sentry.captureException(new Error("API Playground: Unhandled value"), {
        extra: {
          name,
          schema: JSON.stringify(schema),
          value: JSON.stringify(value),
        },
      });
    }
  }, [name, report, schema, value]);

  const renderedValue =
    typeof value !== "string" ? JSON.stringify(value, null, 2) : value;

  // TODO: Once we're confident this is only happening when the schema and
  // output are mismatched, show something to the user to help them debug what's
  // wrong.
  return (
    <div
      data-testid={name ? `value-${name}-fallback` : "value-fallback"}
      className="output p-2 whitespace-pre-wrap bg-r8-gray-3 text-r8-gray-12 font-mono text-r8-sm overflow-y-auto max-h-96"
      translate="no"
    >
      {renderedValue}
    </div>
  );
}
