import * as Ariakit from "@ariakit/react";
import { Warning } from "@phosphor-icons/react";
import {
  Banner,
  Button,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import Cookies from "js-cookie";
import { useCallback, useMemo, useState } from "react";
import { formatCurrency } from "../intl_helpers";
import type { Deployment, Hardware } from "../types";
import { route } from "../urls";
import { DeleteDeploymentButton } from "./delete-deployment-button";
import { DisableDeploymentUI } from "./deployment-disable-toggle";
import { RenameDeploymentForm } from "./rename-deployment-form";
import VersionPicker, { type VersionForVersionPicker } from "./version-picker";

function HardwareOption({
  hardware,
  selected,
}: {
  hardware: Hardware;
  selected: boolean;
}) {
  const costPerHour = useMemo(
    () =>
      hardware.cost_per_second_dollars
        ? Number(hardware.cost_per_second_dollars) * 60 * 60
        : null,
    [hardware.cost_per_second_dollars]
  );
  const [focusVisible, setFocusVisible] = useState(false);

  if (hardware.public_sku == null) {
    return null;
  }

  return (
    <div
      className="relative group"
      data-selected={selected}
      data-focus-visible={focusVisible}
    >
      <label
        className="block cursor-pointer hover:border-r8-gray-12 group group-data-[selected=true]:border-black border w-full appearance-none text-left p-3"
        title={hardware.display_name}
      >
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-2.5">
            <div className="flex-shrink-0 pointer-events-none">
              <Ariakit.Radio
                className="accent-black focus:outline-none"
                onFocusVisible={() => setFocusVisible(true)}
                onBlur={() => setFocusVisible(false)}
                name="hardware"
                id={hardware.public_sku}
                value={hardware.public_sku}
              />
            </div>
            <div>
              <span className="text-base font-semibold leading-none">
                {hardware.display_name}
              </span>
              <div>
                <dl className="text-r8-gray-11 flex flex-col md:flex-row md:items-center gap-1 text-sm mt-1">
                  {hardware.cpu_count != null && (
                    <>
                      <dt hidden>CPU</dt>
                      <dd>{hardware.cpu_count}x CPU</dd>
                      {(hardware.gpu_ram != null || hardware.ram != null) && (
                        <span className="hidden md:inline" aria-hidden>
                          /
                        </span>
                      )}
                    </>
                  )}
                  {hardware.gpu_ram != null && (
                    <>
                      <dt hidden>GPU</dt>
                      <dd>{hardware.gpu_ram}GB GPU RAM</dd>
                      {hardware.ram != null && (
                        <span className="hidden md:inline" aria-hidden>
                          /
                        </span>
                      )}
                    </>
                  )}
                  {hardware.ram != null && (
                    <>
                      <dt hidden>RAM</dt>
                      <dd>{hardware.ram}GB RAM</dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
          {hardware.cost_per_second_dollars != null && (
            <div className="text-right text-base space-y-1">
              <p className="tabular-nums">
                ${Number(hardware.cost_per_second_dollars).toFixed(6)}/sec
              </p>
              <p className="tabular-nums text-r8-gray-11">
                {/* biome-ignore lint/style/noNonNullAssertion: costPerHour is derived from option.unit_cost_per_second. */}
                ${costPerHour!.toFixed(2)}/hour
              </p>
            </div>
          )}
        </div>
      </label>
    </div>
  );
}

export function formatHardwareDailyCost({
  hardware,
  count,
}: {
  hardware: Hardware | null;
  count: number | "";
}): {
  raw: string;
  formatted: string;
} {
  if (typeof count !== "number") {
    return { raw: "", formatted: "-" };
  }

  const costPerSecond = hardware?.cost_per_second_dollars;

  if (costPerSecond == null) {
    return { raw: "", formatted: "-" };
  }

  const cost = Number(costPerSecond) * count * 60 * 60 * 24;
  return {
    raw: cost.toFixed(2),
    formatted: formatCurrency(cost),
  };
}

export default function DeploymentSettingsForm({
  currentVersion,
  deleteDisabledReason,
  deployment,
  hardwareOptions,
  renameDisabledReason,
  versions,
}: {
  currentVersion: VersionForVersionPicker;
  deleteDisabledReason: string;
  deployment: Deployment;
  hardwareOptions: Hardware[];
  renameDisabledReason: string;
  versions: VersionForVersionPicker[];
}) {
  // Note that we're controlling this value
  // only to determine whether to display a warning
  // about incremental rollouts.
  // We don't actually use this value in the form submission,
  // but rather read directly from the FormData.
  const [version, setVersion] = useState(currentVersion);

  const [hardwareSku, setHardwareSku] = useState(
    deployment.current_release.configuration.hardware
  );
  const hardware = useMemo(
    () =>
      hardwareOptions.find(({ public_sku: value }) => value === hardwareSku) ??
      null,
    [hardwareOptions, hardwareSku]
  );
  const [minInstancesValue, setMinInstancesValue] = useState<number | "">(
    deployment.current_release.configuration.min_instances ?? ""
  );
  const [maxInstancesValue, setMaxInstancesValue] = useState<number | "">(
    deployment.current_release.configuration.max_instances ?? ""
  );
  const [errorDetails, setErrorDetails] = useState<Record<
    string,
    string[]
  > | null>(null);

  const limits = useMemo(
    () => ({
      minInstances: {
        min: Math.min(
          0,
          deployment.current_release.configuration.min_instances ??
            Number.POSITIVE_INFINITY
        ),
        max: Math.min(
          deployment._extras.allowed_min_instances ??
            hardware?.default_allowed_min_instances_for_deployments ??
            0,
          typeof maxInstancesValue === "number"
            ? maxInstancesValue
            : Number.POSITIVE_INFINITY
        ),
      },
      maxInstances: {
        min: Math.max(
          Math.min(
            0,
            deployment.current_release.configuration.max_instances ??
              Number.POSITIVE_INFINITY
          ),
          typeof minInstancesValue === "number" ? minInstancesValue : 0
        ),
        max:
          deployment._extras.allowed_max_instances ??
          hardware?.default_allowed_max_instances_for_deployments ??
          0,
      },
    }),
    [
      deployment._extras.allowed_max_instances,
      deployment._extras.allowed_min_instances,
      deployment.current_release.configuration.max_instances,
      deployment.current_release.configuration.min_instances,
      hardware?.default_allowed_max_instances_for_deployments,
      hardware?.default_allowed_min_instances_for_deployments,
      maxInstancesValue,
      minInstancesValue,
    ]
  );

  const onMinInstancesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = Number.parseInt(event.target.value, 10);
      setMinInstancesValue(Number.isInteger(val) && val >= 0 ? val : "");
    },
    []
  );

  const onMaxInstancesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.currentTarget.setCustomValidity("Oh no!");
      const val = Number.parseInt(event.target.value, 10);

      setMaxInstancesValue(Number.isInteger(val) && val >= 0 ? val : "");

      // Set custom error message on the max instance when the number
      // is less than the min instances value.
      event.currentTarget.setCustomValidity(
        minInstancesValue !== "" && val < minInstancesValue
          ? "Maximum instances must be a larger number than minimum instances"
          : ""
      );
    },
    [minInstancesValue]
  );

  const showIncreaseCapacityCTA = useMemo(
    () =>
      (minInstancesValue !== "" &&
        minInstancesValue >= limits.minInstances.max) ||
      (maxInstancesValue !== "" &&
        maxInstancesValue >= limits.maxInstances.max),
    [
      limits.maxInstances.max,
      limits.minInstances.max,
      maxInstancesValue,
      minInstancesValue,
    ]
  );

  const minCost = useMemo(
    () =>
      formatHardwareDailyCost({
        hardware,
        count: minInstancesValue,
      }),
    [hardware, minInstancesValue]
  );
  const maxCost = useMemo(
    () =>
      formatHardwareDailyCost({
        hardware,
        count: maxInstancesValue,
      }),
    [hardware, maxInstancesValue]
  );

  // Prevent the input value from changing on wheel events
  const onWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
  };

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!event.currentTarget.checkValidity()) {
        const form = event.currentTarget;
        const maxInstancesEl = form.querySelector<HTMLInputElement>(
          "[name=max_instances]"
        );

        // Ensure that we show this error on the max_instances field
        // before the min_instances errors. Otherwise the browser
        // will show the first errored field and result in a confusing
        // error message on the min_instances field.
        if (maxInstancesEl && maxInstancesValue < minInstancesValue) {
          Promise.resolve().then(() => maxInstancesEl.reportValidity());
          return;
        }

        Promise.resolve().then(() => form.reportValidity());
        return;
      }

      const formData = new FormData(event.currentTarget);
      const response = await fetch(
        route("api_deployment_edit_or_delete", {
          username: deployment.owner,
          name: deployment.name,
        }),
        {
          method: "PATCH",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
          body: formData,
        }
      );

      if (response.ok) {
        const { next } = await response.json();
        window.location.href = next;
        return;
      }

      if (response.status === 400) {
        const { detail } = await response.json();
        setErrorDetails(detail);
      } else {
        setErrorDetails({
          Error: ["An unexpected error occurred, please try again."],
        });
      }
    },
    [deployment, maxInstancesValue, minInstancesValue]
  );

  const errorDetailsList = errorDetails
    ? Object.entries(errorDetails).map(([key, value]) => {
        return (
          <li key={key}>
            {key}: {value[0]}
          </li>
        );
      })
    : null;

  const hasPinnedMaxMinInstances =
    maxInstancesValue !== "" && maxInstancesValue === limits.minInstances.max;

  const hasPickedNewVersion = version.id !== currentVersion.id;
  const hasPickedNewHardware =
    hardwareSku !== deployment.current_release.configuration.hardware;

  let saveBanner: React.ReactNode = null;

  if (deployment._extras.disabled) {
    saveBanner = (
      <Banner
        severity="warning"
        icon={<Warning />}
        condensed
        heading="Changes will not take immediate effect"
        description={
          <div className="space-y-05lh">
            <p>
              {deployment._extras.disabled_by_system ? "We " : "You "}
              have disabled this deployment. Any changes will be saved but will
              not take effect until
              {deployment._extras.disabled_by_system ? " we " : " you "}
              re-enable the deployment.
            </p>
          </div>
        }
      />
    );
  } else if (hasPickedNewVersion || hasPickedNewHardware) {
    saveBanner = (
      <Banner
        severity="warning"
        icon={<Warning />}
        condensed
        heading="These changes will trigger an incremental rollout"
        description={
          <div className="space-y-05lh">
            <p>
              While this change rolls out, some predictions will use the old
              settings, while others will start to use the new ones. Assuming
              the new settings are compatible with your application, you
              shouldn’t experience any downtime while this happens.
            </p>
            <p>
              Note: We might exceed the specified maximum number of online
              instances during an incremental rollout. This is because we start
              a new instance and wait for it to be ready before shutting down an
              old one.
            </p>
          </div>
        }
      />
    );
  }

  return (
    <div className="flex flex-col md:flex-row gap-8">
      <div className="content-container">
        <form
          className="space-y-2lh"
          id="settings-form"
          onSubmit={onFormSubmit}
          noValidate={true}
        >
          <section id="version" className="scroll-mt-28">
            <h3 className="text-r8-xl text-r8-gray-12 font-semibold mb-1">
              Version
            </h3>
            <VersionPicker
              name="version"
              versions={versions}
              value={currentVersion}
              onVersionChange={setVersion}
            />
          </section>
          <section id="hardware">
            <h3 className="text-r8-xl text-r8-gray-12 font-semibold mb-1">
              Hardware
            </h3>

            <Ariakit.RadioProvider
              value={hardwareSku}
              setValue={(val: string) => {
                setHardwareSku(val);
              }}
            >
              <Ariakit.RadioGroup
                aria-label="Hardware"
                className="flex flex-col gap-4"
              >
                {hardwareOptions.map((h, idx) => (
                  <HardwareOption
                    key={idx}
                    hardware={h}
                    selected={hardwareSku === h.public_sku}
                  />
                ))}
              </Ariakit.RadioGroup>
            </Ariakit.RadioProvider>
          </section>

          <section id="autoscaling">
            <h3 className="text-r8-xl text-r8-gray-12 font-semibold mb-05lh">
              Autoscaling
            </h3>

            <fieldset className="flex flex-col md:grid grid-cols-2 gap-2lh">
              <label>
                <div className="flex items-center justify-between mb-05lh">
                  <div className="block text-base font-semibold">
                    Minimum instances
                  </div>
                  <div className="text-sm text-r8-gray-11">
                    <TooltipProvider>
                      <TooltipAnchor>
                        {limits.minInstances.min} min /{" "}
                        <span
                          className={
                            hasPinnedMaxMinInstances
                              ? "border-dotted border-r8-gray-6 border-b cursor-pointer"
                              : ""
                          }
                        >
                          {limits.minInstances.max} max
                        </span>
                      </TooltipAnchor>
                      {hasPinnedMaxMinInstances ? (
                        <Tooltip>
                          <span>Restricted by maximum instances</span>
                          <TooltipArrow />
                        </Tooltip>
                      ) : null}
                    </TooltipProvider>
                  </div>
                </div>

                <input
                  type="number"
                  id="min"
                  name="min_instances"
                  value={minInstancesValue}
                  required
                  placeholder="Enter a number"
                  className="w-full block bg-white dark:bg-r8-gray-1 border border-r8-gray-12 p-05lh mb-2"
                  min={limits.minInstances.min}
                  max={limits.minInstances.max}
                  step="1"
                  onChange={onMinInstancesChange}
                  onWheel={onWheel}
                />

                <span>
                  Keep one or more instances running at all times to avoid{" "}
                  <a href="https://replicate.com/docs/reference/how-does-replicate-work#cold-boots">
                    cold boots
                  </a>
                  .
                </span>
              </label>

              <label>
                <div className="flex items-center justify-between mb-05lh">
                  <span className="block text-base font-semibold">
                    Maximum instances
                  </span>
                  <span className="text-sm text-r8-gray-11">
                    {limits.maxInstances.min} min / {limits.maxInstances.max}{" "}
                    max
                  </span>
                </div>

                <input
                  type="number"
                  id="max"
                  required
                  placeholder="Enter a number"
                  name="max_instances"
                  value={maxInstancesValue}
                  className="w-full block bg-white dark:bg-r8-gray-1 border border-r8-gray-12 p-05lh mb-2"
                  min={limits.maxInstances.min}
                  max={limits.maxInstances.max}
                  step="1"
                  onChange={onMaxInstancesChange}
                  onWheel={onWheel}
                />

                <span>
                  Limit the number of concurrent predictions to run at once.
                </span>
              </label>
            </fieldset>

            {showIncreaseCapacityCTA && (
              <p className="mt-lh px-6 py-4 bg-r8-gray-3">
                Want to run more instances concurrently?{" "}
                <a href={route("support")}>Contact us</a>.
              </p>
            )}
          </section>
        </form>
        <hr className="my-8" />
        <section id="disable-deployment">
          <DisableDeploymentUI deployment={deployment} />
        </section>
        <hr className="my-8" />
        <section id="rename-deployment">
          <RenameDeploymentForm
            deployment={deployment}
            disabledReason={renameDisabledReason}
          />
        </section>
        <hr className="my-8" />
        <section id="delete-deployment">
          <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
            <div className="flex-shrink-0 w-96">
              <h3 className="text-r8-xl text-r8-gray-12 font-semibold">
                Delete deployment
              </h3>
              <p className="r8-text-sm">
                If you no longer need your deployment, you can delete it.
              </p>
            </div>
            <div>
              <DeleteDeploymentButton
                deployment={deployment}
                disabledReason={deleteDisabledReason}
              />
            </div>
          </div>
        </section>
      </div>

      <aside className="relative flex-shrink-0">
        <div className="top-24 sticky w-full md:w-80 lg:w-96">
          <h3 className="text-r8-xl text-r8-gray-12 font-semibold mb-05lh">
            Pricing summary
          </h3>
          <div className="border p-4">
            <dl className="grid grid-cols-2 gap-x-lh tabular-nums">
              <dt className="font-bold">Base</dt>
              <dd className="flex justify-end overflow-hidden min-w-0 text-right tabular-nums">
                <span
                  className="truncate"
                  data-value={minCost.raw}
                  data-currency="USD"
                >
                  {minCost.formatted}
                </span>
                <span className="flex-shrink-0">/day</span>
              </dd>
              <dd className="text-sm text-r8-gray-11 col-span-2 mb-lh md:max-w-xs">
                The base cost for the configured minimum instances, before
                autoscaling
              </dd>
              <dt className="font-bold">Maximum</dt>
              <dd className="flex justify-end overflow-hidden min-w-0 text-right tabular-nums">
                <span
                  className="truncate"
                  data-value={maxCost.raw}
                  data-currency="USD"
                >
                  {maxCost.formatted}
                </span>
                <span className="flex-shrink-0">/day</span>
              </dd>
              <dd className="text-sm text-r8-gray-11 col-span-2">
                Your bill won't exceed this amount
              </dd>
            </dl>
          </div>
          <div className="mt-4 space-y-4">
            {errorDetailsList ? (
              <ul className="p-4 text-r8-sm text-r8-red-10 bg-r8-red-3">
                {errorDetailsList}
              </ul>
            ) : null}
            {saveBanner}
            <Button
              // Please don't remove or tweak this ID. It's used here
              // so that we can submit the form from outside the form markup.
              form="settings-form"
              type="submit"
              className="w-full"
            >
              Save settings
            </Button>
          </div>
        </div>
      </aside>
    </div>
  );
}
