import { P, match } from "ts-pattern";
import type { Prediction } from "../../types";
import { Duration } from "../duration";
import { EntityMeta } from "./entity-meta";
import { humanizeTokenCount } from "./util";

export function PredictionMetrics({
  metrics,
}: {
  metrics: Prediction["metrics"];
}) {
  if (metrics == null) {
    return null;
  }

  const metricsToShow = {
    "Generated in": metrics.predict_time,
    "Input tokens": metrics.input_token_count,
    "Output tokens": metrics.output_token_count,
    "Tokens per second": metrics.tokens_per_second,
    "Time to first token": metrics.time_to_first_token,
  } as const;
  const metricsEntriesToShow = Object.entries(metricsToShow).filter(
    (value): value is [keyof typeof metricsToShow, number] => Boolean(value[1])
  );

  if (metricsEntriesToShow.length === 0) {
    return null;
  }

  return (
    <EntityMeta>
      {metricsEntriesToShow.map(([metric_name, metric]) => (
        <EntityMeta.Item key={metric_name}>
          <EntityMeta.ItemLabel>{metric_name}</EntityMeta.ItemLabel>
          {match(metric_name)
            .with("Generated in", () => (
              <EntityMeta.ItemValue>
                <Duration seconds={metric} />
              </EntityMeta.ItemValue>
            ))
            .with(P.union("Input tokens", "Output tokens"), () => (
              <EntityMeta.ItemValue>
                {humanizeTokenCount(metric)}
              </EntityMeta.ItemValue>
            ))
            .with("Tokens per second", () => (
              <EntityMeta.ItemValue>
                {metric.toFixed(2)} tokens / second
              </EntityMeta.ItemValue>
            ))
            .with("Time to first token", () => (
              <EntityMeta.ItemValue>
                <Duration seconds={metric} />
              </EntityMeta.ItemValue>
            ))
            .exhaustive()}
        </EntityMeta.Item>
      ))}
    </EntityMeta>
  );
}
