import type { BadgeVariant } from "@replicate/ui";
import { Badge } from "@replicate/ui";
import type { PredictionStatus } from "../types";
import { getHumanStatus } from "../util";

const statusToVariantMap: Record<
  PredictionStatus | "booting" | "queued",
  BadgeVariant
> = {
  succeeded: "success",
  failed: "danger",
  starting: "accent",
  canceled: "default",
  canceling: "default",
  processing: "accent",
  queued: "accent",
  booting: "accent",
};

/**
 * Badge for displaying status of a prediction which can also
 * take additional live information from our instance data whether
 * the model is currently booting. If not, then we assume that the
 * request is queued.
 */
export function LiveStatusBadge({
  status,
  isBooting,
}: {
  /* Prediction status as known by our API */
  status: PredictionStatus;
  /* Additional information from instance state metrics */
  isBooting: boolean | null;
}) {
  let liveStatus: keyof typeof statusToVariantMap = status;
  let text = getHumanStatus(liveStatus);

  if (status === "starting" && isBooting !== null) {
    liveStatus = isBooting ? "booting" : "queued";
    text = isBooting ? "Booting" : "Queued";
  }

  return (
    <Badge variant={statusToVariantMap[liveStatus]} size="sm">
      {text}
    </Badge>
  );
}
