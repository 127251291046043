import { map, type RouteMeta } from "./urls.generated";

/** Perform substitution on a Django template path */
export function template(
  str: string,
  data: Record<string, string | number> = {}
) {
  return str.replace(/<(?:[^:]+:)?([^<>]+)>/g, (m, p) => String(data[p] ?? m));
}

export function route<T extends keyof RouteMeta>(
  name: T,
  ...args: RouteMeta[T]["args"]
): string {
  return template(map[name], args[0] ? args[0] : {});
}
