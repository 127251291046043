import * as Ariakit from "@ariakit/react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  ArrowLeft,
  ArrowRight,
  DotsSixVertical,
  File as FileIcon,
  FilePlus,
  X,
} from "@phosphor-icons/react";
import { IconButton } from "@replicate/ui";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFieldArray } from "react-hook-form";
import { P, match } from "ts-pattern";
import { ValueFromFile } from "./file-input";
import { Label } from "./label";
import { URLValue } from "./url-value";

type ArrayFieldValue<T> = {
  [key: string]: { value: T }[];
};

export function MultiFileInput({
  name,
  disabled,
  required,
  type,
}: {
  name: string;
  required: boolean;
  disabled: boolean;
  type: string;
}) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      const oldIndex = fields.findIndex((value) => value.id === active.id);
      const newIndex = fields.findIndex((value) => value.id === over.id);
      move(oldIndex, newIndex);
    }
  }

  const { fields, append, remove, move } = useFieldArray<
    ArrayFieldValue<File | string>
  >({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled,
    multiple: true,
    onDrop: (acceptedFiles) => {
      for (const file of acceptedFiles) {
        append({ value: file });
      }
    },
  });

  const handleNext = () => {
    if (activeIndex !== null) {
      setActiveIndex((prev) => {
        const nextIndex = prev !== null ? prev + 1 : null;
        return nextIndex !== null ? nextIndex % fields.length : null;
      });
    }
  };

  const handlePrevious = () => {
    if (activeIndex !== null) {
      setActiveIndex((prev) => {
        const prevIndex = prev !== null ? prev - 1 : null;
        return prevIndex !== null
          ? (prevIndex + fields.length) % fields.length
          : null;
      });
    }
  };

  const activeField = activeIndex !== null ? fields[activeIndex] : null;

  return (
    <div className="flex flex-col gap-2 group" data-disabled={disabled}>
      <div className="flex items-center">
        <Label type={type} Icon={FileIcon} required={required} name={name} />
      </div>
      <div className="flex flex-col gap-2">
        <div
          {...getRootProps({
            className:
              "bg-r8-gray-2 p-4 border border-r8-gray-11 border-dashed hover:border-r8-gray-12 cursor-pointer group-data-[disabled=true]:cursor-not-allowed group-data-[disabled=true]:hover:border-r8-gray-7",
          })}
        >
          <input {...getInputProps({ id: name })} />
          <p className="text-r8-sm text-r8-gray-11 flex items-center gap-2 select-none">
            <FilePlus aria-hidden size={16} />
            Add multiple files
          </p>
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div className="my-1.5 divide-y divide-r8-gray-6">
          <SortableContext
            items={fields}
            strategy={verticalListSortingStrategy}
          >
            {fields.map(({ value, id }, index) => (
              <SortableItem
                value={value}
                disabled={disabled}
                onOpen={() => {
                  setActiveIndex(index);
                }}
                onDelete={() => remove(index)}
                key={id}
                id={id}
              />
            ))}
          </SortableContext>
        </div>
      </DndContext>
      <Ariakit.DialogProvider
        open={activeIndex !== null}
        setOpen={() => {
          setActiveIndex(null);
        }}
      >
        <Ariakit.Dialog
          backdrop={<div className="bg-r8-gray-a8" />}
          className="fixed inset-0 lg:inset-12 z-10 bg-white flex flex-col border border-r8-gray-12 overflow-hidden"
        >
          <div className="p-4 flex-shrink-0 border-b border-r8-gray-12 flex items-center justify-between">
            <div />
            <div className="flex-1 items-center justify-center flex flex-shrink-0">
              <p className="font-mono text-r8-gray-12 text-r8-sm">{name}</p>
            </div>
            <div className="flex items-center justify-center">
              <Ariakit.DialogDismiss>
                <X weight="bold" size={18} />
              </Ariakit.DialogDismiss>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center p-6 overflow-hidden">
            <div className="w-full max-w-xl h-full flex items-center justify-center">
              {activeField && (
                <>
                  {typeof activeField.value === "string" ? (
                    <URLValue
                      value={activeField.value}
                      schema={undefined}
                      alwaysRenderAsDownload={false}
                      reportFallback={false}
                    />
                  ) : null}
                  {activeField.value instanceof File ? (
                    <ValueFromFile name="images" file={activeField.value} />
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className="flex-shrink-0 flex items-center justify-center px-4 pt-4">
            {match(activeField?.value)
              .with(P.string, (val) => {
                return (
                  <p className="text-r8-sm text-r8-gray-11 truncate">{val}</p>
                );
              })
              .with(P.instanceOf(File), (file) => {
                return (
                  <p className="text-r8-sm text-r8-gray-11 truncate">
                    {file.name}
                  </p>
                );
              })
              .otherwise(() => null)}
          </div>
          <div className="flex-shrink-0 flex items-center justify-center p-4">
            <IconButton variant="clear" onClick={handlePrevious}>
              <ArrowLeft />
            </IconButton>
            <div className="mx-2 flex items-center">
              <span className="tabular-nums min-w-4 flex-shrink-0 flex items-center justify-center">
                {activeIndex !== null ? activeIndex + 1 : 0}
              </span>
              <span className="mx-1">/</span>
              <span className="tabular-nums min-w-4 flex items-center justify-center">
                {fields.length}
              </span>
            </div>
            <IconButton variant="clear" type="button" onClick={handleNext}>
              <ArrowRight />
            </IconButton>
          </div>
        </Ariakit.Dialog>
      </Ariakit.DialogProvider>
    </div>
  );
}

function SortableItem({
  value,
  disabled,
  onDelete,
  onOpen,
  id,
}: {
  value: File | string;
  disabled: boolean;
  onDelete: () => void;
  onOpen: () => void;
  id: string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const displayName = typeof value === "string" ? value : value.name;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="py-2 flex items-center justify-between group"
    >
      <div className="flex items-center gap-2 truncate flex-1">
        <div
          aria-label="Re-order item"
          role="button"
          className="text-r8-gray-11 group-hover:text-r8-gray-12 flex-shrink-0 cursor-move"
          {...listeners}
        >
          <DotsSixVertical weight="bold" />
        </div>
        <Ariakit.Command
          className="flex-shrink-0 w-16 h-16 hover:opacity-50"
          onClick={onOpen}
        >
          <FilePreview file={value} />
        </Ariakit.Command>
        <span className="text-r8-sm select-none truncate">{displayName}</span>
      </div>
      <div className="flex-shrink-0 flex items-center ml-4">
        <IconButton
          size="xs"
          variant="clear"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <X />
        </IconButton>
      </div>
    </div>
  );
}

function FilePreview({ file }: { file: File | string }) {
  if (typeof file === "string") {
    return (
      <img className="w-full h-full object-cover aspect-square" src={file} />
    );
  }

  if (file.type?.startsWith("image/")) {
    const src = URL.createObjectURL(file);
    return (
      <img
        className="w-full h-full object-cover aspect-square"
        src={src}
        alt={file.name}
        onLoad={() => {
          URL.revokeObjectURL(src);
        }}
      />
    );
  }
  return (
    <div className="w-full h-full bg-r8-gray-4 flex items-center justify-center text-r8-gray-11">
      <FileIcon size={20} />
    </div>
  );
}
