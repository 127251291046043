import { Info, Question } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { Label } from "./label";

export function FallbackInput({
  name,
  required,
  schema,
  report,
}: {
  name: string;
  required: boolean;
  schema: any;
  report: boolean;
}) {
  useEffect(() => {
    if (report) {
      Sentry.captureException(
        new Error("API Playground: Unhandled input schema"),
        {
          extra: {
            name,
            schema: JSON.stringify(schema),
          },
        }
      );
    }
  }, [report, schema, name]);

  return (
    <div className="gap-2 flex flex-col">
      <Label Icon={Question} required={required} name={name} type={null} />
      <Banner
        severity={"info"}
        icon={<Info />}
        description={"This input type is only available via the API."}
      />
    </div>
  );
}
