import { isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import type { TimeDisplayPreference } from "./metrics";

/**
 * A utility function to format a date-like object to a string in UTC,
 * with some guards against invalid dates. We use this function to format
 * tick marks on the x-axis, as well as headings in the MetricTooltip.
 *
 * @param datelike A date-like object
 * @returns A string representation of the date in UTC
 */
export function formatDateToUTC(datelike: Date): string | null {
  if (!isValid(datelike)) return null;
  try {
    return `${formatInTimeZone(datelike, "UTC", "HH:mm")} UTC`;
  } catch {
    return null;
  }
}

export function formatXAxisTick(
  datelike: Date,
  timeDisplayPreference: TimeDisplayPreference
): string | null {
  if (!isValid(datelike)) return null;
  if (timeDisplayPreference === "utc") {
    return formatDateToUTC(datelike);
  }
  return datelike.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}
