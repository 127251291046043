import { formatDistance } from "date-fns";

export function RelativeTime({
  className,
  dateTime,
}: {
  className?: string;
  dateTime: Date;
}) {
  return (
    <time
      className={className}
      dateTime={dateTime.toISOString()}
      title={dateTime.toString()}
    >
      {formatDistance(dateTime, new Date(), { addSuffix: true })}
    </time>
  );
}
