import { Badge, Banner, Button } from "@replicate/ui";
import { set } from "lodash-es";
import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { getInputSchema } from "../../schema";
import type {
  AccessToken,
  Features,
  Model,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../../types";
import { route } from "../../urls";
import APIPlayground from "../api-playground";
import { RunWith, RunWithContext } from "../api-playground/run-with";
import icons from "../icons";

type ContentBlock = {
  id: string;
  title: string;
  content: React.ReactNode;
};

function TableOfContentsLink({
  id,
  children,
  activeSection,
}: { id: string; children: React.ReactNode; activeSection?: string }) {
  return (
    <li
      data-active={activeSection === id}
      className="pl-4 border-l border-r8-gray-6 data-[active='true']:border-r8-gray-12 my-0 py-0.5 hover:border-r8-gray-12 transition-colors pt-0"
    >
      <a href={`#${id}`}>{children}</a>
    </li>
  );
}

const MobileTableOfContents = ({
  contentBlocks,
}: {
  activeSection?: string;
  contentBlocks: ContentBlock[];
}) => {
  return (
    <div className="lg:hidden block">
      <select
        defaultValue={undefined}
        onChange={(e) => {
          const id = e.target.value;
          window.location.hash = id;
        }}
        className="!bg-black !text-white form-select w-full"
      >
        <option>Table of contents</option>
        {contentBlocks.map((block) => {
          return (
            <option key={block.id} value={block.id}>
              {block.title}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const DesktopTableOfContents = ({
  activeSection,
  contentBlocks,
}: {
  activeSection?: string;
  contentBlocks: ContentBlock[];
}) => {
  return (
    <div className="hidden lg:block sticky top-24">
      <p className="font-semibold !mb-0">On this page</p>
      <ul className="text-r8-sm !mt-2 gap-0 list-none">
        {contentBlocks.map((block) => {
          return (
            <TableOfContentsLink
              id={block.id}
              key={block.id}
              activeSection={activeSection}
            >
              {block.title}
            </TableOfContentsLink>
          );
        })}
      </ul>
    </div>
  );
};

export default function ArcticPost({
  model,
  version,
  token,
  permissions,
  initialPrediction,
  initialPredictionVersion,
  features,
  isAuthenticated,
}: {
  model: Model;
  version: Version;
  token: AccessToken;
  permissions: PlaygroundPermissions;
  initialPrediction?: Prediction | null;
  initialPredictionVersion?: Version | null;
  features: Features;
  isAuthenticated: boolean;
}) {
  const [activeSection, setActiveSection] = useState<string>();

  useEffect(() => {
    // Re-trigger a scroll to the anchor in the URL if present.
    // This is necessary because the page is rendered on the server,
    // but the React component is mounted well after the page is fully hydrated.
    const url = new URL(window.location.href);
    const hash = url.hash;
    if (!hash) return;
    const el = document.querySelector(hash);
    if (!el) return;
    el.scrollIntoView();
  }, []);

  const inputSchema = getInputSchema(version);

  const versionWithAdvancedInputs = set(
    version,
    "_extras.dereferenced_openapi_schema.components.schemas.Input.advanced",
    Object.keys(inputSchema.properties).filter((name) => name !== "prompt")
  );

  const modelDetailRoute = route("model_detail", {
    name: model.name,
    username: model.owner,
  });

  const contentBlocks = [
    {
      id: "api-playground",
      title: "Try Arctic in our API playground",
      content: (
        <>
          <h2 className="!mt-0">Try Arctic in our API playground</h2>
          <p>Before you dive in, try Arctic in our API playground.</p>
          <p>
            Try tweaking the prompt and see how Arctic responds. Most models on
            Replicate have an interactive API playground like this, available on
            the model page:{" "}
            <a href={modelDetailRoute}>
              https://replicate.com{modelDetailRoute}
            </a>
            .
          </p>
          <p>
            The API playground is a great way to get a feel for what a model can
            do, and provides copyable code snippets in a variety of languages to
            help you get started.
          </p>
          <div className="border border-r8-gray-5 mb-lh not-prose">
            <div className="border-b border-r8-gray-5 p-4 bg-r8-gray-1">
              <div className="flex items-center justify-between">
                <p className="font-heading !my-0 text-r8-xl">
                  <a href={model.url}>
                    {model.owner}/{model.name}
                  </a>
                </p>
                <Badge variant="default">API Playground</Badge>
              </div>
            </div>
            <div className="p-4">
              <APIPlayground
                model={model}
                initialPrediction={initialPrediction ?? null}
                initialPredictionVersion={initialPredictionVersion ?? null}
                features={features}
                version={versionWithAdvancedInputs}
                hideAdvancedInputs={true}
                hideVersionMismatchWarning={true}
                setPredictionIdInUrl={false}
                isAuthenticated={Boolean(token)}
                permissions={{
                  ...permissions,
                  share: false,
                  tweak: false,
                  delete: false,
                  report: false,
                  create_example: false,
                  debug: false,
                }}
                modelInputSettings={{ hidden: [] }}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      id: "running-arctic-with-javascript",
      title: "Running Arctic with JavaScript",
      content: (
        <>
          <h2 className="!mt-0">Running Arctic with JavaScript</h2>
          <p>
            You can run Arctic with our{" "}
            <a href="https://github.com/replicate/replicate-javascript">
              official JavaScript client
            </a>
            :
          </p>
          <RunWith
            context={RunWithContext.NodeJS}
            input={{
              prompt: "Write fizz buzz in SQL",
            }}
            model={model}
            token={token}
            usesVersionlessApi
            version={version}
          />
        </>
      ),
    },
    {
      id: "running-arctic-with-python",
      title: "Running Arctic with Python",
      content: (
        <>
          <h2>Running Arctic with Python</h2>
          <p>
            You can run Arctic with our{" "}
            <a href="https://github.com/replicate/replicate-python">
              official Python client
            </a>
            :
          </p>
          <RunWith
            context={RunWithContext.Python}
            input={{
              prompt: "Write fizz buzz in SQL",
            }}
            model={model}
            token={token}
            usesVersionlessApi
            version={version}
          />
        </>
      ),
    },
    {
      id: "running-arctic-with-curl",
      title: "Running Arctic with cURL",
      content: (
        <>
          <h2>Running Arctic with cURL</h2>
          <p>
            Your can{" "}
            <a href="https://replicate.com/snowflake/snowflake-arctic-instruct">
              call the HTTP API directly
            </a>{" "}
            with tools like cURL:
          </p>
          <RunWith
            context={RunWithContext.HTTP}
            input={{
              prompt: "Write fizz buzz in SQL",
              top_k: 50,
              top_p: 0.9,
              temperature: 0.6,
              max_new_tokens: 512,
              min_new_tokens: 0,
              stop_sequences: "<|im_end|>",
              prompt_template:
                "<|im_start|>system\\nYou're a helpful assistant<|im_end|>\\n<|im_start|>user\\n{prompt}<|im_end|>\\n\\n<|im_start|>assistant\\n",
              presence_penalty: 1.15,
              frequency_penalty: 0.2,
            }}
            model={model}
            token={token}
            usesVersionlessApi
            version={version}
          />
          <p>
            You can also run Arctic using{" "}
            <a href="https://replicate.com/docs/reference/client-libraries">
              other Replicate client libraries for Go, Swift, and others
            </a>
            .
          </p>
        </>
      ),
    },
    {
      id: "example-chat-app",
      title: "Arctic Demo",
      content: (
        <>
          <h2>Snowflake Arctic Chatbot</h2>
          <p>
            If you want a place to start,{" "}
            <a href="https://arctic.streamlit.app/">
              Streamlit have built a demo app with Replicate
            </a>
            :
          </p>
          <img
            className="w-full mb-2lh"
            src="https://d31rfu1d3w8e4q.cloudfront.net/static/blog/arctic-api/streamlit_demo.png"
          />

          <p>
            <a href="https://arctic.streamlit.app/">Try it out here</a> or{" "}
            <a href="https://github.com/streamlit/snowflake-arctic-st-demo">
              fork it on GitHub
            </a>
            .
          </p>
        </>
      ),
    },
    {
      id: "keep-up-to-speed",
      title: "Keep up to speed",
      content: (
        <>
          <h2>Keep up to speed</h2>
          <ul>
            <li>
              <a href="https://arctic-streamlit-hackathon.devpost.com">
                Sign up for Snowflake's Arctic Hackathon!
              </a>
            </li>
            <li>
              <a href="https://arctic.streamlit.app/">Chat with Arctic.</a>
            </li>
            <li>
              <a href="https://x.com/replicate">
                Follow us on <s>Twitter</s> X to get the latest from the Arctic.
              </a>
            </li>
            <li>
              <a href="https://discord.gg/replicate">
                Hop in our Discord to talk Arctic.
              </a>
            </li>
          </ul>
          <p>Happy hacking! 🦙</p>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-12 gap-8 readme-prose max-w-full">
        <div className="col-span-full lg:col-span-9 row-start-2 lg:row-start-1">
          {!isAuthenticated && (
            <div className="mb-8">
              <h2 className="mt-0">Before you begin</h2>
              <Banner
                severity="info"
                icon={<icons.DeploymentIcon />}
                description="We recommend signing in to Replicate before you get
                      started. If you're new to Replicate, you can try featured models out
                      for free. Once you have an account, you'll have an access
                      token that will let you run Arctic in the cloud."
                meta={
                  <Button
                    render={
                      // biome-ignore lint/a11y/useAnchorContent: <explanation>
                      <a
                        href={`${route(
                          "signin"
                        )}?next=/blog/run-arctic-with-an-api`}
                      />
                    }
                    size="sm"
                    intent="info"
                  >
                    Join Replicate
                  </Button>
                }
              />
            </div>
          )}
          {contentBlocks.map(({ id, content }) => {
            return (
              <InView
                key={id}
                as="section"
                className="scroll-mt-20"
                id={id}
                threshold={0.5}
                onChange={(inView) => {
                  if (inView) setActiveSection(id);
                }}
              >
                {content}
              </InView>
            );
          })}
        </div>
        <div className="col-span-full lg:col-span-4 row-start-1 lg:row-start-1 sticky top-4 lg:static lg:top-0">
          <MobileTableOfContents
            contentBlocks={contentBlocks}
            activeSection={activeSection}
          />
          <DesktopTableOfContents
            contentBlocks={contentBlocks}
            activeSection={activeSection}
          />
        </div>
      </div>
    </div>
  );
}
