import { Badge, Banner, Button } from "@replicate/ui";
import { set } from "lodash-es";
import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { getInputSchema } from "../../schema";
import type {
  AccessToken,
  Features,
  Model,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../../types";
import { route } from "../../urls";
import APIPlayground from "../api-playground";
import { RunWith, RunWithContext } from "../api-playground/run-with";
import icons from "../icons";

type ContentBlock = {
  id: string;
  title: string;
  content: React.ReactNode;
};

function TableOfContentsLink({
  id,
  children,
  activeSection,
}: { id: string; children: React.ReactNode; activeSection?: string }) {
  return (
    <li
      data-active={activeSection === id}
      className="pl-4 border-l border-r8-gray-6 data-[active='true']:border-r8-gray-12 my-0 py-0.5 hover:border-r8-gray-12 transition-colors pt-0"
    >
      <a href={`#${id}`}>{children}</a>
    </li>
  );
}

const MobileTableOfContents = ({
  contentBlocks,
}: {
  activeSection?: string;
  contentBlocks: ContentBlock[];
}) => {
  return (
    <div className="lg:hidden block">
      <select
        defaultValue={undefined}
        onChange={(e) => {
          const id = e.target.value;
          window.location.hash = id;
        }}
        className="!bg-black !text-white form-select w-full"
      >
        <option>Table of contents</option>
        {contentBlocks.map((block) => {
          return (
            <option key={block.id} value={block.id}>
              {block.title}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const DesktopTableOfContents = ({
  activeSection,
  contentBlocks,
}: {
  activeSection?: string;
  contentBlocks: ContentBlock[];
}) => {
  return (
    <div className="hidden lg:block sticky top-24">
      <p className="font-semibold !mb-0">On this page</p>
      <ul className="text-r8-sm !mt-2 gap-0 list-none">
        {contentBlocks.map((block) => {
          return (
            <TableOfContentsLink
              id={block.id}
              key={block.id}
              activeSection={activeSection}
            >
              {block.title}
            </TableOfContentsLink>
          );
        })}
      </ul>
    </div>
  );
};

export default function Llama2Post({
  model,
  version,
  token,
  permissions,
  initialPrediction,
  initialPredictionVersion,
  features,
  isAuthenticated,
}: {
  model: Model;
  version: Version;
  token: AccessToken;
  permissions: PlaygroundPermissions;
  initialPrediction?: Prediction | null;
  initialPredictionVersion?: Version | null;
  features: Features;
  isAuthenticated: boolean;
}) {
  const [activeSection, setActiveSection] = useState<string>();

  useEffect(() => {
    // Re-trigger a scroll to the anchor in the URL if present.
    // This is necessary because the page is rendered on the server,
    // but the React component is mounted well after the page is fully hydrated.
    const url = new URL(window.location.href);
    const hash = url.hash;
    if (!hash) return;
    const el = document.querySelector(hash);
    if (!el) return;
    el.scrollIntoView();
  }, []);

  const inputSchema = getInputSchema(version);

  const versionWithAdvancedInputs = set(
    version,
    "_extras.dereferenced_openapi_schema.components.schemas.Input.advanced",
    Object.keys(inputSchema.properties).filter((name) => name !== "prompt")
  );

  const modelDetailRoute = route("model_detail", {
    name: model.name,
    username: model.owner,
  });

  const contentBlocks = [
    {
      id: "api-playground",
      title: "Try Llama 2 in our API playground",
      content: (
        <>
          <h2 className="!mt-0">Try Llama 2 in our API playground</h2>
          <p>Before you dive in, try Llama 2 in our API playground.</p>
          <p>
            Try tweaking the prompt and see how Llama 2 responds. Most models on
            Replicate have an interactive API playground like this, available on
            the model page:{" "}
            <a href={modelDetailRoute}>
              https://replicate.com{modelDetailRoute}
            </a>
            .
          </p>
          <p>
            The API playground is a great way to get a feel for what a model can
            do, and provides copyable code snippets in a variety of languages to
            help you get started.
          </p>
          <div className="border border-r8-gray-5 mb-lh not-prose">
            <div className="border-b border-r8-gray-5 p-4 bg-r8-gray-1">
              <div className="flex items-center justify-between">
                <p className="font-heading !my-0 text-r8-xl">
                  <a href={model.url}>
                    {model.owner}/{model.name}
                  </a>
                </p>
                <Badge variant="default">API Playground</Badge>
              </div>
            </div>
            <div className="p-4">
              <APIPlayground
                model={model}
                initialPrediction={initialPrediction ?? null}
                initialPredictionVersion={initialPredictionVersion ?? null}
                features={features}
                version={versionWithAdvancedInputs}
                hideAdvancedInputs={true}
                hideVersionMismatchWarning={true}
                setPredictionIdInUrl={false}
                isAuthenticated={Boolean(token)}
                permissions={{
                  ...permissions,
                  share: false,
                  tweak: false,
                  delete: false,
                  report: false,
                  create_example: false,
                  debug: false,
                }}
                modelInputSettings={{ hidden: [] }}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      id: "running-llama-2-with-javascript",
      title: "Running Llama 2 with JavaScript",
      content: (
        <>
          <h2 className="!mt-0">Running Llama 2 with JavaScript</h2>
          <p>
            You can run Llama 2 with our{" "}
            <a href="https://github.com/replicate/replicate-javascript">
              official JavaScript client
            </a>
            :
          </p>
          <RunWith
            context={RunWithContext.NodeJS}
            input={{
              prompt:
                "Can you write a poem about open source machine learning?",
            }}
            model={model}
            token={token}
            usesVersionlessApi
            version={version}
          />
        </>
      ),
    },
    {
      id: "running-llama-2-with-python",
      title: "Running Llama 2 with Python",
      content: (
        <>
          <h2>Running Llama 2 with Python</h2>
          <p>
            You can run Llama 2 with our{" "}
            <a href="https://github.com/replicate/replicate-python">
              official Python client
            </a>
            :
          </p>
          <RunWith
            context={RunWithContext.Python}
            input={{
              prompt:
                "Can you write a poem about open source machine learning?",
            }}
            model={model}
            token={token}
            usesVersionlessApi
            version={version}
          />
        </>
      ),
    },
    {
      id: "running-llama-2-with-curl",
      title: "Running Llama 2 with cURL",
      content: (
        <>
          <h2>Running Llama 2 with cURL</h2>
          <p>
            Your can{" "}
            <a href="https://replicate.com/meta/llama-2-70b-chat/api">
              call the HTTP API directly
            </a>{" "}
            with tools like cURL:
          </p>
          <RunWith
            context={RunWithContext.HTTP}
            input={{
              prompt:
                "Can you write a poem about open source machine learning?",
            }}
            model={model}
            token={token}
            usesVersionlessApi
            version={version}
          />
          <p>
            You can also run Llama using{" "}
            <a href="https://replicate.com/docs/reference/client-libraries">
              other Replicate client libraries for Go, Swift, and others
            </a>
            .
          </p>
        </>
      ),
    },
    {
      id: "choosing-which-model-to-use",
      title: "Choosing which model to use",
      content: (
        <>
          <h2>Choosing which model to use</h2>
          <p>
            There are four variant Llama 2 models on Replicate, each with their
            own strengths:
          </p>
          <ul>
            <li>
              <strong>
                <a href="https://replicate.com/meta/llama-2-70b-chat">
                  meta/llama-2-70b-chat
                </a>
              </strong>
              : 70 billion parameter model fine-tuned on chat completions. If
              you want to build a chat bot with the best accuracy, this is the
              one to use.
            </li>
            <li>
              <strong>
                <a href="https://replicate.com/meta/llama-2-70b">
                  meta/llama-2-70b
                </a>
              </strong>
              : 70 billion parameter base model. Use this if you want to do
              other kinds of language completions, like completing a user’s
              writing.
            </li>
            <li>
              <strong>
                <a href="https://replicate.com/meta/llama-2-13b-chat">
                  meta/llama-2-13b-chat
                </a>
              </strong>
              : 13 billion parameter model fine-tuned on chat completions. Use
              this if you’re building a chat bot and would prefer it to be
              faster and cheaper at the expense of accuracy.
            </li>
            <li>
              <strong>
                <a href="https://replicate.com/meta/llama-2-7b-chat">
                  meta/llama-2-7b-chat
                </a>
              </strong>
              : 7 billion parameter model fine-tuned on chat completions. This
              is an even smaller, faster model.
            </li>
          </ul>
          <p>
            What's the difference between these?{" "}
            <a href="https://replicate.com/blog/all-the-llamas">
              Learn more in our blog post comparing 7B, 13B, and 70B.
            </a>
          </p>
        </>
      ),
    },
    {
      id: "example-chat-app",
      title: "Example chat app",
      content: (
        <>
          <h2>Example chat app</h2>
          <p>
            If you want a place to start, we’ve built a{" "}
            <a href="https://github.com/replicate/llama-chat">
              demo chat app in Next.js
            </a>{" "}
            that can be deployed on Vercel:
          </p>
          <video
            className="w-full mb-2lh"
            src="https://d31rfu1d3w8e4q.cloudfront.net/static/blog/llama-api/llama-chat-demo.mp4"
          />

          <p>
            Take a look at the{" "}
            <a href="https://github.com/replicate/llama-chat">GitHub README</a>{" "}
            to learn how to customize and deploy it.
          </p>
        </>
      ),
    },
    {
      id: "fine-tune-llama-2",
      title: "Fine-tune Llama 2",
      content: (
        <>
          <h2>Fine-tune Llama 2</h2>
          <p>
            Because Llama 2 is open source, you can train it on more data to
            teach it new things, or learn a particular style.
          </p>
          <p>
            Replicate makes this easy.{" "}
            <a href="https://replicate.com/blog/fine-tune-llama-2">
              Take a look at our guide to fine-tune Llama 2.
            </a>
          </p>
        </>
      ),
    },
    {
      id: "run-llama-2-locally",
      title: "Run Llama 2 locally",
      content: (
        <>
          <h2>Run Llama 2 locally</h2>
          <p>
            You can also run Llama 2 without an internet connection. We wrote a
            comprehensive guide to running Llama on your{" "}
            <a href="https://replicate.com/blog/run-llama-locally">
              M1/M2 Mac, on Windows, on Linux, or even your phone.
            </a>
          </p>
        </>
      ),
    },
    {
      id: "keep-up-to-speed",
      title: "Keep up to speed",
      content: (
        <>
          <h2>Keep up to speed</h2>
          <ul>
            <li>
              <a href="https://x.com/replicate">
                Follow us on <s>Twitter</s> X to get the latest from the
                Llamaverse.
              </a>
            </li>
            <li>
              <a href="https://discord.gg/replicate">
                Hop in our Discord to talk Llama.
              </a>
            </li>
          </ul>
          <p>Happy hacking! 🦙</p>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-12 gap-8 readme-prose max-w-full">
        <div className="col-span-full lg:col-span-9 row-start-2 lg:row-start-1">
          {!isAuthenticated && (
            <div className="mb-8">
              <h2 className="mt-0">Before you begin</h2>
              <Banner
                severity="info"
                icon={<icons.DeploymentIcon />}
                description="We recommend signing in to Replicate before you get
                      started. If you're new to Replicate, you can try featured models out
                      for free. Once you have an account, you'll have an access
                      token that will let you run Llama 2 in the cloud."
                meta={
                  <Button
                    render={
                      // biome-ignore lint/a11y/useAnchorContent: <explanation>
                      <a
                        href={`${route(
                          "signin"
                        )}?next=/blog/run-llama-2-with-an-api`}
                      />
                    }
                    size="sm"
                    intent="info"
                  >
                    Join Replicate
                  </Button>
                }
              />
            </div>
          )}
          {contentBlocks.map(({ id, content }) => {
            return (
              <InView
                key={id}
                as="section"
                className="scroll-mt-20"
                id={id}
                threshold={0.5}
                onChange={(inView) => {
                  if (inView) setActiveSection(id);
                }}
              >
                {content}
              </InView>
            );
          })}
        </div>
        <div className="col-span-full lg:col-span-4 row-start-1 lg:row-start-1 sticky top-4 lg:static lg:top-0">
          <MobileTableOfContents
            contentBlocks={contentBlocks}
            activeSection={activeSection}
          />
          <DesktopTableOfContents
            contentBlocks={contentBlocks}
            activeSection={activeSection}
          />
        </div>
      </div>
    </div>
  );
}
