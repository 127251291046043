import { createContext, useContext } from "react";

export interface SvixCustomerContext {
  app_id: string;
  token: string;
}

export const SvixCustomerContext = createContext<SvixCustomerContext | null>(
  null
);

export function useSvixCustomerContext() {
  const context = useContext(SvixCustomerContext);
  return context;
}
