import { P, match } from "ts-pattern";
import type { Prediction } from "../../types";
import { Duration } from "../duration";
import { TokenList } from "./token-list";
import { humanizeTokenCount, tokenize } from "./util";

export default function PredictionTokens({
  prediction,
}: {
  prediction: Prediction;
}) {
  const text = match(prediction.output)
    .with(P.string, (s) => s)
    .with(P.array(P.string), (a) => a.join(""))
    .otherwise(() => "");

  const tokens = tokenize(text);

  return (
    <div className="bg-r8-gray-3">
      <code className="token-list block p-2 md:p-3 whitespace-pre-wrap text-r8-gray-12 font-mono text-r8-sm overflow-y-auto max-h-96">
        <TokenList tokens={tokens} />
      </code>
    </div>
  );
}

function Skeleton() {
  return <div className="animate-pulse bg-r8-gray-3 h-4 w-20 rounded-sm" />;
}

function Item<T>({
  label,
  value,
  loading,
  renderValue,
}: {
  label: string;
  value?: T;
  loading?: boolean;
  renderValue: (value?: T) => React.ReactNode;
}) {
  return (
    <div className="flex flex-col space-y-1">
      <div className="text-r8-sm text-r8-gray-11">{label}</div>
      {loading ? <Skeleton /> : <div>{renderValue(value)}</div>}
    </div>
  );
}

export function PredictionTokenMeta({
  input_token_count,
  output_token_count,
  time_to_first_token,
  tokens_per_second,
  loading = false,
}: {
  input_token_count?: number;
  output_token_count?: number;
  time_to_first_token?: number;
  tokens_per_second?: number;
  loading?: boolean;
}) {
  if (
    !input_token_count &&
    !output_token_count &&
    !time_to_first_token &&
    !tokens_per_second
  ) {
    return null;
  }

  return (
    <div className="flex flex-col md:flex-row md:flex-wrap gap-4 md:gap-8">
      <Item
        label="Input tokens"
        value={input_token_count}
        loading={loading}
        renderValue={(value) => {
          if (!value) {
            return <span className="text-r8-gray-11">–</span>;
          }

          return <span>{humanizeTokenCount(value)}</span>;
        }}
      />

      <Item
        label="Output tokens"
        value={output_token_count}
        loading={loading}
        renderValue={(value) => {
          if (!value) {
            return <span className="text-r8-gray-11">–</span>;
          }

          return <span>{humanizeTokenCount(value)}</span>;
        }}
      />

      <Item
        label="Tokens per second"
        loading={loading}
        value={tokens_per_second}
        renderValue={(value) => {
          if (!value) {
            return <span className="text-r8-gray-11">–</span>;
          }

          return <span>{value.toFixed(2)} tokens / second</span>;
        }}
      />

      <Item
        label="Time to first token"
        loading={loading}
        value={time_to_first_token}
        renderValue={(value) => {
          if (!value || value < 0) {
            return <span className="text-r8-gray-11">–</span>;
          }

          return <Duration seconds={value} />;
        }}
      />
    </div>
  );
}
