export function LogoShorthand({
  color = "currentColor",
  size,
}: {
  color?: string;
  size: string | number;
}) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      viewBox="0 0 1000 1000"
      fill={color}
      width={size}
      height={size}
    >
      <g>
        <polygon points="1000,427.6 1000,540.6 603.4,540.6 603.4,1000 477,1000 477,427.6" />
        <polygon points="1000,213.8 1000,327 364.8,327 364.8,1000 238.4,1000 238.4,213.8" />
        <polygon points="1000,0 1000,113.2 126.4,113.2 126.4,1000 0,1000 0,0" />
      </g>
    </svg>
  );
}
