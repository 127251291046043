import type { ErrorWithJSON } from "./types";

export async function throwForStatus(response: Response): Promise<Response> {
  if (response.ok) {
    return response;
  }

  throwFromJsonError(await response.json<ErrorWithJSON>());
}

function throwFromJsonError(payload: ErrorWithJSON): never {
  const err = new Error(payload.detail);

  if (payload.code != null) {
    (err as any).code = payload.code;
  }

  throw err;
}
