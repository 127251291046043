import { Check, Copy } from "@phosphor-icons/react";
import copy from "copy-to-clipboard";
import { useState } from "react";
import toast from "react-hot-toast";

export function CopyLogsButton({ logs }: { logs: string | null }) {
  const [didCopy, setDidCopy] = useState(false);

  const copyContent = async () => {
    if (!logs) {
      toast("No logs to copy!");
      return;
    }
    try {
      await navigator.clipboard.writeText(logs);
    } catch (e) {
      copy(logs);
    }
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 3000);
  };

  return (
    <button
      type="button"
      onClick={copyContent}
      disabled={didCopy}
      className="text-r8-sm hover:bg-r8-gray-a3 text-r8-gray-11 px-2 py-1 inline-flex items-center gap-2"
    >
      <span className="flex-shrink-0">{didCopy ? <Check /> : <Copy />}</span>
      <span>{didCopy ? "Copied logs" : "Copy logs"}</span>
    </button>
  );
}
