import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import type React from "react";
import type { Prediction } from "../../types";
import { formatSource } from "../../util";
import ConditionalWrap from "../conditional-wrap";
import { LiveStatusBadge } from "../live-status-badge";
import PredictionDuration from "../prediction-duration";
import { RelativeTime } from "../relative-time";
import { usePlaygroundContext } from "./context";

export function Skeleton() {
  return <div className="animate-pulse bg-r8-gray-3 h-4 w-20 rounded-sm" />;
}

export function EntityMeta({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col md:flex-row md:flex-wrap gap-6 text-r8-gray-12">
      {children}
    </div>
  );
}

EntityMeta.ItemLabel = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-r8-sm text-r8-gray-11">{children}</div>;
};

EntityMeta.ItemFallback = () => {
  return <span className="text-r8-gray-11">–</span>;
};

EntityMeta.ItemValue = ({
  children,
  loading,
}: { children: React.ReactNode; loading?: boolean }) => {
  if (loading) return <Skeleton />;
  return <div>{children}</div>;
};

EntityMeta.Item = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col space-y-1">{children}</div>;
};

interface PredictionMetaItemProps {
  prediction?: Prediction;
  loading?: boolean;
}

// Exporting a bunch of re-usable meta items that we can use across
// predictions and trainings
export const PredictionMetaItems = {
  Model: ({ loading }: PredictionMetaItemProps) => {
    const { version } = usePlaygroundContext();
    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Model</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>
          <a href={version._extras.url}>{version._extras.name}</a>
        </EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  OfficialModel: ({ prediction, loading }: PredictionMetaItemProps) => {
    if (prediction?._extras.official_model == null) {
      return <EntityMeta.ItemFallback />;
    }

    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Official model</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>
          <a href={prediction._extras.official_model.url}>
            {prediction._extras.official_model.full_name}
          </a>
        </EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  Deployment: ({ prediction, loading }: PredictionMetaItemProps) => {
    const value = () => {
      if (!prediction) {
        return <EntityMeta.ItemFallback />;
      }
      const deployment = prediction._extras.deployment;
      if (!deployment) {
        throw new Error(
          "Trying to render deployment MetaItem without deployment"
        );
      }
      return (
        <a href={deployment._extras.url}>
          {deployment.owner}/{deployment.name}
        </a>
      );
    };
    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Deployment</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>{value()}</EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  Id: ({ prediction, loading }: PredictionMetaItemProps) => (
    <EntityMeta.Item>
      <EntityMeta.ItemLabel>ID</EntityMeta.ItemLabel>
      <EntityMeta.ItemValue loading={loading}>
        {prediction ? prediction.id : <EntityMeta.ItemFallback />}
      </EntityMeta.ItemValue>
    </EntityMeta.Item>
  ),
  Status: ({ prediction, loading }: PredictionMetaItemProps) => {
    const value = () => {
      if (!prediction) {
        return <EntityMeta.ItemFallback />;
      }

      return prediction.status ? (
        <LiveStatusBadge
          status={prediction.status}
          isBooting={prediction._extras.is_waiting_for_boot}
        />
      ) : (
        <EntityMeta.ItemFallback />
      );
    };
    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Status</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>{value()}</EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  Source: ({ prediction, loading }: PredictionMetaItemProps) => {
    const value = () => {
      if (!prediction) {
        return <EntityMeta.ItemFallback />;
      }

      const source = prediction._extras.source;
      const apiTokenName = prediction._extras.api_token_name;
      return source ? (
        <ConditionalWrap
          condition={Boolean(apiTokenName)}
          wrap={(children) => (
            <TooltipProvider>
              <TooltipAnchor className="border-dotted border-b border-r8-gray-10 inline-block text-r8-gray-11">
                {children}
              </TooltipAnchor>
              <Tooltip className="max-w-48 text-center">
                <p>Prediction created using the API token</p>
                <p>
                  <strong>{apiTokenName}</strong>
                </p>
                <TooltipArrow />
              </Tooltip>
            </TooltipProvider>
          )}
        >
          <span>{formatSource(source)}</span>
        </ConditionalWrap>
      ) : (
        "–"
      );
    };

    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Source</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>{value()}</EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  Hardware: ({ prediction, loading }: PredictionMetaItemProps) => {
    const value = () => {
      if (!prediction) {
        return <EntityMeta.ItemFallback />;
      }
      const { hardware } = prediction._extras;
      return hardware ? <abbr>{hardware}</abbr> : <EntityMeta.ItemFallback />;
    };
    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Hardware</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>{value()}</EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  TotalDuration: ({ prediction, loading }: PredictionMetaItemProps) => {
    const value = () => {
      if (!prediction) {
        return <EntityMeta.ItemFallback />;
      }

      const metrics = prediction.metrics;
      if (!metrics) {
        return <EntityMeta.ItemFallback />;
      }

      const { predict_time: predictTime, total_time: totalTime } = metrics;

      if (!predictTime || !totalTime) {
        return <EntityMeta.ItemFallback />;
      }
      return (
        <PredictionDuration
          className="inline-block"
          enqueuedTime={totalTime - predictTime}
          predictTime={predictTime}
        />
      );
    };

    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Total duration</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>{value()}</EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
  Created: ({ prediction, loading }: PredictionMetaItemProps) => {
    const value = () => {
      if (!prediction?.created_at) {
        return <EntityMeta.ItemFallback />;
      }

      return (
        <>
          <RelativeTime
            className="text-r8-gray-11"
            dateTime={new Date(prediction.created_at)}
          />
          {
            // TODO: Either check if the creator and viewer are different
            // before adding a byline or always show the byline.
            prediction._extras.is_shared && prediction._extras.created_by && (
              <span>
                {" "}
                by{" "}
                <a href={prediction._extras.created_by.url}>
                  @{prediction._extras.created_by.username}
                </a>
              </span>
            )
          }
        </>
      );
    };

    return (
      <EntityMeta.Item>
        <EntityMeta.ItemLabel>Created</EntityMeta.ItemLabel>
        <EntityMeta.ItemValue loading={loading}>{value()}</EntityMeta.ItemValue>
      </EntityMeta.Item>
    );
  },
};
