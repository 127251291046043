import type { PredictionStatus, Version } from "./types";
import { route } from "./urls";

export function getMetaTagContent(name: string) {
  const metaTag = document.querySelector(`meta[name='${name}']`);
  return metaTag ? metaTag.getAttribute("content") : null;
}

export function formatSource(source: string) {
  if (source === "web") {
    return "Web";
  }

  if (source === "api") {
    return "API";
  }

  return source;
}

export function getHumanStatus(status: PredictionStatus) {
  if (status === "processing") {
    return "Running";
  }

  return status.charAt(0).toUpperCase() + status.slice(1);
}

export function deconstructVersionFullName(versionFullName: string): Pick<
  Version,
  "id"
> & {
  _extras: Pick<Version["_extras"], "name" | "url"> & {
    model: Pick<Version["_extras"]["model"], "owner" | "name">;
  };
} {
  // version looks like replicate/robohacker:97d3c66e4783da1fa156458a362bf12d31ffd6215b80611f83bb4da25c3ffaf9
  const [modelFullName, id] = versionFullName.split(":");
  const [modelOwner, modelName] = modelFullName.split("/");
  return {
    id,
    _extras: {
      model: {
        owner: modelOwner,
        name: modelName,
      },
      name: `${modelOwner}/${modelName}:${id.slice(0, 8)}`,
      url: route("version_detail", {
        username: modelOwner,
        name: modelName,
        docker_image_id: id,
      }),
    },
  };
}

const cloudfrontAssetPath = "https://d31rfu1d3w8e4q.cloudfront.net";

/**
 *
 * @param path Path to the static asset
 * @description A client-side implementation of our `{% static %}` template tag in Django.
 *  Our static assets are hosted on Cloudfront. In order to get the correct path to the asset, we need to prepend the Cloudfront URL to the path.
 */
export function getStaticAssetPath(path: string) {
  return `${cloudfrontAssetPath}${path}`;
}

export function getMaxDecimalPlaces(
  values: (number | string | null | undefined)[]
): number {
  let maxDecimalPlaces = 0;

  for (let value of values) {
    if (!value) {
      continue;
    }

    value = value.toString();

    if (!value.includes(".")) {
      continue;
    }

    maxDecimalPlaces = Math.max(
      maxDecimalPlaces,
      value.split(".")[1].length || 0
    );
  }

  return maxDecimalPlaces;
}
