import * as Ariakit from "@ariakit/react";
import CodeBlock from "../code-block";
import { File } from "@phosphor-icons/react";

export type FileItem = {
  content: React.ReactNode;
  language: string;
};

export function FilesView({
  files,
  initialTab,
}: { files: Record<string, FileItem>; initialTab?: string }) {
  return (
    <Ariakit.TabProvider defaultSelectedId={initialTab} orientation="vertical">
      <div className="flex flex-col lg:flex-row h-96 lg:h-72 divide-y lg:divide-y-0 lg:divide-x divide-black overflow-hidden">
        <div className="flex flex-shrink-0 lg:w-48 p-2">
          <Ariakit.TabList className="flex flex-col gap-1 w-full">
            {Object.keys(files).map((fileName) => {
              return (
                <Ariakit.Tab
                  key={fileName}
                  className="text-left text-r8-sm p-2 inline-flex items-center gap-2 aria-selected:bg-black w-full aria-selected:text-white aria-selected:font-semibold hover:bg-r8-gray-2"
                  id={fileName}
                >
                  <File />
                  {fileName}
                </Ariakit.Tab>
              );
            })}
          </Ariakit.TabList>
        </div>
        <div className="flex-1 overflow-hidden h-full">
          {Object.keys(files).map((fileName) => {
            const { content, language } = files[fileName];
            return (
              <Ariakit.TabPanel
                className="h-full code-panel overflow-auto"
                tabId={fileName}
                key={fileName}
              >
                {typeof content === "string" ? (
                  <CodeBlock
                    className={`language-${language}`}
                    textContent={content}
                  />
                ) : (
                  content
                )}
              </Ariakit.TabPanel>
            );
          })}
        </div>
      </div>
    </Ariakit.TabProvider>
  );
}
