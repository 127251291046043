export function NullValue({
  name,
  message,
}: {
  name?: string;
  message: string;
}) {
  return (
    <div
      data-testid={name ? `value-${name}-null` : "value-null"}
      className="output p-2 whitespace-pre-wrap bg-r8-gray-3 text-r8-gray-12 font-mono text-r8-sm overflow-y-auto max-h-96"
      dir="auto"
      translate="no"
    >
      {message}
    </div>
  );
}
