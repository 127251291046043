import { useEffect, useRef, type ReactNode } from "react";
import { P, match } from "ts-pattern";

const officialErrors = {
  TIME_LIMIT: () => {
    return (
      <div>
        <p className="mb-lh">
          To continue running predictions, you'll need to sign up and enter your
          credit card.
        </p>
        <p className="mb-lh">
          There is no charge to sign up, then your predictions will be billed by
          the second. <a href="/pricing">See pricing for more details.</a>
        </p>
        <p className="mb-lh">
          <a className="form-button" href="/account">
            Sign up and enter credit card
          </a>
        </p>
      </div>
    );
  },
};

export function PredictionErrorState({
  error,
  fallback,
}: {
  error: unknown;
  fallback: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div ref={ref}>
      <div className="mb-1">
        <p className="text-r8-xs uppercase tracking-wide text-r8-red-10 font-semibold">
          Error
        </p>
      </div>
      {match(error)
        // Likely a server error. Let's treat as a normal error.
        .with(P.instanceOf(Error), (err) => {
          return <p className="text-r8-red-10">{err.message}</p>;
        })
        .with(
          {
            detail: P.string,
            title: P.string.optional(),
            code: P.string.optional(),
            status: P.number,
          },
          (err) => {
            const ErrorComponent = err.code ? officialErrors[err.code] : null;
            return (
              <div className="space-y-2">
                <p className="text-r8-red-10">{err.detail}</p>
                {ErrorComponent ? <ErrorComponent /> : null}
              </div>
            );
          }
        )
        .otherwise(() => {
          return fallback;
        })}
    </div>
  );
}
