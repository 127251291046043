import {
  Button,
  Dialog,
  DialogDescription,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import type { Version } from "../types";
import { route } from "../urls";

const FALLBACK_ERROR_MESSAGE =
  "An unknown error occurred while deleting this version";

async function deleteVersion({ version }: { version: Version }) {
  const response = await fetch(
    route("api_version_delete", {
      username: version._extras.model.owner,
      name: version._extras.model.name,
      docker_image_id: version.id,
    }),
    {
      method: "DELETE",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    }
  );

  if (response.ok) {
    const { next } = await response.json<{ next: string }>();
    return next;
  }

  if (response.status === 400) {
    const { detail } = await response.json<{ detail: string }>();
    throw new Error(detail);
  }

  throw new Error(FALLBACK_ERROR_MESSAGE);
}

export default function DeleteVersionButton({
  version,
  validationError,
}: {
  version: Version;
  validationError?: string;
}) {
  const { mutate, status } = useMutation({
    mutationFn: deleteVersion,
    onSuccess: (next) => {
      window.location.href = next;
    },
    onError: (error) => {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error(FALLBACK_ERROR_MESSAGE);
      }
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate({ version });
  };

  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor>
        <DialogProvider>
          <DialogDisclosure
            render={
              <Button intent="danger" disabled={!!validationError}>
                Delete
              </Button>
            }
          >
            Delete version
          </DialogDisclosure>
          <Dialog className="p-4" size="lg">
            <DialogHeading>Delete version</DialogHeading>
            <DialogDescription>
              When deleting a version all in progress predictions will be
              cancelled, all running instances will be shut down and the
              associated container images will be deleted.
            </DialogDescription>
            <div className="mt-6 flex items-center justify-end gap-4">
              <DialogDismiss
                render={<Button variant="outlined">Cancel</Button>}
              />
              <form onSubmit={handleSubmit}>
                <Button
                  loading={status === "pending"}
                  intent="danger"
                  type="submit"
                >
                  Delete version
                </Button>
              </form>
            </div>
          </Dialog>
        </DialogProvider>
      </TooltipAnchor>
      {validationError ? (
        <Tooltip>
          <TooltipArrow />
          {validationError}
        </Tooltip>
      ) : null}
    </TooltipProvider>
  );
}
