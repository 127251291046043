import { Button } from "@replicate/ui";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import type { AccessToken } from "../types";
import { route } from "../urls";
import { AuthTokenInput } from "./auth-token";

export default function WebhookSigningKey() {
  const [showSigningKey, setShowSigningKey] = useState(false);

  const { data, status, refetch } = useQuery({
    enabled: showSigningKey,
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {
      const res = await fetch(route("api_webhook_signing_key"));
      if (!res.ok) {
        throw Error("Failed to fetch signing key.");
      }

      const json = await res.json<{ token: AccessToken }>();
      return json.token;
    },
    queryKey: ["api-webhook-signing-key"],
  });

  return (
    <div>
      {showSigningKey ? (
        <>
          {status === "success" && <AuthTokenInput token={data} />}
          {status === "pending" && (
            <p className="text-sm text-r8-gray-11 animate-pulse">
              Getting signing key...
            </p>
          )}
          {status === "error" && (
            <div className="flex items-center gap-2">
              <p className="text-r8-sm text-r8-red-11">
                Something went wrong when fetching signing key.
              </p>
              <Button
                onClick={() => refetch()}
                size="sm"
                variant="outlined"
                intent="danger"
              >
                Retry
              </Button>
            </div>
          )}
        </>
      ) : (
        <Button
          onClick={() => {
            setShowSigningKey(true);
          }}
          size="sm"
        >
          Show signing key
        </Button>
      )}
    </div>
  );
}
