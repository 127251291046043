import type { BooleanCogPropertyItemSchema, CogOutputValue } from "../../types";
import { FallbackValue } from "./fallback-value";

export function BooleanValue({
  name,
  reportFallback,
  schema,
  value,
}: {
  name?: string;
  reportFallback: boolean;
  schema: BooleanCogPropertyItemSchema | undefined;
  value: CogOutputValue;
}) {
  if (typeof value !== "boolean") {
    return (
      <FallbackValue
        name={name}
        schema={schema}
        value={value}
        report={reportFallback}
      />
    );
  }

  return (
    <input
      data-testid={name ? `value-${name}-boolean` : "value-boolean"}
      type="checkbox"
      name={name}
      className="w-5 h-5 relative top-1 mr-2"
      disabled
      defaultChecked={value}
    />
  );
}
