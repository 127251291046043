import { autoUpdate, offset, useFloating } from "@floating-ui/react";
import {
  ArrowClockwise,
  ArrowLeft,
  CheckCircle,
  Play,
  Spinner,
} from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactElement,
  type ReactNode,
} from "react";
import { WindupChildren } from "windups";

type TerminalLineProps = {
  children: ReactNode;
  typed?: boolean;
  interactive?: boolean;
};

function NormalCommandLine({ children }: { children: ReactNode }) {
  const { setIsLineFinished } = useContext(TerminalWindowContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setIsLineFinished(true);
  }, []);

  return <>{children}</>;
}

function TypedCommandLine({ children }: { children: ReactNode }) {
  const { setIsLineFinished } = useContext(TerminalWindowContext);
  return (
    <WindupChildren
      onFinished={() => {
        setIsLineFinished(true);
      }}
    >
      {children}
    </WindupChildren>
  );
}

function InteractiveCommandLine({ children }: { children: ReactNode }) {
  const [run, setRun] = useState(false);
  const { setIsLineFinished, isDemoFinished, restartDemo } = useContext(
    TerminalWindowContext
  );

  const { refs, floatingStyles, update } = useFloating({
    strategy: "fixed",
    placement: "right",
    open: run,
    middleware: [offset(16)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <div>
      {children}
      <div className="absolute right-2 top-0 bottom-0 flex items-center justify-center">
        {!run ? (
          <button
            ref={refs.setReference}
            type="button"
            className="disabled:opacity-50 disabled:cursor-not-allowed rounded-full w-6 h-6 bg-white text-r8-gray-12 flex items-center justify-center transition-all animate-pulse"
            onClick={() => {
              setRun(true);
              setIsLineFinished(true);
            }}
          >
            <Play weight="fill" />
          </button>
        ) : (
          <button
            type="button"
            disabled={!isDemoFinished}
            onClick={() => {
              setRun(false);
              restartDemo();
            }}
            className="disabled:opacity-50 disabled:cursor-not-allowed rounded-full w-6 h-6 bg-white text-r8-gray-12 flex items-center justify-center transition-all animate-pulse"
          >
            <ArrowClockwise weight="fill" />
          </button>
        )}
        <AnimatePresence>
          {!run && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              ref={refs.setFloating}
              style={floatingStyles}
              className="hidden lg:block"
            >
              <div className="flex items-center gap-1 text-r8-gray-11 text-r8-sm font-sans">
                <ArrowLeft className="" weight="fill" />{" "}
                <span>Psst, I'm interactive!</span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export function CommandLine({
  children,
  typed,
  interactive,
}: TerminalLineProps) {
  return (
    <div className="hover:bg-white/10 transition-colors px-4 py-2 flex items-center gap-2 relative">
      <span>$</span>
      {interactive ? (
        <InteractiveCommandLine>{children}</InteractiveCommandLine>
      ) : typed ? (
        <TypedCommandLine>{children}</TypedCommandLine>
      ) : (
        <NormalCommandLine>{children}</NormalCommandLine>
      )}
    </div>
  );
}

type OutputLineProps = {
  children: ReactNode;
  duration: number;
};

export function OutputLine({ children, duration }: OutputLineProps) {
  const { setIsLineFinished } = useContext(TerminalWindowContext);
  const [status, setStatus] = useState<"in-progress" | "done">("in-progress");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStatus("done");
      setIsLineFinished(true);
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration, setIsLineFinished]);

  return (
    <div className="hover:bg-white/10 transition-colors px-4 py-2 flex items-center gap-2">
      {duration > 0 && (
        <div>
          {status === "in-progress" ? (
            <Spinner className="animate-spin" size={17} />
          ) : status === "done" ? (
            <CheckCircle size={17} weight="duotone" />
          ) : (
            ""
          )}
        </div>
      )}
      {children}
    </div>
  );
}

type TerminalWindowProps = {
  children: React.ReactNode | ReactElement[];
  className?: string;
};

const TerminalWindowContext = createContext({
  isLineFinished: false,
  setIsLineFinished: (isLineFinished: boolean) => {},
  isDemoFinished: false,
  restartDemo: () => {},
});

export function TerminalWindow({ children, className }: TerminalWindowProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLineFinished, setIsLineFinished] = useState(false);
  const [isDemoFinished, setIsDemoFinished] = useState(false);
  const isArrayOfChildren = Array.isArray(children);

  const restartDemo = () => {
    setIsDemoFinished(false);
    if (!isArrayOfChildren) return 0;
    const indexOfFirstInteractiveLine = children.findIndex(
      (child) => child.props.interactive
    );
    setCurrentIndex(indexOfFirstInteractiveLine);
  };

  useEffect(() => {
    if (!isArrayOfChildren) {
      return;
    }

    if (currentIndex === children.length) {
      setIsDemoFinished(true);
    }
  }, [currentIndex, children, isArrayOfChildren]);

  useEffect(() => {
    // Check if children is an array
    if (!isArrayOfChildren) {
      return;
    }

    if (isLineFinished && currentIndex < children.length) {
      setIsLineFinished(false);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentIndex, children, isLineFinished, isArrayOfChildren]);

  return (
    <TerminalWindowContext.Provider
      value={{ isLineFinished, setIsLineFinished, isDemoFinished, restartDemo }}
    >
      <div className={`bg-black text-white py-2 ${className}`}>
        <div className="whitespace-pre overflow-auto font-mono">
          {!isArrayOfChildren
            ? children
            : currentIndex === 0
              ? children[0]
              : children.slice(0, currentIndex + 1)}
        </div>
      </div>
    </TerminalWindowContext.Provider>
  );
}
