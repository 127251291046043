import Cookies from "js-cookie";
import { type FormEvent, type MouseEvent, useState } from "react";
import AccountSelect, { type AccountSelectOption } from "./account-select";
import { LogoShorthand } from "./icons/generic/logo-shorthand";
import SignInPageGoo from "./sign-in-page-goo";

export default function VercelIntegration({
  accounts,
  actingPrincipalUsername,
}: { accounts: AccountSelectOption[]; actingPrincipalUsername: string }) {
  const [loading, setLoading] = useState(false);
  const [csrfToken, setCsrfToken] = useState(Cookies.get("csrftoken"));

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    // Just in case the csrf wasnt set at component-render, we can set it here
    if (!csrfToken) {
      setCsrfToken(Cookies.get("csrftoken"));
    }
    event.currentTarget.submit();
  };

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.close();
  };

  return (
    <div className="h-screen bg-black p-4 md:p-8 relative z-0 max-w-[1920px] mx-auto w-full flex items-center">
      <div className="absolute inset-0 w-full h-full pointer-events-none">
        <SignInPageGoo />
      </div>
      <div className="bg-white max-w-3xl mx-auto z-1 flex flex-col py-20">
        <div className="flex-1 grid grid-rows-1">
          <div className="space-y-1 text-center flex flex-col items-center justify-center relative px-8">
            <div className="mb-8">
              <div className="flex justify-between items-center">
                <div className="w-8 md:w-10">
                  <LogoShorthand size={42} />
                </div>
                <div className="w-8 md:w-8 ml-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-plus"
                  >
                    <path d="M5 12h14" />
                    <path d="M12 5v14" />
                  </svg>
                </div>
                <div className="w-8 md:w-10">
                  <svg
                    width="42"
                    height="42"
                    viewBox="0 0 76 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M37.5274 0L75.0548 65H0L37.5274 0Z"
                      fill="#000000"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <form method="post" className="inline-flex" onSubmit={handleSubmit}>
              <div className="space-y-2">
                <h1 className="font-heading text-r8-3xl md:text-r8-4xl">
                  Vercel integration
                </h1>
                <div className="pt-4 space-y-2">
                  <div className="w-1/2 mx-auto">
                    <AccountSelect
                      accounts={accounts}
                      id="account_select"
                      name="principal_username"
                      value={actingPrincipalUsername}
                    />
                  </div>
                  <p className="pt-4">
                    Do you wish to continue setting up this Vercel integration?
                    We will create an API key for the selected account, and
                    share it with your projects on Vercel.
                  </p>
                  <div className="pt-6 space-x-4">
                    <button
                      type="submit"
                      className="form-button-secondary"
                      onClick={handleCancel}
                    >
                      No thanks
                    </button>
                    <input
                      type="hidden"
                      name="csrfmiddlewaretoken"
                      value={csrfToken}
                    />
                    <input type="hidden" name="confirm" value="yes" />
                    <button
                      type="submit"
                      className="form-button"
                      disabled={loading}
                    >
                      {loading ? "Loading…" : "Add integration"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
