import { Hash } from "@phosphor-icons/react";
import { useController } from "react-hook-form";
import { Label } from "./label";

export function MultiNumericInput({
  disabled,
  name,
  required,
  type,
}: {
  disabled: boolean;
  name: string;
  required: boolean;
  type: "number[]";
}) {
  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
      validate: {
        // Matches a string of numbers separated by commas
        value(value: string) {
          // If it's not a string, we don't need to do anything
          if (typeof value !== "string") {
            return true;
          }
          const errors = value
            .split(",")
            .map((v): [string, number] => [v, Number(v)])
            .map(([stringV, numberV]) =>
              Number.isNaN(numberV) ? stringV.trim() : null
            )
            .filter((v): v is string => v != null);
          if (errors.length === 0) {
            return true;
          }
          if (errors.length === 1) {
            return `${errors[0]} is not a number`;
          }
          return `${errors.join(", ")} are not numbers`;
        },
      },
    },
  });

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between md:flex-wrap gap-2">
        <Label Icon={Hash} name={name} required={required} type={type} />
        <span className="text-r8-sm text-r8-gray-11">
          Enter a string of numbers separated by commas
        </span>
      </div>
      <div>
        <div className="flex gap-4">
          <input
            id={name}
            className="border p-2 bg-white dark:bg-r8-gray-1 border-r8-gray-12 w-full resize-none disabled:cursor-not-allowed disabled:opacity-50"
            dir="auto"
            disabled={formState.isSubmitting || disabled}
            type="string"
            {...field}
            // Casting the value to a string is necessary to avoid this runtime error:
            // - Warning: `value` prop on `input` should not be null.
            value={field.value ?? ""}
          />
        </div>
      </div>
    </div>
  );
}
