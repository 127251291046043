import type { Prediction } from "../types";
import { CopyLogsButton } from "./copy-logs-button";
import { DownloadLogsButton } from "./download-logs-button";
import { LogsDialog } from "./logs-dialog";

export function LogsFooter({ prediction }: { prediction: Prediction }) {
  return (
    <div className="py-2 lg:flex lg:justify-end">
      <div className="flex flex-col lg:flex-row lg:items-center gap-1">
        <CopyLogsButton logs={prediction.logs} />
        <LogsDialog prediction={prediction} />
        <DownloadLogsButton
          filename={`prediction-${prediction.id}-logs`}
          logs={prediction.logs}
        />
      </div>
    </div>
  );
}
