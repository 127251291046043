import { isNumber } from "lodash-es";

export function MinMaxMessage({
  maximum,
  minimum,
}: {
  maximum: number | undefined;
  minimum: number | undefined;
}) {
  const value =
    isNumber(minimum) && isNumber(maximum)
      ? `(minimum: ${minimum}, maximum: ${maximum})`
      : isNumber(maximum)
        ? `(maximum: ${maximum})`
        : isNumber(minimum)
          ? `(minimum: ${minimum})`
          : null;

  if (value) {
    return <span className="text-r8-sm text-r8-gray-11">{value}</span>;
  }

  return null;
}
