import { Cube, RocketLaunch } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef } from "react";
import { LogoShorthand } from "./generic/logo-shorthand";
import languages from "./languages";

const icons = {
  DeploymentIcon: ({
    weight = "duotone",
    ...rest
  }: ComponentPropsWithoutRef<typeof RocketLaunch>) => (
    <RocketLaunch weight={weight} {...rest} />
  ),
  ModelIcon: ({
    weight = "duotone",
    ...rest
  }: ComponentPropsWithoutRef<typeof Cube>) => (
    <Cube weight={weight} {...rest} />
  ),
  OfficialModelIcon: (
    props: ComponentPropsWithoutRef<typeof LogoShorthand>
  ) => <LogoShorthand {...props} />,
  languages,
};

export default icons;
