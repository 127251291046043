import type { AccountAvatar } from "../../types";
import { route } from "../../urls";
import type { SearchEntity } from "./hooks";

export interface SearchModel {
  description: string | null;
  name: string;
  owner_avatar: AccountAvatar;
  username: string;
  run_count: string;
  latest_enabled_version_id: string | null;
  hardware: string;
  is_official_model: boolean;
}

export interface SearchCollection {
  description: string;
  name: string;
  slug: string;
}

export interface SearchDeployment {
  full_name: string;
}

type SearchResults<T extends SearchEntity[]> = {
  [Key in T[number]]: Key extends "models"
    ? SearchModel[]
    : Key extends "collections"
      ? SearchCollection[]
      : Key extends "deployments"
        ? SearchDeployment[]
        : never;
};

export async function search<T extends SearchEntity[]>(
  query: string,
  entities: T
): Promise<SearchResults<T>> {
  const u = new URL(route("api_search"), window.location.href);

  const urlParams = new URLSearchParams();
  urlParams.set("query", query);

  // There is probably a more idiomatic
  // way of doing this in JS.
  for (const entity of entities) {
    urlParams.append("entities", entity);
  }

  u.search = urlParams.toString();

  const res = await fetch(u.href);
  if (!res.ok) {
    throw new Error("Failed to fetch search results");
  }

  return await res.json<SearchResults<T>>();
}

export async function searchDeploymentModelOptions(
  query: string | undefined
): Promise<{ models: SearchModel[] }> {
  if (!query) {
    return Promise.resolve({ models: [] });
  }

  const u = new URL(
    route("api_search_deployment_model_options"),
    window.location.href
  );
  u.searchParams.set("query", query);

  const res = await fetch(u.href);
  if (!res.ok) {
    throw new Error("Failed to fetch search results");
  }

  return await res.json<{ models: SearchModel[] }>();
}
