import { Tab, TabList, TabPanel, TabProvider } from "@replicate/ui";
import { isEmpty } from "lodash-es";
import { useQueryParam } from "../hooks";
import {
  getInputSchema,
  getOutputSchema,
  getSortedProperties,
  isFile,
} from "../schema";
import type {
  AccessToken,
  CogInputPropertySchema,
  Deployment,
  Version,
} from "../types";
import { route } from "../urls";
import AuthToken from "./auth-token";
import CodeBlock from "./code-block";

const DeploymentAPIReference = ({
  deployment,
  token,
}: {
  deployment: Deployment;
  token: AccessToken | null;
}) => {
  const DEFAULT_TAB = "nodejs";
  const [activeTabId, setActiveTabId] = useQueryParam("tab", DEFAULT_TAB);
  const inputProperties = getSortedProperties(
    getInputSchema(deployment.current_release.version)
  );

  return (
    <div>
      <TabProvider
        defaultActiveId={DEFAULT_TAB}
        activeId={activeTabId}
        setActiveId={(id) => setActiveTabId(id ?? undefined)}
        selectedId={activeTabId ?? DEFAULT_TAB}
      >
        <div className="mb-lh">
          <div className="flex flex-col md:flex-row gap-4 mb-lh" id="run">
            <h4 className="inline-block no-focus no-underline flex-grow">
              Run the deployment
            </h4>
            <TabList size="sm" variant="pills">
              <Tab id="nodejs">Node.js</Tab>
              <Tab id="python">Python</Tab>
              <Tab id="http">HTTP</Tab>
            </TabList>
          </div>
          <TabPanel tabId="nodejs">
            <RunWithNodeJS
              deployment={deployment}
              token={token}
              inputProperties={inputProperties}
            />
          </TabPanel>
          <TabPanel tabId="python">
            <RunWithPython
              deployment={deployment}
              token={token}
              inputProperties={inputProperties}
            />
          </TabPanel>
          <TabPanel tabId="http">
            <RunWithCurl
              deployment={deployment}
              token={token}
              inputProperties={inputProperties}
            />
          </TabPanel>
        </div>
      </TabProvider>
      <hr className="my-12" />
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex-1 min-w-0">
          <InputDocs version={deployment.current_release.version} />
        </div>
        <div className="flex-1 min-w-0">
          <OutputSchema version={deployment.current_release.version} />
        </div>
      </div>
    </div>
  );
};

const InputDocs = ({
  version,
}: {
  version: Version;
}) => {
  const schema = getInputSchema(version);
  const sortedProperties = getSortedProperties(schema);

  return (
    <>
      <a
        id="inputs"
        href="#inputs"
        className="inline-block no-focus no-underline"
      >
        <h4 className="with-anchor-hash">Inputs</h4>
      </a>

      <ul className="content-container">
        {isEmpty(sortedProperties) ? (
          <li>This version has no properties</li>
        ) : null}
        {sortedProperties.map(([name, property]) => {
          const propertyDefault =
            typeof property.default === "object"
              ? JSON.stringify(property.default)
              : property.default;
          const propertyEnum = "enum" in property ? property.enum : undefined;

          return (
            <li key={name} className="pb-lh border-b border-r8-gray-5">
              <a
                id={`input-${name}`}
                href={`#input-${name}`}
                className="inline-block no-focus no-underline"
              >
                <h5 className="pt-lh pb-05lh with-anchor-hash">
                  <code>{name}</code>
                  {"type" in property && (
                    <>
                      {" "}
                      <em className="text-r8-gray-11">
                        {isFile(property) ? "file" : property.type}
                      </em>
                    </>
                  )}
                </h5>
              </a>

              <div>{property.description}</div>
              {Array.isArray(propertyEnum) && (
                <div className="mt-05lh">
                  Allowed values:
                  {propertyEnum.map((value: string | number, index: number) => (
                    <span key={value}>
                      <code>{value}</code>
                      {index !== (propertyEnum.length || 0) - 1 && ", "}
                    </span>
                  ))}
                </div>
              )}
              {typeof propertyDefault !== "undefined" && (
                <div className="mt-05lh">
                  Default value: <code>{propertyDefault}</code>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

const OutputSchema = ({
  version,
}: {
  version: Version;
}) => (
  <>
    <a
      id="output-schema"
      href="#output-schema"
      className="inline-block no-focus no-underline mb-lh"
    >
      <h4 className="with-anchor-hash">Output schema</h4>
    </a>

    <p className="mb-lh">
      This is the raw JSON schema describing the model's ouput structure.
    </p>

    <CodeBlock
      language="json"
      textContent={JSON.stringify(getOutputSchema(version), null, 2)}
    />
  </>
);

export const RunWithNodeJS = ({
  deployment,
  token,
  inputProperties,
}: {
  deployment: Deployment;
  token: AccessToken | null;
  inputProperties: [string, CogInputPropertySchema][];
}) => {
  if (!inputProperties[0]) {
    return null;
  }

  const apiTokenSettingsUrl = route("account_api_token_settings");

  const jsExampleCode = `import Replicate from "replicate";

const replicate = new Replicate({
  auth: process.env.REPLICATE_API_TOKEN,
});

let prediction = await replicate.deployments.predictions.create(
  "${deployment.owner}",
  "${deployment.name}",
  {
    input: {
      ${inputProperties[0][0]}: "${inputProperties[0][1].default || "..."}"
    }
  }
);
prediction = await replicate.wait(prediction);
console.log(prediction.output);`;

  return (
    <div className="space-y-lh">
      <p>
        Install{" "}
        <a href="https://github.com/replicate/replicate-javascript">
          the Node.js client
        </a>
        :
      </p>

      <CodeBlock textContent="npm install replicate" language="shell" />

      <p>
        Next, <a href={apiTokenSettingsUrl}>copy your API token</a> and
        authenticate by setting it as an environment variable:
      </p>

      <AuthToken token={token} type="shell" />

      <p>Then, run the model:</p>

      <CodeBlock textContent={jsExampleCode} language="javascript" />

      <p>
        To learn more, take a look at{" "}
        <a href="https://github.com/replicate/replicate-javascript">
          the Node.js library documentation
        </a>
        .
      </p>
    </div>
  );
};

export const RunWithPython = ({
  deployment,
  token,
  inputProperties,
}: {
  deployment: Deployment;
  token: AccessToken | null;
  inputProperties: [string, CogInputPropertySchema][];
}) => {
  if (!inputProperties[0]) {
    return null;
  }

  const apiTokenSettingsUrl = route("account_api_token_settings");

  const pythonExampleCode = `import replicate

deployment = replicate.deployments.get("${deployment.owner}/${deployment.name}")
prediction = deployment.predictions.create(
  input={"${inputProperties[0][0]}": "${inputProperties[0][1].default || "..."}"}
)
prediction.wait()
print(prediction.output)
`;

  return (
    <div className="space-y-lh">
      <p>
        Install{" "}
        <a href="https://github.com/replicate/replicate-python">
          the Python client
        </a>
        :
      </p>

      <CodeBlock textContent="pip install replicate" language="shell" />

      <p>
        Next, <a href={apiTokenSettingsUrl}>copy your API token</a> and
        authenticate by setting it as an environment variable:
      </p>

      <AuthToken token={token} type="shell" />

      <p>Then, run the model:</p>

      <CodeBlock textContent={pythonExampleCode} language="python" />

      <p>
        To learn more, take a look at{" "}
        <a href="https://github.com/replicate/replicate-python">
          the Python library documentation
        </a>
        .
      </p>
    </div>
  );
};

export const RunWithCurl = ({
  deployment,
  token,
  inputProperties,
}: {
  deployment: Deployment;
  token: AccessToken | null;
  inputProperties: [string, CogInputPropertySchema][];
}) => {
  if (!inputProperties[0]) {
    return null;
  }

  const apiTokenSettingsUrl = route("account_api_token_settings");

  const curlCode = `curl -s -X POST \\
  -d '{"input": {"${inputProperties[0][0]}": "${
    inputProperties[0][1].default || "..."
  }"}}' \\
  -H "Authorization: Bearer $REPLICATE_API_TOKEN" \\
  "https://api.replicate.com/v1/deployments/${deployment.owner}/${
    deployment.name
  }/predictions"`;

  const jsonResponseCode = `{
  "completed_at": null,
  "created_at": "2023-03-08T17:54:26.385912Z",
  "error": null,
  "id": "j6t4en2gxjbnvnmxim7ylcyihu",
  "input": {"${inputProperties[0][0]}": "${
    inputProperties[0][1].default || "..."
  }"},
  "logs": null,
  "metrics": {},
  "output": null,
  "started_at": null,
  "status": "starting",
}`;

  const curlStatusCode = `curl -s -H "Authorization: Bearer $REPLICATE_API_TOKEN" \\
  "https://api.replicate.com/v1/predictions/j6t4en2gxjbnvnmxim7ylcyihu"
`;

  const completedJson = `{
  "id": "j6t4en2gxjbnvnmxim7ylcyihu",
  "input": {"${inputProperties[0][0]}": "${
    inputProperties[0][1].default || "..."
  }"},
  "output": "...",
  "status": "succeeded"
}`;

  return (
    <div className="space-y-lh">
      <p>
        First, <a href={apiTokenSettingsUrl}>copy your API token</a> and
        authenticate by setting it as an environment variable:
      </p>

      <AuthToken token={token} type="shell" />

      <p>Then, call the HTTP API directly with cURL:</p>

      <CodeBlock textContent={curlCode} language="shell" />

      <p>The API response is your new prediction as a JSON object:</p>

      <CodeBlock textContent={jsonResponseCode} language="json" />

      <p>
        Note that <code>status</code> is "starting" but there's no{" "}
        <code>output</code> yet. Refetch the prediction from the API using the
        prediction <code>id</code> from the previous response:
      </p>

      <CodeBlock textContent={curlStatusCode} language="shell" />

      <p>If the prediction has completed, you'll see a response like this:</p>

      <CodeBlock textContent={completedJson} language="json" />

      <p>
        For models that take longer to return a response, you'll need to poll
        the API periodically for an update. Alternatively, you can specify a{" "}
        <a href="/docs/reference/http#create-prediction--webhook">
          webhook URL
        </a>{" "}
        to be called when the prediction is complete. Take a look at the{" "}
        <a href="/docs/reference/http#create-prediction--webhook">
          webhook docs
        </a>{" "}
        for details on setting that up.
      </p>

      <p className="mb-lh">
        To learn more about Replicate's HTTP API, check out the{" "}
        <a href="/docs/reference/http">reference docs</a>.
      </p>
    </div>
  );
};

export default DeploymentAPIReference;
