import { useController } from "react-hook-form";
import { Label } from "./label";

export function BooleanInput({
  disabled,
  name,
  required,
  type,
}: {
  disabled: boolean;
  name: string;
  required: boolean;
  type: "boolean";
}) {
  const { field, formState } = useController({ name });

  return (
    <div className="gap-2 flex items-center">
      <input
        id={name}
        type="checkbox"
        disabled={formState.isSubmitting || disabled}
        checked={field.value}
        className="disabled:cursor-not-allowed disabled:opacity-50"
        {...field}
      />
      <Label name={name} required={required} type={type} />
    </div>
  );
}
