import Cookies from "js-cookie";
import type {
  APIPrediction,
  CogInputSchema,
  CogTrainingInputSchema,
  Deployment,
  ErrorWithJSON,
  Model,
  Prediction,
  Version,
} from "../../types";
import { route } from "../../urls";
import { getMetaTagContent } from "../../util";
import { uploadFileInputs, uploadMultipleFileInputs } from "./upload";

export async function getPrediction({
  uuid,
  signal,
}: {
  uuid: string | null;
  signal: AbortSignal | undefined;
}): Promise<Prediction> {
  if (!uuid) {
    throw new Error("No prediction uuid provided");
  }

  const res = await fetch(
    route("api_prediction_detail", { prediction_uuid: uuid }),
    { signal }
  );

  if (res.ok) {
    return res.json<Prediction>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to fetch prediction",
      status: res.status,
    });
  }
}

export type PredictionInput = Record<
  string,
  string | string[] | File | File[] | boolean | number
>;

export async function createDeploymentPrediction({
  deployment,
  input,
  inputSchema,
}: {
  deployment: Deployment;
  input: PredictionInput;
  inputSchema: CogInputSchema | null;
}): Promise<APIPrediction> {
  const inputWithUploadedFiles = inputSchema
    ? await uploadMultipleFileInputs({ input, schema: inputSchema })
    : await uploadFileInputs({ input });

  const res = await fetch(
    route("api_deployment_prediction_create", {
      username: deployment.owner,
      name: deployment.name,
    }),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: JSON.stringify({
        input: inputWithUploadedFiles,
      }),
    }
  );

  if (res.ok) {
    return res.json<APIPrediction>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to create prediction.",
      status: res.status,
    });
  }
}

export async function createOfficialModelPrediction({
  model,
  input,
  inputSchema,
}: {
  model: Model;
  input: PredictionInput;
  inputSchema: CogInputSchema | null;
}): Promise<APIPrediction> {
  const inputWithUploadedFiles = inputSchema
    ? await uploadMultipleFileInputs({ input, schema: inputSchema })
    : await uploadFileInputs({ input });

  const maybeAnalyticsId = getMetaTagContent("blog-analytics-id");

  const res = await fetch(
    route("api_official_model_prediction_create", {
      username: model.owner,
      name: model.name,
    }),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        ...(maybeAnalyticsId
          ? { "X-REPLICATE-BLOG-ANALYTICS-ID": maybeAnalyticsId }
          : {}),
      },
      body: JSON.stringify({
        input: inputWithUploadedFiles,
      }),
    }
  );

  if (res.ok) {
    return res.json<APIPrediction>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to create prediction.",
      status: res.status,
    });
  }
}

export async function createVersionPrediction({
  input,
  inputSchema,
  version,
}: {
  input: PredictionInput;
  inputSchema: CogInputSchema | null;
  version: Version;
}): Promise<APIPrediction> {
  const inputWithUploadedFiles = inputSchema
    ? await uploadMultipleFileInputs({ input, schema: inputSchema })
    : await uploadFileInputs({ input });

  const maybeAnalyticsId = getMetaTagContent("blog-analytics-id");

  const res = await fetch(
    route("api_version_prediction_create", {
      username: version._extras.model.owner,
      name: version._extras.model.name,
      docker_image_id: version.id,
    }),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        ...(maybeAnalyticsId
          ? { "X-REPLICATE-BLOG-ANALYTICS-ID": maybeAnalyticsId }
          : {}),
      },
      body: JSON.stringify({
        input: inputWithUploadedFiles,
      }),
    }
  );

  if (res.ok) {
    return res.json<APIPrediction>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to create prediction.",
      status: res.status,
    });
  }
}

export async function createVersionTraining({
  destination,
  visibility = "private",
  input,
  inputSchema,
  version,
}: {
  destination: string;
  visibility?: "public" | "private";
  input: PredictionInput;
  inputSchema: CogTrainingInputSchema | null;
  version: Pick<Version, "id"> & {
    _extras: {
      model: Pick<Version["_extras"]["model"], "name" | "owner">;
    };
  };
}): Promise<APIPrediction> {
  const inputWithUploadedFiles = inputSchema
    ? await uploadMultipleFileInputs({ input, schema: inputSchema })
    : await uploadFileInputs({ input });

  const res = await fetch(
    route("api_version_training_create", {
      username: version._extras.model.owner,
      name: version._extras.model.name,
      docker_image_id: version.id,
    }),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: JSON.stringify({
        input: inputWithUploadedFiles,
        destination,
        visibility,
      }),
    }
  );

  if (res.ok) {
    return res.json<APIPrediction>();
  }

  try {
    const errorJson = await res.json<ErrorWithJSON>();
    return Promise.reject({
      ...errorJson,
      status: res.status,
    });
  } catch (e) {
    return Promise.reject({
      detail: "Failed to create training.",
      status: res.status,
    });
  }
}

export async function sharePrediction({ id }: { id: string }) {
  const res = await fetch(`/api/predictions/${id}/share`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (!res.ok) {
    throw new Error("Failed to share prediction");
  }
}

export async function cancelPrediction({ id }: { id: string }) {
  const res = await fetch(`/api/predictions/${id}/stop`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (!res.ok) {
    throw new Error("Failed to cancel prediction");
  }
}

export async function deletePrediction({ id }: { id: string }) {
  const res = await fetch(`/api/predictions/${id}/delete`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (!res.ok) {
    throw new Error("Failed to delete prediction");
  }
}

export async function addExample({ id, url }: { id: string; url: string }) {
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ prediction_uuid: id }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (!res.ok) {
    throw new Error("Failed to add example");
  }
}

export async function fetchAndRenderMarkdown({ url }) {
  const remoteSourceRes = await fetch(url);
  if (!remoteSourceRes.ok) {
    throw new Error(`Failed to fetch markdown from remote source: ${url}`);
  }

  const text = await remoteSourceRes.text();

  const markdownRes = await fetch("/api/render-markdown", {
    method: "POST",
    body: new URLSearchParams({
      content: text,
    }),
  });

  if (!markdownRes.ok) {
    throw new Error("Failed to render markdown");
  }

  return markdownRes.text();
}
