import { Check, Star, X } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import { match } from "ts-pattern";
import type { Prediction } from "../../types";
import { route } from "../../urls";
import { addExample } from "./api";
import { usePlaygroundContext } from "./context";

export function AddExamplePrediction({
  prediction,
}: {
  prediction: Prediction;
}) {
  const { version } = usePlaygroundContext();
  const { mutate, isPending, status } = useMutation({
    mutationFn: addExample,
  });

  const handleAdd = () => {
    const createExampleUrl = route("api_example_create", {
      username: version._extras.model.owner,
      name: version._extras.model.name,
    });
    mutate({
      id: prediction.id,
      url: createExampleUrl,
    });
  };

  const buttonIcon = match(status)
    .with("idle", () => (
      <span>
        <Star size={16} />
      </span>
    ))
    .with("pending", () => null)
    .with("success", () => (
      <span>
        <Check size={16} />
      </span>
    ))
    .with("error", () => (
      <span>
        <X size={16} />
      </span>
    ))
    .exhaustive();

  const buttonText = match(status)
    .with("idle", () => "Add to examples")
    .with("pending", () => "Adding...")
    .with("success", () => "Added")
    .with("error", () => "Adding failed")
    .exhaustive();

  return (
    <Button
      loading={status === "pending"}
      disabled={isPending}
      variant="outlined"
      onClick={handleAdd}
      startIcon={buttonIcon}
    >
      <span>{buttonText}</span>
    </Button>
  );
}
