import * as Ariakit from "@ariakit/react";
import {
  Cube,
  CurrencyDollar,
  GraduationCap,
  Lifebuoy,
  Password,
  Plus,
  RocketLaunch,
  Sparkle,
  Star,
  User,
} from "@phosphor-icons/react";
import { ComboboxGroup, ComboboxGroupLabel, Spinner } from "@replicate/ui";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  CollectionSearchResultItem,
  DeploymentSearchResultItem,
  ModelSearchResultItem,
  SearchCombobox,
  SearchPageResultItem,
} from ".";
import { useMediaMatch } from "../../hooks";
import { route } from "../../urls";
import { useSearch } from "./hooks";

export default function QuickSearchMenu({
  username,
}: {
  username: string;
}) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const comboboxStore = Ariakit.useComboboxStore({
    setValue: setSearchTerm,
    orientation: "vertical",
  });

  const isOpen = comboboxStore.useState((state) => state.open);

  useHotkeys(
    "mod+k",
    (e) => {
      e.preventDefault();
      comboboxStore.toggle();
      comboboxStore.move(null);
    },
    [comboboxStore]
  );

  const defaultPages = useMemo(() => {
    return [
      {
        name: "Models",
        href: route("model_list"),
        icon: Cube,
      },
      {
        name: "Predictions",
        href: route("prediction_list"),
        icon: Sparkle,
      },
      {
        name: "Trainings",
        href: route("training_list"),
        icon: GraduationCap,
      },
      {
        name: "Deployments",
        href: route("deployment_list"),
        icon: RocketLaunch,
      },
      {
        name: "Billing",
        href: route("account_billing_settings"),
        icon: CurrencyDollar,
      },
      {
        name: "Stars",
        href: route("star_list", {
          username,
        }),
        icon: Star,
      },
      {
        name: "Support",
        href: route("support"),
        icon: Lifebuoy,
      },
      {
        name: "API Tokens",
        href: route("account_api_token_settings"),
        icon: Password,
      },
      {
        name: "Profile",
        href: route("account_detail", { username }),
        icon: User,
      },
      {
        name: "Create model",
        href: route("model_create"),
        icon: Plus,
      },
    ];
  }, [username]);

  const searchResults = useSearch({
    query: searchTerm,
    entities: ["models", "collections", "deployments"],
  });
  const isLoading = searchResults.isFetching;

  const modelResults = useMemo(() => {
    if (!searchResults.isSuccess) {
      return [];
    }

    return searchResults.data.models.map((result) => {
      return {
        ...result,
        nwo: `${result.username}/${result.name}`,
      };
    });
  }, [searchResults]);

  const collectionResults = useMemo(() => {
    if (!searchResults.isSuccess) {
      return [];
    }

    return searchResults.data.collections;
  }, [searchResults]);

  const deploymentsResults = useMemo(() => {
    if (!searchResults.isSuccess) {
      return [];
    }

    return searchResults.data.deployments;
  }, [searchResults]);

  const sortedPages = matchSorter(defaultPages, searchTerm ?? "", {
    keys: ["name"],
  });

  const showLinkToSearchForm = Boolean(searchTerm);

  const isDesktop = useMediaMatch("(min-width: 1024px)");

  const showPagesGroup = sortedPages.length > 0;
  const showCollectionsGroup = collectionResults.length > 0;
  const showDeploymentsGroup = deploymentsResults.length > 0;
  const showModelsGroup = modelResults.length > 0;

  const showModelEmptyMessage =
    Boolean(searchTerm) && searchResults.isSuccess && modelResults.length === 0;

  // What the heck is going here, you might be asking?
  // The only time we want to show a loading state in the dropdown itself, is when:
  // - We're mid search, and awaiting a response
  // - We don't have any hardcoded pages to show, nor any search results to show.
  const showGenericLoadingState =
    !showPagesGroup && searchResults.isPending && Boolean(searchTerm);

  return (
    <Ariakit.ComboboxProvider store={comboboxStore} placement="bottom">
      <form method="GET" action={route("search")}>
        <div className="relative">
          <SearchCombobox
            searchTerm={searchTerm}
            isLoading={isLoading}
            id="search-combobox"
            linkToSearchForm={showLinkToSearchForm}
            placeholder="Search..."
            className="w-full bg-r8-gray-2 px-2 py-1.5 text-r8-gray-12 text-r8-sm border border-transparent focus:ring-black/20 focus:outline-none focus:ring-2 focus:border-black"
          />
          <div
            data-open={isOpen}
            className="hidden md:flex absolute right-2 top-0 bottom-0 items-center font-mono pointer-events-none opacity-100 data-[open=true]:opacity-0 transition-opacity"
          >
            <span className="text-r8-xs bg-r8-gray-6 px-2 py-0.5 text-r8-gray-11">
              <kbd>⌘</kbd>+<kbd>K</kbd>
            </span>
          </div>
        </div>
      </form>

      <Ariakit.ComboboxPopover
        gutter={8}
        portal
        className="r8-popover bg-white dark:bg-r8-gray-1 max-h-72 overflow-auto h-full z-10"
        sameWidth={isDesktop}
        fitViewport
        wrapperProps={{
          className: "md:min-w-96",
          style: {
            width: "100%",
          },
        }}
      >
        {showGenericLoadingState ? (
          <div className="p-3 flex items-center gap-2">
            <p className="text-r8-sm text-r8-gray-11">Searching...</p>
            <div className="w-3 h-3">
              <Spinner />
            </div>
          </div>
        ) : null}

        {showPagesGroup ? (
          <ComboboxGroup>
            <ComboboxGroupLabel>Pages</ComboboxGroupLabel>
            {sortedPages.map((result) => (
              <Ariakit.ComboboxItem
                key={result.href}
                render={
                  <SearchPageResultItem icon={result.icon} href={result.href}>
                    {result.name}
                  </SearchPageResultItem>
                }
              />
            ))}
          </ComboboxGroup>
        ) : null}

        {showDeploymentsGroup ? (
          <ComboboxGroup>
            <ComboboxGroupLabel>Deployments</ComboboxGroupLabel>
            {deploymentsResults.map((result) => (
              <Ariakit.ComboboxItem
                key={result.full_name}
                render={<DeploymentSearchResultItem deployment={result} />}
              />
            ))}
          </ComboboxGroup>
        ) : null}

        {showCollectionsGroup ? (
          <ComboboxGroup>
            <ComboboxGroupLabel>Collections</ComboboxGroupLabel>
            {collectionResults.map((result) => (
              <Ariakit.ComboboxItem
                key={result.slug}
                render={<CollectionSearchResultItem collection={result} />}
              />
            ))}
          </ComboboxGroup>
        ) : null}

        {showModelsGroup ? (
          <ComboboxGroup>
            <ComboboxGroupLabel>Models</ComboboxGroupLabel>
            {modelResults.length > 0 ? (
              modelResults.map((result) => (
                <Ariakit.ComboboxItem
                  key={`${result.username}-${result.name}`}
                  render={<ModelSearchResultItem condensed model={result} />}
                />
              ))
            ) : (
              <>
                {showModelEmptyMessage && (
                  <p className="text-r8-sm text-r8-gray-11 px-3 pb-2">
                    No models match your query
                  </p>
                )}
              </>
            )}
          </ComboboxGroup>
        ) : null}
      </Ariakit.ComboboxPopover>
    </Ariakit.ComboboxProvider>
  );
}
