import { ResizeObserver } from "@juggle/resize-observer";
import { Check as CheckIcon, Copy as CopyIcon } from "@phosphor-icons/react";
import {
  IconButton,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import hljs from "highlight.js";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useDimensions from "react-cool-dimensions";
import type { SupportedLanguage } from "../highlight";

type CodeBlockProps = {
  className?: string;
  textContent: string | string[];
  copyContent?: string;
  copyable?: boolean;
  onCopy?: () => void;
  extraActions?: CodeBlockAction[];
  language?: SupportedLanguage;
};

const CodeBlock = ({
  textContent,
  copyContent,
  copyable = true,
  onCopy = () => {},
  extraActions = [],
  language,
}: CodeBlockProps) => {
  const codeBlockRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // We only want to try to highlight if a language is passed.
    if (!language) return;
    // Abort if the ref isn't set...
    if (!codeBlockRef.current) return;
    // ...or if there's no text content.
    if (!textContent) return;

    // We might have multiple <code> elements, so we need to highlight them all.
    const innerCodeElements = codeBlockRef.current.querySelectorAll("code");
    for (const el of innerCodeElements) {
      hljs.highlightElement(el);
    }
  }, [language, textContent]);

  const { observe: observeActions, width: actionsWidth } = useDimensions({
    polyfill: ResizeObserver,
  });

  const trimmedTextContent = useMemo(
    () =>
      (Array.isArray(textContent) ? textContent : [textContent]).map(
        (content) => content.replace(/(^\n+)|(\n+$)/g, "")
      ),
    [textContent]
  );
  const trimmedCopyContent = copyContent?.trim();

  const handleCopy = useCallback(() => {
    const content = trimmedCopyContent || trimmedTextContent.join("\n");
    copy(content);
    setCopied(true);
    onCopy();
  }, [onCopy, trimmedCopyContent, trimmedTextContent]);

  const handleMouseLeave = () => setCopied(false);

  const numLines = trimmedTextContent.reduce(
    (total, content) => total + content.split("\n").length,
    0
  );

  let copyAction: CodeBlockAction | undefined;
  if (copyable) {
    copyAction = copied
      ? {
          label: "Copy",
          onClick: handleCopy,
          icon: <CheckIcon />,
          tooltip: "Copied!",
        }
      : {
          label: "Copy",
          onClick: handleCopy,
          icon: <CopyIcon />,
          tooltip: "Copy",
        };
  }

  const actions: CodeBlockAction[] = copyAction
    ? [...extraActions, copyAction]
    : extraActions;

  const languageClass = language ? `language-${language}` : "";

  return (
    <div
      ref={codeBlockRef}
      className={"group relative"}
      onMouseLeave={handleMouseLeave}
    >
      <pre
        className="code"
        style={{
          paddingRight: `calc(${actionsWidth}px + 2rem)`,
        }}
      >
        {trimmedTextContent
          .map((content, i) => (
            <code key={i} className={languageClass}>
              {content}
            </code>
          ))
          .reduce((acc, el, i) => (
            <Fragment key={i}>
              {acc}
              {"\n"}
              {el}
            </Fragment>
          ))}
      </pre>
      {actions.length && (
        <div
          ref={observeActions}
          className={classNames(
            "absolute space-x-2 right-2 h-full opacity-0 pointer-events-none group-hover:opacity-100 flex",
            numLines > 1 ? "top-2 items-start" : "top-0 items-center"
          )}
        >
          {actions.map((props, idx) => (
            <CodeBlockButton key={idx} {...props} />
          ))}
        </div>
      )}
    </div>
  );
};

export type CodeBlockAction = {
  onClick: () => void;
  tooltip?: string;
  icon: JSX.Element;
  // For a11y purposes
  label: string;
};

const CodeBlockButton = ({
  onClick,
  tooltip,
  icon: Icon,
  label,
}: CodeBlockAction) => {
  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor
        render={
          <IconButton
            className="pointer-events-auto relative"
            onClick={onClick}
            variant="filled"
            intent="primary"
          />
        }
      >
        {Icon}
        <span className="sr-only">{label}</span>
      </TooltipAnchor>
      <Tooltip>
        <TooltipArrow />
        {tooltip}
      </Tooltip>
    </TooltipProvider>
  );
};

export default CodeBlock;
