import { Hash } from "@phosphor-icons/react";
import classNames from "classnames";
import type React from "react";
import { useController } from "react-hook-form";
import { Label } from "./label";
import { MinMaxMessage } from "./min-max-message";
import { isNumber } from "./util";

export function NumericInput({
  disabled,
  maximum,
  minimum,
  name,
  required,
  type,
}: {
  disabled: boolean;
  maximum: number | undefined;
  minimum: number | undefined;
  name: string;
  required: boolean;
  type: "number" | "integer";
}) {
  const showSlider =
    maximum != null &&
    isNumber(maximum) &&
    minimum != null &&
    isNumber(minimum);
  const step = type === "integer" ? 1 : 0.01;

  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
      ...(maximum != null && isNumber(maximum)
        ? {
            max: {
              value: maximum,
              message: `Maximum value is ${maximum}`,
            },
          }
        : {}),
      ...(minimum != null && isNumber(minimum)
        ? {
            min: {
              value: minimum,
              message: `Minimum value is ${minimum}`,
            },
          }
        : {}),
    },
  });

  const handleNumberInputChangeWithConversion = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    // If the value is an empty string, it's safe to assume
    // the user cleared the contents of the input field,
    // so we should set the value to null.
    if (value === "") {
      field.onChange(null);
      return;
    }

    if (type === "integer") {
      field.onChange(Number.parseInt(value, 10));
    } else {
      field.onChange(Number.parseFloat(value));
    }
  };

  // Slider values can never be null, so we need to convert them to numbers.
  const handleSliderChangeWithConversion = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (type === "integer") {
      field.onChange(Number.parseInt(value, 10));
    } else {
      field.onChange(Number.parseFloat(value));
    }
  };

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between md:flex-wrap gap-2">
        <Label Icon={Hash} name={name} required={required} type={type} />
        <MinMaxMessage maximum={maximum} minimum={minimum} />
      </div>
      <div>
        <div className="flex gap-4">
          <input
            id={name}
            className={classNames(
              "border p-2 border-r8-gray-12 bg-white dark:bg-r8-gray-1 w-full flex-shrink-0 disabled:cursor-not-allowed disabled:opacity-50",
              showSlider ? "max-w-[5.5rem]" : "max-w-full min-w-[5rem]"
            )}
            dir="auto"
            disabled={formState.isSubmitting || disabled}
            max={maximum}
            min={minimum}
            step={step}
            type="number"
            {...field}
            onChange={handleNumberInputChangeWithConversion}
            onWheel={(e) => {
              if (e.currentTarget) {
                e.currentTarget.blur();
              }
            }}
            // Casting the value to a string is necessary to avoid this runtime error:
            // - Warning: `value` prop on `input` should not be null.
            // - Consider using an empty string to clear the component or `undefined` for uncontrolled components.
            value={field.value ?? ""}
          />
          {showSlider && (
            <div className="w-full flex items-center min-w-0">
              <label
                className="sr-only"
                htmlFor={`${name}-range`}
                translate="no"
              >
                {name}
              </label>
              <input
                id={`${name}-range`}
                className="w-full disabled:cursor-not-allowed disabled:opacity-50 accent-r8-gray-12"
                disabled={formState.isSubmitting || disabled}
                max={maximum}
                min={minimum}
                step={step}
                type="range"
                {...field}
                value={field.value ?? ""}
                onChange={handleSliderChangeWithConversion}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
