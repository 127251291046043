import type { Icon } from "@phosphor-icons/react";

export function Label({
  Icon,
  name,
  required,
  type,
}: {
  Icon?: Icon;
  name: string;
  required: boolean;
  type: string | null;
}) {
  return (
    <div className="flex items-center">
      <label className="text-r8-base font-mono" htmlFor={name}>
        <div className="flex items-center gap-1.5" translate="no">
          {Icon ? <Icon aria-hidden size={14} /> : null}
          {name}
        </div>
      </label>
      {required && <span className="text-r8-red-10">*</span>}
      {type && (
        <span className="ml-1.5 font-sans text-r8-sm text-r8-gray-11">
          {type}
        </span>
      )}
    </div>
  );
}
