import { Banner, Button } from "@replicate/ui";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { throwForStatus } from "../fetch_helpers";
import { route } from "../urls";

export default function EndAllSubscriptionsNotice({
  firstName,
  username,
}: {
  firstName: string | null;
  username: string;
}) {
  return (
    <Banner
      severity="info"
      icon="💛"
      heading="We’ve changed how you get billed on Replicate"
      description={
        <div className="space-y-05lh">
          <p>Hello {firstName ? firstName : "there"},</p>
          <p>
            You were an early subscriber to Replicate. Thanks for believing in
            us early on – we wouldn’t be here without you. :)
          </p>
          <p>
            When you joined, we charged people $10/month to use Replicate, but
            we now charge people for what they use.
          </p>
          <p>
            As of December 2023, we’ve started charging you for the amount of
            compute time you use.{" "}
            <a href="https://replicate.com/docs/billing">
              You can learn more about how billing works in the docs.
            </a>
          </p>
          <p>
            You won’t get charged if you don’t use Replicate and we’ve given you
            $500 credit as a thank you for being with us from the start, so you
            shouldn’t get a surprise bill.
          </p>
          <p>
            Thanks again for being an early user. Hope you enjoy Replicate for
            many more years to come.
          </p>
          <p>Ben</p>
          <div className="space-x-lh">
            <Button
              onClick={async () => {
                const response = await fetch(
                  route(
                    "api_clear_needs_informing_about_end_of_subscriptions",
                    {
                      username,
                    }
                  ),
                  {
                    method: "POST",
                    headers: {
                      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
                    },
                  }
                );

                try {
                  await throwForStatus(response);
                  location.reload();
                } catch (err) {
                  Sentry.captureException(err);
                }
              }}
            >
              I understand
            </Button>
            <a href={route("support")}>Contact us</a>
          </div>
        </div>
      }
    />
  );
}
