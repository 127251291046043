import { SlidersHorizontal } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { trackEvent } from "../../analytics";
import type { Prediction } from "../../types";
import { route } from "../../urls";
import { usePlaygroundContext } from "./context";

export function TweakPrediction({ prediction }: { prediction: Prediction }) {
  const { version } = usePlaygroundContext();

  const trackClickHandler = () => {
    const eventPayload = {
      model: `${version._extras.model.owner}/${version._extras.model.name}`,
      prediction: prediction.id,
    };
    if (prediction._extras.official_model) {
      eventPayload.model = prediction._extras.official_model.full_name;
    }
    trackEvent("btn_tweak_prediction", eventPayload);
  };

  const versionUrl = route("version_detail", {
    username: version._extras.model.owner,
    name: version._extras.model.name,
    docker_image_id: version.id,
  });
  let tweakUrl = `${versionUrl}?prediction=${prediction.id}`;

  // If the prediction used an official model, rewrite the tweak url to not
  // point at a specific model version.
  if (prediction._extras.official_model) {
    tweakUrl = `${prediction._extras.official_model.url}?prediction=${prediction.id}`;
  }

  if (prediction._extras.deployment) {
    tweakUrl = `${prediction._extras.deployment._extras.url}/playground?prediction=${prediction.id}`;
  }

  return (
    <Button
      startIcon={<SlidersHorizontal />}
      variant="outlined"
      render={
        // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
        <a href={tweakUrl} />
      }
      onClick={trackClickHandler}
    >
      Tweak it
    </Button>
  );
}
