import * as Sentry from "@sentry/react";
import copy from "copy-to-clipboard";
import { Copy as CopyIcon } from "lucide-react";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

export default function CopyToClipboard({ content }) {
  const [didCopy, setDidCopy] = useState(false);

  const copyContent = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (e) {
      Sentry.captureException(e);
      copy(content);
    }
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 5000);
  }, [content]);

  return (
    <div className="flex font-mono border border-r8-gray-9 bg-r8-gray-1 rounded">
      <input
        readOnly
        value={content}
        className="py-2 px-2 flex-grow bg-transparent"
      />
      <button
        className="flex-none relative border-l p-2 bg-r8-gray-3 hover:bg-r8-gray-9 hover:text-r8-gray-1"
        type="button"
        onClick={() => {
          copyContent();
        }}
      >
        <CopyIcon />
        {didCopy && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-1 bg-r8-gray-12 text-r8-gray-1 p-2 rounded text-sm mb-2">
            Copied!
            <div className="absolute h-3 w-3 bg-r8-gray-12 -rotate-45 transform -translate-x-1/2 left-1/2 -bottom-1" />
          </div>
        )}
      </button>
    </div>
  );
}

CopyToClipboard.propTypes = {
  content: PropTypes.string.isRequired,
};
