import { Circle } from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { match } from "ts-pattern";
import type { Model } from "../types";
import { route } from "../urls";
import BgGoo from "./api-playground/bg-goo";

export default function ModelStatusIndicator({
  initialStatus,
  model,
  isOfficialModel,
}: {
  initialStatus: "offline" | "online";
  model: Model;
  isOfficialModel: boolean;
}) {
  const query = useQuery({
    initialData: {
      status: initialStatus,
    },
    retry: false,
    queryKey: ["model-status", model.owner, model.name],
    refetchOnWindowFocus: true,
    refetchInterval: 15 * 1000,
    queryFn: async () => {
      const res = await fetch(
        route("model_status", {
          username: model.owner,
          name: model.name,
        }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );

      if (!res.ok) {
        throw new Error("Failed to fetch model status");
      }

      return res.json<{ status: "online" | "offline" | null }>();
    },
  });

  if (query.isError) return null;
  if (query.isSuccess && query.data.status === null) return null;

  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor
        render={
          // biome-ignore lint/a11y/useAnchorContent: Content injected by TooltipAnchor
          <a
            className={`relative overflow-hidden flex font-semibold no-default no-focus rounded-full px-3 py-1 items-center gap-2 text-r8-sm hover:text-r8-gray-1 focus:text-r8-gray-1 ${match(
              { status: query.data.status, official: isOfficialModel }
            )
              .with(
                { status: "offline" },
                () =>
                  "bg-r8-blue-3 focus:bg-r8-blue-9 hover:bg-r8-blue-9 text-r8-blue-10"
              )
              .with(
                { status: "online" },
                () =>
                  "bg-r8-green-3 focus:bg-r8-green-9 hover:bg-r8-green-9 text-r8-green-10"
              )
              .with(
                { status: "online", official: true },
                () =>
                  "bg-r8-red-10 focus:bg-r8-red-12 hover:bg-r8-red-9 text-r8-gray-1 [text-shadow:0_1px_0_rgba(0,0,0,0.25)]"
              )
              .otherwise(
                () =>
                  "bg-r8-gray-3 focus:bg-r8-gray-9 hover:bg-r8-gray-9 text-r8-gray-10"
              )}`}
            href="https://replicate.com/docs/reference/how-does-replicate-work#cold-boots"
          />
        }
      >
        {query.data.status === "online" && isOfficialModel ? (
          <div className="absolute inset-0 w-full h-full opacity-30">
            <BgGoo speed={0.2} resolution={2.0} depth={4} />
          </div>
        ) : null}
        <Circle
          weight="fill"
          className="inline-block fill-current w-2 h-2 relative z-1"
        />
        <span className="relative z-1">
          {query.data.status === "offline" ? "Cold" : "Warm"}
        </span>
      </TooltipAnchor>
      <Tooltip className="max-w-64 text-r8-xs z-50">
        <TooltipArrow />
        <p className="text-r8-xs">
          <strong className="font-semibold">
            This {isOfficialModel ? "official model" : "model"} is{" "}
            {query.data.status === "offline" ? "cold" : "warm"}.
          </strong>{" "}
          You'll get a fast response if the model is warm and already running,
          and a slower response if the model is cold and starting up.
        </p>
      </Tooltip>
    </TooltipProvider>
  );
}
