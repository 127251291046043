import { Info } from "@phosphor-icons/react";
import type { Prediction, Version } from "../../types";
import { route } from "../../urls";
import { usePrediction } from "./hooks";
import { PredictionDetailOutput } from "./prediction-detail-output";
import { PredictionErrorState } from "./prediction-error-state";
import { PredictionLoadingState } from "./prediction-loading-state";

export function InitialOutput({
  prediction,
  versionMismatch,
  outputVersion,
  hideVersionMismatchWarning,
}: {
  prediction: Prediction | null;
  versionMismatch: boolean;
  outputVersion: Version;
  hideVersionMismatchWarning: boolean;
}) {
  const predictionQuery = usePrediction({
    uuid: prediction?.id ?? null,
  });

  if (!prediction) {
    return (
      <p className="text-r8-gray-11 text-r8-sm">
        No output yet! Press "Submit" to start a prediction.
      </p>
    );
  }

  return (
    <div>
      <div data-testid="default-example-output">
        {predictionQuery.status === "pending" && (
          <PredictionLoadingState>Starting…</PredictionLoadingState>
        )}
        {predictionQuery.status === "error" && (
          <PredictionErrorState
            error={predictionQuery.error}
            fallback="Failed to fetch prediction."
          />
        )}
        {predictionQuery.status === "success" && (
          <PredictionDetailOutput
            isNewPrediction={false}
            prediction={predictionQuery.data}
            queryStartedAt={predictionQuery.dataUpdatedAt}
          />
        )}
        {versionMismatch && !hideVersionMismatchWarning && (
          <div className="bg-r8-blue-2 text-r8-blue-11 p-2.5 mb-4 flex items-center gap-2 mt-4">
            <div className="flex-shrink-0">
              <Info size={16} />
            </div>
            <p className="text-r8-sm">
              This example was created by a different version,{" "}
              <a
                href={route("version_detail", {
                  username: outputVersion._extras.model.owner,
                  name: outputVersion._extras.model.name,
                  docker_image_id: outputVersion.id,
                })}
                className="underline no-focus no-default hover:decoration-r8-blue-10"
              >
                {outputVersion._extras.name}
              </a>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
