import { Check, Trash, X } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { match } from "ts-pattern";
import type { Prediction } from "../../types";
import { deletePrediction } from "./api";
import { CircularSpinner } from "./circular-spinner";

export function DeletePrediction({
  prediction,
}: {
  prediction: Prediction;
}) {
  const { mutate, isPending, status } = useMutation({
    mutationFn: deletePrediction,
    onSuccess: () => {
      window.location.href = "/";
    },
    onError: (e) => {
      let errorMessage = "Deleting prediction failed";
      if (e instanceof Error) {
        errorMessage += `: ${e.message}`;
      }
      toast.error(errorMessage);
    },
  });

  const handleDelete = () => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this prediction?"
    );
    if (!shouldDelete) return;
    mutate({ id: prediction.id });
  };

  const buttonIcon = match(status)
    .with("idle", () => (
      <span>
        <Trash size={16} />
      </span>
    ))
    .with("pending", () => (
      <span className="w-4 h-4">
        <CircularSpinner />
      </span>
    ))
    .with("success", () => (
      <span>
        <Check size={16} />
      </span>
    ))
    .with("error", () => (
      <span>
        <X size={16} />
      </span>
    ))
    .exhaustive();

  const buttonText = match(status)
    .with("idle", () => "Delete")
    .with("pending", () => "Deleting...")
    .with("success", () => "Deleted")
    .with("error", () => "Deletion failed")
    .exhaustive();

  return (
    <Button
      disabled={isPending}
      intent="danger"
      variant="outlined"
      onClick={handleDelete}
      startIcon={buttonIcon}
    >
      <span>{buttonText}</span>
    </Button>
  );
}
