import { useMemo, type ComponentPropsWithoutRef, type ReactNode } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { P, match } from "ts-pattern";
import {
  makeCogRunSnippet,
  makeDockerRunSnippet,
  makeHTTPRunSnippet,
  makeNodeImportAndConfigureClientSnippet,
  makeNodeRunSnippet,
  makePythonImportAndConfigureClientSnippet,
  makePythonRunSnippet,
  makePythonRunSnippetWithStreaming,
  makeVersionlessHTTPRunSnippet,
  makeVersionlessNodeRunSnippet,
  makeVersionlessNodeRunSnippetWithStreaming,
  makeVersionlessPythonRunSnippet,
  makeVersionlessPythonRunSnippetWithStreaming,
} from "../../code-snippets";
import type { SupportedLanguage } from "../../highlight";
import { getOutputSchema } from "../../schema";
import type { AccessToken, Model, Version } from "../../types";
import { route } from "../../urls";
import AuthToken from "../auth-token";
import CodeBlock from "../code-block";
import StreamingCodeBlock from "../streaming-code-block";

export enum RunWithContext {
  Cog = "cog",
  Docker = "docker",
  HTTP = "http",
  NodeJS = "nodejs",
  Python = "python",
}

export const runWithHighlightedLanguageMap: {
  [key in RunWithContext]: SupportedLanguage;
} = {
  [RunWithContext.Cog]: "shell",
  [RunWithContext.Docker]: "shell",
  [RunWithContext.HTTP]: "shell",
  [RunWithContext.NodeJS]: "javascript",
  [RunWithContext.Python]: "python",
};

const useFormValues = () => {
  const { getValues } = useFormContext();
  return {
    ...useWatch(), // subscribe to form value updates
    ...getValues(), // always merge with latest form values
  };
};

/**
 *
 * Custom component that reads the current form values
 * from the surrounding React Hook Form context,
 * rather than from an "inputs" prop
 */
export function RunWithAPIPlayground(
  props: {
    token?: AccessToken | null;
    version: Version;
  } & (
    | {
        model?: never;
        usesVersionlessApi: false;
      }
    | {
        model: Model;
        usesVersionlessApi: true;
      }
  ) &
    (
      | {
          context: RunWithContext.Cog | RunWithContext.Docker;
          dockerImageName: string | null;
        }
      | {
          context: Exclude<
            RunWithContext,
            RunWithContext.Cog | RunWithContext.Docker
          >;
          dockerImageName?: never;
        }
    )
) {
  const input = useFormValues();
  return <RunWith {...props} input={input} />;
}

export function RunWith(
  props: ComponentPropsWithoutRef<typeof RunWithAPIPlayground> & {
    input: Record<string, any>;
  }
) {
  const { context, model, token, version } = props;
  const showAuthToken = token !== undefined;

  return (
    <div className="space-y-lh">
      <InstallDependencies context={context} />
      {showAuthToken && <SetAuthToken context={context} token={token} />}
      <ImportAndConfigureClient context={context} version={version} />
      <RunPreamble context={context} model={model} version={version} />
      {match(props)
        .with(
          {
            context: P.union(RunWithContext.Cog, RunWithContext.Docker),
            usesVersionlessApi: true,
          },
          (props) => (
            <RunSnippet
              context={props.context}
              dockerImageName={props.dockerImageName}
              input={props.input}
              model={props.model}
              version={props.version}
              usesVersionlessApi
            />
          )
        )
        .with(
          {
            context: P.union(RunWithContext.Cog, RunWithContext.Docker),
            usesVersionlessApi: false,
          },
          (props) => (
            <RunSnippet
              context={props.context}
              dockerImageName={props.dockerImageName}
              input={props.input}
              model={props.model}
              version={props.version}
              usesVersionlessApi={false}
            />
          )
        )
        .with(
          {
            usesVersionlessApi: true,
          },
          (props) => (
            <RunSnippet
              context={props.context}
              dockerImageName={props.dockerImageName}
              input={props.input}
              model={props.model}
              version={props.version}
              usesVersionlessApi
            />
          )
        )
        .with(
          {
            usesVersionlessApi: false,
          },
          (props) => (
            <RunSnippet
              context={props.context}
              dockerImageName={props.dockerImageName}
              input={props.input}
              model={props.model}
              version={props.version}
              usesVersionlessApi={false}
            />
          )
        )
        .exhaustive()}
      <LearnMore context={context} />
    </div>
  );
}

export function InstallDependencies({
  context,
}: {
  context: RunWithContext;
}) {
  const summary = useMemo<ReactNode>(
    () =>
      match(context)
        .with(RunWithContext.Cog, () => (
          <>
            Install <a href="https://github.com/replicate/cog">Cog</a>
          </>
        ))
        .with(RunWithContext.Docker, () => null)
        .with(RunWithContext.HTTP, () => null)
        .with(RunWithContext.NodeJS, () => (
          <>
            Install{" "}
            <a href="https://github.com/replicate/replicate-javascript">
              Replicate’s Node.js client library
            </a>
          </>
        ))
        .with(RunWithContext.Python, () => (
          <>
            Install{" "}
            <a href="https://github.com/replicate/replicate-python">
              Replicate’s Python client library
            </a>
          </>
        ))
        .exhaustive(),
    [context]
  );
  const body = useMemo<ReactNode>(
    () =>
      match(context)
        .with(RunWithContext.Cog, () => (
          <>
            <CodeBlock language="shell" textContent="brew install cog" />
            <p>
              If you don’t have <a href="https://brew.sh/">Homebrew</a>, there
              are{" "}
              <a href="https://github.com/replicate/cog#install">
                other installation options available
              </a>
              .
            </p>
          </>
        ))
        .with(RunWithContext.Docker, () => null)
        .with(RunWithContext.HTTP, () => null)
        .with(RunWithContext.NodeJS, () => (
          <CodeBlock language="shell" textContent="npm install replicate" />
        ))
        .with(RunWithContext.Python, () => (
          <CodeBlock language="shell" textContent="pip install replicate" />
        ))
        .exhaustive(),
    [context]
  );

  if (!summary || !body) {
    return null;
  }

  return <InstructionDetails summary={summary}>{body}</InstructionDetails>;
}

export function SetAuthToken({
  context,
  token,
}: {
  context: RunWithContext;
  token: AccessToken | null;
}) {
  return match(context)
    .with(P.union(RunWithContext.Cog, RunWithContext.Docker), () => null)
    .with(
      P.union(
        RunWithContext.HTTP,
        RunWithContext.NodeJS,
        RunWithContext.Python
      ),
      () => (
        <InstructionDetails
          summary={
            <>
              Set the <code>REPLICATE_API_TOKEN</code> environment variable
            </>
          }
        >
          <AuthToken token={token} type="shell" />
        </InstructionDetails>
      )
    )
    .exhaustive();
}

export function ImportAndConfigureClient({
  context,
  version,
}: {
  context: RunWithContext;
  version: Version;
}) {
  const summary = useMemo<ReactNode>(
    () =>
      match(context)
        .with(RunWithContext.Cog, () =>
          version._extras.model.visibility === "public"
            ? null
            : "Log in with Cog"
        )
        .with(RunWithContext.Docker, () => null)
        .with(RunWithContext.HTTP, () => null)
        .with(RunWithContext.NodeJS, () => "Import and set up the client")
        .with(RunWithContext.Python, () => "Import the client")
        .exhaustive(),
    [context, version]
  );
  const body = useMemo<ReactNode>(
    () =>
      match(context)
        .with(RunWithContext.Cog, () =>
          version._extras.model.visibility === "public" ? null : (
            <CodeBlock language="shell" textContent="cog login" />
          )
        )
        .with(RunWithContext.Docker, () => null)
        .with(RunWithContext.HTTP, () => null)
        .with(RunWithContext.NodeJS, () => (
          <CodeBlock
            language="javascript"
            textContent={makeNodeImportAndConfigureClientSnippet()}
          />
        ))
        .with(RunWithContext.Python, () => (
          <CodeBlock
            language="python"
            textContent={makePythonImportAndConfigureClientSnippet()}
          />
        ))
        .exhaustive(),
    [context, version]
  );

  if (!summary || !body) {
    return null;
  }

  return <InstructionDetails summary={summary}>{body}</InstructionDetails>;
}

function RunPreamble({
  context,
  model,
  version,
}: {
  context: RunWithContext;
  model?: Model;
  version: Version;
}) {
  const { owner, name } = model ?? version._extras.model;

  return match(context)
    .with(RunWithContext.Cog, () => (
      <p>
        Pull and run {owner}/{name} using Cog (this will download the full model
        and run it in your local environment):
      </p>
    ))
    .with(RunWithContext.Docker, () => (
      <p>
        Pull and run {owner}/{name} using Docker (this will download the full
        model and run it in your local environment):
      </p>
    ))
    .with(
      P.union(
        RunWithContext.HTTP,
        RunWithContext.NodeJS,
        RunWithContext.Python
      ),
      () => (
        <p>
          Run{" "}
          <span className="font-semibold">
            {owner}/{name}
          </span>{" "}
          using Replicate’s API. Check out the{" "}
          <a
            href={`${route("model_api_reference", {
              username: owner,
              name: name,
            })}/schema`}
          >
            model's schema
          </a>{" "}
          for an overview of inputs and outputs.
        </p>
      )
    )
    .exhaustive();
}

function RunSnippet({
  context,
  dockerImageName,
  input,
  model,
  usesVersionlessApi,
  version,
}: {
  input: Record<string, any>;
  version: Version;
} & (
  | {
      model?: never;
      usesVersionlessApi: false;
    }
  | {
      model: Model;
      usesVersionlessApi: true;
    }
) &
  (
    | {
        context: RunWithContext.Cog | RunWithContext.Docker;
        dockerImageName: string | null;
      }
    | {
        context: Exclude<
          RunWithContext,
          RunWithContext.Cog | RunWithContext.Docker
        >;
        dockerImageName?: never;
      }
  )) {
  const language = runWithHighlightedLanguageMap[context];

  const run = useMemo(
    () =>
      match(context)
        .with(RunWithContext.Cog, () =>
          usesVersionlessApi || dockerImageName == null
            ? null
            : makeCogRunSnippet({
                dockerImageName,
                input,
                version,
              })
        )
        .with(RunWithContext.Docker, () =>
          usesVersionlessApi || dockerImageName == null
            ? null
            : makeDockerRunSnippet({
                dockerImageName,
                input,
                version,
              })
        )
        .with(RunWithContext.HTTP, () =>
          usesVersionlessApi
            ? makeVersionlessHTTPRunSnippet({
                input,
                model,
                version,
              })
            : makeHTTPRunSnippet({
                input,
                version,
              })
        )
        .with(RunWithContext.NodeJS, () => {
          const output = getOutputSchema(version);
          const supportsStreaming = output?.["x-cog-array-type"] === "iterator";

          if (usesVersionlessApi) {
            if (supportsStreaming) {
              return (
                <StreamingCodeBlock
                  language="javascript"
                  standardContent={makeVersionlessNodeRunSnippet({
                    input,
                    model,
                    version,
                  })}
                  streamingContent={makeVersionlessNodeRunSnippetWithStreaming({
                    input,
                    model,
                    version,
                  })}
                />
              );
            }

            return makeVersionlessNodeRunSnippet({
              input,
              model,
              version,
            });
          }

          return makeNodeRunSnippet({
            input,
            version,
          });
        })
        .with(RunWithContext.Python, () => {
          const output = getOutputSchema(version);
          const supportsStreaming = output?.["x-cog-array-type"] === "iterator";

          if (usesVersionlessApi) {
            if (supportsStreaming) {
              return (
                <StreamingCodeBlock
                  language="python"
                  standardContent={makeVersionlessPythonRunSnippet({
                    input,
                    model,
                    version,
                  })}
                  streamingContent={makeVersionlessPythonRunSnippetWithStreaming(
                    {
                      input,
                      model,
                      version,
                    }
                  )}
                />
              );
            }

            return makeVersionlessPythonRunSnippet({
              input,
              model,
              version,
            });
          }

          if (supportsStreaming) {
            return (
              <StreamingCodeBlock
                language="python"
                standardContent={makePythonRunSnippet({
                  input,
                  version,
                })}
                streamingContent={makePythonRunSnippetWithStreaming({
                  input,
                  // Models that support streaming in the Python SDK have two
                  // flavors, normal (kuprel/min-dalle) or concatenate
                  // (andreasjansson/plasma)
                  streaming:
                    output?.["x-cog-array-display"] === "concatenate"
                      ? "concatenate"
                      : "entry",
                  version,
                })}
              />
            );
          }

          return makePythonRunSnippet({
            input,
            version,
          });
        })
        .exhaustive(),
    [context, dockerImageName, input, model, usesVersionlessApi, version]
  );

  if (
    typeof run === "string" ||
    (Array.isArray(run) && run.every((x) => typeof x === "string"))
  ) {
    return <CodeBlock language={language} textContent={run} />;
  }

  return run;
}

function LearnMore({
  context,
}: {
  context: RunWithContext;
}) {
  return match(context)
    .with(RunWithContext.Cog, () => (
      <p>
        To learn more, take a look at{" "}
        <a href="https://github.com/replicate/cog">the Cog documentation</a>.
      </p>
    ))
    .with(RunWithContext.Docker, () => null)
    .with(RunWithContext.HTTP, () => (
      <p>
        To learn more, take a look at{" "}
        <a href="https://replicate.com/docs/reference/http">
          Replicate’s HTTP API reference docs
        </a>
        .
      </p>
    ))
    .with(RunWithContext.NodeJS, () => (
      <p>
        To learn more, take a look at{" "}
        <a href="https://replicate.com/docs/get-started/nodejs">
          the guide on getting started with Node.js
        </a>
        .
      </p>
    ))
    .with(RunWithContext.Python, () => (
      <p>
        To learn more, take a look at{" "}
        <a href="https://replicate.com/docs/get-started/python">
          the guide on getting started with Python
        </a>
        .
      </p>
    ))
    .exhaustive();
}

function InstructionDetails({
  summary,
  children,
}: {
  summary: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="space-y-lh">
      <div className="space-y-lh">{summary}</div>
      <div className="space-y-lh">{children}</div>
    </div>
  );
}
