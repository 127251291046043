import { Suspense, lazy, type ComponentPropsWithoutRef } from "react";

const SupportFormComponent = lazy(() => import("./component"));

export default function SupportForm(
  props: ComponentPropsWithoutRef<typeof SupportFormComponent>
) {
  return (
    <Suspense>
      <SupportFormComponent {...props} />
    </Suspense>
  );
}
