import { useEffect } from "react";
import type { PredictionStatus } from "../../types";
import { getStaticAssetPath } from "../../util";

const statusFaviconMap: Record<PredictionStatus, string> = {
  processing: getStaticAssetPath("/static/favicons/running.svg"),
  starting: getStaticAssetPath("/static/favicons/running.svg"),
  canceled: getStaticAssetPath("/static/favicons/canceled.svg"),
  failed: getStaticAssetPath("/static/favicons/failed.svg"),
  succeeded: getStaticAssetPath("/static/favicons/succeeded.svg"),
  canceling: getStaticAssetPath("/static/favicons/running.svg"),
};

export function PredictionStatusFavicon({
  status,
}: { status: PredictionStatus }) {
  useEffect(() => {
    const icoLink = document.getElementById("favicon-ico");
    const svgLink = document.getElementById("favicon-svg");

    if (!icoLink || !svgLink) {
      return;
    }

    icoLink.setAttribute("href", statusFaviconMap[status]);
    svgLink.setAttribute("href", statusFaviconMap[status]);
  }, [status]);

  return null;
}
