import { atom } from "jotai";

export type Flags = Record<string, boolean>;

export const usedFlagsAtom = atom<
  Record<
    keyof Flags,
    {
      count: number;
      value: Flags[keyof Flags];
    }
  >
>({});
export const focusedFlagAtom = atom<keyof Flags | null>(null);
