import type { CogOutputValue, NumericCogPropertyItemSchema } from "../../types";
import { FallbackValue } from "./fallback-value";

export function isNumber(n: unknown): boolean {
  return !Number.isNaN(n) && Number.isFinite(n);
}

function isNumberLike(n: any): boolean {
  return isNumber(Number.parseFloat(n));
}

export function NumericValue({
  name,
  reportFallback,
  schema,
  value,
}: {
  name?: string;
  reportFallback: boolean;
  schema: NumericCogPropertyItemSchema | undefined;
  value: CogOutputValue;
}) {
  if (!isNumberLike(value)) {
    return (
      <FallbackValue
        name={name}
        schema={schema}
        value={value}
        report={reportFallback}
      />
    );
  }

  return (
    <div
      data-testid={name ? `value-${name}-numeric` : "value-numeric"}
      className="output p-2 whitespace-pre-wrap bg-r8-gray-3 text-r8-gray-12 font-mono text-r8-sm overflow-y-auto max-h-96"
      dir="auto"
      translate="no"
    >
      {JSON.stringify(value)}
    </div>
  );
}
