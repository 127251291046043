import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { addMinutes } from "date-fns";
import { TERMINAL_PREDICTION_STATUSES } from "../api-playground/constants";
import {
  type ActivityDateRange,
  type FetchPredictionWebhooksInfoParams,
  type RecentActivityResponse,
  fetchEndpointFailedAttempts,
  fetchPredictionWebhooksInfo,
  fetchRecentActivity,
} from "./api";

export function useRecentActivity({
  token,
  appId,
  range,
}: { token: string; appId: string; range: ActivityDateRange }) {
  return useQuery({
    queryKey: ["recent-activity", appId, range],
    queryFn: () => fetchRecentActivity({ token, appId, range }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
}

export function transformActivity(recentActivity: RecentActivityResponse) {
  const data = recentActivity.data.failureCount.map((failureCount, i) => ({
    fail: failureCount,
    success: recentActivity.data.successCount[i],
    x: addMinutes(new Date(recentActivity.startDate), i * 5),
  }));

  const totalFail = recentActivity.data.failureCount.reduce(
    (acc, count) => acc + count,
    0
  );

  const totalSuccess = recentActivity.data.successCount.reduce(
    (acc, count) => acc + count,
    0
  );

  return {
    data,
    totalFail,
    totalSuccess,
    startDate: recentActivity.startDate,
    endDate: recentActivity.endDate,
  };
}

export function useEndpointFailedAttempts({
  token,
  appId,
  before,
  after,
}: { token: string; appId: string; before?: string; after?: string }) {
  return useQuery({
    refetchOnWindowFocus: false,
    enabled: Boolean(before) && Boolean(after),
    queryKey: ["endpoint-attempts", appId, before, after],
    queryFn: () => {
      if (!before || !after) return Promise.reject("Invalid date range");
      return fetchEndpointFailedAttempts({ token, appId, before, after });
    },
    placeholderData: keepPreviousData,
  });
}

export function usePredictionWebhooksInfo(
  params: FetchPredictionWebhooksInfoParams
) {
  return useQuery({
    queryKey: ["prediction-webhooks", params.prediction.id],
    queryFn: () => fetchPredictionWebhooksInfo(params),
    retry: false,
    refetchOnWindowFocus() {
      return !TERMINAL_PREDICTION_STATUSES.includes(params.prediction.status);
    },
  });
}
