import { P, match } from "ts-pattern";
import type {
  AnyArrayCogPropertyItemSchema,
  ArrayArrayCogPropertyItemSchema,
  BasicStringArrayCogPropertyItemSchema,
  BooleanArrayCogPropertyItemSchema,
  NumericArrayCogPropertyItemSchema,
  ObjectArrayCogPropertyItemSchema,
  URLArrayCogPropertyItemSchema,
  UnknownArrayCogPropertyItemSchema,
} from "../../types";
import { FallbackInput } from "./fallback-input";
import { MultiNumericInput } from "./multi-numeric-input";
import { MultiStringInput } from "./multi-string-input";

export function ArrayInput({
  disabled,
  name,
  required,
  schema,
}: {
  disabled: boolean;
  name: string;
  required: boolean;
  schema: AnyArrayCogPropertyItemSchema;
}) {
  return match(schema)
    .with(
      {
        type: "array",
        items: {
          type: "string",
          format: "uri",
        },
      },
      (matchedSchema) => {
        // Check at build time that we've filtered to an expected type.
        matchedSchema satisfies URLArrayCogPropertyItemSchema;

        return (
          <FallbackInput
            name={name}
            required={required}
            schema={matchedSchema}
            report
          />
        );
      }
    )
    .with(
      {
        type: "array",
        items: {
          type: "string",
          format: P.nullish.optional(),
        },
      },
      (matchedSchema) => {
        // Check at build time that we've filtered to an expected type.
        matchedSchema satisfies BasicStringArrayCogPropertyItemSchema;

        return (
          <MultiStringInput
            disabled={disabled}
            name={name}
            required={required}
            type="string[]"
          />
        );
      }
    )
    .with(
      {
        type: "array",
        items: {
          type: P.union("integer", "number"),
          format: P.nullish.optional(),
        },
      },
      (matchedSchema) => {
        // Check at build time that we've filtered to an expected type.
        matchedSchema satisfies NumericArrayCogPropertyItemSchema;

        return (
          <MultiNumericInput
            disabled={disabled}
            name={name}
            required={required}
            type="number[]"
          />
        );
      }
    )
    .with(
      {
        type: "array",
        items: {
          type: P.union("array", "boolean"),
          format: P.nullish.optional(),
        },
      },
      (matchedSchema) => {
        // Check at build time that we've filtered to an expected type.
        matchedSchema satisfies
          | ArrayArrayCogPropertyItemSchema
          | BooleanArrayCogPropertyItemSchema;

        return (
          // Return but don't report it, as it's a known unsupported type.
          <FallbackInput
            name={name}
            required={required}
            schema={matchedSchema}
            report={false}
          />
        );
      }
    )
    .otherwise((matchedSchema) => {
      // Check at build time that we've filtered to an expected type.
      matchedSchema satisfies
        | ObjectArrayCogPropertyItemSchema
        | UnknownArrayCogPropertyItemSchema;

      return (
        // Return and report it, as this is unexpected.
        <FallbackInput
          name={name}
          required={required}
          schema={matchedSchema}
          report
        />
      );
    });
}
