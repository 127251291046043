import { useFloating } from "@floating-ui/react";
import { Pause, Play } from "@phosphor-icons/react";
import type React from "react";
import { useMemo, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { useInterval, usePreloadImages } from "../../hooks";
import type { PredictionStatus } from "../../types";
import { isImageUrl } from "./util";

export function PredictionOutputScrubber({
  items,
  children,

  status,
}: {
  items: any[];
  children: (item: any) => React.ReactNode;
  status?: PredictionStatus;
}) {
  const isArrayOfImageURLs = useMemo(() => {
    return items.every((item) => {
      return isImageUrl(item);
    });
  }, [items]);

  // We only want to preload images when the prediction has succeeded,
  // and we know that the output is an array of image URLs.
  usePreloadImages(items, isArrayOfImageURLs && status === "succeeded");

  const [playStatus, setPlayStatus] = useState<"playing" | "paused">("paused");
  const [active, setActive] = useState(Math.max(0, items.length - 1));
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(
    undefined
  );
  const activeItem = items[active];

  const { refs, floatingStyles } = useFloating({
    placement: "top",
  });

  const [min, max] = [0, items.length - 1];
  const step = 1;

  const handleTogglePlay = () => {
    if (playStatus === "paused") {
      setPlayStatus("playing");
    } else {
      setPlayStatus("paused");
    }
  };

  useInterval(
    () => {
      if (active === max) {
        setActive(min);
      } else {
        setActive(active + 1);
      }
    },
    playStatus === "playing" ? 1000 : null
  );

  return (
    <div className="relative">
      <label htmlFor={"output-scrubber"} className="sr-only">
        Scrub through output
      </label>
      {activeItem && children(activeItem)}
      {items.length > 1 && (
        <div className="flex items-center gap-4">
          <div className="flex-shrink-0 flex items-center">
            <button
              onClick={handleTogglePlay}
              type="button"
              className="w-6 h-6 flex items-center justify-center rounded-full"
            >
              {playStatus === "paused" ? (
                <Play weight="fill" />
              ) : (
                <Pause weight="fill" />
              )}
            </button>
          </div>
          <div
            className="py-4 group flex-1"
            onMouseMove={(e) => {
              if (!isArrayOfImageURLs) return;

              const bbox = e.currentTarget.getBoundingClientRect();

              // Clamp the tooltip position between the left and right edges of the container.
              const clientX = Math.max(
                bbox.left + 96 / 2,
                Math.min(e.clientX, bbox.right - 96 / 2)
              );

              const clientY = e.clientY;

              const approximateIndex = Math.floor(
                ((e.clientX - bbox.left) / (bbox.right - bbox.left)) *
                  items.length
              );

              setHoveredIndex(approximateIndex);

              refs.setPositionReference({
                getBoundingClientRect() {
                  return {
                    width: 0,
                    height: 0,
                    x: clientX,
                    y: bbox.top,
                    left: clientX,
                    right: clientX,
                    top: bbox.top,
                    bottom: clientY,
                  };
                },
              });
            }}
          >
            {isArrayOfImageURLs && (
              <div
                className="opacity-0 group-hover:opacity-100 transition-opacity aspect-video h-16 overflow-hidden shadow-md"
                aria-hidden="true"
                ref={refs.setFloating}
                style={floatingStyles}
              >
                <img src={items[hoveredIndex ?? active]} />
              </div>
            )}

            <Range
              step={step}
              min={min}
              max={max}
              values={[active]}
              onChange={(values) => {
                setActive(values[0]);
              }}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className="h-2 w-full flex"
                  style={{
                    ...props.style,
                    background: getTrackBackground({
                      values: [active],
                      colors: ["var(--gray-12)", "var(--gray-3)"],
                      min,
                      max,
                      rtl: false,
                    }),
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  className="w-5 h-5 rounded-full bg-r8-gray-12 focus:ring focus:outline-none flex items-center justify-center"
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
