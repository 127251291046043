import { Info } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import { useController } from "react-hook-form";
import { Label } from "./label";

export function DisableSafetyCheckerInput({
  name,
  required,
  type,
}: {
  name: string;
  required: boolean;
  type: "boolean";
}) {
  const { field } = useController({ name });
  return (
    <div className="gap-2 flex flex-col">
      <div className="gap-2 flex items-center">
        <input
          id={name}
          type="checkbox"
          disabled={true}
          checked={false}
          className="disabled:cursor-not-allowed disabled:opacity-50"
          {...field}
        />
        <Label name={name} required={required} type={type} />
      </div>
      <Banner
        icon={<Info />}
        condensed
        description={
          <p>
            This model’s safety checker can’t be disabled when running on the
            website.{" "}
            <a href="/docs/how-does-replicate-work#safety">
              Learn more about platform safety on Replicate.
            </a>
          </p>
        }
      />
    </div>
  );
}
