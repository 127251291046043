import { Info, TextT } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import { useController } from "react-hook-form";
import { useMediaMatch, useUpdateEffect } from "../../hooks";
import { Label } from "../api-playground/label";
import { HighlightWordExtension } from "./highlight";

export function TiptapTextInput({
  required,
  name,
  onSubmit,
  type,
  disabled,
  placeholder,
  wordToHighlight,
}: {
  disabled: boolean;
  name: string;
  onSubmit?: () => void;
  required: boolean;
  type: "string" | "string | string[]";
  placeholder?: string;
  /**
   * When provided, the word will be highlighted in the editor.
   * Useful for things like trigger words.
   */
  wordToHighlight?: string;
}) {
  // Corresponds with "md:" Tailwind breakpoint
  const isDesktopScreen = useMediaMatch("(min-width: 768px)");

  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });

  const editor = useEditor(
    {
      extensions: [
        Document.extend({
          addKeyboardShortcuts() {
            return {
              Enter: ({ editor }) => {
                // If this is a Shift + Enter, don't submit the form.
                // @ts-ignore - Tiptap doesn't seem to have a correct type for the editor object.
                if (editor.view?.input?.shiftKey) return false;
                // If we're not on a desktop screen, don't submit the form.
                if (!isDesktopScreen) return false;
                if (!onSubmit) return false;
                onSubmit();
                return true;
              },
              "Shift-Enter": ({ editor }) => {
                return editor.commands.enter();
              },
            };
          },
        }),
        Paragraph,
        Text,
        HighlightWordExtension.configure({ wordToHighlight }),
        History,
      ],
      parseOptions: {
        preserveWhitespace: "full",
      },
      editable: !disabled && !formState.isSubmitting,
      content: field.value,
      onUpdate({ editor }) {
        field.onChange(editor.getText({ blockSeparator: "\n" }));
      },
    },
    [isDesktopScreen, wordToHighlight]
  );

  // Sadly, Tiptap doesn't support fully controlled editing.
  // This hook ensures that if field.value changes elsewhere (e.g., the JSON editor),
  // the Tiptap editor will be updated to reflect that change.
  // This hook shouldn't fire when the form tab is open, since the Tiptap editor
  // will have the same value as the field.
  useUpdateEffect(() => {
    if (!editor) return;
    const editorValue = editor.getText({ blockSeparator: "\n" });
    const fieldValue = field.value ?? "";

    if (editorValue !== fieldValue) {
      editor.commands.setContent(fieldValue, true, {
        preserveWhitespace: "full",
      });
    }
  }, [field.value, editor]);

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:items-center md:justify-between">
        <Label type={type} Icon={TextT} required={required} name={name} />
        {onSubmit && isDesktopScreen && (
          <span
            className={`text-r8-xs text-r8-gray-11 transition-all ${
              field.value
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-1"
            }`}
          >
            <span translate="no">
              <kbd className="bg-r8-gray-1 border py-px px-1">Shift</kbd> +{" "}
              <kbd className="bg-r8-gray-1 border py-px px-1">Return</kbd>
            </span>{" "}
            to add a new line
          </span>
        )}
      </div>
      <EditorContent
        id={name}
        dir="auto"
        autoComplete="off"
        data-1p-ignore
        className="border bg-white dark:bg-r8-gray-1 resize-none data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50 border-r8-gray-12 [&_div]:p-2"
        editor={editor}
        placeholder={placeholder}
        required={required}
        data-disabled={formState.isSubmitting || disabled}
      />
      {/*
        Prevents a potential clash with numerical values
        being specified in the JSON editor, which don't have
        the "includes" method and will hence throw an error.
      */}
      {typeof field.value === "string" && field.value?.includes("\\") && (
        <Banner
          icon={<Info />}
          condensed
          description={
            <p>
              Backslashes aren't interpreted as an escape sequence. For example,{" "}
              <code className="p-0 bg-transparent">"\n"</code> is two
              characters, not a newline.
            </p>
          }
        />
      )}
    </div>
  );
}
