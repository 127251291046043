import * as Ariakit from "@ariakit/react";
import Ansi from "@curvenote/ansi-to-react";
import { ArrowsOut, X } from "@phosphor-icons/react";
import ScrollToBottom from "react-scroll-to-bottom";
import type { Prediction } from "../types";
import { LiveStatusBadge } from "./live-status-badge";

export function LogsDialog({ prediction }: { prediction: Prediction }) {
  const { id, status, logs } = prediction;

  return (
    <Ariakit.DialogProvider>
      <Ariakit.DialogDisclosure className="text-r8-sm hover:bg-r8-gray-a3 text-r8-gray-11 px-2 py-1 inline-flex items-center gap-2">
        <ArrowsOut />
        <span>Fullscreen logs</span>
      </Ariakit.DialogDisclosure>
      <Ariakit.Dialog portal>
        <div className="h-screen overflow-hidden flex flex-col divide-y z-50 fixed inset-0 bg-white dark:bg-r8-gray-1">
          <div className="flex-shrink-0 px-4 py-2 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Ariakit.DialogHeading className="text-r8-xl">
                Logs <span className="font-mono text-r8-sm">({id})</span>
              </Ariakit.DialogHeading>
              <LiveStatusBadge
                status={status}
                isBooting={prediction._extras.is_waiting_for_boot}
              />
            </div>
            <Ariakit.DialogDismiss className="w-7 h-7 inline-flex items-center justify-center relative hover:bg-r8-gray-100">
              <X size={18} weight="bold" />
            </Ariakit.DialogDismiss>
          </div>
          <div className="p-4 overflow-hidden h-full min-h-0">
            <ScrollToBottom
              initialScrollBehavior="auto"
              followButtonClassName="hidden"
              className="h-full min-h-full border overflow-y-scroll bg-r8-gray-2 text-r8-gray-12 text-r8-sm font-mono overscroll-contain"
            >
              <div className="p-4 whitespace-pre" role="log" aria-label="Logs">
                <Ansi useClasses>{logs ?? undefined}</Ansi>
              </div>
            </ScrollToBottom>
          </div>
        </div>
      </Ariakit.Dialog>
    </Ariakit.DialogProvider>
  );
}
