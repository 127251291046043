import type {
  CogInputPropertySchema,
  CogInputSchema,
  CogOutputSchema,
  CogTrainingInputSchema,
  OpenapiSchema,
  Version,
} from "./types";

export function getSchema(version: Version): OpenapiSchema {
  return version._extras.dereferenced_openapi_schema;
}

export function getInputSchema(version: Version): CogInputSchema {
  return getSchema(version).components.schemas.Input;
}

export function getTrainingInputSchema(
  version: Version
): CogTrainingInputSchema | undefined {
  return getSchema(version).components.schemas.TrainingInput;
}

export function getSortedProperties(
  inputSchema: CogInputSchema | CogTrainingInputSchema
): [string, CogInputPropertySchema][] {
  return Object.entries(inputSchema.properties).sort(
    ([, a], [, b]) => a["x-order"] - b["x-order"]
  );
}

export function isFile(property: CogInputPropertySchema): boolean {
  return (
    "type" in property &&
    property.type === "string" &&
    property.format === "uri"
  );
}

export function getFileProperties(inputSchema: CogInputSchema): string[] {
  const fileInputs: string[] = [];
  for (const [name, property] of Object.entries(inputSchema.properties)) {
    if (isFile(property)) {
      fileInputs.push(name);
    }
  }
  return fileInputs;
}

export function getOutputSchema(version: Version): CogOutputSchema {
  return getSchema(version).components.schemas.Output;
}
