import * as Sentry from "@sentry/react";
import copy from "copy-to-clipboard";
import { Copy as CopyIcon } from "lucide-react";
import { useCallback, useState } from "react";
import type { Model, Version } from "../../types";

export function RunWithNPX({
  version,
  model,
}: {
  version: Version;
  model?: Model;
}) {
  // If a model is present, it suggests this is an official model,
  // so we should use the non-internal owner / name from it
  const { owner, name } = model || version._extras.model;
  const modelName = `${owner}/${name}`;
  const command = `npx create-replicate --model=${modelName}`;

  const [didCopy, setDidCopy] = useState(false);

  const copyContent = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(command);
    } catch (e) {
      Sentry.captureException(e);
      copy(command);
    }
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 5000);
  }, [command]);

  return (
    <div>
      <p className="pb-2 text-r8-sm text-r8-gray-11">
        Run this model in Node.js with{" "}
        <a href="/docs/get-started/nodejs#quickstart-scaffold-a-project-with-a-one-liner">
          one line of code
        </a>
        :
      </p>
      <div className="flex bg-gradient-to-r from-branding-pinkDarker to-branding-blushDarker px-3 py-4 rounded-md text-white ">
        <div className="font-mono flex-grow truncate bg-transparent before:content-['$'] before:pr-2 before:opacity-40 whitespace-nowrap">
          {command}
        </div>
        <button
          className="flex-none relative group ml-2"
          type="button"
          onClick={() => {
            copyContent();
          }}
        >
          <CopyIcon className="opacity-40 group-hover:opacity-100" />
          {didCopy && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-1 bg-black text-white p-2 rounded text-sm mb-2">
              Copied!
              <div className="absolute h-3 w-3 bg-black -rotate-45 transform -translate-x-1/2 left-1/2 -bottom-1" />
            </div>
          )}
        </button>
      </div>
    </div>
  );
}
