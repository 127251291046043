import * as Ariakit from "@ariakit/react";
import { FrameCorners, X } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import ConditionalWrap from "../conditional-wrap";

function getImageAspectRatio(url: string) {
  return new Promise<number>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img.width / img.height);
    };
    img.onerror = reject;
    img.src = url;
  });
}

export function BeforeAfterSlider({
  beforeImgSrc,
  afterImgSrc,
}: {
  beforeImgSrc: string;
  afterImgSrc: string;
}) {
  const [open, setOpen] = useState(false);

  const { data, status } = useQuery({
    queryKey: ["before-after-images", beforeImgSrc, afterImgSrc],
    queryFn: async () => {
      const aspectRatios = await Promise.allSettled([
        getImageAspectRatio(beforeImgSrc),
        getImageAspectRatio(afterImgSrc),
      ]);
      return {
        before:
          aspectRatios[0].status === "fulfilled" ? aspectRatios[0].value : null,
        after:
          aspectRatios[1].status === "fulfilled" ? aspectRatios[1].value : null,
      };
    },
  });

  if (status === "pending") {
    return <p className="text-r8-sm text-r8-gray-11">Loading...</p>;
  }

  if (status === "success") {
    if (!data.before && !data.after) {
      return (
        <div>
          <p className="text-r8-sm text-r8-red-10">
            We were unable to load these images. Please make sure the URLs are
            valid.
          </p>
          <pre className="bg-r8-gray-2 p-2 text-r8-xs overflow-auto mt-2">
            {JSON.stringify(
              {
                input: beforeImgSrc,
                outut: afterImgSrc,
              },
              null,
              2
            )}
          </pre>
        </div>
      );
    }

    // If we only have the output, we can just render that as a fallback.
    if (!data.before && data.after) {
      return (
        <div className="relative">
          <img src={afterImgSrc} alt="Output" className="w-auto" />
        </div>
      );
    }

    // Safe to assume we have both aspect ratios, which means we have both images, so we can render the slider.
    return (
      <Ariakit.DialogProvider open={open} setOpen={setOpen}>
        <div className="group relative z-0">
          <Ariakit.DialogDisclosure
            className="absolute top-4 left-4 z-10"
            render={
              <Button size="sm" startIcon={<FrameCorners weight="bold" />}>
                Fullscreen
              </Button>
            }
          />
          <ConditionalWrap
            condition={open}
            wrap={(children) => (
              <Ariakit.Dialog
                portal
                className="fixed bg-black z-[999] inset-0 w-full h-full flex flex-col overflow-hidden"
                aria-label="Before and after slider"
              >
                <div className="p-4 flex-shrink-0 flex items-center">
                  <Ariakit.DialogDismiss className="text-white">
                    <X size={18} weight="bold" />
                  </Ariakit.DialogDismiss>
                </div>
                <div className="px-4 pb-4 flex min-h-0 min-w-0 h-full items-center justify-center overflow-auto">
                  <AnimatePresence>
                    {data.before && (
                      <motion.div
                        className="w-full lg:h-full lg:w-auto"
                        style={{
                          aspectRatio: data.before,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                        initial={{
                          opacity: 0,
                        }}
                      >
                        {children}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </Ariakit.Dialog>
            )}
          >
            <ReactCompareSlider
              itemOne={
                <div className="h-full w-full relative">
                  <div className="absolute top-0 left-4 bottom-0 pointer-events-none flex items-center justify-center transition-opacity opacity-100 group-hover:opacity-0">
                    <span className="bg-black/70 text-white px-2 py-1 text-r8-sm font-semibold">
                      Before
                    </span>
                  </div>
                  <ReactCompareSliderImage
                    src={beforeImgSrc}
                    alt="Input image"
                  />
                </div>
              }
              itemTwo={
                <div className="h-full w-full">
                  <div className="absolute top-0 right-4 bottom-0 pointer-events-none flex items-center justify-center transition-opacity opacity-100 group-hover:opacity-0">
                    <span className="bg-black/70 text-white px-2 py-1 text-r8-sm font-semibold">
                      After
                    </span>
                  </div>
                  <ReactCompareSliderImage
                    src={afterImgSrc}
                    alt="Output image"
                  />
                </div>
              }
            />
          </ConditionalWrap>
        </div>
      </Ariakit.DialogProvider>
    );
  }
}
