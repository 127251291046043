export function formatNumber(number, options = {}) {
  return new Intl.NumberFormat("en-US", options).format(number);
}

export function formatCurrency(number, options = {}) {
  return formatNumber(number, {
    ...options,
    style: "currency",
    currency: "USD",
  });
}
