import { useState } from "react";
import type { Prediction, Version } from "../../types";
import { CommandLine, OutputLine, TerminalWindow } from "./terminal-window";

export default function ScaffoldCommandDemo({
  predictions,
  versions,
}: { predictions: Prediction[]; versions: Version[] }) {
  const [{ randomPrediction, randomVersion }] = useState(() => {
    const randomPrediction =
      predictions[Math.floor(Math.random() * predictions.length)];
    const randomVersion = versions.find(
      (v) => v.id === randomPrediction.version
    ) as Version;
    return {
      randomPrediction,
      randomVersion,
    };
  });

  const qualifiedModelName = `${randomVersion._extras.model.owner}/${randomVersion._extras.model.name}:${randomVersion._extras.short_id}`;

  return (
    <TerminalWindow className="h-72">
      <CommandLine typed={true}>
        replicate scaffold --template node {randomPrediction.id}{" "}
        ./replicate-node-starter
      </CommandLine>
      <OutputLine duration={2000}>
        Looking up prediction ID:
        <a
          className="decoration-current text-white"
          href={`https://replicate.com/p/${randomPrediction.id}`}
        >
          {randomPrediction.id}
        </a>
      </OutputLine>
      <OutputLine duration={2500}>
        Cloning starter repo and installing dependencies with NPM...
      </OutputLine>
      <OutputLine duration={2500}>
        Running example prediction on {qualifiedModelName}
      </OutputLine>
      <OutputLine duration={0}>
        {JSON.stringify(randomPrediction.output)}
      </OutputLine>
      <OutputLine duration={0}>
        To run more predictions, run `npm run predict` in the
        ./replicate-starter directory.
      </OutputLine>
    </TerminalWindow>
  );
}
