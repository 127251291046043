import { SignOut } from "@phosphor-icons/react";
import { MenuItem } from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import { logout } from ".";

export function LogoutMenuItem() {
  const mutation = useMutation({
    mutationFn: logout,
  });

  return (
    <MenuItem intent="danger" onClick={() => mutation.mutate()}>
      <div className="flex items-center gap-2">
        <SignOut weight="bold" />
        <span>Log out</span>
      </div>
    </MenuItem>
  );
}
