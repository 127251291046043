import { P, match } from "ts-pattern";
import type { Prediction } from "../../types";

export function PredictionFailedState({
  prediction,
}: {
  prediction: Prediction;
}) {
  return (
    <div className="space-y-2">
      <p className="text-r8-red-10 font-semibold">Prediction failed.</p>
      {prediction.error &&
        match(prediction.error)
          .with(
            P.when((msg) => msg.includes("CUDA out of memory")),
            () => {
              const errorSummary =
                "Not enough memory available to process your request. Try reducing the size or number of any file inputs or outputs.";

              return (
                <div data-error className={"mb-4 text-r8-red-10"}>
                  <span>{errorSummary}</span>
                  {errorSummary && (
                    <details className="mt-2 text-r8-sm">
                      <summary>More details</summary>
                      <pre className="bg-r8-red-2 mt-2 p-4 overflow-x-auto whitespace-pre-wrap">
                        {prediction.error}
                      </pre>
                    </details>
                  )}
                </div>
              );
            }
          )
          .otherwise(() => (
            <p className="text-r8-sm text-r8-red-10">{prediction.error}</p>
          ))}
    </div>
  );
}
