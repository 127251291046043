import { ArrowDown } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, type ComponentPropsWithoutRef } from "react";
import { SSEProvider, useSSE } from "react-hooks-sse";
import type { Prediction } from "../../types";
import { Autoscroll } from "../autoscroll";
import { queryKeys } from "./hooks";
import { StringValue } from "./string-value";

function StreamingOutput({
  prediction,
  useExperimentalAutoscroller,
  onDone,
  onOutput,
}: {
  prediction?: Prediction;
  useExperimentalAutoscroller?: boolean;
  onDone?: () => void;
  onOutput?: (output: string) => void;
}) {
  const [streamingComplete, setStreamingComplete] = useState(false);
  const queryClient = useQueryClient();

  const done = useSSE<boolean, boolean>("done", false, {
    parser: (e) => {
      return true;
    },
    stateReducer: (_, action) => {
      return action.data;
    },
  });

  const output = useSSE<string, string>("output", "", {
    parser: (e) => (typeof e === "string" ? e : ""),
    stateReducer: (state, action) => {
      return state + action.data;
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: This effect should only run when output updates.
  useEffect(() => {
    onOutput?.(output);
  }, [output]);

  useEffect(() => {
    if (!done) return;
    if (streamingComplete) return;
    if (!prediction) return;
    onDone?.();

    setStreamingComplete(true);
    // Refetch the prediction data to get the UI to update.
    queryClient.invalidateQueries({
      queryKey: queryKeys.predictions.uuid(prediction.id),
    });
  }, [done, streamingComplete, prediction, queryClient, onDone]);

  if (!useExperimentalAutoscroller) {
    return (
      <StringValue
        name="Output"
        // Yes, this is a lie.
        // For the time being, we only support streaming
        // text output, so we can get away with directly
        // rendering our output as a string.
        schema={{ type: "string" }}
        value={output}
        shouldAutoScroll={true}
        reportFallback={false}
      />
    );
  }

  return (
    <div className="relative">
      <Autoscroll
        startPosition="top"
        overflowThreshold={100}
        className="output max-h-96 p-2 whitespace-pre-wrap bg-r8-gray-3 text-r8-gray-12 font-mono text-r8-sm overflow-y-auto overflow-x-hidden"
      >
        {({ hasContentBelow, scrollToBottom }) => (
          <>
            <AnimatePresence>
              {hasContentBelow && (
                <motion.div
                  className="absolute bottom-4 right-4 z-10"
                  initial={{ opacity: 0, y: 8 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 8 }}
                >
                  <button
                    className="w-7 h-7 rounded-full bg-r8-gray-12 text-white flex items-center justify-center"
                    type="button"
                    onClick={() => {
                      scrollToBottom();
                    }}
                  >
                    <ArrowDown weight="bold" />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
            {output}
          </>
        )}
      </Autoscroll>
    </div>
  );
}

export function PredictionStreamingOutput({
  streamUrl,
  ...props
}: ComponentPropsWithoutRef<typeof StreamingOutput> & {
  streamUrl: string;
}) {
  return (
    <SSEProvider endpoint={streamUrl}>
      <StreamingOutput {...props} />
    </SSEProvider>
  );
}
