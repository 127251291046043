import { Portal } from "@ariakit/react";
import { List } from "@phosphor-icons/react";
import {
  Button,
  Disclosure,
  DisclosureContent,
  DisclosureProvider,
} from "@replicate/ui";
import { route } from "../../urls";
import { useFlag } from "../flags/if-flag";

type MobileNavItem = {
  label: string;
  href: string;
  type: "link" | "cta";
  beta?: boolean;
};

export default function MobileMenu({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) {
  const portalElement = document.getElementById("js-mobile-menu");
  const isPlaygroundEnabled = useFlag("web-launch-gallery");

  const links: MobileNavItem[] = [
    {
      label: "Explore",
      href: route("explore"),
      type: "link",
    },
    {
      label: "Pricing",
      href: route("pricing"),
      type: "link",
    },
    {
      label: "Docs",
      href: "https://replicate.com/docs",
      type: "link",
    },
    {
      label: "Blog",
      href: route("blog_index"),
      type: "link",
    },
    {
      label: "Changelog",
      href: route("changelog_list"),
      type: "link",
    },
  ];

  if (isPlaygroundEnabled) {
    links.splice(2, 0, {
      label: "Playground",
      href: "https://replicate.com/playground",
      type: "link",
      beta: true,
    });
  }

  if (isAuthenticated) {
    links.unshift({
      label: "Dashboard",
      href: route("home"),
      type: "cta",
    });
  } else {
    links.push({
      label: "Sign in",
      href: route("signin"),
      type: "cta",
    });
  }

  return (
    <DisclosureProvider>
      <Disclosure
        render={<Button size="sm" variant="outlined" startIcon={<List />} />}
      >
        Menu
      </Disclosure>
      <Portal portalElement={portalElement}>
        <DisclosureContent className="px-6 bg-r8-gray-1 border-b border-r8-gray-6">
          <nav className="flex flex-col divide-y divide-r8-gray-4 py-2">
            {links.map((link) => (
              <a
                className={
                  link.type === "cta"
                    ? "bg-r8-gray-12 text-r8-gray-1 px-2 py-2.5 text-center no-focus space-x-2 group"
                    : "block no-default no-focus px-2 py-2.5 no-underline text-r8-gray-11 hover:text-r8-gray-12 text-r8-sm space-x-2 group"
                }
                key={link.label}
                href={link.href}
              >
                <span>{link.label}</span>
                {link.beta ? (
                  <span className="text-r8-green-a10 border border-r8-green-a10 rounded-full px-2 py-0.5 text-xs group-focus:text-r8-gray-1 group-focus:border-r8-gray-1 group-focus:bg-r8-gray-12 group-hover:bg-r8-green-10 group-hover:border-r8-green-1 group-hover:text-r8-green-1">
                    Beta
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </DisclosureContent>
      </Portal>
    </DisclosureProvider>
  );
}
