import {
  Camera,
  CloudArrowUp,
  File as FileIcon,
  FilePlus,
  Trash,
  X,
} from "@phosphor-icons/react";
import {
  IconButton,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import * as Sentry from "@sentry/react";
import mime from "mime";
import React, { useEffect, useState } from "react";
import { useDropzone, type Accept } from "react-dropzone";
import { useController } from "react-hook-form";
import { P, match } from "ts-pattern";
import { Label } from "./label";
import { URLValue } from "./url-value";
import { isImageUrl } from "./util";
import { WebcamInputV2 } from "./webcam-input-v2";

type FileInputModality = "file" | "url" | "webcam";

interface FileInputProps {
  accept?: Accept;
  disabled: boolean;
  name: string;
  placeholder?: string;
  // "full" shows an actual preview of the file and large clear button
  // "simple" shows a URL to the file (or local blob) and small clear button
  previewType: "simple" | "full";
  required: boolean;
  type: "file";
}

function maybeGetImageFromClipboardEvent(
  event: React.ClipboardEvent<HTMLInputElement>
): File | null {
  const items = event.clipboardData.items;
  let image: File | null = null;
  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf("image") !== -1) {
      const file = items[i].getAsFile();
      if (file) {
        image = file;
        break;
      }
    }
  }

  return image;
}

export function FileInput({
  disabled,
  name,
  type,
  required,
  accept,
  placeholder = "Drop a file or click to upload",
  previewType = "simple",
}: FileInputProps) {
  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });
  const [defaultValue] = useState(field.value);
  const showWebcam = isImageUrl(defaultValue);
  const [modality, setModality] = useState<FileInputModality>(() => {
    return "url";
  });

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept,
    disabled: formState.isSubmitting || disabled,
    multiple: false,
    onDrop: (acceptedFiles) => {
      field.onChange(acceptedFiles[0]);
    },
    noClick: modality !== "file",
    onDragEnter: () => {
      setModality("file");
    },
    onDragLeave: () => {
      if (field.value) return;
      setModality("url");
    },
  });

  const eligibleForFullPreview =
    isImageUrl(field.value) || field.value instanceof File;

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const image = maybeGetImageFromClipboardEvent(e);
    if (image) {
      e.preventDefault();
      field.onChange(image);
      setModality("file");
    }
    // Otherwise, this is a non-image paste, so we don't need to do anything.
  };

  // This useEffect is used to reset the modality of the file picker back to the URL input
  // when switching to the "JSON" tab of the API Playground. This is necessary because the JSON tab
  // can't handle file inputs and immediately clears the value of this field. By resetting the modality
  // this ensures that if/when the users goes back to the "Form" tab, they can still see the URL input
  // and re-upload the file if necessary.
  useEffect(() => {
    if (modality === "webcam" && field.value === "") {
      setModality("url");
    }
  }, [field.value, modality]);

  return (
    <div
      data-dragging={isDragActive}
      className={
        "flex flex-col gap-2 group transition-shadow data-[dragging=true]:shadow-lg"
      }
      data-disabled={disabled}
    >
      <div className="flex items-center">
        <Label type={type} Icon={FileIcon} required={required} name={name} />
      </div>
      <div {...getRootProps({ className: "relative" })}>
        <div className="relative">
          {modality === "url" ? (
            <input
              value={field.value ?? ""}
              type="url"
              id={name}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value || null);
              }}
              disabled={formState.isSubmitting || disabled}
              onPaste={handlePaste}
              required={required}
              className="border py-2 pl-2 pr-16 border-r8-gray-12 bg-white dark:bg-r8-gray-1 w-full resize-none disabled:cursor-not-allowed disabled:opacity-50 truncate"
              placeholder={placeholder}
            />
          ) : null}
          {modality === "file" ? (
            <div
              data-dragging={isDragActive}
              className="border border-r8-gray-12 border-dashed p-4 bg-r8-gray-2"
            >
              <input type="file" {...getInputProps({ id: name })} />
              <div className="flex items-center justify-between">
                <p className="text-r8-sm text-r8-gray-11 flex items-center gap-2 select-none">
                  <FilePlus aria-hidden size={16} />
                  Drop a file or click to upload
                </p>
                <div className="flex-shrink-0">
                  <IconButton
                    size="sm"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      field.onChange(null);
                      setModality("url");
                    }}
                    variant="clear"
                  >
                    <X />
                  </IconButton>
                </div>
              </div>
              {previewType === "simple" && (
                <SimplePreview
                  onClear={() => {
                    field.onChange(null);
                    setModality("url");
                  }}
                  value={field.value}
                />
              )}
            </div>
          ) : null}
          {modality === "webcam" ? (
            <WebcamInputV2
              disabled={formState.isSubmitting || disabled}
              name={name}
              onCancel={() => {
                field.onChange(null);
                setModality("url");
              }}
              onChange={(file) => {
                field.onChange(file);
              }}
            />
          ) : null}
          {modality === "url" ? (
            <div className="absolute top-0 right-2 bottom-0 flex items-center gap-1.5">
              <TooltipProvider>
                <TooltipAnchor
                  render={
                    <IconButton
                      type="button"
                      disabled={formState.isSubmitting || disabled}
                      onClick={() => {
                        field.onChange(null);
                        setModality("file");
                      }}
                      size="sm"
                      variant="clear"
                    >
                      <span className="sr-only">
                        Upload a file from your machine
                      </span>
                      <CloudArrowUp weight="duotone" />
                    </IconButton>
                  }
                />
                <Tooltip className="w-50 text-center">
                  <TooltipArrow />
                  <p>Choose a file from your machine</p>
                  <p className="text-r8-xs">
                    Hint: you can also drag files onto the input
                  </p>
                </Tooltip>
              </TooltipProvider>
              {showWebcam ? (
                <TooltipProvider>
                  <TooltipAnchor
                    render={
                      <IconButton
                        disabled={formState.isSubmitting || disabled}
                        type="button"
                        onClick={() => {
                          setModality("webcam");
                        }}
                        size="sm"
                        variant="clear"
                      >
                        <span className="sr-only">
                          Take a photo with your webcam
                        </span>
                        <Camera weight="duotone" />
                      </IconButton>
                    }
                  />
                  <Tooltip>
                    <TooltipArrow />
                    Take a picture with your webcam
                  </Tooltip>
                </TooltipProvider>
              ) : null}
            </div>
          ) : null}
        </div>
        {previewType === "full" && eligibleForFullPreview && (
          <div className="px-3 pb-3 pt-1 border-r8-gray-12 border-l border-r border-b bg-r8-gray-a1">
            <FileInputPreview
              onClear={(e) => {
                e.stopPropagation();
                field.onChange(null);
              }}
              disabled={disabled}
              name={name}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function SimplePreview({
  value,
  onClear,
}: {
  value?: File | string;
  onClear: () => void;
}) {
  if (!value) return null;

  return (
    <div
      data-testid="simple-preview"
      className="flex items-center mt-1 overflow-hidden"
    >
      <div className="truncate min-w-0">
        {match(value)
          .with(P.string, (val) => {
            return <p className="text-r8-sm text-r8-gray-11 truncate">{val}</p>;
          })
          .with(P.instanceOf(File), (file) => {
            return (
              <p className="text-r8-sm text-r8-gray-11 truncate">{file.name}</p>
            );
          })
          .otherwise(() => null)}
      </div>
      <div className="flex-shrink-0 ml-2">
        <button
          className="w-6 h-6 rounded-full hover:bg-r8-gray-6 flex items-center justify-center"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        >
          <Trash size={16} />
        </button>
      </div>
    </div>
  );
}

function PreviewFallback({
  name,
  value,
  report,
}: {
  name: string;
  value: string;
  report: boolean;
}) {
  useEffect(() => {
    if (report) {
      Sentry.captureException(
        new Error("API Playground: Unhandled preview type"),
        {
          extra: {
            name,
            value: JSON.stringify(value),
          },
        }
      );
    }
  }, [name, report, value]);

  return (
    <div
      data-testid={`preview-${name}-fallback`}
      className="bg-r8-gray-2 p-2 text-r8-sm font-mono overflow-auto"
    >
      {value}
    </div>
  );
}

export const ValueFromFile = React.memo(
  ({
    file,
    name,
  }: {
    file: File;
    name: string;
  }) => {
    const [url, setUrl] = useState("");

    useEffect(() => {
      if (file instanceof File) {
        // file selected from computer
        setUrl(URL.createObjectURL(file));
      }
    }, [file]);

    return (
      <URLValue
        alwaysRenderAsDownload={false}
        mimeType={file?.type}
        name={name}
        schema={undefined}
        value={url}
        reportFallback
      />
    );
  }
);

export function FileInputPreview({
  name,
  disabled,
  onClear,
  showHeader = true,
}: {
  name: string;
  disabled?: boolean;
  onClear?: (e: React.MouseEvent) => void;
  showHeader?: boolean;
}) {
  const { field } = useController({ name });

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    field.onChange(null);
    if (onClear) {
      onClear(e);
    }
  };

  if (!field.value) return null;

  return (
    <div>
      {field.value && showHeader && (
        <div className="flex items-center justify-between my-3">
          <div className="flex items-center truncate">
            <span className="text-r8-xs uppercase tracking-wide text-r8-gray-11">
              Preview
            </span>
            {field.value instanceof File && (
              <span className="text-r8-xs uppercase tracking-wide text-r8-gray-11 ml-1 truncate">
                {" "}
                – {field.value.name}
              </span>
            )}
          </div>
          <div className="flex-shrink-0 ml-4">
            <button
              type="button"
              disabled={disabled}
              className="flex items-center gap-1 text-r8-xs hover:bg-r8-gray-2 rounded-md p-1 disabled:cursor-not-allowed"
              onClick={handleClear}
            >
              <span>Clear file</span>
            </button>
          </div>
        </div>
      )}
      {match(field.value)
        .with(P.instanceOf(File), (file) => {
          return <ValueFromFile file={file} name={field.name} />;
        })
        .with(P.string, (url) => {
          const mimeType = mime.getType(field.value);
          return (
            <URLValue
              alwaysRenderAsDownload={false}
              mimeType={mimeType}
              name={field.name}
              schema={undefined}
              value={url}
              fallbackComponent={null}
              reportFallback={false}
            />
          );
        })
        .otherwise(() => (
          <PreviewFallback
            value={JSON.stringify(field.value)}
            name={name}
            report
          />
        ))}
    </div>
  );
}
