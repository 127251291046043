import { Star } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useState } from "react";
import toast from "react-hot-toast";
import { match } from "ts-pattern";
import { trackEvent } from "../analytics";
import type { Model } from "../types";
import { route } from "../urls";

export default function StarModel({
  model,
  initialIsStarred,
  display = "button",
}: {
  model: Model;
  initialIsStarred: boolean;
  display?: "list-item" | "button";
}) {
  const [isStarred, setIsStarred] = useState(initialIsStarred);

  const { mutate, status } = useMutation({
    mutationFn: starModel,
    onSuccess: () => {
      setIsStarred(!isStarred);
    },
    onError: (e) => {
      let errorMessage = "Starring model failed";
      if (e instanceof Error) {
        errorMessage += `: ${e.message}`;
      }
      toast.error(errorMessage);
    },
  });

  const starIcon = <Star size={16} />;
  const filledStarIcon = <Star size={16} weight="fill" />;
  const currentIcon = isStarred ? filledStarIcon : starIcon;
  const nextIcon = isStarred ? starIcon : filledStarIcon;
  const currentText = isStarred ? "Starred" : "Star";
  const nextText = isStarred ? "Star" : "Starred";

  const buttonIcon = match(status)
    .with("idle", () => currentIcon)
    .with("pending", () => nextIcon)
    .with("success", () => currentIcon)
    .with("error", () => currentIcon)
    .exhaustive();

  const buttonText = match(status)
    .with("idle", () => currentText)
    .with("pending", () => nextText)
    .with("success", () => currentText)
    .with("error", () => currentText)
    .exhaustive();

  if (display === "list-item") {
    return (
      <button
        type="button"
        onClick={() => {
          mutate({
            model,
            isStarred,
          });
        }}
        className="model-meta-list--item w-full text-left hover:bg-r8-gray-2 p-2 -m-1.5"
      >
        <dt>{buttonIcon}</dt>
        <dd>{buttonText}</dd>
      </button>
    );
  }

  return (
    <Button
      variant="outlined"
      className="w-full"
      onClick={() => {
        mutate({
          model,
          isStarred,
        });
      }}
      startIcon={buttonIcon}
    >
      <span>{buttonText}</span>
    </Button>
  );
}

export async function starModel({
  model,
  isStarred,
}: { model: Model; isStarred: boolean }) {
  const endpoint = isStarred ? "api_unstar_model" : "api_star_model";
  const res = await fetch(
    route(endpoint, {
      username: model.owner,
      name: model.name,
    }),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    }
  );

  if (res.ok) {
    trackEvent(`btn_${isStarred ? "unstar" : "star"}_model`, {
      model: `${model.owner}/${model.name}`,
    });
    return res.json();
  }

  throw new Error("Failed to star model");
}
