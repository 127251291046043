import { WarningOctagon } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { trackEvent } from "../../analytics";
import type { Prediction } from "../../types";
import { route } from "../../urls";
import { usePlaygroundContext } from "./context";

export function ReportPrediction({
  prediction,
}: {
  prediction: Prediction;
}) {
  const { version } = usePlaygroundContext();
  const trackClickHandler = () => {
    trackEvent("btn_report_prediction", {
      model: `${version._extras.model.owner}/${version._extras.model.name}`,
      prediction: prediction.id,
    });
  };
  const reportPredictionPath = route("report_prediction_output", {
    prediction_uuid: prediction.id,
  });

  return (
    <Button
      render={
        // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
        <a href={reportPredictionPath} />
      }
      variant="outlined"
      startIcon={<WarningOctagon aria-hidden size={16} />}
      onClick={trackClickHandler}
    >
      <span>Report</span>
    </Button>
  );
}
