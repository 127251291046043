import { getStaticAssetPath } from "../util";

export default function GlyphLoader({
  screenReaderLabel,
}: { screenReaderLabel?: string }) {
  return (
    <div
      role="status"
      className="relative filter invert dark:invert-0 dark:opacity-100 opacity-70"
    >
      {screenReaderLabel ? (
        <span className="sr-only">{screenReaderLabel}</span>
      ) : null}
      <img
        className="w-4"
        src={getStaticAssetPath("/static/glyph-loader.gif")}
      />
    </div>
  );
}
