import { Suspense, lazy } from "react";
import type { SetupRun } from "../../types";
const SetupRunLogsComponent = lazy(() => import("./component"));

export default function SetupRunLogs({ runs }: { runs: SetupRun[] }) {
  return (
    <Suspense
      fallback={
        <div className="text-r8-sm text-r8-gray-11">Loading setup runs...</div>
      }
    >
      {runs?.length ? (
        <SetupRunLogsComponent runs={runs} />
      ) : (
        <div className="text-r8-gray-12">No setup runs found.</div>
      )}
    </Suspense>
  );
}
