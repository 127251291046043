import * as Sentry from "@sentry/react";
import { P, match } from "ts-pattern";
import type {
  AnyAnyOfCogPropertyItemSchema,
  AnyArrayCogPropertyItemSchema,
  BasicStringCogPropertyItemSchema,
  BooleanCogPropertyItemSchema,
  CogInputPropertyItemSchema,
  CogInputPropertyValue,
  CogOutputItemSchema,
  CogOutputValue,
  NumericCogPropertyItemSchema,
  ObjectCogPropertyItemSchema,
  SecretCogPropertyItemSchema,
  URLCogPropertyItemSchema,
  UnknownCogPropertyItemSchema,
} from "../../types";
import { BooleanValue } from "./boolean-value";
import { FallbackValue } from "./fallback-value";
import { NullValue } from "./null-value";
import { NumericValue } from "./numeric-value";
import { PredictionDefaultOutput } from "./prediction-output";
import { SecretValue } from "./secret-value";
import { StringValue } from "./string-value";
import { URLValue } from "./url-value";

export function PropertyValue({
  alwaysRenderURLAsDownload,
  name,
  reportFallback,
  schema,
  value,
  shouldAutoScroll = false,
}: {
  alwaysRenderURLAsDownload: boolean;
  name: string;
  reportFallback: boolean;
  schema: CogInputPropertyItemSchema | CogOutputItemSchema | undefined;
  value: CogInputPropertyValue | CogOutputValue;
  shouldAutoScroll?: boolean;
}) {
  if (!schema) {
    if (value == null) {
      return <NullValue name={name} message="No output" />;
    }

    return (
      <FallbackValue
        name={name}
        schema={schema}
        value={value}
        report={reportFallback}
      />
    );
  }

  try {
    return match(schema)
      .with(
        {
          type: P.union("number", "integer"),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies NumericCogPropertyItemSchema;

          return (
            <NumericValue
              name={name}
              schema={matchedSchema}
              value={value}
              reportFallback={reportFallback}
            />
          );
        }
      )
      .with(
        {
          type: "string",
          format: "password",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies SecretCogPropertyItemSchema;

          return (
            <SecretValue
              name={name}
              schema={matchedSchema}
              value={value}
              reportFallback={reportFallback}
            />
          );
        }
      )
      .with(
        {
          type: "string",
          format: "uri",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies URLCogPropertyItemSchema;

          return (
            <URLValue
              alwaysRenderAsDownload={alwaysRenderURLAsDownload}
              name={name}
              schema={matchedSchema}
              value={value}
              reportFallback={reportFallback}
            />
          );
        }
      )
      .with(
        {
          type: "string",
          format: P.nullish.optional(),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies BasicStringCogPropertyItemSchema;

          return (
            <StringValue
              shouldAutoScroll={shouldAutoScroll}
              name={name}
              schema={matchedSchema}
              value={value}
              reportFallback={reportFallback}
            />
          );
        }
      )
      .with(
        {
          type: "boolean",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies BooleanCogPropertyItemSchema;

          return (
            <BooleanValue
              name={name}
              schema={matchedSchema}
              value={value}
              reportFallback={reportFallback}
            />
          );
        }
      )
      .with(
        {
          type: "array",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies AnyArrayCogPropertyItemSchema;

          // TODO: This is a component used for inputs as well as outputs, so
          // should use a generic component for rendering them.
          return (
            <PredictionDefaultOutput
              alwaysRenderURLsAsDownload={alwaysRenderURLAsDownload}
              output={value}
              reportFallback={reportFallback}
              schema={matchedSchema}
              shouldAutoScroll={shouldAutoScroll}
            />
          );
        }
      )
      .with(
        {
          type: "object",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies ObjectCogPropertyItemSchema;

          return (
            <FallbackValue
              name={name}
              schema={matchedSchema}
              value={value}
              report={reportFallback}
            />
          );
        }
      )
      .with(
        {
          anyOf: P.array(P.any),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies AnyAnyOfCogPropertyItemSchema;

          return (
            <FallbackValue
              name={name}
              schema={matchedSchema}
              value={value}
              report={reportFallback}
            />
          );
        }
      )
      .with(
        {
          type: P.nullish.optional(),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies UnknownCogPropertyItemSchema;

          return (
            <FallbackValue
              name={name}
              schema={matchedSchema}
              value={value}
              report={false}
            />
          );
        }
      )
      .exhaustive();
  } catch (err) {
    if (reportFallback) {
      Sentry.captureException(err, {
        extra: {
          name,
          schema: JSON.stringify(schema),
          value: JSON.stringify(value),
        },
      });
    }
    return (
      <FallbackValue name={name} schema={schema} value={value} report={false} />
    );
  }
}
