import ScrollToBottom from "react-scroll-to-bottom";
import type { CogOutputValue, StringCogPropertyItemSchema } from "../../types";
import { ConditionalWrap } from "../conditional-wrap";
import { FallbackValue } from "./fallback-value";

export function StringValue({
  name,
  reportFallback,
  schema,
  value,
  shouldAutoScroll = false,
}: {
  name?: string;
  reportFallback: boolean;
  schema: StringCogPropertyItemSchema | undefined;
  value: CogOutputValue;
  shouldAutoScroll?: boolean;
}) {
  if (typeof value !== "string") {
    return (
      <FallbackValue
        name={name}
        schema={schema}
        value={value}
        report={reportFallback}
      />
    );
  }

  return (
    <ConditionalWrap
      condition={shouldAutoScroll}
      wrap={(children) => {
        return (
          <ScrollToBottom className="bg-r8-gray-3">{children}</ScrollToBottom>
        );
      }}
    >
      <div
        data-testid={name ? `value-${name}-string` : "value-string"}
        className={`output p-2 whitespace-pre-wrap bg-r8-gray-3 text-r8-gray-12 font-mono text-r8-sm ${
          shouldAutoScroll ? "max-h-96" : "overflow-y-auto max-h-96"
        }`}
        dir="auto"
        translate="no"
      >
        {value || <span>&nbsp;</span>}
      </div>
    </ConditionalWrap>
  );
}
