import TextTooltip from "./text-tooltip";

export default function PriceTooltip({
  hardware,
  price,
  p50price,
}: {
  hardware: string;
  price: string;
  p50price: string;
}) {
  return (
    <TextTooltip
      anchorText={`~${p50price}`}
      tooltipClass="w-72 z-10"
      tooltipText={
        <div className="space-y-2 text-r8-sm">
          <p>
            This model costs approximately{" "}
            <span className="font-semibold">{p50price}</span> to run on
            Replicate, but this varies depending on your inputs.
          </p>
          <p>
            This model runs on {hardware} hardware which costs{" "}
            <a href="https://replicate.com/docs/billing">
              <span className="font-semibold">{price}</span>
            </a>
            .
          </p>
        </div>
      }
    />
  );
}
