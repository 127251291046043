import { Info } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import type { CogOutputValue, SecretCogPropertyItemSchema } from "../../types";
import { FallbackValue } from "./fallback-value";

export function SecretValue({
  name,
  reportFallback,
  schema,
  value,
  shouldAutoScroll = false,
}: {
  name?: string;
  reportFallback: boolean;
  schema: SecretCogPropertyItemSchema | undefined;
  value: CogOutputValue;
  shouldAutoScroll?: boolean;
}) {
  if (typeof value !== "string") {
    return (
      <FallbackValue
        name={name}
        schema={schema}
        value={value}
        report={reportFallback}
      />
    );
  }

  const redaction = "\u2588".repeat(20); // █ FULL BLOCK (U+2588)

  return (
    <>
      <div
        data-testid={name ? `value-${name}-secret` : "value-secret"}
        className={`output p-2 whitespace-pre-wrap bg-r8-gray-3 text-r8-gray-12 font-mono text-r8-sm mb-05lh ${
          shouldAutoScroll ? "max-h-96" : "overflow-y-auto max-h-96"
        }`}
        dir="auto"
        translate="no"
      >
        <span
          title="[REDACTED]"
          role="img"
          aria-label="Redacted text"
          className="inline-block break-all break-words overflow-clip tracking-tighter"
        >
          {redaction}
        </span>
      </div>
      <Banner
        icon={<Info />}
        condensed
        description={
          <p>This value was redacted after being sent to the model.</p>
        }
      />
    </>
  );
}
