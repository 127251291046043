import { CaretDown, XCircle } from "@phosphor-icons/react";
import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
  FormGroup,
  IconButton,
  Select,
  SelectItem,
  SelectPopover,
  SelectProvider,
  Spinner,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { matchSorter } from "match-sorter";
import { type ReactNode, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useImmer } from "use-immer";

interface VercelEnvConfiguration {
  type: string;
  value: string;
  configurationId: null;
  id: string;
  key: string;
  createdAt: number;
  updatedAt: number;
  createdBy: string;
  updatedBy: null | string;
}

interface VercelProject {
  accountId: string;
  createdAt: number;
  env: VercelEnvConfiguration[];
  framework: string;
  id: string;
  name: string;
  updatedAt: number;
}

interface VercelProjectPickerProps {
  projects: Array<VercelProject>;
  configuredProjects: Array<VercelProject>;
  unconfigurableProjects: Array<VercelProject>;
  vercelConfigurationId: string;
  principalUsername: string;
}

async function addEnvironmentVariable({
  projectId,
  vercelConfigurationId,
  principalUsername,
}: {
  projectId: string;
  vercelConfigurationId: string;
  principalUsername: string;
}) {
  const headers = new Headers();
  headers.set("X-CSRFToken", Cookies.get("csrftoken") as string);
  headers.set("Content-Type", "application/x-www-form-urlencoded");

  const formData = new URLSearchParams();
  formData.append("vercelConfigurationId", vercelConfigurationId);
  formData.append("principal_username", principalUsername);

  const resp = await fetch(`/vercel-integration/project/${projectId}/add`, {
    method: "POST",
    credentials: "include",
    body: formData,
    headers,
  });

  if (resp.status !== 204) {
    throw new Error("Failed to add Vercel project.");
  }

  return;
}

async function removeEnvironmentVariable({
  projectId,
  vercelConfigurationId,
  principalUsername,
}: {
  projectId: string;
  vercelConfigurationId: string;
  principalUsername: string;
}) {
  const headers = new Headers();
  headers.set("X-CSRFToken", Cookies.get("csrftoken") as string);
  headers.set("Content-Type", "application/x-www-form-urlencoded");

  const formData = new URLSearchParams();
  formData.append("vercelConfigurationId", vercelConfigurationId);
  formData.append("principal_username", principalUsername);

  const resp = await fetch(`/vercel-integration/project/${projectId}/remove`, {
    method: "POST",
    credentials: "include",
    body: formData,
    headers,
  });

  if (resp.status !== 204) {
    throw new Error("Failed to remove Vercel project.");
  }

  return;
}

export default function VercelProjectPicker(
  props: VercelProjectPickerProps
): ReactNode {
  return (
    <>
      <VercelProjectPickerInner {...props} />
      <Toaster />
    </>
  );
}

export function VercelProjectPickerInner({
  projects = [],
  configuredProjects = [],
  unconfigurableProjects = [],
  vercelConfigurationId,
  principalUsername,
}: VercelProjectPickerProps) {
  const [filterValue, setFilterValue] = useState("");

  const [localSelectedProjects, setLocalSelectedProjects] =
    useImmer(configuredProjects);

  const selectableProjects = projects.filter(
    (project) => !localSelectedProjects.find((p) => p.id === project.id)
  );

  const filteredSelectableProjects = matchSorter(
    selectableProjects,
    filterValue,
    { keys: ["name"] }
  );

  const addEnvVarMutation = useMutation({ mutationFn: addEnvironmentVariable });
  const removeEnvVarMutation = useMutation({
    mutationFn: removeEnvironmentVariable,
  });

  const handleRemoveProject = async (projectId: string) => {
    await toast.promise(
      removeEnvVarMutation.mutateAsync({
        projectId,
        vercelConfigurationId,
        principalUsername,
      }),
      {
        loading: "Removing Vercel project...",
        success: () => "Successfully removed Vercel project",
        error: () => "Failed to remove Vercel project",
      }
    );

    setLocalSelectedProjects((draft) => {
      const projectIndex = draft.findIndex((p) => p.id === projectId);
      if (projectIndex === -1) return;
      draft.splice(projectIndex, 1);
    });
  };

  const handleAddProject = async (projectId: string) => {
    await toast.promise(
      addEnvVarMutation.mutateAsync({
        projectId,
        vercelConfigurationId,
        principalUsername,
      }),
      {
        loading: "Adding Vercel project...",
        success: () => "Successfully added Vercel project",
        error: () => "Failed to add Vercel project",
      }
    );

    setLocalSelectedProjects((draft) => {
      const matchingProject = projects.find(
        (project) => project.id === projectId
      );
      if (!matchingProject) return;
      draft.push(matchingProject);
    });
  };

  return (
    <div>
      <ComboboxProvider
        value={filterValue}
        setValue={(value) => {
          setFilterValue(value);
        }}
        resetValueOnHide
      >
        <SelectProvider
          defaultValue={""}
          setValue={(projectId) => {
            handleAddProject(projectId as string);
          }}
        >
          <FormGroup id="add-project">
            <FormGroup.Control
              endAdornment={<CaretDown />}
              endAdornmentOptions={{
                absolute: true,
                inert: true,
              }}
              input={
                <Select
                  disabled={
                    addEnvVarMutation.isPending ||
                    removeEnvVarMutation.isPending
                  }
                >
                  Add a Vercel project
                </Select>
              }
            />
          </FormGroup>
          <SelectPopover
            className="flex flex-col max-h-64"
            sameWidth
            gutter={8}
          >
            <FormGroup size="sm" id="search-vercel-projects">
              <FormGroup.Control
                input={
                  <Combobox
                    autoSelect
                    placeholder="Search for Vercel projects by name"
                  />
                }
              />
            </FormGroup>
            <ComboboxList className="flex-1 h-full overflow-auto">
              {filteredSelectableProjects.length > 0 ? (
                filteredSelectableProjects.map((project) => (
                  <SelectItem
                    key={project.id}
                    value={project.id}
                    render={<ComboboxItem />}
                    disabled={unconfigurableProjects.some(
                      (unconfigurableProject) =>
                        unconfigurableProject.id === project.id
                    )}
                  >
                    <span className="flex-1">{project.name}</span>
                    {unconfigurableProjects.some(
                      (unconfigurableProject) =>
                        unconfigurableProject.id === project.id
                    ) && (
                      <div className="text-r8-gray-11 text-r8-sm ml-auto">
                        (REPLICATE_API_TOKEN already set by user)
                      </div>
                    )}
                  </SelectItem>
                ))
              ) : (
                <div className="py-4 text-center text-r8-sm text-r8-gray-11">
                  No projects found. Please make sure you have at least one
                  project
                </div>
              )}
            </ComboboxList>
          </SelectPopover>
        </SelectProvider>
      </ComboboxProvider>
      {localSelectedProjects.length > 0 && (
        <ul className="divide-y divide-r8-gray-6 mt-2">
          {localSelectedProjects.map((project) => {
            const isDeleting =
              removeEnvVarMutation.variables?.projectId === project.id &&
              removeEnvVarMutation.isPending;

            return (
              <li
                key={project.id}
                className="flex items-center justify-between py-2.5"
              >
                <div
                  className={`text-r8-gray-12 text-r8-sm ${
                    isDeleting ? "animate-pulse" : ""
                  }`}
                >
                  {project.name}
                </div>
                <IconButton
                  type="button"
                  aria-label="Remove project"
                  size="sm"
                  variant="clear"
                  intent="danger"
                  disabled={isDeleting}
                  onClick={() => handleRemoveProject(project.id)}
                >
                  {isDeleting ? (
                    <Spinner />
                  ) : (
                    <XCircle size={24} weight="bold" />
                  )}
                </IconButton>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
