import * as Ariakit from "@ariakit/react";
import mime from "mime";
import { useEffect, useRef } from "react";
import { match } from "ts-pattern";
import { getOutputSchema } from "../../schema";
import type { CogOutputSchema, Prediction, Version } from "../../types";

function MiniPredictionOutput({
  output,
  schema,
  isActive,
}: {
  output: any;
  schema: CogOutputSchema;
  isActive?: boolean;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    if (isActive) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }

    return () => {
      videoRef.current?.pause();
    };
  }, [isActive]);

  return match(schema)
    .with(
      { type: "string", format: "uri" },
      {
        type: "array",
        items: {
          type: "string",
          format: "uri",
        },
      },
      () => {
        // let's get the first image if an array, otherwise just use the string which is the uri.
        const imageHref = Array.isArray(output) ? output[0] : output;
        const mimeType = mime.getType(imageHref);

        if (mimeType?.includes("image")) {
          return (
            <img
              src={imageHref}
              className="object-cover w-full h-full"
              alt="Prediction output"
            />
          );
        }

        if (mimeType?.includes("video")) {
          return (
            <video
              ref={videoRef}
              src={imageHref}
              className="object-cover w-full h-full"
              loop
              muted
            />
          );
        }

        return null;
      }
    )
    .with(
      {
        type: "array",
        items: {
          type: "string",
        },
      },
      () => {
        const joinedOutput = (output as string[]).join("");
        return (
          <div className="w-full h-full relative">
            <div
              aria-hidden="true"
              className="absolute bottom-0 w-full h-1/3 bg-gradient-to-t from-r8-gray-4 pointer-events-none"
            />
            <div className="w-full h-full overflow-hidden p-4 text-r8-sm font-mono bg-r8-gray-2 leading-6">
              {joinedOutput}
            </div>
          </div>
        );
      }
    )
    .otherwise(() => null);
}

export function PredictionCards({
  predictions,
  activeId,
  onChange,
  versions,
}: {
  predictions: Prediction[];
  activeId: string;
  onChange: (id: string) => void;
  versions: Version[];
}) {
  return (
    <Ariakit.TabProvider
      activeId={activeId}
      setActiveId={(val) => {
        if (val) {
          onChange(val);
        }
      }}
    >
      <Ariakit.TabList className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 !ml-0 !pl-0 gap-6">
        {predictions.map((p) => {
          const predictionVersion = versions.find(
            (v) => v.id === p.version
          ) as Version;
          const outputSchema = getOutputSchema(predictionVersion);

          return (
            <Ariakit.Tab
              id={p.id}
              key={p.id}
              className="border border-black divide-y divide-black flex flex-col w-full text-left transition-opacity opacity-50 aria-selected:opacity-100 hover:opacity-75"
            >
              <div className="aspect-square flex-shrink-0 relative overflow-hidden w-full">
                <MiniPredictionOutput
                  isActive={p.id === activeId}
                  output={p.output}
                  schema={outputSchema}
                />
              </div>
              <div className="p-3 flex-1 h-full w-full">
                <p className="font-semibold text-r8-base leading-5">
                  {predictionVersion._extras.model.owner}/
                  {predictionVersion._extras.model.name}
                </p>
                <p className="font-mono text-r8-gray-11 text-r8-xs">{p.id}</p>
              </div>
            </Ariakit.Tab>
          );
        })}
      </Ariakit.TabList>
    </Ariakit.TabProvider>
  );
}
