/** !!! GENERATED FILE: See script/generate-client-routes for details !!! */

/** Map of route name to metadata  */
export type RouteMeta = {
  about: { path: "/about"; args: [] };
  account_api_token_settings: { path: "/account/api-tokens"; args: [] };
  account_billing_settings: { path: "/account/billing"; args: [] };
  account_detail: {
    path: "/<slug:username>";
    args: [params: { username: string }];
  };
  account_email_settings: { path: "/account/email"; args: [] };
  account_members_settings: { path: "/account/members"; args: [] };
  account_profile_settings: { path: "/account/profile"; args: [] };
  account_settings: { path: "/account"; args: [] };
  account_setup_shared_slack_channel: {
    path: "/account/slack-integration/connect";
    args: [];
  };
  account_vercel_settings: { path: "/account/vercel-integration"; args: [] };
  api_clear_needs_informing_about_end_of_subscriptions: {
    path: "/api/users/<slug:username>/clear-needs-informing-about-end-of-subscriptions";
    args: [params: { username: string }];
  };
  api_create_support_case: { path: "/api/create-support-case"; args: [] };
  api_deployment_create: { path: "/api/deployments"; args: [] };
  api_deployment_edit_or_delete: {
    path: "/api/deployments/<slug:username>/<slug:name>";
    args: [params: { username: string; name: string }];
  };
  api_deployment_instance_time_metrics: {
    path: "/api/deployments/<slug:username>/<slug:name>/metrics/instance-time";
    args: [params: { username: string; name: string }];
  };
  api_deployment_instance_time_metrics_with_period: {
    path: "/api/deployments/<slug:username>/<slug:name>/metrics/instance-time/<str:period>";
    args: [params: { username: string; name: string; period: string }];
  };
  api_deployment_prediction_create: {
    path: "/api/deployments/<slug:username>/<slug:name>/predictions";
    args: [params: { username: string; name: string }];
  };
  api_dismiss_account_rename: {
    path: "/api/users/<slug:username>/dismiss-rename";
    args: [params: { username: string }];
  };
  api_example_create: {
    path: "/api/models/<slug:username>/<str:name>/examples";
    args: [params: { username: string; name: string }];
  };
  api_model_delete: {
    path: "/api/models/<slug:username>/<str:name>";
    args: [params: { username: string; name: string }];
  };
  api_model_display_settings_update: {
    path: "/api/models/<slug:username>/<str:name>/display-settings";
    args: [params: { username: string; name: string }];
  };
  api_model_training_display_settings_update: {
    path: "/api/models/<slug:username>/<str:name>/training-display-settings";
    args: [params: { username: string; name: string }];
  };
  api_model_versions: {
    path: "/api/models/<slug:username>/<str:name>/versions";
    args: [params: { username: string; name: string }];
  };
  api_official_model_prediction_create: {
    path: "/api/models/<slug:username>/<str:name>/predictions";
    args: [params: { username: string; name: string }];
  };
  api_organization_create_token: {
    path: "/api/organizations/<slug:organization_name>/tokens";
    args: [params: { organization_name: string }];
  };
  api_organization_disable_token: {
    path: "/api/organizations/<slug:organization_name>/tokens/<str:token_hashid>/disable";
    args: [params: { organization_name: string; token_hashid: string }];
  };
  api_organization_get_invoice: {
    path: "/api/organizations/<slug:organization_name>/invoices/<slug:invoice_id>";
    args: [params: { organization_name: string; invoice_id: string }];
  };
  api_organization_list_invoices: {
    path: "/api/organizations/<slug:organization_name>/invoices";
    args: [params: { organization_name: string }];
  };
  api_organization_list_payments: {
    path: "/api/organizations/<slug:organization_name>/payments";
    args: [params: { organization_name: string }];
  };
  api_prediction_delete: {
    path: "/api/predictions/<slug:prediction_uuid>/delete";
    args: [params: { prediction_uuid: string }];
  };
  api_prediction_detail: {
    path: "/api/predictions/<slug:prediction_uuid>";
    args: [params: { prediction_uuid: string }];
  };
  api_prediction_share: {
    path: "/api/predictions/<str:prediction_uuid>/share";
    args: [params: { prediction_uuid: string }];
  };
  api_prediction_stop: {
    path: "/api/predictions/<slug:prediction_uuid>/stop";
    args: [params: { prediction_uuid: string }];
  };
  api_rename_account: {
    path: "/api/users/<slug:username>/rename";
    args: [params: { username: string }];
  };
  api_search: { path: "/api/search"; args: [] };
  api_search_deployment_model_options: {
    path: "/api/deployments/search-model-options";
    args: [];
  };
  api_set_principal: { path: "/api/principal"; args: [] };
  api_star_model: {
    path: "/api/models/<slug:username>/<str:name>/star";
    args: [params: { username: string; name: string }];
  };
  api_theme: { path: "/api/theme"; args: [] };
  api_unstar_model: {
    path: "/api/models/<slug:username>/<str:name>/unstar";
    args: [params: { username: string; name: string }];
  };
  api_user_create_token: {
    path: "/api/users/<slug:username>/tokens";
    args: [params: { username: string }];
  };
  api_user_disable_token: {
    path: "/api/users/<slug:username>/tokens/<str:token_hashid>/disable";
    args: [params: { username: string; token_hashid: string }];
  };
  api_user_get_invoice: {
    path: "/api/users/<slug:username>/invoices/<slug:invoice_id>";
    args: [params: { username: string; invoice_id: string }];
  };
  api_user_list_invoices: {
    path: "/api/users/<slug:username>/invoices";
    args: [params: { username: string }];
  };
  api_user_list_payments: {
    path: "/api/users/<slug:username>/payments";
    args: [params: { username: string }];
  };
  api_version_capabilities: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/capabilities";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_version_delete: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/delete";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_version_docker_image: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/docker-image";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_version_input_hints: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/input-hints";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_version_prediction_create: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/predictions";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_version_prediction_price: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/prediction-price";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_version_training_create: {
    path: "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/trainings";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  api_webhook_signing_key: { path: "/api/webhooks/default/secret"; args: [] };
  blog_detail: { path: "/blog/<str:slug>"; args: [params: { slug: string }] };
  blog_index: { path: "/blog"; args: [] };
  changelog_detail: {
    path: "/changelog/<slug:slug>";
    args: [params: { slug: string }];
  };
  changelog_list: { path: "/changelog"; args: [] };
  changelog_preview: {
    path: "/changelog/<str:id>/preview";
    args: [params: { id: string }];
  };
  collection_detail: {
    path: "/collections/<str:slug>";
    args: [params: { slug: string }];
  };
  collection_list: { path: "/collections"; args: [] };
  deployment_api_reference: {
    path: "/deployments/<slug:username>/<slug:name>/api";
    args: [params: { username: string; name: string }];
  };
  deployment_create: { path: "/deployments/create"; args: [] };
  deployment_detail: {
    path: "/deployments/<slug:username>/<slug:name>";
    args: [params: { username: string; name: string }];
  };
  deployment_list: { path: "/deployments"; args: [] };
  deployment_prediction_list: {
    path: "/deployments/<slug:username>/<slug:name>/predictions";
    args: [params: { username: string; name: string }];
  };
  deployment_settings: {
    path: "/deployments/<slug:username>/<slug:name>/settings";
    args: [params: { username: string; name: string }];
  };
  deployment_setup_logs: {
    path: "/deployments/<slug:username>/<slug:name>/setup-logs";
    args: [params: { username: string; name: string }];
  };
  docs_arxiv: { path: "/docs/arxiv"; args: [] };
  docs_arxiv_about: { path: "/docs/arxiv/about"; args: [] };
  example_delete: {
    path: "/<slug:username>/<str:name>/examples/<str:prediction_uuid>/delete";
    args: [params: { username: string; name: string; prediction_uuid: string }];
  };
  example_list: {
    path: "/<slug:username>/<str:name>/examples";
    args: [params: { username: string; name: string }];
  };
  example_set_default: {
    path: "/<slug:username>/<str:name>/examples/<str:prediction_uuid>/set-default";
    args: [params: { username: string; name: string; prediction_uuid: string }];
  };
  explore: { path: "/explore"; args: [] };
  file_upload: {
    path: "/api/upload/<str:filename>";
    args: [params: { filename: string }];
  };
  home: { path: "/"; args: [] };
  invite_accept: {
    path: "/invites/<str:pk>/accept";
    args: [params: { pk: string }];
  };
  invite_detail: { path: "/invites/<str:pk>"; args: [params: { pk: string }] };
  join: { path: "/join"; args: [] };
  model_api_reference: {
    path: "/<slug:username>/<str:name>/api";
    args: [params: { username: string; name: string }];
  };
  model_badge: {
    path: "/<slug:username>/<str:name>/badge";
    args: [params: { username: string; name: string }];
  };
  model_create: { path: "/create"; args: [] };
  model_deploy: {
    path: "/<slug:username>/<str:name>/deploy";
    args: [params: { username: string; name: string }];
  };
  model_detail: {
    path: "/<slug:username>/<str:name>";
    args: [params: { username: string; name: string }];
  };
  model_list: { path: "/models"; args: [] };
  model_status: {
    path: "/<slug:username>/<str:name>/status";
    args: [params: { username: string; name: string }];
  };
  model_train: {
    path: "/<slug:username>/<str:name>/train";
    args: [params: { username: string; name: string }];
  };
  model_update: {
    path: "/<slug:username>/<str:name>/edit";
    args: [params: { username: string; name: string }];
  };
  newsletter_detail: {
    path: "/newsletter/<str:slug>";
    args: [params: { slug: string }];
  };
  newsletter_index: { path: "/newsletter"; args: [] };
  newsletter_signup: { path: "/newsletter/signup"; args: [] };
  organization_billing_canceled: {
    path: "/orgs/<slug:organization_name>/settings/billing/canceled";
    args: [params: { organization_name: string }];
  };
  organization_billing_edit: {
    path: "/orgs/<slug:organization_name>/settings/billing/edit";
    args: [params: { organization_name: string }];
  };
  organization_billing_new: {
    path: "/orgs/<slug:organization_name>/settings/billing/new";
    args: [params: { organization_name: string }];
  };
  organization_billing_succeeded: {
    path: "/orgs/<slug:organization_name>/settings/billing/succeeded";
    args: [params: { organization_name: string }];
  };
  organization_connect: {
    path: "/orgs/connect/<slug:organization_name>";
    args: [params: { organization_name: string }];
  };
  organization_create_invite: {
    path: "/orgs/<slug:organization_name>/settings/members/invite/new";
    args: [params: { organization_name: string }];
  };
  organization_create_or_join: { path: "/orgs/join"; args: [] };
  organization_email_invite: {
    path: "/orgs/<slug:organization_name>/settings/members/invite/email";
    args: [params: { organization_name: string }];
  };
  organization_invoice_detail: {
    path: "/orgs/<slug:organization_name>/invoices/<slug:invoice_id>";
    args: [params: { organization_name: string; invoice_id: string }];
  };
  organization_remove_member: {
    path: "/orgs/<slug:organization_name>/settings/members/<slug:member_username>/delete";
    args: [params: { organization_name: string; member_username: string }];
  };
  organization_set_email: {
    path: "/orgs/<slug:organization_name>/settings/set-email";
    args: [params: { organization_name: string }];
  };
  organization_set_spend_limit: {
    path: "/orgs/<slug:organization_name>/settings/set-spend-limit";
    args: [params: { organization_name: string }];
  };
  prediction_detail: {
    path: "/p/<str:prediction_uuid>";
    args: [params: { prediction_uuid: string }];
  };
  prediction_list: { path: "/predictions"; args: [] };
  pricing: { path: "/pricing"; args: [] };
  privacy: { path: "/privacy"; args: [] };
  report_prediction_output: {
    path: "/p/<str:prediction_uuid>/report";
    args: [params: { prediction_uuid: string }];
  };
  search: { path: "/search"; args: [] };
  showcase: { path: "/showcase"; args: [] };
  signin: { path: "/signin"; args: [] };
  signout: { path: "/signout"; args: [] };
  social_begin: { path: "/login/github/"; args: [] };
  star_list: {
    path: "/<slug:username>/stars";
    args: [params: { username: string }];
  };
  support: { path: "/support"; args: [] };
  terms: { path: "/terms"; args: [] };
  training_list: { path: "/trainings"; args: [] };
  user_billing_canceled: {
    path: "/users/<slug:username>/settings/billing/canceled";
    args: [params: { username: string }];
  };
  user_billing_edit: {
    path: "/users/<slug:username>/settings/billing/edit";
    args: [params: { username: string }];
  };
  user_billing_new: {
    path: "/users/<slug:username>/settings/billing/new";
    args: [params: { username: string }];
  };
  user_billing_succeeded: {
    path: "/users/<slug:username>/settings/billing/succeeded";
    args: [params: { username: string }];
  };
  user_invoice_detail: {
    path: "/users/<slug:username>/invoices/<slug:invoice_id>";
    args: [params: { username: string; invoice_id: string }];
  };
  user_set_email: {
    path: "/users/<slug:username>/settings/set-email";
    args: [params: { username: string }];
  };
  user_set_provenance: {
    path: "/users/<slug:username>/settings/set-provenance";
    args: [params: { username: string }];
  };
  user_set_spend_limit: {
    path: "/users/<slug:username>/settings/set-spend-limit";
    args: [params: { username: string }];
  };
  version_detail: {
    path: "/<slug:username>/<str:name>/versions/<str:docker_image_id>";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
  version_list: {
    path: "/<slug:username>/<str:name>/versions";
    args: [params: { username: string; name: string }];
  };
  version_setup_logs: {
    path: "/<slug:username>/<str:name>/versions/<str:docker_image_id>/setup-logs";
    args: [params: { username: string; name: string; docker_image_id: string }];
  };
};

/** Map of route name to template path */
export const map: Record<keyof RouteMeta, string> = {
  about: "/about",
  account_api_token_settings: "/account/api-tokens",
  account_billing_settings: "/account/billing",
  account_detail: "/<slug:username>",
  account_email_settings: "/account/email",
  account_members_settings: "/account/members",
  account_profile_settings: "/account/profile",
  account_settings: "/account",
  account_setup_shared_slack_channel: "/account/slack-integration/connect",
  account_vercel_settings: "/account/vercel-integration",
  api_clear_needs_informing_about_end_of_subscriptions:
    "/api/users/<slug:username>/clear-needs-informing-about-end-of-subscriptions",
  api_create_support_case: "/api/create-support-case",
  api_deployment_create: "/api/deployments",
  api_deployment_edit_or_delete: "/api/deployments/<slug:username>/<slug:name>",
  api_deployment_instance_time_metrics:
    "/api/deployments/<slug:username>/<slug:name>/metrics/instance-time",
  api_deployment_instance_time_metrics_with_period:
    "/api/deployments/<slug:username>/<slug:name>/metrics/instance-time/<str:period>",
  api_deployment_prediction_create:
    "/api/deployments/<slug:username>/<slug:name>/predictions",
  api_dismiss_account_rename: "/api/users/<slug:username>/dismiss-rename",
  api_example_create: "/api/models/<slug:username>/<str:name>/examples",
  api_model_delete: "/api/models/<slug:username>/<str:name>",
  api_model_display_settings_update:
    "/api/models/<slug:username>/<str:name>/display-settings",
  api_model_training_display_settings_update:
    "/api/models/<slug:username>/<str:name>/training-display-settings",
  api_model_versions: "/api/models/<slug:username>/<str:name>/versions",
  api_official_model_prediction_create:
    "/api/models/<slug:username>/<str:name>/predictions",
  api_organization_create_token:
    "/api/organizations/<slug:organization_name>/tokens",
  api_organization_disable_token:
    "/api/organizations/<slug:organization_name>/tokens/<str:token_hashid>/disable",
  api_organization_get_invoice:
    "/api/organizations/<slug:organization_name>/invoices/<slug:invoice_id>",
  api_organization_list_invoices:
    "/api/organizations/<slug:organization_name>/invoices",
  api_organization_list_payments:
    "/api/organizations/<slug:organization_name>/payments",
  api_prediction_delete: "/api/predictions/<slug:prediction_uuid>/delete",
  api_prediction_detail: "/api/predictions/<slug:prediction_uuid>",
  api_prediction_share: "/api/predictions/<str:prediction_uuid>/share",
  api_prediction_stop: "/api/predictions/<slug:prediction_uuid>/stop",
  api_rename_account: "/api/users/<slug:username>/rename",
  api_search: "/api/search",
  api_search_deployment_model_options: "/api/deployments/search-model-options",
  api_set_principal: "/api/principal",
  api_star_model: "/api/models/<slug:username>/<str:name>/star",
  api_theme: "/api/theme",
  api_unstar_model: "/api/models/<slug:username>/<str:name>/unstar",
  api_user_create_token: "/api/users/<slug:username>/tokens",
  api_user_disable_token:
    "/api/users/<slug:username>/tokens/<str:token_hashid>/disable",
  api_user_get_invoice: "/api/users/<slug:username>/invoices/<slug:invoice_id>",
  api_user_list_invoices: "/api/users/<slug:username>/invoices",
  api_user_list_payments: "/api/users/<slug:username>/payments",
  api_version_capabilities:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/capabilities",
  api_version_delete:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/delete",
  api_version_docker_image:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/docker-image",
  api_version_input_hints:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/input-hints",
  api_version_prediction_create:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/predictions",
  api_version_prediction_price:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/prediction-price",
  api_version_training_create:
    "/api/models/<slug:username>/<str:name>/versions/<str:docker_image_id>/trainings",
  api_webhook_signing_key: "/api/webhooks/default/secret",
  blog_detail: "/blog/<str:slug>",
  blog_index: "/blog",
  changelog_detail: "/changelog/<slug:slug>",
  changelog_list: "/changelog",
  changelog_preview: "/changelog/<str:id>/preview",
  collection_detail: "/collections/<str:slug>",
  collection_list: "/collections",
  deployment_api_reference: "/deployments/<slug:username>/<slug:name>/api",
  deployment_create: "/deployments/create",
  deployment_detail: "/deployments/<slug:username>/<slug:name>",
  deployment_list: "/deployments",
  deployment_prediction_list:
    "/deployments/<slug:username>/<slug:name>/predictions",
  deployment_settings: "/deployments/<slug:username>/<slug:name>/settings",
  deployment_setup_logs: "/deployments/<slug:username>/<slug:name>/setup-logs",
  docs_arxiv: "/docs/arxiv",
  docs_arxiv_about: "/docs/arxiv/about",
  example_delete:
    "/<slug:username>/<str:name>/examples/<str:prediction_uuid>/delete",
  example_list: "/<slug:username>/<str:name>/examples",
  example_set_default:
    "/<slug:username>/<str:name>/examples/<str:prediction_uuid>/set-default",
  explore: "/explore",
  file_upload: "/api/upload/<str:filename>",
  home: "/",
  invite_accept: "/invites/<str:pk>/accept",
  invite_detail: "/invites/<str:pk>",
  join: "/join",
  model_api_reference: "/<slug:username>/<str:name>/api",
  model_badge: "/<slug:username>/<str:name>/badge",
  model_create: "/create",
  model_deploy: "/<slug:username>/<str:name>/deploy",
  model_detail: "/<slug:username>/<str:name>",
  model_list: "/models",
  model_status: "/<slug:username>/<str:name>/status",
  model_train: "/<slug:username>/<str:name>/train",
  model_update: "/<slug:username>/<str:name>/edit",
  newsletter_detail: "/newsletter/<str:slug>",
  newsletter_index: "/newsletter",
  newsletter_signup: "/newsletter/signup",
  organization_billing_canceled:
    "/orgs/<slug:organization_name>/settings/billing/canceled",
  organization_billing_edit:
    "/orgs/<slug:organization_name>/settings/billing/edit",
  organization_billing_new:
    "/orgs/<slug:organization_name>/settings/billing/new",
  organization_billing_succeeded:
    "/orgs/<slug:organization_name>/settings/billing/succeeded",
  organization_connect: "/orgs/connect/<slug:organization_name>",
  organization_create_invite:
    "/orgs/<slug:organization_name>/settings/members/invite/new",
  organization_create_or_join: "/orgs/join",
  organization_email_invite:
    "/orgs/<slug:organization_name>/settings/members/invite/email",
  organization_invoice_detail:
    "/orgs/<slug:organization_name>/invoices/<slug:invoice_id>",
  organization_remove_member:
    "/orgs/<slug:organization_name>/settings/members/<slug:member_username>/delete",
  organization_set_email: "/orgs/<slug:organization_name>/settings/set-email",
  organization_set_spend_limit:
    "/orgs/<slug:organization_name>/settings/set-spend-limit",
  prediction_detail: "/p/<str:prediction_uuid>",
  prediction_list: "/predictions",
  pricing: "/pricing",
  privacy: "/privacy",
  report_prediction_output: "/p/<str:prediction_uuid>/report",
  search: "/search",
  showcase: "/showcase",
  signin: "/signin",
  signout: "/signout",
  social_begin: "/login/github/",
  star_list: "/<slug:username>/stars",
  support: "/support",
  terms: "/terms",
  training_list: "/trainings",
  user_billing_canceled: "/users/<slug:username>/settings/billing/canceled",
  user_billing_edit: "/users/<slug:username>/settings/billing/edit",
  user_billing_new: "/users/<slug:username>/settings/billing/new",
  user_billing_succeeded: "/users/<slug:username>/settings/billing/succeeded",
  user_invoice_detail: "/users/<slug:username>/invoices/<slug:invoice_id>",
  user_set_email: "/users/<slug:username>/settings/set-email",
  user_set_provenance: "/users/<slug:username>/settings/set-provenance",
  user_set_spend_limit: "/users/<slug:username>/settings/set-spend-limit",
  version_detail: "/<slug:username>/<str:name>/versions/<str:docker_image_id>",
  version_list: "/<slug:username>/<str:name>/versions",
  version_setup_logs:
    "/<slug:username>/<str:name>/versions/<str:docker_image_id>/setup-logs",
};
