import { Info, TextT } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useMediaMatch } from "../../hooks";
import { Label } from "./label";

export function TextInput({
  disabled,
  name,
  onSubmit,
  required,
  type,
  placeholder,
}: {
  disabled: boolean;
  name: string;
  onSubmit?: () => void;
  required: boolean;
  type: "string" | "string | string[]";
  placeholder?: string;
}) {
  // Corresponds with "md:" Tailwind breakpoint
  const isDesktopScreen = useMediaMatch("(min-width: 768px)");

  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }, []);

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:items-center md:justify-between">
        <Label type={type} Icon={TextT} required={required} name={name} />
        {onSubmit && isDesktopScreen && (
          <span
            className={`text-r8-xs text-r8-gray-11 transition-all ${
              field.value
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-1"
            }`}
          >
            <span translate="no">
              <kbd className="bg-r8-gray-1 border py-px px-1">Shift</kbd> +{" "}
              <kbd className="bg-r8-gray-1 border py-px px-1">Return</kbd>
            </span>{" "}
            to add a new line
          </span>
        )}
      </div>
      <ReactTextareaAutosize
        id={name}
        dir="auto"
        maxRows={15}
        placeholder={placeholder}
        required={required}
        disabled={formState.isSubmitting || disabled}
        onKeyDown={(e) => {
          const isRegularEnter = e.key === "Enter" && !e.shiftKey;
          if (!isDesktopScreen) return;
          if (!isRegularEnter) return;
          if (onSubmit) {
            e.preventDefault();
            onSubmit();
          }
        }}
        className="border p-2 bg-white dark:bg-r8-gray-1 border-r8-gray-12 w-full resize-none disabled:cursor-not-allowed disabled:opacity-50"
        {...field}
        // Casting the value to a string is necessary to avoid this runtime error:
        // - Warning: `value` prop on `input` should not be null.
        // - Consider using an empty string to clear the component or `undefined` for uncontrolled components.
        value={field.value ?? ""}
        onChange={(e) => {
          const value = e.target.value;
          field.onChange(value || null);
        }}
        autoComplete="off"
        data-1p-ignore /* https://developer.1password.com/docs/web/compatible-website-design/#build-logical-forms */
      />
      {/*
        Prevents a potential clash with numerical values
        being specified in the JSON editor, which don't have
        the "includes" method and will hence throw an error.
      */}
      {typeof field.value === "string" && field.value?.includes("\\") && (
        <Banner
          icon={<Info />}
          condensed
          description={
            <p>
              Backslashes aren't interpreted as an escape sequence. For example,{" "}
              <code className="p-0 bg-transparent">"\n"</code> is two
              characters, not a newline.
            </p>
          }
        />
      )}
    </div>
  );
}
