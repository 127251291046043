import { MenuItem } from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import { switchAccount } from ".";
import type { AccountWithAvatar } from "../../types";
import { Avatar } from "../avatar";

export function SwitchAccountMenuItem({
  account,
}: { account: AccountWithAvatar }) {
  const mutation = useMutation({
    mutationFn: switchAccount,
    onSuccess: () => {
      const redirect = new URL(window.location.href);
      // Hint to the backend that we're switching accounts.
      redirect.searchParams.set("action", "switch-account");
      window.location.href = redirect.href;
    },
  });

  return (
    <MenuItem
      key={account.username}
      onClick={() => {
        mutation.mutate({ account });
      }}
    >
      <div className="flex items-center gap-2">
        <div className="w-6 h-6 flex-shrink-0">
          <Avatar
            avatar={{
              kind: account.kind,
              url: account.avatar_url,
            }}
          />
        </div>
        <span>{account.username}</span>
      </div>
    </MenuItem>
  );
}
