import { ArrowsClockwise, Camera, X } from "@phosphor-icons/react";
import { IconButton } from "@replicate/ui";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { ValueFromFile } from "./file-input";
import type { WebcamInputProps } from "./webcam-input";

export function WebcamInputV2({
  name,
  onChange,
  disabled,
  onCancel,
}: WebcamInputProps & { onCancel: () => void }) {
  const [captured, setCaptured] = useState<File | null>(null);
  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);
  const [webcamStatus, setWebcamStatus] = useState<
    "disabled" | "enabled" | "disallowed" | "captured"
  >("enabled");

  const handleWebcamCapture = useCallback(() => {
    const canvas = webcamRef.current?.getCanvas();
    if (!canvas) return;

    canvas.toBlob((blob) => {
      if (!blob) return;
      const file = new File([blob], "webcam.png", { type: "image/png" });
      onChange(file);
      setWebcamStatus("captured");
      setCaptured(file);
    });
  }, [onChange]);

  return (
    <div className="border border-r8-gray-12">
      <div className="flex items-center justify-between bg-r8-gray-12">
        <div aria-hidden className="flex-1" />
        <div className="flex-1 text-center">
          <p className="text-r8-xs font-semibold uppercase tracking-wide text-white">
            Photo booth
          </p>
        </div>
        <div className="bg-r8-gray-12 p-2 flex-1 flex justify-end text-white">
          <IconButton
            size="sm"
            onClick={() => {
              onCancel();
            }}
          >
            <X />
          </IconButton>
        </div>
      </div>
      {webcamStatus === "disallowed" && (
        <button
          type="button"
          disabled={disabled}
          onClick={() => {
            setWebcamStatus("enabled");
          }}
          className="p-4 w-full bg-red/10 h-full border-2 border-transparent hover:border-red"
        >
          <p className="text-r8-sm text-red">
            Webcam access is disabled. <br />
            Please enable it in your browser and try again.
          </p>
        </button>
      )}
      {webcamStatus === "disabled" && (
        <button
          disabled={disabled}
          onClick={() => {
            setWebcamStatus("enabled");
          }}
          type="button"
          className="bg-r8-gray-2 hover:border-r8-gray-9 disabled:hover:border-r8-gray-7 disabled:cursor-not-allowed"
        >
          <p className="text-r8-sm text-r8-gray-11 select-none flex items-center gap-2">
            <Camera aria-hidden size={16} />
            Enable webcam
          </p>
        </button>
      )}
      {webcamStatus === "enabled" && (
        <div className="flex flex-col">
          <Webcam
            audio={false}
            data-testid={`webcam-${name}`}
            ref={webcamRef}
            className="bg-r8-gray-10"
            onUserMediaError={() => {
              setWebcamStatus("disallowed");
            }}
          />
          <div className="flex items-center justify-center bg-r8-gray-12 py-2">
            <button
              onClick={handleWebcamCapture}
              className="rounded-full w-10 h-10 flex items-center justify-center bg-gradient-to-br from-branding-red via-branding-blushDarker to-branding-pinkDarker"
              type="button"
            >
              <Camera size={20} fill="white" weight="fill" />
            </button>
          </div>
        </div>
      )}
      {webcamStatus === "captured" && captured && (
        <div className="flex flex-col">
          <ValueFromFile name={name} file={captured} />
          <div className="flex items-center justify-center bg-r8-gray-12 py-2">
            <button
              onClick={() => {
                setWebcamStatus("enabled");
              }}
              className="rounded-full w-10 h-10 flex items-center justify-center bg-gradient-to-br from-branding-red via-branding-blushDarker to-branding-pinkDarker"
              type="button"
            >
              <ArrowsClockwise size={20} fill="white" weight="fill" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
