import { Tab, TabList, TabPanel, TabProvider } from "@replicate/ui";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryParam } from "../hooks";
import type {
  AccessToken,
  Features,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../types";
import { deconstructVersionFullName } from "../util";
import {
  PlaygroundContext,
  usePlaygroundContext,
  type PlaygroundContextValue,
} from "./api-playground/context";
import { EntityMeta, PredictionMetaItems } from "./api-playground/entity-meta";
import { queryKeys, usePrediction } from "./api-playground/hooks";
import { InputOutputGrid } from "./api-playground/input-output-grid";
import { InputOverview } from "./api-playground/input-overview";
import { PredictionErrorState } from "./api-playground/prediction-error-state";
import { PredictionLoadingState } from "./api-playground/prediction-loading-state";
import { getElementVisibility, getRenderMode } from "./api-playground/util";
import CodeBlock from "./code-block";
import { TrainingDetail } from "./training-detail";

export function TrainingInner({
  training,
  showHeader,
}: {
  training: Prediction;
  showHeader?: boolean;
}) {
  const DEFAULT_TAB = "form";
  const [activeTabId, setActiveTabId] = useQueryParam("input", DEFAULT_TAB);
  const { version } = usePlaygroundContext();

  const query = usePrediction({
    uuid: training.id,
  });

  const output = query.data?.output;
  const trainedVersion =
    typeof output === "object" &&
    !Array.isArray(output) &&
    output?.version &&
    typeof output.version === "string"
      ? deconstructVersionFullName(output.version)
      : null;

  return (
    <>
      {showHeader ? (
        <header className="mb-4">
          <h1 className="text-r8-3xl">Training</h1>
          <div className="inline-flex flex-wrap items-center gap-2">
            {query.data && (
              <>
                <a
                  href={version._extras.url}
                  className="text-r8-lg text-r8-gray-11"
                >
                  {version._extras.name}
                </a>
                {trainedVersion && (
                  <>
                    <span>→</span>
                    <a
                      href={trainedVersion._extras.url}
                      className="text-r8-lg text-r8-gray-11"
                    >
                      {trainedVersion._extras.name}
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        </header>
      ) : null}
      <div className="prediction-detail">
        <div className="py-4 md:px-0 prediction-meta">
          <EntityMeta>
            <PredictionMetaItems.Model
              prediction={query.data}
              loading={query.isPending}
            />
            {trainedVersion ? (
              <EntityMeta.Item>
                <EntityMeta.ItemLabel>Trained version</EntityMeta.ItemLabel>
                <EntityMeta.ItemValue>
                  <a href={trainedVersion._extras.url}>
                    {trainedVersion._extras.name}
                  </a>
                </EntityMeta.ItemValue>
              </EntityMeta.Item>
            ) : null}
            <PredictionMetaItems.Id
              prediction={query.data}
              loading={query.isPending}
            />
            <PredictionMetaItems.Status
              prediction={query.data}
              loading={query.isPending}
            />
            <PredictionMetaItems.Source
              prediction={query.data}
              loading={query.isPending}
            />
            <PredictionMetaItems.Hardware
              prediction={query.data}
              loading={query.isPending}
            />
            <PredictionMetaItems.TotalDuration
              prediction={query.data}
              loading={query.isPending}
            />
            <PredictionMetaItems.Created
              prediction={query.data}
              loading={query.isPending}
            />
          </EntityMeta>
        </div>
        <InputOutputGrid
          mobileOutputFirst
          input={
            query.data ? (
              <TabProvider
                defaultActiveId={DEFAULT_TAB}
                activeId={activeTabId}
                setActiveId={(id) => setActiveTabId(id ?? undefined)}
                selectedId={activeTabId ?? DEFAULT_TAB}
              >
                <TabList size="sm">
                  <Tab id="form">Form</Tab>
                  <Tab id="json">JSON</Tab>
                </TabList>
                <TabPanel className="py-2">
                  <InputOverview
                    alwaysRenderURLsAsDownload={
                      query.data._extras.may_have_sensitive_output
                    }
                    properties={
                      version._extras.dereferenced_openapi_schema.components
                        .schemas.TrainingInput?.properties
                    }
                    input={query.data.input}
                    reportFallback={
                      // TODO: When we reliably include training input in the
                      // schema, report fallbacks for "runnable" versions (or
                      // should it be "trainable" versions?)
                      Boolean(
                        version._extras.dereferenced_openapi_schema.components
                          .schemas.TrainingInput
                      )
                    }
                  />
                </TabPanel>
                <TabPanel className="py-2">
                  <CodeBlock
                    language="json"
                    textContent={JSON.stringify(query.data.input, null, 2)}
                  />
                </TabPanel>
              </TabProvider>
            ) : null
          }
          output={
            <>
              {query.status === "pending" && (
                <div className="pb-4">
                  <PredictionLoadingState>Starting...</PredictionLoadingState>
                </div>
              )}
              {query.status === "error" && (
                <div className="pb-4">
                  <PredictionErrorState
                    error={query.error}
                    fallback="Failed to fetch training."
                  />
                </div>
              )}
              {query.status === "success" && (
                <TrainingDetail training={query.data} />
              )}
            </>
          }
        />
      </div>
    </>
  );
}

export default function Training({
  features,
  isAuthenticated,
  permissions,
  token,
  training,
  version,
}: {
  features: Features;
  isAuthenticated: boolean;
  permissions: PlaygroundPermissions;
  token: AccessToken;
  training: Prediction;
  version: Version;
}) {
  const queryClient = useQueryClient();
  if (training) {
    queryClient.setQueryData<Prediction>(
      queryKeys.predictions.uuid(training.id),
      training
    );
  }

  const context: PlaygroundContextValue = {
    elementVisibility: getElementVisibility({ permissions, version }),
    features,
    hideAdvancedInputs: false,
    hideVersionMismatchWarning: false,
    isAuthenticated,
    modelStatus: null,
    permissions,
    renderMode: getRenderMode({ features }),
    token: token ?? null,
    version: version,
    modelInputSettings: { hidden: [] },
  };

  return (
    <PlaygroundContext.Provider value={context}>
      <TrainingInner training={training} showHeader />
    </PlaygroundContext.Provider>
  );
}
