import { CaretDown } from "@phosphor-icons/react";
import {
  Badge,
  Button,
  Select,
  SelectGroup,
  SelectGroupLabel,
  SelectItem,
  SelectItemCheck,
  SelectPopover,
  SelectProvider,
} from "@replicate/ui";
import { useQuery } from "@tanstack/react-query";
import { History } from "lucide-react";
import type { Version } from "../types";
import { route } from "../urls";
import { RelativeTime } from "./relative-time";
import { VersionIcons } from "./version-icons";

export default function ModelVersionPicker({
  selectedTab,
  version,
}: {
  selectedTab?: "playground" | "setup-logs";
  version: Version;
}) {
  const routeName =
    selectedTab === "setup-logs" ? "version_setup_logs" : "version_detail";

  const query = useQuery({
    queryFn: async () => {
      const res = await fetch(
        route("api_model_versions", {
          username: version._extras.model.owner,
          name: version._extras.model.name,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) throw new Error("Failed to fetch model versions");
      return await res.json<{ data: Version[]; has_more: boolean }>();
    },
    queryKey: [
      "api_model_versions",
      version._extras.model.owner,
      version._extras.model.name,
    ],
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="flex items-center gap-4">
      <div>
        <label
          className="text-r8-sm text-r8-gray-11 mb-1 block"
          htmlFor="model-version-picker"
        >
          Version
        </label>
        <SelectProvider
          placement="bottom-start"
          defaultValue={version._extras.short_id}
          focusLoop
          setValue={(shortId) => {
            const newVersion = query.data?.data.find(
              (v) => v._extras.short_id === shortId
            );
            if (!newVersion) {
              return;
            }
            let path = route(routeName, {
              username: version._extras.model.owner,
              name: version._extras.model.name,
              docker_image_id: newVersion.id,
            });

            // TODO(explore): Clean this up when we've decided on the experiment.
            if (window.location.pathname.startsWith("/models")) {
              path = `/models${path}`;
            }

            window.location.href = path;
          }}
        >
          <Select
            id="model-version-picker"
            disabled={query.status === "pending"}
            render={
              <Button
                variant="outlined"
                startIcon={<History />}
                endIcon={<CaretDown weight="bold" />}
              >
                {version._extras.short_id}
                {version.id === version._extras.model.latest_version?.id && (
                  <Badge variant="success" size="sm" rounded>
                    Latest
                  </Badge>
                )}
              </Button>
            }
          />
          <SelectPopover
            disabled={query.status === "pending"}
            gutter={8}
            className="w-96 max-h-72 overflow-auto z-10"
          >
            <SelectGroup>
              <SelectGroupLabel>Versions</SelectGroupLabel>
              {query.isSuccess ? (
                <>
                  {query.data?.data.map((v) => {
                    return (
                      <SelectItem
                        key={v._extras.short_id}
                        id={v._extras.short_id}
                        value={v._extras.short_id}
                        className="relative"
                      >
                        <div className="flex items-center justify-between gap-4 w-full">
                          <SelectItemCheck
                            checked={
                              v._extras.short_id === version._extras.short_id
                            }
                          />
                          <div className="flex items-center gap-2">
                            <span>{v._extras.short_id}</span>
                            {v.id === v._extras.model.latest_version?.id && (
                              <Badge variant="success" size="sm" rounded>
                                Latest
                              </Badge>
                            )}
                          </div>
                          <RelativeTime
                            className="text-r8-sm opacity-70"
                            dateTime={new Date(v.created_at)}
                          />
                        </div>
                      </SelectItem>
                    );
                  })}
                  {query.data?.has_more && (
                    <SelectItem
                      render={
                        // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
                        <a
                          href={route("version_list", {
                            username: version._extras.model.owner,
                            name: version._extras.model.name,
                          })}
                        />
                      }
                    >
                      Show all versions
                    </SelectItem>
                  )}
                </>
              ) : (
                <div className="p-2">
                  <p className="text-r8-sm text-r8-red-11">
                    Failed to load versions. Head to the{" "}
                    <a
                      className="no-focus"
                      href={route("version_list", {
                        username: version._extras.model.owner,
                        name: version._extras.model.name,
                      })}
                    >
                      versions page
                    </a>{" "}
                    to see all versions for this model.
                  </p>
                </div>
              )}
            </SelectGroup>
          </SelectPopover>
        </SelectProvider>
      </div>
      <div className="pt-6">
        <VersionIcons version={version} />
      </div>
    </div>
  );
}
