import { RocketLaunch } from "@phosphor-icons/react";
import { Banner, Button } from "@replicate/ui";
import { useCallback, useState, type FormEvent, type ReactNode } from "react";

export default function DismissableBanner({
  title,
  description,
  callToAction,
  href,
  id,
}: {
  title: string;
  description: string;
  callToAction: string;
  href: string;
  id: string;
}): ReactNode {
  const [dismissed, setDismissed] = useState(false);
  const dismissForNow = useCallback(
    (event?: FormEvent<HTMLFormElement>) => {
      if (event) {
        event.preventDefault();
      }

      setDismissed(true);
      localStorage.setItem(`dismiss-${id}`, "true");
    },
    [id]
  );

  if (localStorage.getItem(`dismiss-${id}`) === "true" || dismissed) {
    return null;
  }

  return (
    <Banner
      description={
        <div className="content-container flex items-center gap-2 flex-wrap">
          <div className="flex gap-2 items-center">
            <RocketLaunch className="flex-shrink-0" weight="duotone" />{" "}
            <p className="font-bold">{title}</p>
            <p>{description}</p>
          </div>

          {/* biome-ignore lint/a11y/useAnchorContent: content is injected */}
          <Button intent="warning" size="xs" render={<a href={href} />}>
            {callToAction}
          </Button>
        </div>
      }
      dismissible
      flushTop
      centered
      fullWidth
      severity="warning"
      onDismiss={dismissForNow}
    />
  );
}
