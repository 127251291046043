import { Download } from "@phosphor-icons/react";
import toast from "react-hot-toast";

export function DownloadLogsButton({
  logs,
  filename,
}: { filename: string; logs: string | null }) {
  const handleDownload = () => {
    if (!logs) {
      toast("No logs to download!");
      return;
    }
    try {
      const blob = new Blob([logs], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${filename}.txt`;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();
    } catch (e) {
      toast.error("Failed to download logs");
    }
  };

  return (
    <button
      type="button"
      onClick={handleDownload}
      className="text-r8-sm hover:bg-r8-gray-a3 text-r8-gray-11 px-2 py-1 inline-flex items-center gap-2"
    >
      <span className="flex-shrink-0">
        <Download />
      </span>
      <span>Download logs</span>
    </button>
  );
}
