import type React from "react";
import GlyphLoader from "../glyph-loader";

interface InputOutputGridProps {
  input: React.ReactNode;
  inputHeadingExtra?: React.ReactNode;
  output: React.ReactNode;
  stickyOutput?: boolean;
  outputIsLoading?: boolean;
  mobileOutputFirst?: boolean;
}

export function InputOutputGrid({
  input,
  output,
  inputHeadingExtra,
  outputIsLoading = false,
  stickyOutput = false,
  mobileOutputFirst = false,
}: InputOutputGridProps) {
  return (
    <div className="input-output-grid">
      <div
        className={`flex ${
          mobileOutputFirst ? "flex-col-reverse" : "flex-col"
        } md:flex-row`}
      >
        <div className="input-col relative md:flex-1 pb-4 min-w-0">
          <div className="mb-2 flex items-center justify-between">
            <div className="flex-1">
              <h2 className="!my-0 text-r8-2xl">Input</h2>
            </div>
            {inputHeadingExtra}
          </div>
          {input}
        </div>
        <div
          className="input-output-grid--divider flex-shrink-0 md:mx-6 mb-6 md:my-0"
          // biome-ignore lint/a11y/useAriaPropsForRole: Default values for "missing" aria attributes apply according to https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/roles/separator_role
          role="separator"
        >
          <div className="bg-r8-gray-6 md:w-px md:h-full h-px w-full" />
        </div>
        <div className="output-col md:flex-1 pb-4 min-w-0">
          <div
            className={`flex flex-col ${stickyOutput ? "sticky top-20" : ""}`}
          >
            <div className="mb-2">
              <div className="flex items-center gap-2">
                <h2 className="!my-0 text-r8-2xl">Output</h2>
                {outputIsLoading && (
                  <GlyphLoader screenReaderLabel="Output is loading" />
                )}
              </div>
            </div>
            {output}
          </div>
        </div>
      </div>
    </div>
  );
}
