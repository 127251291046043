import * as PhosphorIcons from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import type React from "react";

export default function IconTooltip({
  icon,
  tooltipText,
  tooltipClass = "",
  anchorClass = "",
  placement = "top",
}: {
  icon: string;
  tooltipText: React.ReactNode;
  tooltipClass?: string;
  anchorClass?: string;
  placement?: "top" | "bottom" | "left" | "right";
}) {
  const Icon = PhosphorIcons[icon];
  if (!Icon) {
    console.warn(`${icon} not found`);
    return null;
  }
  return (
    <TooltipProvider placement={placement} showTimeout={0}>
      <TooltipAnchor
        render={<Icon weight="fill" className={`inline ${anchorClass}`} />}
      />
      <Tooltip className={`max-w-48 text-r8-xs z-50 ${tooltipClass}`}>
        <TooltipArrow className="fill-black dark:fill-white" />
        {tooltipText}
      </Tooltip>
    </TooltipProvider>
  );
}
